import Sidebar from 'dashboard/components/sidebar/Sidebar';
import { NotificationCenter } from './NotificationCenter';
import { useLocation } from 'react-router-dom';
import Content from './Content';

type Props = {
  children?: React.ReactNode;
  dashboardHeader?: React.ReactNode;
  dashboardMainHeaderTitle?: React.ReactNode;
};

export default function Dashboard({
  children,
  dashboardHeader,
  dashboardMainHeaderTitle,
}: Props) {
  const location = useLocation();
  const DASHBOARD_ROUTE = '/dashboard';

  return (
    <div className="h-screen flex overflow-hidden bg-gray-dark800 scrollbar">
      <Sidebar />
      {process.env.NODE_ENV !== 'test' && <NotificationCenter />}

      <section className="flex flex-col w-0 flex-1 pt-8 overflow-hidden scrollbar">
        {dashboardHeader}

        {location.pathname === DASHBOARD_ROUTE && <Content />}

        <main className="flex-1 relative overflow-y-auto focus:outline-none scrollbar">
          {dashboardMainHeaderTitle}
          <div
            className="w-full mx-auto py-6 px-4 flex flex-col"
            style={{
              padding: '0 6rem 4rem 6rem',
            }}
          >
            {children}
          </div>
        </main>
      </section>
    </div>
  );
}
