import { SyntheticEvent } from 'react';
import { MdDone } from 'react-icons/md';
import { Tooltip } from 'ui/components/tooltip';
import { TableBodyColumn } from 'ui/components/table';
import TableBodyRow from 'ui/components/table/TableBodyRow';
import { GenerationUnit } from 'generationUnits/models/generationUnit';
import { EditButton, RemoveButton } from 'dashboard/components/table/buttons';
import { CommercialAgreement } from 'commercialAgreements/models/commercialAgreement';
import { TRANSLATE_RECEIPT_RULE_TO_PORTUGUESE } from 'commercialAgreements/components/table/CommercialAgreementsTableItem';
import SuccessIcon from 'assets/icons/list/SuccessIcon';
import UnavailableIcon from 'assets/icons/list/UnavailableIcon';
import { formatDateToLocaleDateString } from 'utils/form';
import { Link, useHistory } from 'react-router-dom';

type Props = {
  generationUnit: GenerationUnit;
  onClickEditButton: (id: number) => void;
  onClickRemoveButton: (id: number) => void;
  disableEditButton: boolean;
  disableRemoveButton: boolean;
};

const DASHBOARD_COMMERCIAL_AGREEMENTS_CREATE =
  '/dashboard/billing-cycle/commercial-agreements/create';

const DASHBOARD_COMMERCIAL_AGREEMENTS =
  '/dashboard/billing-cycle/commercial-agreements';

export default function GenerationUnitsTableItem({
  generationUnit: {
    id,
    generationUnitLegalName,
    generationUnitCapacityFactorAverage,
    generationUnitEnergySource,
    generationUnitAddressState,
    generationUnitPowerCapacity,
    powerDistributionUnit: { powerDistributionUnitLegalName },
    cooperative: { cooperativeLegalName },
    financialAccount: { financialAccountLegalName },
    commercialAgreements = {} as CommercialAgreement,
  },
  onClickEditButton,
  onClickRemoveButton,
  disableEditButton,
  disableRemoveButton,
}: Props) {
  const { push } = useHistory();

  const handleClickEditButton = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    onClickEditButton(id);
  };

  const handleClickRemoveButton = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    onClickRemoveButton(id);
  };

  const GenerationUnitEnergySource = {
    UTE: 'Termelétrica',
    EOL: 'Eólica',
    UFV: 'Solar',
    CGH: 'Hidráulica',
  };

  const icmsValue = 'SIM';

  const commercialAgreementHasOemValue =
    (commercialAgreements?.commercialAgreementHasOem && 'Discriminação de O&M') ||
    'Nenhuma seleção';

  const commercialAgreementHasExtraRemunerationValue =
    (commercialAgreements?.commercialAgreementHasExtraRemuneration && 'SIM') ||
    'NÃO';

  const commercialAgreementReceiptRuleValue =
    TRANSLATE_RECEIPT_RULE_TO_PORTUGUESE[
      commercialAgreements?.commercialAgreementReceiptRule
    ];

  const commercialAgreementHasPisCofinsValue =
    (commercialAgreements?.commercialAgreementHasPisCofins && 'SIM') || 'NÃO';

  return (
    <TableBodyRow dataTestId={`generation-unit-row-id-${id}`}>
      <TableBodyColumn>
        <Tooltip
          label={
            <>
              <p>
                Fonte:{' '}
                <span className="text-sx font-bold">
                  {GenerationUnitEnergySource[generationUnitEnergySource]}
                </span>
              </p>
              <p>
                Potência:{' '}
                <span className="text-sx font-bold">
                  {generationUnitPowerCapacity} kW
                </span>
              </p>
              <p>
                FC:{' '}
                <span className="text-sx font-bold">
                  {generationUnitCapacityFactorAverage}
                </span>
              </p>
            </>
          }
          placement="bottom"
          arrowPosition="top"
        >
          {generationUnitLegalName}
        </Tooltip>
      </TableBodyColumn>
      <TableBodyColumn className="font-normal">
        {powerDistributionUnitLegalName}
      </TableBodyColumn>
      <TableBodyColumn className="font-normal">
        {generationUnitAddressState}
      </TableBodyColumn>
      <TableBodyColumn className="font-normal">
        {cooperativeLegalName}
      </TableBodyColumn>
      <TableBodyColumn className="text-center">-</TableBodyColumn>
      <TableBodyColumn className="font-normal">
        {financialAccountLegalName}
      </TableBodyColumn>
      <TableBodyColumn className="text-center">-</TableBodyColumn>
      <TableBodyColumn>
        {!!commercialAgreements ? (
          <Tooltip
            label={
              <div className="flex flex-col items-start justify-center gap-1">
                <div className="flex flex-col items-start justify-center gap-1">
                  <p className="font-medium text-sm py-1 leading-3">
                    Vigente desde:
                  </p>
                  <p className="font-bold text-sm">
                    {formatDateToLocaleDateString(
                      commercialAgreements?.commercialAgreementStartValidity as unknown as string,
                      {
                        year: 'numeric',
                        month: '2-digit',
                      }
                    )}
                  </p>
                </div>
                <div className="flex flex-col items-start justify-center gap-1">
                  <p className="font-medium text-sm py-1 leading-3">
                    Política de recebimento:
                  </p>
                  <p className="font-bold text-sm leading-3 flex items-center justify-start">
                    <MdDone size={18} className="inline-block mr-2" />
                    {commercialAgreementReceiptRuleValue}
                  </p>
                </div>
                <div className="flex flex-col items-start justify-center gap-1">
                  <p className="font-medium text-sm py-1 leading-3">
                    Remuneração adicional:
                  </p>
                  <p className="font-light text-sm pb-1 leading-3">
                    Geração ponta:
                    <span className="font-bold p-2">
                      {commercialAgreementHasExtraRemunerationValue}
                    </span>
                  </p>
                  <p className="font-light text-sm pb-1 leading-3">
                    Isenção de PIS/COFINS:
                    <span className="font-bold p-2">
                      {commercialAgreementHasPisCofinsValue}
                    </span>
                  </p>
                  <p className="font-light text-sm pb-1 leading-3">
                    Isenção de ICMS:
                    <span className="font-bold p-2">{icmsValue}</span>
                  </p>
                </div>
                <div className="flex flex-col items-start justify-center gap-1">
                  <p className="font-medium text-sm py-1 leading-3">
                    Custo operacional:
                  </p>
                  <p className="font-bold text-sm leading-3 flex items-center justify-start">
                    <MdDone size={18} className="inline-block mr-2" />
                    <span>{commercialAgreementHasOemValue}</span>
                  </p>
                </div>
              </div>
            }
            placement="bottom"
            arrowPosition="top"
          >
            <SuccessIcon
              OnClick={() => {
                push({
                  pathname: DASHBOARD_COMMERCIAL_AGREEMENTS,
                  state: {
                    generationUnitLegalName,
                  },
                });
              }}
            />
          </Tooltip>
        ) : (
          <Tooltip
            placement="bottom"
            arrowPosition="top"
            label={'Nenhum acordo cadastrado'}
          >
            <Link to={DASHBOARD_COMMERCIAL_AGREEMENTS_CREATE}>
              <UnavailableIcon />
            </Link>
          </Tooltip>
        )}
      </TableBodyColumn>
      <TableBodyColumn className="flex gap-x-3 items-end justify-end">
        <EditButton onClick={handleClickEditButton} disabled={disableEditButton} />
        <RemoveButton
          onClick={handleClickRemoveButton}
          disabled={disableRemoveButton}
        />
      </TableBodyColumn>
    </TableBodyRow>
  );
}
