import { IcmsTax } from 'icmsTaxes/models/icmsTaxes';
import IcmsTaxesTableItem from './IcmsTaxesTableItem';
import Table from 'ui/components/table/Table';
import TableHead from 'ui/components/table/TableHead';
import TableHeadColumn from 'ui/components/table/TableHeadColumn';
import TableBody from 'ui/components/table/TableBody';

type Props = {
  icmsTaxes: IcmsTax[];
  onClickEditButton: (id: number) => void;
  onClickRemoveButton: (id: number) => void;
};

export default function IcmsTaxesTable({
  icmsTaxes,
  onClickEditButton,
  onClickRemoveButton,
}: Props) {
  return (
    <Table>
      <TableHead>
        <TableHeadColumn>Tipo</TableHeadColumn>
        <TableHeadColumn className="text-center">UF</TableHeadColumn>
        <TableHeadColumn>Validade</TableHeadColumn>
        <TableHeadColumn>1ª Faixa</TableHeadColumn>
        <TableHeadColumn>2ª Faixa</TableHeadColumn>
        <TableHeadColumn>3ª Faixa</TableHeadColumn>
        <TableHeadColumn>4ª Faixa</TableHeadColumn>
        <TableHeadColumn>5ª Faixa</TableHeadColumn>
        <TableHeadColumn className="text-right">Ações</TableHeadColumn>
      </TableHead>
      <TableBody>
        {icmsTaxes.map((icmsTax) => (
          <IcmsTaxesTableItem
            key={icmsTax.id}
            icmsTax={icmsTax}
            onClickEditButton={onClickEditButton}
            onClickRemoveButton={onClickRemoveButton}
          />
        ))}
      </TableBody>
    </Table>
  );
}
