export type DangerMessageProps = {
  'ERRO/AVISO': 'ERRO' | 'AVISO';
  IDENTIFICAÇÃO: string;
  'DESCRIÇÃO DA MENSAGEM': string;
};

export function handleValidateDangerModal(errors: DangerMessageProps[]) {
  return {
    hasError: errors.find((error) => error['ERRO/AVISO'] === 'ERRO') ? true : false,
    hasWarning: errors.find((error) => error['ERRO/AVISO'] === 'AVISO')
      ? true
      : false,
    hasReplace: errors.find(
      (error) =>
        error['DESCRIÇÃO DA MENSAGEM'] ===
        'UG já tem rateio cadastrado para este ciclo'
    )
      ? true
      : false,
  };
}
