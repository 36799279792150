import { useEffect, useMemo } from 'react';

import {
  useForm,
  Control,
  useWatch,
  FieldError,
  ErrorOption,
  UseFormSetValue,
  UseFormRegister,
} from 'react-hook-form';

import { Button } from 'ui';
import TextField from 'ui/form/TextField';

import {
  MdClose,
  MdAttachFile,
  MdCloudUpload,
  MdRemoveRedEye,
} from 'react-icons/md';

import { convertToDecimal, dateWithTimezone } from 'utils/form';

import {
  BillConsumedEnergyKwh,
  BillExcessEnergyCredits,
  GenerationUnitBillDataId,
  BillConsumedEnergyCredits,
  BillGeneratedEnergyCredits,
  BillConsumedEnergyCreditsTariffs,
} from 'powerDistributionUnits/powerDistributionUnitBillData/generationUnitBillData/models/generationUnitBillData';

import { GenerationUnitConsumptionGroupTypes } from 'generationUnits/models/generationUnit';

import { GenerationUnitLeaseCycle } from 'generationUnits/generationUnitLeaseCycles/models/generationUnitLeaseCycles';

export type FormFields = {
  billFileMetadata: [File];
  id: GenerationUnitBillDataId;
  billDueDate: string;
  billIssueDate: string;
  billReferenceDate: string;
  billNextReadingDate: string;
  billReadingStartedAt: string;
  billReadingFinishedAt: string;
  billTotalValue: string;
  billTotalReadingDays: string;
  billConsumedEnergyKwh: BillConsumedEnergyKwh;
  billExcessEnergyCredits: BillExcessEnergyCredits;
  billConsumedEnergyCredits: BillConsumedEnergyCredits;
  billGeneratedEnergyCredits: BillGeneratedEnergyCredits;
  billConsumedEnergyCreditsTariffs: BillConsumedEnergyCreditsTariffs;
};

type Field =
  | 'billDueDate'
  | 'billIssueDate'
  | 'billTotalValue'
  | 'billFileMetadata'
  | 'billReferenceDate'
  | 'billNextReadingDate'
  | 'billReadingStartedAt'
  | 'billTotalReadingDays'
  | 'billReadingFinishedAt'
  | 'billConsumedEnergyKwh'
  | 'billExcessEnergyCredits'
  | 'billConsumedEnergyCredits'
  | 'billGeneratedEnergyCredits'
  | 'billConsumedEnergyCreditsTariffs';

export const FORM_FIELDS: Field[] = [
  'billFileMetadata',
  'billDueDate',
  'billIssueDate',
  'billReferenceDate',
  'billNextReadingDate',
  'billReadingStartedAt',
  'billReadingFinishedAt',
  'billTotalValue',
  'billTotalReadingDays',
  'billConsumedEnergyKwh',
  'billExcessEnergyCredits',
  'billConsumedEnergyCredits',
  'billGeneratedEnergyCredits',
  'billConsumedEnergyCreditsTariffs',
];

export type FormErrors = {
  billFileMetadata?: [FieldError?];
  id?: FieldError;
  billDueDate?: FieldError;
  billIssueDate?: FieldError;
  billReferenceDate?: FieldError;
  billNextReadingDate?: FieldError;
  billReadingStartedAt?: FieldError;
  billReadingFinishedAt?: FieldError;
  billTotalValue?: FieldError;
  billTotalReadingDays?: FieldError;
  billConsumedEnergyKwh?: {
    consumedEnergyKwhTotal?: FieldError;
    consumedEnergyKwhOnPeak?: FieldError;
    consumedEnergyKwhOffPeak?: FieldError;
  };
  billExcessEnergyCredits?: {
    excessEnergyCreditsTotal?: FieldError;
    excessEnergyCreditsOnPeak?: FieldError;
    excessEnergyCreditsOffPeak?: FieldError;
  };
  billConsumedEnergyCredits?: {
    consumedEnergyCreditsTotal?: FieldError;
    consumedEnergyCreditsOnPeak?: FieldError;
    consumedEnergyCreditsOffPeak?: FieldError;
  };
  billGeneratedEnergyCredits?: {
    generatedEnergyCreditsTotal?: FieldError;
    generatedEnergyCreditsOnPeak?: FieldError;
    generatedEnergyCreditsOffPeak?: FieldError;
  };
  billConsumedEnergyCreditsTariffs?: {
    consumedEnergyCreditsTariffsTotal?: FieldError;
    consumedEnergyCreditsTariffsOnPeak?: FieldError;
    consumedEnergyCreditsTariffsOffPeak?: FieldError;
  };
};

export default function GenerationUnitBillDataFormFields({
  control,
  setValue,
  register,
  formErrors,
  uploadedFile,
  onClickOpenModal,
  onClickCancelModal,
  handleClickDownloadBill,
  generationUnitLeaseCycles,
}: {
  formErrors: FormErrors;
  uploadedFile?: File | null;
  control: Control<FormFields>;
  onClickOpenModal?: () => void;
  onClickCancelModal?: () => void;
  handleClickDownloadBill?: () => void;
  setValue: UseFormSetValue<FormFields>;
  register: UseFormRegister<FormFields>;
  generationUnitLeaseCycles: GenerationUnitLeaseCycle;
}) {
  const {
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<FormFields>();

  const {
    generationUnit: { generationUnitConsumptionGroupType },
    consumerUnitBillingCapture,
    generationUnitLeaseCycleDate,
  } = generationUnitLeaseCycles;

  const HAS_GENERATION_UNIT_CONSUMPTION_GROUP_TYPE_B =
    generationUnitConsumptionGroupType === GenerationUnitConsumptionGroupTypes.B;

  const HAS_CONSUMER_UNIT_BILLING_CAPTURE_URL =
    !!consumerUnitBillingCapture?.consumerUnitBillingCaptureUrl;

  const [
    billReferenceDate,
    billReadingFinishedAt,
    excessEnergyCreditsOnPeak = '0',
    excessEnergyCreditsOffPeak = '0',
    consumedEnergyCreditsOnPeak = '0',
    consumedEnergyCreditsOffPeak = '0',
    generatedEnergyCreditsOnPeak = '0',
    generatedEnergyCreditsOffPeak = '0',
  ] = useWatch({
    control,
    name: [
      'billReferenceDate',
      'billReadingFinishedAt',
      'billExcessEnergyCredits.excessEnergyCreditsOnPeak',
      'billExcessEnergyCredits.excessEnergyCreditsOffPeak',
      'billConsumedEnergyCredits.consumedEnergyCreditsOnPeak',
      'billConsumedEnergyCredits.consumedEnergyCreditsOffPeak',
      'billGeneratedEnergyCredits.generatedEnergyCreditsOnPeak',
      'billGeneratedEnergyCredits.generatedEnergyCreditsOffPeak',
    ],
  });

  const CHANGE_COMMAS_TO_DOTS = (value: string) =>
    value?.replace(/\./g, '').replace(',', '.').trim();

  useEffect(() => {
    if (HAS_GENERATION_UNIT_CONSUMPTION_GROUP_TYPE_B) {
      setValue('billExcessEnergyCredits.excessEnergyCreditsOnPeak', '0.00');
      setValue('billConsumedEnergyCredits.consumedEnergyCreditsOnPeak', '0.00');
      setValue('billGeneratedEnergyCredits.generatedEnergyCreditsOnPeak', '0.00');
    }
  }, [setValue, HAS_GENERATION_UNIT_CONSUMPTION_GROUP_TYPE_B]);

  useMemo(() => {
    !HAS_GENERATION_UNIT_CONSUMPTION_GROUP_TYPE_B &&
      setValue(
        'billExcessEnergyCredits.excessEnergyCreditsOnPeak',
        convertToDecimal(
          String(
            parseFloat(CHANGE_COMMAS_TO_DOTS(generatedEnergyCreditsOnPeak)) -
              parseFloat(CHANGE_COMMAS_TO_DOTS(consumedEnergyCreditsOnPeak))
          )
        )
      );

    setValue(
      'billExcessEnergyCredits.excessEnergyCreditsOffPeak',
      convertToDecimal(
        String(
          parseFloat(CHANGE_COMMAS_TO_DOTS(generatedEnergyCreditsOffPeak)) -
            parseFloat(CHANGE_COMMAS_TO_DOTS(consumedEnergyCreditsOffPeak))
        )
      )
    );
  }, [
    setValue,
    consumedEnergyCreditsOnPeak,
    consumedEnergyCreditsOffPeak,
    generatedEnergyCreditsOnPeak,
    generatedEnergyCreditsOffPeak,
    HAS_GENERATION_UNIT_CONSUMPTION_GROUP_TYPE_B,
  ]);

  useMemo(() => {
    const REFERENCE_DATE_ERROR: ErrorOption = {
      type: 'manual',
      message: 'O mês de referência deve ser igual ao mês vigente do ciclo',
    };

    if (billReferenceDate?.length === 7) {
      const referenceDateWithTimezone = dateWithTimezone(
        new Date(
          +billReferenceDate.split('/')[1],
          +billReferenceDate.split('/')[0] - 1,
          1
        )
      );

      const readingFinishedAtWithTimezone = dateWithTimezone(
        new Date(
          +billReadingFinishedAt.split('/')[2],
          +billReadingFinishedAt.split('/')[1] - 1,
          1
        )
      );

      const generationUnitBillingCycleDateWithTimezone = dateWithTimezone(
        new Date(
          +generationUnitLeaseCycleDate.split('-')[0],
          +generationUnitLeaseCycleDate.split('-')[1] - 1,
          1
        )
      );

      const isReferenceDateInvalid =
        referenceDateWithTimezone < readingFinishedAtWithTimezone ||
        referenceDateWithTimezone > readingFinishedAtWithTimezone ||
        referenceDateWithTimezone < generationUnitBillingCycleDateWithTimezone ||
        referenceDateWithTimezone > generationUnitBillingCycleDateWithTimezone;

      if (isReferenceDateInvalid) {
        setError('billReferenceDate' as keyof FormFields, REFERENCE_DATE_ERROR);
        setTimeout(() => clearErrors(), 6000);
      }
    }
  }, [
    billReferenceDate,
    billReadingFinishedAt,
    generationUnitLeaseCycleDate,
    setError,
    clearErrors,
  ]);

  useMemo(() => {
    const NEGATIVE_RESULT_ERROR: ErrorOption = {
      type: 'manual',
      message: 'Valor negativo não permitido.',
    };

    if (parseFloat(CHANGE_COMMAS_TO_DOTS(excessEnergyCreditsOnPeak)) < 0) {
      setError(
        'billExcessEnergyCredits.excessEnergyCreditsOnPeak' as keyof FormFields,
        NEGATIVE_RESULT_ERROR
      );
      setTimeout(() => clearErrors(), 4000);

      setValue('billExcessEnergyCredits.excessEnergyCreditsOnPeak', '0,00');
    }

    if (parseFloat(CHANGE_COMMAS_TO_DOTS(excessEnergyCreditsOffPeak)) < 0) {
      setError(
        'billExcessEnergyCredits.excessEnergyCreditsOffPeak' as keyof FormFields,
        NEGATIVE_RESULT_ERROR
      );
      setTimeout(() => clearErrors(), 4000);

      setValue('billExcessEnergyCredits.excessEnergyCreditsOffPeak', '0,00');
    }
  }, [
    setError,
    setValue,
    clearErrors,
    excessEnergyCreditsOnPeak,
    excessEnergyCreditsOffPeak,
  ]);

  return (
    <div className="w-full flex flex-col gap-8">
      <div className="w-auto">
        {HAS_CONSUMER_UNIT_BILLING_CAPTURE_URL ? (
          <Button
            size="none"
            type="button"
            variant="tertiaryGray"
            onClick={handleClickDownloadBill}
            className="flex justify-start items-center p-2"
          >
            <MdRemoveRedEye
              size={30}
              color="#B6B6B6"
              style={{
                marginLeft: '5px',
                marginRight: '5px',
              }}
            />
            <div className="flex flex-col justify-center items-start w-full cursor-pointer">
              <p className="font-normal text-sm text-gray-dark300 cursor-pointer">
                Visualizar fatura
              </p>
            </div>
          </Button>
        ) : uploadedFile ? (
          <Button
            size="none"
            className="flex justify-start items-center p-2"
            style={{
              backgroundColor: 'transparent',
              border: '2px solid #cccccc5d',
            }}
          >
            <MdAttachFile
              size={25}
              color="#cccccc5d"
              style={{
                marginRight: '10px',
              }}
            />
            <div className="flex flex-col justify-center items-start w-full">
              <p className="font-normal text-sm text-gray-dark400">
                {uploadedFile.name}
              </p>
            </div>
            <MdClose size={30} color="#cccccc5d" onClick={onClickCancelModal} />
          </Button>
        ) : (
          <Button
            size="none"
            type="button"
            variant="tertiaryGray"
            onClick={onClickOpenModal}
            className="flex justify-start items-center p-2"
          >
            <MdCloudUpload
              size={30}
              color="#B6B6B6"
              style={{
                marginLeft: '5px',
                marginRight: '5px',
              }}
            />
            <div className="flex flex-col justify-center items-start w-full cursor-pointer">
              <p className="font-normal text-sm text-gray-dark300 cursor-pointer">
                Importar fatura
              </p>
            </div>
          </Button>
        )}
      </div>
      <div className="grid grid-cols-4 gap-8">
        <TextField
          required
          mask="date"
          id="billDueDate"
          placeholder="DD/MM/AAAA"
          label="Data de vencimento"
          error={formErrors.billDueDate?.message}
          {...register('billDueDate', {
            required: 'Campo obrigatório',
          })}
        />
        <TextField
          required
          mask="date"
          id="billIssueDate"
          placeholder="DD/MM/AAAA"
          label="Data de disponibilidade"
          error={formErrors.billIssueDate?.message}
          {...register('billIssueDate', {
            required: 'Campo obrigatório',
          })}
        />
        <TextField
          required
          mask="date"
          maxLength={7}
          placeholder="MM/AAAA"
          id="billReferenceDate"
          label="Mês de referência"
          error={
            errors.billReferenceDate?.message ||
            formErrors.billReferenceDate?.message
          }
          {...register('billReferenceDate', {
            required: 'Campo obrigatório',
          })}
        />
      </div>
      <div className="grid grid-cols-4 gap-8">
        <TextField
          required
          mask="date"
          id="billReadingStartedAt"
          placeholder="DD/MM/AAAA"
          label="Data leitura anterior"
          error={formErrors.billReadingStartedAt?.message}
          {...register('billReadingStartedAt', {
            required: 'Campo obrigatório',
          })}
        />
        <TextField
          required
          mask="date"
          placeholder="DD/MM/AAAA"
          id="billReadingFinishedAt"
          label="Data leitura atual"
          error={formErrors.billReadingFinishedAt?.message}
          {...register('billReadingFinishedAt', {
            required: 'Campo obrigatório',
          })}
        />
        <TextField
          required
          mask="date"
          id="billNextReadingDate"
          placeholder="DD/MM/AAAA"
          label="Data leitura futura"
          error={formErrors.billNextReadingDate?.message}
          {...register('billNextReadingDate', {
            required: 'Campo obrigatório',
          })}
        />
      </div>
      <div className="grid grid-cols-4 gap-8">
        <TextField
          required
          mask="currency"
          id="billTotalValue"
          placeholder="R$ 0,00"
          label="Valor da fatura da Unidade Geradora"
          error={formErrors.billTotalValue?.message}
          {...register('billTotalValue', {
            required: 'Campo obrigatório',
          })}
        />
      </div>
      <div className="grid grid-cols-4 gap-8">
        <TextField
          required
          mask="decimal"
          placeholder="0,00"
          label="Créditos gerados ponta"
          id="generatedEnergyCreditsOnPeak"
          disabled={HAS_GENERATION_UNIT_CONSUMPTION_GROUP_TYPE_B}
          error={
            formErrors.billGeneratedEnergyCredits?.generatedEnergyCreditsOnPeak
              ?.message
          }
          {...register('billGeneratedEnergyCredits.generatedEnergyCreditsOnPeak', {
            required: 'Campo obrigatório',
          })}
        />
        <TextField
          required
          mask="decimal"
          placeholder="0,00"
          id="generatedEnergyCreditsOffPeak"
          label="Créditos gerados fora ponta"
          error={
            formErrors.billGeneratedEnergyCredits?.generatedEnergyCreditsOffPeak
              ?.message
          }
          {...register('billGeneratedEnergyCredits.generatedEnergyCreditsOffPeak', {
            required: 'Campo obrigatório',
          })}
        />
      </div>
      <div className="grid grid-cols-4 gap-8">
        <TextField
          required
          mask="decimal"
          placeholder="0,00"
          id="consumedEnergyCreditsOnPeak"
          label="Créditos utilizados ponta"
          disabled={HAS_GENERATION_UNIT_CONSUMPTION_GROUP_TYPE_B}
          error={
            formErrors.billConsumedEnergyCredits?.consumedEnergyCreditsOnPeak
              ?.message
          }
          {...register('billConsumedEnergyCredits.consumedEnergyCreditsOnPeak', {
            required: 'Campo obrigatório',
          })}
        />
        <TextField
          required
          mask="decimal"
          placeholder="0,00"
          id="consumedEnergyCreditsOffPeak"
          label="Créditos utilizados fora ponta"
          error={
            formErrors.billConsumedEnergyCredits?.consumedEnergyCreditsOffPeak
              ?.message
          }
          {...register('billConsumedEnergyCredits.consumedEnergyCreditsOffPeak', {
            required: 'Campo obrigatório',
          })}
        />
      </div>
      <div className="grid grid-cols-4 gap-8">
        <TextField
          required
          disabled
          mask="decimal"
          placeholder="0,00"
          id="excessEnergyCreditsOnPeak"
          label="Créditos excedentes ponta"
          error={
            errors.billExcessEnergyCredits?.excessEnergyCreditsOnPeak?.message ||
            formErrors.billExcessEnergyCredits?.excessEnergyCreditsOnPeak?.message
          }
          {...register('billExcessEnergyCredits.excessEnergyCreditsOnPeak', {
            required: 'Campo obrigatório',
          })}
        />
        <TextField
          required
          disabled
          mask="decimal"
          placeholder="0,00"
          id="excessEnergyCreditsOffPeak"
          label="Créditos excedentes fora ponta"
          error={
            errors.billExcessEnergyCredits?.excessEnergyCreditsOffPeak?.message ||
            formErrors.billExcessEnergyCredits?.excessEnergyCreditsOffPeak?.message
          }
          {...register('billExcessEnergyCredits.excessEnergyCreditsOffPeak', {
            required: 'Campo obrigatório',
          })}
        />
      </div>
    </div>
  );
}
