import { Button } from 'ui';
import { Dashboard, DashboardMainHeaderForm } from 'dashboard/components/dashboard';
import ConsumerUnitFields, {
  FormFields,
} from 'consumerUnits/components/form/ConsumerUnitFields';
import { Page } from 'dashboard/components/dashboard/Breadcrumbs';

import { useForm, SubmitHandler, ErrorOption } from 'react-hook-form';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { setFormError } from 'utils/form';
import {
  ConsumerUnitCreated,
  ConsumerUnitCreateMutationVariables,
  CONSUMER_UNIT_CREATE_MUTATION,
} from 'consumerUnits/graphql/consumerUnitCreateMutation';
import {
  ConsumerUnitsList,
  consumerUnitsTypename,
  CONSUMER_UNITS_QUERY,
} from 'consumerUnits/graphql/consumerUnitsQuery';
import {
  POWER_DISTRIBUTION_UNITS_SELECT_QUERY,
  POWER_DISTRIBUTION_UNITS_SELECT_QUERY_VARIABLES,
  PowerDistributionUnitsSelectList,
} from 'powerDistributionUnits/graphql/powerDistributionUnitsSelectQuery';
import {
  COOPERATIVE_MEMBERS_SELECT_QUERY,
  COOPERATIVE_MEMBERS_SELECT_QUERY_VARIABLES,
  CooperativeMembersSelectList,
} from 'cooperatives/graphql/cooperativeMembersSelectQuery';

import useToastContext from 'ui/hooks/useToast';
import { useHistory } from 'react-router';
import { ToastProps } from 'ui/contexts/overlay/Toast';
import { useLoading } from 'ui/contexts/overlay/Loading';
import { useEffect } from 'react';
import { has } from 'lodash';

const DASHBOARD_CONSUMER_UNITS_ROUTE = '/dashboard/business-entities/consumer-units';

const BREADCRUMB_PAGES: Page[] = [
  {
    name: 'Unidades Consumidoras',
    route: DASHBOARD_CONSUMER_UNITS_ROUTE,
    current: false,
  },
  {
    name: 'Nova Unidade Consumidora',
    route: null,
    current: true,
  },
];
const TITLE = 'Nova Unidade Consumidora';
const CREATE_ERROR_TOAST: ToastProps = {
  text: 'Houve um erro ao tentar criar a Unidade Consumidora',
  title: 'Algo deu errado!',
  variant: 'danger',
};

const CREATE_SUCCESS_TOAST: ToastProps = {
  title: 'Sucesso!',
  variant: 'primary',
  text: 'Sucesso ao cadastrar a Unidade Consumidora.',
};

export default function CreateConsumerUnitsPage() {
  const { push } = useHistory();
  const { addToast } = useToastContext();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    control,
    setValue,
    resetField,
    formState: { errors },
  } = useForm<FormFields>();
  const { showLoading, closeLoading, LoadingOverlay } = useLoading();

  const [consumerUnitCreateMutation, { loading: loadingCreate }] = useMutation<
    ConsumerUnitCreated,
    ConsumerUnitCreateMutationVariables
  >(CONSUMER_UNIT_CREATE_MUTATION, {
    onError(error: ApolloError) {
      if (has(error.graphQLErrors[0], 'details')) {
        addToast(CREATE_ERROR_TOAST);
      }

      setFormError(
        error,
        (field: string, error: ErrorOption) => {
          setError(field as keyof FormFields, error);

          setTimeout(() => clearErrors(), 2500);
        },
        t
      );
    },
    onCompleted() {
      addToast(CREATE_SUCCESS_TOAST);
      push(DASHBOARD_CONSUMER_UNITS_ROUTE);
    },
  });

  const [
    powerDistributionUnitsSelect,
    {
      data: powerDistributionUnits,
      loading: loadingPowerDistributionUnits,
      refetch: refetchPowerDistributionUnits,
    },
  ] = useLazyQuery<PowerDistributionUnitsSelectList>(
    POWER_DISTRIBUTION_UNITS_SELECT_QUERY,
    POWER_DISTRIBUTION_UNITS_SELECT_QUERY_VARIABLES
  );

  const [
    cooperativeMembersSelect,
    {
      data: cooperativeMembers,
      loading: loadingCooperativeMembers,
      refetch: refetchCooperativeMembers,
    },
  ] = useLazyQuery<CooperativeMembersSelectList>(
    COOPERATIVE_MEMBERS_SELECT_QUERY,
    COOPERATIVE_MEMBERS_SELECT_QUERY_VARIABLES
  );

  const onSubmit: SubmitHandler<FormFields> = (consumerUnitCreateInput) => {
    consumerUnitCreateInput.consumerUnitAddressPostalCode = Number(
      consumerUnitCreateInput.consumerUnitAddressPostalCode
    );

    consumerUnitCreateInput.consumerUnitDiscountPercentageValue =
      consumerUnitCreateInput.consumerUnitDiscountPercentageValue.replace(',', '.');

    consumerUnitCreateMutation({
      variables: {
        consumerUnitCreateInput,
      },
      update(cache, { data }) {
        const existingData = cache.readQuery<ConsumerUnitsList>({
          query: CONSUMER_UNITS_QUERY,
        });
        cache.writeQuery({
          query: CONSUMER_UNITS_QUERY,
          data: {
            consumerUnits: {
              __typename: consumerUnitsTypename,
              afterCursor: existingData?.consumerUnits.afterCursor || null,
              beforeCursor: existingData?.consumerUnits.beforeCursor || null,
              entries: [
                data?.consumerUnitCreate,
                ...(existingData?.consumerUnits.entries || []),
              ],
            },
          },
        });
      },
    });
  };

  const isLoading =
    loadingPowerDistributionUnits || loadingCreate || loadingCooperativeMembers;

  useEffect(() => {
    if (isLoading) {
      showLoading();
      return;
    }

    closeLoading();
  }, [isLoading, showLoading, closeLoading]);

  useEffect(() => {
    refetchPowerDistributionUnits && refetchPowerDistributionUnits();

    powerDistributionUnitsSelect();
  }, [powerDistributionUnitsSelect, refetchPowerDistributionUnits]);

  useEffect(() => {
    refetchCooperativeMembers && refetchCooperativeMembers();

    cooperativeMembersSelect();
  }, [cooperativeMembersSelect, refetchCooperativeMembers]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay />
      <Dashboard
        dashboardHeader={<></>}
        dashboardMainHeaderTitle={
          <DashboardMainHeaderForm breadcrumbPages={BREADCRUMB_PAGES} title={TITLE}>
            <Button type="submit" size="sm" disabled={isLoading}>
              Salvar
            </Button>
          </DashboardMainHeaderForm>
        }
      >
        <ConsumerUnitFields
          powerDistributionUnits={
            powerDistributionUnits?.powerDistributionUnits.entries
          }
          control={control}
          cooperativeMembers={cooperativeMembers?.cooperativeMembers.entries}
          errors={errors}
          register={register}
          setValue={setValue}
          setError={setError}
          resetField={resetField}
        />
      </Dashboard>
    </form>
  );
}
