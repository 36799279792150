import { gql } from '@apollo/client';

export type ContactGroupDeleteMutationVariables = {
  id: number;
};

export const contactGroupTypename = 'ContactGroup';

export const CONTACT_GROUP_DELETE_MUTATION = gql`
  mutation contactGroupDelete($id: ID!) {
    contactGroupDelete(id: $id) {
      message
    }
  }
`;
