import { useSubscription } from '@apollo/client';
import { INTRANEX_APP_VERSION_PUBLISHED_SUBSCRIPTION } from 'graphql/intranexAppVersionPublishedSubscription';
import { useEffect } from 'react';
import { MdFlag } from 'react-icons/md';
import { Button } from 'ui';
import { ToastProps } from 'ui/contexts/overlay/Toast';
import useToastContext from 'ui/hooks/useToast';

const NEW_APP_VERSION_PUBLISHED_TOAST: ToastProps = {
  title: 'Nova versão',
  variant: 'primary',
  persistentToast: true,
  icon: <MdFlag className="text-gray-dark400 text-4xl" />,
  text: (
    <>
      <p>Você tem uma nova versão do IntraNEX disponível.</p>
      <Button
        variant="primaryGreen"
        className="mt-5"
        onClick={() => window.location.reload()}
      >
        Atualizar
      </Button>{' '}
    </>
  ),
};

export const NotificationCenter = () => {
  const { addToast } = useToastContext();

  const { data: appVersionPublished, loading } = useSubscription(
    INTRANEX_APP_VERSION_PUBLISHED_SUBSCRIPTION,
    {
      variables: {},
    }
  );

  useEffect(() => {
    if (!loading && !!appVersionPublished) {
      addToast(NEW_APP_VERSION_PUBLISHED_TOAST);
    }
  }, [loading, appVersionPublished, addToast]);

  return null;
};
