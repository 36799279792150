import { gql } from '@apollo/client';

export interface UserResetPasswordCheckVariables {
  userToken: string;
}

export const USER_RESET_PASSWORD_CHECK = gql`
  query internalUserResetPasswordCheck($userToken: String!) {
    internalUserResetPasswordCheck(userToken: $userToken) {
      message
    }
  }
`;
