import { Cooperative } from 'cooperatives/models/cooperative';
import { ConsumerUnit } from 'consumerUnits/model/consumerUnit';
import { EconomyReport } from 'economyReport/model/economyReport';
import { GenerationUnit } from 'generationUnits/models/generationUnit';
import { CooperativeMember } from 'cooperatives/models/cooperativeMember';
import { CommercialAgreement } from 'commercialAgreements/models/commercialAgreement';
import { ConsumerUnitBillData } from 'powerDistributionUnits/powerDistributionUnitBillData/consumerUnitBillData/models/consumerUnitBillData';

export type ChargeId = number;

export enum ChargeStatus {
  PAYED = 'PAYED',
  OPENED = 'OPENED',
  QUEUED = 'QUEUED',
  OVERDUE = 'OVERDUE',
  PENDING = 'PENDING',
  EXPIRED = 'EXPIRED',
  CANCELED = 'CANCELED',
  DEFAULTER = 'DEFAULTER',
}

export enum ChargePaymentMethods {
  PIX = 'PIX',
  CREDIT = 'CREDIT',
  BANK_SLIP = 'BANK_SLIP',
}

export enum ChargeUpdateRequestType {
  CHARGE_CANCEL = 'CHARGE_CANCEL',
  CHARGE_UPDATE = 'CHARGE_UPDATE',
  CHARGE_CANCEL_REISSUE = 'CHARGE_CANCEL_REISSUE',
}

export enum ChargeUpdateCancelType {
  REISSUE = 'REISSUE',
  CANCEL_REISSUE = 'CANCEL_REISSUE',
}

export interface Charges {
  id: ChargeId;
  consumerUnit: ConsumerUnit;
  cooperative: Cooperative;
  cooperativeMember: CooperativeMember;
  economyReport?: EconomyReport;
  generationUnit: GenerationUnit;
  comercialAgreement: CommercialAgreement;
  powerDistributionUnitBillData: ConsumerUnitBillData;
  chargeDueDate: string;
  chargeInterest: string;
  chargeTotalPaid: string;
  chargeInvoiceUrl: string;
  chargeTotalBilled: string;
  chargePaymentDate: string;
  chargeClientPaymentDate: string;
  chargeCreatedDate: string;
  chargeTotalCharged: string;
  chargeAppTotalFees: string;
  chargeStatus: ChargeStatus;
  chargeCooperativeTotalValue: string;
  chargeGenerationUnitTotalValue: string;
  chargePaymentMethod: ChargePaymentMethods;
  chargeGenerationUnitOemTotalValue: string;
  chargeCooperativeSubscriptionValue: string;
  chargeGenerationUnitRealStateTotalValue: string;
  chargeGenerationUnitEquipmentRentTotalValue: string;
}

export interface ChargeUpdateCancel {
  chargeUpdateCancelType?: ChargeUpdateCancelType;
}

export interface ChargesUpdate extends ChargeUpdateCancel {
  id: ChargeId;
  sendEmail: boolean;
  newDueDate?: string;
  chargeUpdateReason: string;
  keepEarlyPaymentDiscount: boolean;
  chargeUpdateRequestingUser: string;
  chargeUpdateRequestType: ChargeUpdateRequestType;
}
