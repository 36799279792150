import { gql } from '@apollo/client';

import { ConsumerUnit } from 'consumerUnits/model/consumerUnit';

interface ConsumerUnitSelectConnection {
  entries: ConsumerUnit[];
}

export interface ConsumerUnitsSelectList {
  consumerUnits: ConsumerUnitSelectConnection;
}

export const consumerUnitsTypename = 'ConsumerUnitConnection';

const MAX_SIZE = 10000;

export const CONSUMER_UNITS_SELECT_QUERY_VARIABLES = {
  variables: { pageSize: MAX_SIZE, maximumLimit: MAX_SIZE },
};

export const CONSUMER_UNITS_SELECT_QUERY = gql`
  query consumerUnits(
    $after: String
    $before: String
    $pageSize: Int
    $maximumLimit: Int
  ) {
    consumerUnits(
      after: $after
      before: $before
      pageSize: $pageSize
      maximumLimit: $maximumLimit
    ) {
      entries {
        id
        consumerUnitAddressState
        consumerUnitAddressDistrict
        consumerUnitConsumptionGroupType
        consumerUnitPowerDistributionUnitIdentifier
        cooperative {
          cooperativeLegalName
        }
        cooperativeMember {
          cooperativeMemberLegalName
          cooperativeMemberDocumentId
        }
        powerDistributionUnit {
          powerDistributionUnitLegalName
        }
        apportionmentCycleConsumerUnit {
          apportionmentCycleConsumerUnitPercentual
          generationUnit {
            generationUnitLegalName
          }
        }
      }
    }
  }
`;
