import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler, ErrorOption } from 'react-hook-form';
import { ApolloError, useMutation } from '@apollo/client';
import has from 'lodash/has';
import omit from 'lodash/omit';
import { Button } from 'ui';
import { ToastProps } from 'ui/contexts/overlay/Toast';
import { useLoading } from 'ui/contexts/overlay/Loading';
import useToastContext from 'ui/hooks/useToast';
import { setFormError, convertToParseFloat, dateWithoutTimezone } from 'utils/form';
import { Dashboard, DashboardMainHeaderForm } from 'dashboard/components/dashboard';
import { Page } from 'dashboard/components/dashboard/Breadcrumbs';
import IcmsTaxFormFields, {
  FormFields,
} from 'icmsTaxes/components/form/IcmsTaxFields';
import {
  IcmsTaxCreated,
  IcmsTaxMutationVariables,
  ICMS_TAX_CREATE_MUTATION,
} from 'icmsTaxes/graphql/icmsTaxCreateMutation';
import {
  icmsTaxTypename,
  IcmsTaxList,
  ICMS_TAXES_QUERY,
} from 'icmsTaxes/graphql/icmsTaxesQuery';

import { IcmsRangesFeeType } from 'icmsTaxes/models/icmsTaxes';

const DASHBOARD_ICMS_TAXES_ROUTE = '/dashboard/taxes-fees/icms-taxes';

const BREADCRUMB_PAGES: Page[] = [
  { name: 'ICMS', route: DASHBOARD_ICMS_TAXES_ROUTE, current: false },
  {
    name: 'Novo ICMS',
    route: null,
    current: true,
  },
];
const TITLE = 'Novo ICMS';
const CREATE_ERROR_TOAST: ToastProps = {
  text: 'Houve um erro ao tentar criar o ICMS',
  title: 'Algo deu errado!',
  variant: 'danger',
};

const CREATE_SUCCESS_TOAST: ToastProps = {
  title: 'Sucesso!',
  variant: 'primary',
  text: 'Sucesso ao cadastrar o ICMS.',
};

export default function CreateIcmsTaxesPage() {
  const { push } = useHistory();
  const { addToast } = useToastContext();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    control,
    formState: { errors },
  } = useForm<FormFields>();
  const { showLoading, closeLoading, LoadingOverlay } = useLoading();

  const [icmsTaxCreateMutation, { loading: loadingCreate }] = useMutation<
    IcmsTaxCreated,
    IcmsTaxMutationVariables
  >(ICMS_TAX_CREATE_MUTATION, {
    onError(error: ApolloError) {
      if (has(error.graphQLErrors[0], 'details')) {
        addToast(CREATE_ERROR_TOAST);
      }

      setFormError(
        error,
        (field: string, error: ErrorOption) => {
          setError(field as keyof FormFields, error);

          setTimeout(() => clearErrors(), 2500);
        },
        t
      );
    },
    onCompleted() {
      addToast(CREATE_SUCCESS_TOAST);
      push(DASHBOARD_ICMS_TAXES_ROUTE);
    },
  });

  const filledRanges = (icmsRangesFee: IcmsRangesFeeType[]) =>
    icmsRangesFee.filter(
      (range) =>
        range.consumptionKwhEndRange &&
        range.consumptionKwhStartRange &&
        range.consumptionRangePercentage
    );

  const onSubmit: SubmitHandler<FormFields> = (icmsTaxCreateInput) => {
    const [validityStart, validityEnd] = icmsTaxCreateInput.icmsValidity;

    Object.assign(icmsTaxCreateInput, {
      icmsRangesFee: filledRanges(icmsTaxCreateInput.icmsRangesFee),
    });

    icmsTaxCreateInput.icmsRangesFee.map(
      (rangeFee) =>
        (rangeFee.consumptionRangePercentage = convertToParseFloat(
          rangeFee.consumptionRangePercentage
        ))
    );

    icmsTaxCreateMutation({
      variables: {
        icmsTaxCreateInput: {
          ...omit(icmsTaxCreateInput, 'icmsValidity'),
          icmsValidityStartDate: dateWithoutTimezone(validityStart)
            .toISOString()
            .split('T')[0],
          icmsValidityEndDate: dateWithoutTimezone(validityEnd)
            .toISOString()
            .split('T')[0],
        },
      },
      update(cache, { data }) {
        const existingData = cache.readQuery<IcmsTaxList>({
          query: ICMS_TAXES_QUERY,
        });
        cache.writeQuery({
          query: ICMS_TAXES_QUERY,
          data: {
            icmsTaxes: {
              __typename: icmsTaxTypename,
              afterCursor: existingData?.icmsTaxes.afterCursor || null,
              beforeCursor: existingData?.icmsTaxes.beforeCursor || null,
              entries: [
                data?.icmsTaxCreate,
                ...(existingData?.icmsTaxes.entries || []),
              ],
            },
          },
        });
      },
    });
  };
  const isLoading = loadingCreate;

  useEffect(() => {
    if (isLoading) {
      showLoading();
      return;
    }

    closeLoading();
  }, [isLoading, showLoading, closeLoading]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay />
      <Dashboard
        dashboardHeader={<></>}
        dashboardMainHeaderTitle={
          <DashboardMainHeaderForm breadcrumbPages={BREADCRUMB_PAGES} title={TITLE}>
            <Button type="submit" size="sm" disabled={isLoading}>
              Salvar
            </Button>
          </DashboardMainHeaderForm>
        }
      >
        <IcmsTaxFormFields errors={errors} register={register} control={control} />
      </Dashboard>
    </form>
  );
}
