import { gql } from '@apollo/client';

export interface CooperativeMemberQueryVariables {
  id: string;
}

export const CooperativeMemberTypename = 'CooperativeMember';

export const COOPERATIVE_MEMBER_QUERY = gql`
  query cooperativeMember($id: ID!) {
    cooperativeMember(id: $id) {
      id
      cooperativeMemberEmail
      cooperativeMemberLegalName
      cooperativeMemberEntityType
      cooperativeMemberDocumentId
      cooperativeMemberModalityType
      cooperativeMemberOptionalEmail
      cooperative {
        id
        cooperativeLegalName
      }
    }
  }
`;
