import { useState } from 'react';
import ReactModal from 'react-modal';
import Loading from 'ui/components/overlay/Loading';
import { rootElement } from 'app/constants';
import { ModalFormProps } from 'ui/models/overlay';
import Modal from 'ui/components/modal/Modal';

/**
 * It is important for users of screenreaders that other page content be
 * hidden (via the aria-hidden attribute) while the modal is open.
 *
 * http://reactcommunity.org/react-modal/accessibility/#app-element
 */
ReactModal.setAppElement(document.getElementById(rootElement.id) as HTMLElement);

export const useOverlayModal = () => {
  const [isVisible, setIsVisible] = useState(false);

  const openModal = () => setIsVisible(true);
  const closeModal = () => setIsVisible(false);

  const RenderConfirm = ({
    title,
    variant,
    children,
    canClose = true,
    ...props
  }: ModalFormProps) => (
    <Modal
      isOpen={isVisible}
      onCloseModal={closeModal}
      contentLabel={title}
      variant={variant}
      shouldCloseOnOverlayClick={canClose}
      shouldCloseOnEsc={canClose}
      children={children}
      {...props}
    />
  );

  const RenderLoading = () => (
    <Loading isOpen={isVisible} onCloseModal={closeModal} />
  );

  return {
    openModal,
    closeModal,
    RenderConfirm,
    RenderLoading,
  };
};
