import { useEffect, useState } from 'react';

import { MdCloudDownload } from 'react-icons/md';

import { useLocation } from 'react-router-dom';

import { SubmitHandler, useForm } from 'react-hook-form';

import { useLazyQuery, useMutation } from '@apollo/client';

import { useCSVDownloader } from 'react-papaparse';

import { INITIAL_QUERY_STATE_CONFIG } from 'graphql/apollo/config';

import { Button } from 'ui';
import { ModalProps } from 'ui/models/overlay';
import useToastContext from 'ui/hooks/useToast';
import { useModal } from 'ui/contexts/overlay/Modal';
import { ToastProps } from 'ui/contexts/overlay/Toast';
import { useLoading } from 'ui/contexts/overlay/Loading';

import { Pagination } from 'dashboard/components/table';
import { Dashboard, DashboardMainHeaderForm } from 'dashboard/components/dashboard';

import { ChargeId, Charges } from 'charges/models/charges';
import { CHARGES_QUERY } from 'charges/graphql/chargesQuerys';
import ChargesTable from 'charges/components/table/ChargesTable';
import ChargesAlertModal from 'charges/components/modal/ChargesAlertModal';
import ChargesFormFilters, {
  FormFilters,
} from 'charges/components/form/ChargesFormFilters';
import ChargesUpdateModal, {
  ChargesUpdateModalData,
} from 'charges/components/modal/ChargesUpdateModal';

import { unMaskFormFields } from 'utils/unmask';
import { dateWithoutTimezone } from 'utils/form';
import {
  CHARGE_BILLING_EMAIL_RESENT_MUTATION,
  ChargeBillingEmailResentMutationVariables,
} from 'charges/graphql/chargeBillingEmailResent';

type State = {
  charges: Charges[];
  hasNextPage: boolean;
  hasPreviousPage: boolean;
};

const LIST_ERROR_TOAST: ToastProps = {
  variant: 'danger',
  title: 'Algo deu errado!',
  text: 'Não foi possível carregar a lista de Cobranças',
};

const BILLING_EMAIL_RESENT_ERROR_TOAST: ToastProps = {
  variant: 'danger',
  title: 'Algo deu errado!',
  text: 'Não foi possível reenviar o email de cobrança',
};

const BILLING_EMAIL_RESENT_SUCCESS_TOAST: ToastProps = {
  variant: 'primary',
  title: 'Sucesso!',
  text: 'Sucesso ao reenviar o email de cobrança',
};

const EXPORT_SUCCESS_TOAST: ToastProps = {
  variant: 'primary',
  title: 'Sucesso!',
  text: 'Sucesso ao baixar Relatório Financeiro',
};

const EXPORT_ALERT_MODAL: ModalProps = {
  variant: 'alert',
  title: 'Atenção!',
  text: 'Caso nenhum filtro seja selecionado, será exportado toda a base de dados dessa tela. Deseja continuar mesmo assim?',
};

const CHARGE_UPDATE_MODAL: ModalProps = {
  variant: 'primary',
  title: 'Edição de cobrança',
  text: 'Deseja editar a cobrança?',
};

const DASHBOARD_TITLE = 'Cobranças';

type ChargeFilterOptions = {
  id: ChargeId;
};

export default function ListChargesPage() {
  const location = useLocation();
  const { addToast } = useToastContext();
  const { CSVDownloader, Type } = useCSVDownloader();
  const { LoadingOverlay, showLoading, closeLoading } = useLoading();

  const [showChargeModal, setShowChargeModal] = useState(false);
  const [showChargeUpdateModal, setShowChargeUpdateModal] = useState(false);

  const [updateChargeData, setUpdateChargeData] = useState<ChargesUpdateModalData>();

  const {
    ModalOverlay,
    showConfirm: modalShowConfirm,
    closeConfirm: modalCloseConfirm,
  } = useModal();

  const {
    ModalOverlay: ModalOverlayUpdate,
    showConfirm: modalUpdateShowConfirm,
    closeConfirm: modalUpdateCloseConfirm,
  } = useModal();

  const [hasActiveFilters, setHasActiveFilters] = useState(false);

  const [state, setState] = useState<State>({
    charges: [],
    hasNextPage: false,
    hasPreviousPage: false,
  });

  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<FormFilters>();

  const [billingEmailResent, { loading: billingEmailResentLoading }] =
    useMutation<ChargeBillingEmailResentMutationVariables>(
      CHARGE_BILLING_EMAIL_RESENT_MUTATION,
      {
        onError() {
          addToast(BILLING_EMAIL_RESENT_ERROR_TOAST);
        },
        onCompleted() {
          addToast(BILLING_EMAIL_RESENT_SUCCESS_TOAST);
        },
      }
    );

  const [ChargesList, { data, loading, error, refetch }] = useLazyQuery(
    CHARGES_QUERY,
    INITIAL_QUERY_STATE_CONFIG
  );

  useEffect(() => {
    ChargesList();
  }, [ChargesList]);

  useEffect(() => {
    refetch && refetch();
  }, [refetch]);

  useEffect(() => {
    if (error) {
      addToast(LIST_ERROR_TOAST);
    }
  }, [addToast, error]);

  const isLoading = loading || billingEmailResentLoading;

  useEffect(() => {
    if (isLoading) {
      return showLoading();
    }

    if (data) {
      setState({
        charges: data.charges.entries,
        hasNextPage: !!data.charges.afterCursor,
        hasPreviousPage: !!data.charges.beforeCursor,
      });
    }

    closeLoading();
  }, [data, isLoading, closeLoading, showLoading]);

  const onClickContinue = () => {
    modalCloseConfirm();
    addToast(EXPORT_SUCCESS_TOAST);
  };

  const onClickEditButton = ({
    chargeId,
    consumerUnit,
    powerDistributionUnitBillData,
  }: ChargesUpdateModalData) => {
    setUpdateChargeData({
      chargeId,
      consumerUnit,
      powerDistributionUnitBillData,
    });

    setShowChargeModal(false);
    modalUpdateShowConfirm();
    setShowChargeUpdateModal(true);
  };

  const hasActiveFiltersHandler = (value: boolean) => {
    setHasActiveFilters(value);
  };

  const handleClickNext = () => {
    refetch &&
      refetch({
        before: null,
        after: data.charges.afterCursor,
      });
  };

  const handleClickBefore = () => {
    refetch &&
      refetch({
        after: null,
        before: data.charges.beforeCursor,
      });
  };

  const { id } = (location.state as ChargeFilterOptions) || {};

  useEffect(() => {
    !!location.state &&
      refetch({
        filters: {
          id,
        },
      });
  }, [refetch, location.state, id]);

  const onSubmit: SubmitHandler<FormFilters> = (chargesInput) => {
    const {
      chargeCreatedDate,
      chargeDueDate,
      chargePaymentDate,
      chargeStatusPayed,
      chargeStatusQueued,
      chargeStatusOpened,
      chargeStatusPending,
      chargeStatusExpired,
      chargeStatusOverdue,
      chargeStatusCanceled,
      chargeStatusDefaulter,
    } = chargesInput;

    const isoDatesWithoutTimezone = [
      chargePaymentDate,
      chargeCreatedDate,
      chargeDueDate,
    ].map((dates) => dates?.map((date) => dateWithoutTimezone(date).toISOString()));

    const chargePaymentStartDate =
      (!!isoDatesWithoutTimezone[0] && isoDatesWithoutTimezone[0][0]) || null;

    const chargePaymentEndDate =
      (!!isoDatesWithoutTimezone[0] && isoDatesWithoutTimezone[0][1]) || null;

    const chargeCreatedStartDate =
      (!!isoDatesWithoutTimezone[1] && isoDatesWithoutTimezone[1][0]) || null;

    const chargeCreatedEndDate =
      (!!isoDatesWithoutTimezone[1] && isoDatesWithoutTimezone[1][1]) || null;

    const chargeDueStartDate =
      (!!isoDatesWithoutTimezone[2] &&
        isoDatesWithoutTimezone[2][0].split('T')[0]) ||
      null;

    const chargeDueEndDate =
      (!!isoDatesWithoutTimezone[2] &&
        isoDatesWithoutTimezone[2][1].split('T')[0]) ||
      null;

    const CHARGE_STATUS = {
      chargeStatusPayed,
      chargeStatusQueued,
      chargeStatusOpened,
      chargeStatusPending,
      chargeStatusOverdue,
      chargeStatusExpired,
      chargeStatusCanceled,
      chargeStatusDefaulter,
    };

    Object.entries(CHARGE_STATUS).forEach(([key, value]) => {
      if (!value) {
        delete CHARGE_STATUS[key as keyof typeof CHARGE_STATUS];
      }
    });

    Object.assign(chargesInput, { chargeStatus: CHARGE_STATUS });

    delete chargesInput.chargeDueDate;
    delete chargesInput.chargePaymentDate;
    delete chargesInput.chargeCreatedDate;
    delete chargesInput.chargeStatusPayed;
    delete chargesInput.chargeStatusQueued;
    delete chargesInput.chargeStatusOpened;
    delete chargesInput.chargeStatusPending;
    delete chargesInput.chargeStatusOverdue;
    delete chargesInput.chargeStatusExpired;
    delete chargesInput.chargeStatusCanceled;
    delete chargesInput.chargeStatusDefaulter;

    Object.assign(chargesInput, {
      ...unMaskFormFields(chargesInput),
      chargeDueEndDate: chargeDueEndDate,
      chargeDueStartDate: chargeDueStartDate,
      chargePaymentEndDate: chargePaymentEndDate,
      chargePaymentStartDate: chargePaymentStartDate,
      chargeCreatedEndDate: chargeCreatedEndDate,
      chargeCreatedStartDate: chargeCreatedStartDate,
    });

    refetch &&
      refetch({
        filters: Object.fromEntries(
          Object.entries(chargesInput).filter(([, value]) => !!value)
        ),
      });
  };

  return (
    <>
      <Dashboard
        dashboardMainHeaderTitle={
          <DashboardMainHeaderForm title={DASHBOARD_TITLE}>
            {hasActiveFilters ? (
              <CSVDownloader
                data={[]}
                bom={true}
                type={Type.Link}
                filename="Relatório Financeiro"
                config={{
                  delimiter: ';',
                }}
              >
                <Button
                  size="sm"
                  variant="secondaryGray"
                  className="justify-end"
                  onClick={() => addToast(EXPORT_SUCCESS_TOAST)}
                >
                  <MdCloudDownload size={20} /> Relatório Financeiro
                </Button>
              </CSVDownloader>
            ) : (
              <Button
                size="sm"
                variant="secondaryGray"
                className="justify-end"
                onClick={() => {
                  setShowChargeModal(true);
                  setShowChargeUpdateModal(false);

                  modalShowConfirm();
                }}
              >
                <MdCloudDownload size={20} /> Relatório Financeiro
              </Button>
            )}
          </DashboardMainHeaderForm>
        }
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <ChargesFormFilters
            errors={errors}
            control={control}
            setValue={setValue}
            register={register}
            isLoading={isLoading}
            hasActiveFilters={hasActiveFiltersHandler}
          />
        </form>

        <div className="rounded-lg">
          <ChargesTable
            charges={state.charges}
            disableEditButton={isLoading}
            onClickEditButton={onClickEditButton}
            onClickSendEmailButton={(chargeId: ChargeId) => {
              billingEmailResent({
                variables: {
                  billingEmailResentInput: {
                    chargeId,
                  },
                },
              });
            }}
          />

          <Pagination
            onNextClick={handleClickNext}
            disableNext={!state.hasNextPage}
            onPreviousClick={handleClickBefore}
            disableBefore={!state.hasPreviousPage}
          />
        </div>

        {showChargeModal && (
          <ChargesAlertModal
            ModalOverlay={ModalOverlay}
            alert={EXPORT_ALERT_MODAL}
            onClickContinue={onClickContinue}
            onClickCloseModal={modalCloseConfirm}
          />
        )}

        {showChargeUpdateModal && (
          <ChargesUpdateModal
            refetch={refetch}
            modalProps={CHARGE_UPDATE_MODAL}
            ModalOverlay={ModalOverlayUpdate}
            chargeUpdateModalData={updateChargeData}
            onClickCloseModal={() => {
              modalUpdateCloseConfirm();
              setShowChargeUpdateModal(false);
            }}
          />
        )}
      </Dashboard>

      <LoadingOverlay />
    </>
  );
}
