import { ChangeEvent } from 'react';

import { Button } from 'ui';
import { ModalFormProps } from 'ui/models/overlay';

import FileUpload from 'powerDistributionUnits/powerDistributionUnitBillData/generationUnitBillData/components/upload/fileUpload';

type Props = {
  onClickCloseModal: () => void;
  onClickCancelModal: () => void;
  ModalOverlay: (any: ModalFormProps) => JSX.Element;
  handleUploadFile: (event: ChangeEvent<HTMLInputElement>) => void;
};

const UPLOAD_BUTTON_TITLE = 'Importar arquivo';
const MODAL_TITLE = 'Importação da fatura da Unidade Geradora';

export default function UploadGenerationUnitBillData({
  ModalOverlay,
  handleUploadFile,
  onClickCloseModal,
  onClickCancelModal,
}: Props) {
  return (
    <ModalOverlay title={MODAL_TITLE} variant="primary">
      <div className="w-full flex flex-col gap-4 pt-6">
        <FileUpload
          id="billFileMetadata"
          label={UPLOAD_BUTTON_TITLE}
          onChange={handleUploadFile}
          accept=".pdf, .png, .jpg, .jpeg"
        />
        <div className="flex items-center justify-end mt-5 w-full gap-2">
          <div className="flex flex-row-reverse">
            <Button
              type="submit"
              variant="primaryGreen"
              size="md"
              onClick={onClickCloseModal}
            >
              <p className="font-bold">Carregar</p>
            </Button>
            <Button
              size="md"
              className="mr-2"
              variant="secondaryGray"
              onClick={onClickCancelModal}
            >
              <p className="font-medium">Cancelar</p>
            </Button>
          </div>
        </div>
      </div>
    </ModalOverlay>
  );
}
