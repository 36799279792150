import { gql } from '@apollo/client';

import { ChargeId } from 'charges/models/charges';

export interface ChargeBillingEmailResentMutationInput {
  chargeId: ChargeId;
}

export interface ChargeBillingEmailResentMutationVariables {
  billingEmailResentInput: ChargeBillingEmailResentMutationInput;
}

export const CHARGE_BILLING_EMAIL_RESENT_MUTATION = gql`
  mutation billingEmailResent($billingEmailResentInput: BillingEmailResentInput!) {
    billingEmailResent(input: $billingEmailResentInput) {
      id
    }
  }
`;
