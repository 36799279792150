import Table from 'ui/components/table/Table';
import TableBody from 'ui/components/table/TableBody';
import TableHead from 'ui/components/table/TableHead';
import TableHeadColumn from 'ui/components/table/TableHeadColumn';

import ContactGroupsTableItem from './ContactGroupsTableItem';

import { ContactGroupsList } from 'contactGroups/models/contactGroups';

type Props = {
  contactGroups: ContactGroupsList[];
  onClickEditButton: (id: number) => void;
  onClickRemoveButton: (id: number) => void;
};

export default function ContactGroupsTable({
  contactGroups,
  onClickEditButton,
  onClickRemoveButton,
}: Props) {
  return (
    <Table>
      <TableHead>
        <TableHeadColumn>NOME</TableHeadColumn>
        <TableHeadColumn>PROPRIETÁRIO</TableHeadColumn>
        <TableHeadColumn>TELEFONE</TableHeadColumn>
        <TableHeadColumn>Nº DE UC'S</TableHeadColumn>
        <TableHeadColumn>Nº DE UG'S</TableHeadColumn>
        <TableHeadColumn>COOPERATIVAS</TableHeadColumn>
        <TableHeadColumn className="text-right">AÇÕES</TableHeadColumn>
      </TableHead>
      <TableBody>
        {contactGroups.map((contactGroup: ContactGroupsList) => (
          <ContactGroupsTableItem
            key={contactGroup.id}
            contactGroups={contactGroup}
            onClickEditButton={onClickEditButton}
            onClickRemoveButton={onClickRemoveButton}
          />
        ))}
      </TableBody>
    </Table>
  );
}
