import { gql } from '@apollo/client';

export type InternalUserResetPasswordInstructionsVariables = {
  userEmail: string;
};

export const INTERNAL_USER_RESET_PASSWORD_INSTRUCTIONS_MUTATION = gql`
  mutation internalUserResetPasswordInstructions($userEmail: String!) {
    internalUserResetPasswordInstructions(userEmail: $userEmail) {
      message
    }
  }
`;
