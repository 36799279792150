type Props = {
  children: React.ReactNode;
  className?: string;
};

export default function TableHeadColumn({ children, className }: Props) {
  return (
    <th
      scope="col"
      className={`px-6 py-3 text-left font-medium text-gray-dark500 uppercase tracking-wider ${className}`}
    >
      {children}
    </th>
  );
}
