import React from 'react';

import { Button } from 'ui';

import { MdAttachFile, MdCloudUpload } from 'react-icons/md';
import useToastContext from 'ui/hooks/useToast';
import { ToastProps } from 'ui/contexts/overlay/Toast';

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 10485760;

const MAX_SIZE_TOAST: ToastProps = {
  text: 'O arquivo selecionado é maior que o tamanho máximo permitido',
  title: 'Algo deu errado!',
  variant: 'danger',
};

export type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement> & {
    label?: string;
    error?: string;
    ref?: React.Ref<HTMLInputElement>;
  },
  HTMLInputElement
>;

const FileUpload = React.forwardRef<HTMLInputElement, Props>(
  ({ error, className, disabled, label, ...otherProps }, ref) => {
    const { addToast } = useToastContext();

    const [uploadedFiles, setUploadedFiles] = React.useState<File[]>([]);

    const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
      event.target.files && setUploadedFiles(Array.from(event.target.files));

      if (
        event.target.files &&
        event.target.files[0].size > DEFAULT_MAX_FILE_SIZE_IN_BYTES
      ) {
        setUploadedFiles([]);
        addToast(MAX_SIZE_TOAST);
        return;
      }
    };

    return (
      <div className="flex w-full relative items-center justify-center">
        {uploadedFiles.length ? (
          <Button
            className="flex justify-start items-center gap-2 w-full "
            style={{
              backgroundColor: 'transparent',
              border: '2px solid #cccccc5d',
            }}
          >
            <MdAttachFile
              size={25}
              color="#cccccc5d"
              style={{
                marginRight: '10px',
              }}
            />
            <div className="flex flex-col justify-center items-start w-full gap-1">
              <p className="text-gray-dark500 font-light text-lg">
                {uploadedFiles[0]?.name}
              </p>
            </div>
          </Button>
        ) : (
          <Button
            className="flex justify-start items-center gap-2 w-full"
            style={{
              backgroundColor: 'transparent',
              border: '2px dashed #cccccc5d',
            }}
          >
            <MdCloudUpload
              size={45}
              color="FFFFFF"
              style={{
                marginRight: '10px',
              }}
            />
            <div className="flex flex-col justify-center items-start w-full gap-1">
              <label className="text-gray-dark400 font-medium">{label}</label>
              <p className="text-gray-dark400 font-light">CSV de no máximo 10 MB</p>
            </div>
          </Button>
        )}
        <input
          type="file"
          ref={ref}
          {...otherProps}
          onInput={handleInput}
          data-testid="file-input"
          title="Selecione um arquivo"
          style={{
            top: 0,
            left: 0,
            opacity: 0,
            width: '100%',
            height: '100%',
            cursor: 'pointer',
            position: 'absolute',
          }}
        />
      </div>
    );
  }
);
export default FileUpload;
