export default function PendingIcon({ OnClick }: { OnClick?: () => void }) {
  return (
    <div className="flex items-center" onClick={OnClick}>
      <svg
        width="24"
        height="27"
        viewBox="0 0 24 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.693 4.82153H6.75012C5.79548 4.82153 5.02308 5.6026 5.02308 6.55725L5.0144 20.443C5.0144 21.3976 5.7868 22.1787 6.74144 22.1787H17.1644C18.119 22.1787 18.9001 21.3976 18.9001 20.443V10.0287L13.693 4.82153ZM15.4287 18.7072H8.48583V16.9715H15.4287V18.7072ZM15.4287 15.2358H8.48583V13.5001H15.4287V15.2358ZM12.8251 10.8965V6.12332L17.5983 10.8965H12.8251Z"
          fill="#717171"
        />
        <circle
          cx="6"
          cy="7"
          r="3"
          fill="#6B6B6B"
          stroke="#111111"
          strokeWidth="2"
        />
      </svg>
    </div>
  );
}
