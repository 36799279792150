import classNames from 'classnames';
import React from 'react';
import { EmailInput } from 'ui';
import { Props as BaseProps } from 'ui/components/form/BaseInput';

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement> &
    BaseProps & {
      label?: string;
      wrapperClassName?: string;
    },
  HTMLInputElement
>;

export const EmailField = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      id,
      mask,
      name,
      type,
      error,
      label,
      required,
      disabled,
      className,
      autoComplete,
      wrapperClassName,
      ...props
    },
    ref
  ) => {
    return (
      <fieldset className={wrapperClassName}>
        <div className="flex mb-3">
          <label
            htmlFor={id}
            className={classNames('block text-sm font-medium text-gray-dark400', {
              'text-gray-dark600': disabled,
            })}
          >
            {label}
          </label>
          {required && (
            <p
              className={classNames('text-gray-dark400 h-0', {
                'text-gray-dark600': disabled,
              })}
            >
              *
            </p>
          )}
        </div>
        <EmailInput
          id={id}
          ref={ref}
          type={type}
          mask={mask}
          name={name}
          error={error}
          disabled={disabled}
          autoComplete={autoComplete}
          className={classNames(
            'mt-2 focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-dark500 bg-gray-dark600 shadow-none rounded-md hover:border-primary placeholder-gray-dark500 focus-within:text-white text-white',
            className,
            {
              'bg-gray-dark700 border-gray-dark600 hover:bg-gray-dark700 hover:border-gray-dark600 placeholder-gray-dark600 !text-gray-dark600':
                disabled,
            }
          )}
          {...props}
        />
      </fieldset>
    );
  }
);

export default EmailField;
