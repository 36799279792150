import Table from 'ui/components/table/Table';
import TableBody from 'ui/components/table/TableBody';
import TableHead from 'ui/components/table/TableHead';
import TableHeadColumn from 'ui/components/table/TableHeadColumn';

import ChargesByFinancialAccountPayoutTableItem from './ChargesByFinancialAccountPayoutTableItem';

import { ChargesByFinancialAccountPayout } from 'financialAccountPayouts/chargesByFinancialAccountPayout/models/chargesByFinancialAccountPayout';

type Props = {
  chargesByFinancialAccountPayout: ChargesByFinancialAccountPayout[];
};

export default function ChargesByFinancialAccountPayoutTable({
  chargesByFinancialAccountPayout,
}: Props) {
  return (
    <Table>
      <TableHead>
        <TableHeadColumn>ID</TableHeadColumn>
        <TableHeadColumn>UC</TableHeadColumn>
        <TableHeadColumn>COOPERADO</TableHeadColumn>
        <TableHeadColumn>VALOR</TableHeadColumn>
        <TableHeadColumn>MULTAS</TableHeadColumn>
        <TableHeadColumn>JUROS</TableHeadColumn>
        <TableHeadColumn>DATA PAGAMENTO</TableHeadColumn>
      </TableHead>
      <TableBody>
        {chargesByFinancialAccountPayout?.map(
          (financialAccountPayoutInfo: ChargesByFinancialAccountPayout) => (
            <ChargesByFinancialAccountPayoutTableItem
              key={financialAccountPayoutInfo?.id}
              customCharges={financialAccountPayoutInfo}
            />
          )
        )}
      </TableBody>
    </Table>
  );
}
