import React from 'react';
import { RadioGroup } from '@headlessui/react';
import classnames from 'classnames';

export interface Option<T> {
  label: string | React.ReactNode;
  value: T;
  disabled?: boolean;
}

export enum RadioInputVariant {
  DEFAULT = 'default',
  OUTLINED = 'outlined',
}

export type Props<T> = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'onChange' | 'value'
> & {
  options: Option<T>[];
  value: T;
  onChange: (value: T) => void;
  error?: string;
  disabled?: boolean;
  className?: string;
  variant?: RadioInputVariant;
};

const ForwardRefRadioInput = <T extends unknown>(
  { variant, options, error, className, ...props }: Props<T>,
  ref: React.ForwardedRef<HTMLDivElement>
) => (
  <>
    <RadioGroup
      as="div"
      {...props}
      className={classnames('flex flex-wrap gap-x-3.5 gap-3', className)}
      ref={ref}
    >
      {options.map(({ label, value, ...optionAttrs }) => (
        <RadioGroup.Option
          {...optionAttrs}
          value={value}
          className={({ checked, active, disabled }) =>
            classnames({
              'opacity-30': disabled,
              'flex items-center cursor-pointer border-gray-dark500 bg-gray-dark600 w-full border rounded-lg text-sm font-medium text-gray-dark400 hover:ring-gray-dark400 hover:border-gray-dark400':
                variant === RadioInputVariant.OUTLINED,
              'ring-1 ring-inset ring-primary border-primary':
                (active || checked) &&
                !disabled &&
                variant === RadioInputVariant.OUTLINED,
            })
          }
          key={JSON.stringify(value)}
        >
          {({ checked, active, disabled }) => (
            <div
              className={classnames('flex items-center w-full cursor-pointer', {
                group: !disabled,
                'h-full px-4 py-3': variant === RadioInputVariant.OUTLINED,
              })}
            >
              <div
                className={classnames(
                  'flex items-center justify-center rounded-full w-6 h-6 mr-1.5',
                  {
                    'ring-2 ring-inset ring-primary': active,
                  }
                )}
              >
                <div
                  className={classnames(
                    'w-4 h-4 bg-gray-dark600 ring-1 ring-gray-dark500 group-hover:ring-gray-dark400 ring-inset rounded-full',
                    {
                      'ring-5': checked,
                      '!ring-primary': checked,
                      '!bg-gray-dark700 !ring-gray-dark600': disabled,
                    }
                  )}
                ></div>
              </div>
              {label && (
                <span
                  className={classnames('text-gray-dark500 text-sm', {
                    '!text-gray-dark400 opacity-30': disabled,
                    '!text-gray-dark400': !disabled && checked,
                  })}
                >
                  {label}
                </span>
              )}
            </div>
          )}
        </RadioGroup.Option>
      ))}
    </RadioGroup>
    {error && <p className="text-red-500 text-xs mt-3">{error}</p>}
  </>
);

export const CheckboxInput = React.forwardRef(ForwardRefRadioInput) as <T>(
  props: Props<T> & { ref?: React.ForwardedRef<HTMLDivElement> }
) => ReturnType<typeof ForwardRefRadioInput>;

export default CheckboxInput;
