import { gql } from '@apollo/client';
import { FinancialAccount } from 'financialAccounts/models/financialAccounts';

interface FinancialAccountConnection {
  afterCursor: string | null;
  beforeCursor: string | null;
  entries: FinancialAccount[];
}

export interface FinancialAccountList {
  financialAccounts: FinancialAccountConnection;
}

export const financialAccountsTypename = 'FinancialAccountConnection';

const MAX_SIZE = 10000;

export const FINANCIAL_ACCOUNTS_SELECT_QUERY_VARIABLES = {
  variables: { pageSize: MAX_SIZE, maximumLimit: MAX_SIZE },
};

export const FINANCIAL_ACCOUNTS_QUERY = gql`
  query financialAccounts(
    $after: String
    $before: String
    $pageSize: Int
    $maximumLimit: Int
  ) {
    financialAccounts(
      after: $after
      before: $before
      pageSize: $pageSize
      maximumLimit: $maximumLimit
    ) {
      afterCursor
      beforeCursor
      entries {
        id
        financialAccountLegalName
        financialAccountEntityType
        financialAccountDocumentId
        financialAccountAddressCity
        financialAccountAddressState
        financialAccountAddressStreet
        financialAccountAddressDistrict
        financialAccountAddressPostalCode
        financialAccountAddressComplement
        bank {
          id
        }
      }
    }
  }
`;
