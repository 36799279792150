import classNames from 'classnames';

type Props = {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
};

export default function TableBodyColumn({ children, style, className }: Props) {
  return (
    <td
      className={classNames('px-6 py-4 whitespace-nowrap', className)}
      style={style}
    >
      {children}
    </td>
  );
}
