import { useState } from 'react';

import classNames from 'classnames';

import { FieldError } from 'react-hook-form';

import { MdChevronLeft } from 'react-icons/md';

export type AccordionProps = {
  title: string;
  open?: boolean;
  length?: number;
  className?: string;
  required?: boolean;
  children?: React.ReactNode;
  error?: string | FieldError;
};

export default function Accordion({
  title,
  error,
  children,
  className,
  length = 0,
  open = false,
  required = false,
}: AccordionProps) {
  const isEmpty = length === 0;
  const [isOpen, setIsOpen] = useState(open);

  return (
    <div>
      <div
        className={classNames(
          'flex flex-col items-center justify-center w-full bg-gray-dark600 shadow-none rounded-md focus:outline-none',
          {
            'border border-gray-dark500': isOpen && !error,
            'border border-red-500 shadow-none rounded-md hover:border-red-500':
              error && isOpen,
          }
        )}
      >
        <div
          className={classNames(
            'flex w-full sm:text-sm bg-gray-dark600 shadow-none rounded-md text-gray-dark400 focus:outline-none',
            className,
            {
              'border border-gray-dark500': !isOpen && !error,
              'border-b border-gray-dark500': !!children && isOpen && !error,
              'border border-red-500 shadow-none rounded-md hover:border-red-500':
                error && !isOpen,
              'border-b border-red-500 shadow-none rounded-md hover:border-red-500':
                !!children && error && isOpen,
            }
          )}
        >
          <button
            type="button"
            className={classNames(
              'flex items-center justify-between w-full px-6 py-3 text-left text-sm font-medium text-gray-dark400 rounded-md focus:outline-none',
              {
                'text-gray-dark500': isEmpty,
              }
            )}
            onClick={() => setIsOpen(!isOpen)}
          >
            <div className="flex items-center justify-start gap-2">
              <MdChevronLeft
                size={20}
                aria-hidden="true"
                style={{ transform: `rotate(${isOpen ? 90 : -90}deg)` }}
              />
              <span className="font-bold">
                {title}
                {required && '*'}
              </span>
            </div>
            <p
              className={classNames('text-gray-dark400 text-sm font-light', {
                'text-gray-dark500': isEmpty,
              })}
            >
              {length} itens
            </p>
          </button>
        </div>

        {!!children && isOpen && (
          <div className="w-full px-10 py-6 flex flex-col gap-4">{children}</div>
        )}
      </div>
      {!!error && (
        <p className="text-red-500 text-xs mt-3 font-normal text-left">{error}</p>
      )}
    </div>
  );
}
