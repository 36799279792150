import { TariffFlagTariff } from './tariffFlagTariff';

export type TariffFlagModalityId = number;

export enum TariffFlagModalityModalityFlagType {
  GREEN = 'GREEN',
  YELLOW = 'YELLOW',
  RED_ON_PEAK = 'RED_ON_PEAK',
  RED_ON_PEAK_TWO = 'RED_ON_PEAK_TWO',
}
export interface TariffFlagModality {
  id: TariffFlagModalityId;
  tariffFlagModalityModalityFlag: TariffFlagModalityModalityFlagType;
  tariffFlagModalityValidityDate: Date;
  tariffFlagTariff: TariffFlagTariff;
}
