import { gql } from '@apollo/client';
import { GenerationUnit } from 'generationUnits/models/generationUnit';

interface GenerationUnitConnection {
  afterCursor: string | null;
  beforeCursor: string | null;
  entries: GenerationUnit[];
}

export interface GenerationUnitsList {
  generationUnits: GenerationUnitConnection;
}

export const GenerationUnitsTypename = 'GenerationUnit';

export const GENERATION_UNITS_QUERY = gql`
  query generationUnits(
    $after: String
    $before: String
    $pageSize: Int
    $filters: GenerationUnitFilterOptions
    $maximumLimit: Int
  ) {
    generationUnits(
      after: $after
      before: $before
      pageSize: $pageSize
      filters: $filters
      maximumLimit: $maximumLimit
    ) {
      entries {
        id
        generationUnitLegalName
        generationUnitCapacityFactorAverage
        generationUnitEnergySource
        generationUnitAddressState
        powerDistributionUnitCredentialsUser
        generationUnitPowerCapacity
        cooperative {
          cooperativeLegalName
        }
        financialAccount {
          financialAccountLegalName
        }
        powerDistributionUnit {
          powerDistributionUnitLegalName
        }
        commercialAgreements {
          id
          commercialAgreementStartValidity
          commercialAgreementHasPisCofins
          commercialAgreementHasExtraRemuneration
          commercialAgreementReceiptRule
          commercialAgreementLeaseValue
          commercialAgreementOemValue
          commercialAgreementHasOem
          commercialAgreementDiscountMin
          commercialAgreementDiscountMax
          commercialAgreementBaseCostPerCreditIcms
          commercialAgreementBaseCostPerCredit
        }
      }
      beforeCursor
      afterCursor
    }
  }
`;
