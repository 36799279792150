import { gql } from '@apollo/client';

interface PowerDistributionUnitsSelectConnection {
  entries: {
    id: number;
    powerDistributionUnitLegalName: string;
  }[];
}

export interface PowerDistributionUnitsSelectList {
  powerDistributionUnits: PowerDistributionUnitsSelectConnection;
}

export const powerDistributionUnitTypename = 'PowerDistributionUnitConnection';

const MAX_SIZE = 10000;

export const POWER_DISTRIBUTION_UNITS_SELECT_QUERY_VARIABLES = {
  variables: { pageSize: MAX_SIZE, maximumLimit: MAX_SIZE },
};

export const POWER_DISTRIBUTION_UNITS_SELECT_QUERY = gql`
  query powerDistributionUnits(
    $after: String
    $before: String
    $pageSize: Int
    $maximumLimit: Int
  ) {
    powerDistributionUnits(
      after: $after
      before: $before
      pageSize: $pageSize
      maximumLimit: $maximumLimit
    ) {
      entries {
        id
        powerDistributionUnitLegalName
      }
    }
  }
`;
