import { gql } from '@apollo/client';
import {
  IcmsConsumerUnitConsumptionClassType,
  IcmsTax,
} from 'icmsTaxes/models/icmsTaxes';

export type IcmsRangesFeeType = {
  consumptionRangePercentage: string;
  consumptionKwhStartRange: string;
  consumptionKwhEndRange: string;
};

export interface IcmsTaxCreateMutationInput {
  icmsCountryState: string;
  icmsConsumerUnitConsumptionClass: IcmsConsumerUnitConsumptionClassType;
  icmsValidityStartDate: string;
  icmsValidityEndDate: string;
  icmsRangesFee: IcmsRangesFeeType[];
}

export interface IcmsTaxMutationVariables {
  icmsTaxCreateInput: IcmsTaxCreateMutationInput;
}

export interface IcmsTaxCreated {
  icmsTaxCreate: IcmsTax;
}

export const ICMS_TAX_CREATE_MUTATION = gql`
  mutation icmsTaxCreate($icmsTaxCreateInput: IcmsTaxCreateInput!) {
    icmsTaxCreate(input: $icmsTaxCreateInput) {
      id
      icmsCountryState
      icmsConsumerUnitConsumptionClass
      icmsValidityStartDate
      icmsValidityEndDate
      icmsRangesFee {
        consumptionRangePercentage
        consumptionKwhStartRange
        consumptionKwhEndRange
      }
    }
  }
`;
