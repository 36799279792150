export default function UnavailableIcon({ OnClick }: { OnClick?: () => void }) {
  return (
    <div className="flex items-center" onClick={OnClick}>
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.25 1.75C4.29253 1.75 3.5 2.54253 3.5 3.5V17.5C3.5 18.4575 4.29253 19.25 5.25 19.25H15.75C16.7075 19.25 17.5 18.4575 17.5 17.5V7L12.25 1.75H5.25ZM5.25 3.5H11.375V7.875H15.75V17.5H5.25V3.5ZM7 10.5V12.25H14V10.5H7ZM7 14V15.75H14V14H7Z"
          fill="#717171"
        />
      </svg>
    </div>
  );
}
