import classNames from 'classnames';

type Props = {
  children: React.ReactNode;
  className?: string;
  dataTestId?: string;
};

export default function TableBodyRow({ children, className, dataTestId }: Props) {
  return (
    <tr
      className={classNames(
        'text-sm font-medium text-gray-dark500 hover:text-gray-dark400 hover:bg-gray-dark800',
        className
      )}
      data-testid={dataTestId}
    >
      {children}
    </tr>
  );
}
