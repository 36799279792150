import Login from 'auth/components/login/Login';
import NewPasswordForm from 'auth/components/login/NewPasswordForm';
import { LoadingProvider } from 'ui/contexts/overlay/Loading';

const NewPasswordPage = () => (
  <LoadingProvider>
    <Login>
      <NewPasswordForm />
    </Login>
  </LoadingProvider>
);

export default NewPasswordPage;
