import { gql } from '@apollo/client';

export type InternalUserDeleteMutationVariables = {
  id: number;
};

export const INTERNAL_USER_DELETE_MUTATION = gql`
  mutation internalUserDelete($id: ID!) {
    internalUserDelete(userId: $id) {
      message
    }
  }
`;
