import Table from 'ui/components/table/Table';
import TableBody from 'ui/components/table/TableBody';
import TableHead from 'ui/components/table/TableHead';
import TableHeadColumn from 'ui/components/table/TableHeadColumn';

import { FinancialAccountPayouts } from 'financialAccountPayouts/models/financialAccountPayouts';

import FinancialAccountPayoutsTableItem from './FinancialAccountPayoutsTableItem';

type Props = {
  financialAccountPayouts: FinancialAccountPayouts[];
};

export default function FinancialAccountPayoutsTable({
  financialAccountPayouts,
}: Props) {
  return (
    <Table>
      <TableHead>
        <TableHeadColumn>ID</TableHeadColumn>
        <TableHeadColumn>CONTA FINANCEIRA</TableHeadColumn>
        <TableHeadColumn>DATA SOLICITAÇÃO</TableHeadColumn>
        <TableHeadColumn>DATA TRANSFERÊNCIA</TableHeadColumn>
        <TableHeadColumn>VALOR TRANSFERÊNCIA</TableHeadColumn>
        <TableHeadColumn>STATUS</TableHeadColumn>
        <TableHeadColumn className="text-center">DETALHES</TableHeadColumn>
      </TableHead>
      <TableBody>
        {financialAccountPayouts.map((transfer: FinancialAccountPayouts) => (
          <FinancialAccountPayoutsTableItem
            key={transfer.id}
            financialAccountPayouts={transfer}
          />
        ))}
      </TableBody>
    </Table>
  );
}
