export default function SuccessIcon({ OnClick }: { OnClick?: () => void }) {
  return (
    <div className="flex items-center justify-center" onClick={OnClick}>
      <svg
        width="31"
        height="35"
        viewBox="0 0 31 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_13714_21046)">
          <path
            d="M17.75 6.25H8.75C7.5125 6.25 6.51125 7.2625 6.51125 8.5L6.5 26.5C6.5 27.7375 7.50125 28.75 8.73875 28.75H22.25C23.4875 28.75 24.5 27.7375 24.5 26.5V13L17.75 6.25ZM20 24.25H11V22H20V24.25ZM20 19.75H11V17.5H20V19.75ZM16.625 14.125V7.9375L22.8125 14.125H16.625Z"
            fill="#717171"
          />
        </g>
        <defs>
          <clipPath id="clip0_13714_21046">
            <rect width="31" height="35" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}
