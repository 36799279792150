import { Button } from 'ui';

import { MdClose } from 'react-icons/md';
import { IoMdCloudDownload } from 'react-icons/io';

import { convertToBRL, convertToDecimal } from 'utils/form';

import { ConsumerUnitBillingCycle } from 'consumerUnits/consumerUnitBillingCycles/models/consumerUnitBillingCycles';

export type Props = {
  onClickCloseModal?: () => void;
  consumerUnitBillingCycle?: ConsumerUnitBillingCycle;
};

export default function ConsumerUnitBillDataEconomyWallet({
  onClickCloseModal,
  consumerUnitBillingCycle,
}: Props) {
  const {
    economyReport: {
      economyValue = '0',
      economyReportUrl = '',
      economyValueMonthlyPercentage = '0',
    } = {},
    economyValuesAveragePercentage: {
      economyValueSum = '0',
      economyValuePercentage = '0',
    } = {},
    consumerUnit: { consumerUnitPowerDistributionUnitIdentifier = '' } = {},
  } = consumerUnitBillingCycle || {};

  return (
    <div className="flex flex-col items-start justify-start gap-2 h-screen fixed bg-gray-dark700 right-0 top-0 border-l border-gray-dark600">
      <div className="flex px-10 py-8 items-center justify-start gap-12 border-b border-gray-dark600">
        <h1 className="text-gray-dark400 w-full font-normal text-2xl">
          Carteira de economia
        </h1>
        <Button
          type="button"
          size="none"
          variant="tertiaryGray"
          style={{
            padding: '.1rem',
          }}
        >
          <MdClose size={20} color="#6B6B6B" onClick={onClickCloseModal} />
        </Button>
      </div>
      <div className="w-full flex flex-col px-10 py-8 gap-4">
        <div className="flex items-center justify-start gap-x-2">
          <h2 className="text-gray-dark400 leading-4">UC:</h2>
          <span className="text-gray-dark400 font-bold">
            {consumerUnitPowerDistributionUnitIdentifier}
          </span>
        </div>
      </div>
      <div className="flex w-full flex-col px-10 py-8 border-t border-gray-dark600 gap-4">
        <div>
          <h2 className="text-gray-dark400 leading-4">Economia mensal:</h2>
          <p className="text-gray-dark400 font-bold">
            {convertToBRL(economyValue)} (
            {convertToDecimal(economyValueMonthlyPercentage)}%)
          </p>
        </div>
      </div>
      <div className="flex w-full flex-col px-10 py-8 border-t border-gray-dark600 gap-4">
        <div>
          <h2 className="text-gray-dark400 leading-4">Economia total com a NEX:</h2>
          <p className="text-gray-dark400 font-bold">
            {convertToBRL(economyValueSum)} (
            {convertToDecimal(economyValuePercentage)}% médio)
          </p>
        </div>
      </div>
      <a href={economyReportUrl} target="_blank" rel="noreferrer">
        <Button
          size="none"
          type="button"
          variant="tertiaryGray"
          className="flex justify-start items-center p-2 ml-10"
        >
          <IoMdCloudDownload
            size={30}
            color="#B6B6B6"
            style={{
              marginLeft: '5px',
              marginRight: '5px',
            }}
          />
          <div className="flex flex-col justify-center items-start cursor-pointer">
            <p className="font-normal text-sm text-gray-dark300 cursor-pointer">
              Relatório de economia
            </p>
          </div>
        </Button>
      </a>
    </div>
  );
}
