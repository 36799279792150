import { Button } from 'ui';
import { Dashboard, DashboardMainHeaderForm } from 'dashboard/components/dashboard';
import CommercialAgreementFields, {
  FormFields,
} from 'commercialAgreements/components/form/CommercialAgreementsFormFields';

import {
  GENERATION_UNITS_SELECT_QUERY,
  GENERATION_UNITS_SELECT_QUERY_VARIABLES,
  GenerationUnitsSelectList,
} from 'generationUnits/graphql/generationUnitsSelectQuery';

import { Page } from 'dashboard/components/dashboard/Breadcrumbs';

import { useForm, SubmitHandler, ErrorOption } from 'react-hook-form';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { formatDateToSubmitDateString, setFormError } from 'utils/form';
import { unMaskFormFields } from 'utils/unmask';

import {
  COMMERCIAL_AGREEMENT_CREATE_MUTATION,
  CommercialAgreementCreated,
  CommercialAgreementMutationVariables,
} from 'commercialAgreements/graphql/commercialAgreementCreateMutation';

import useToastContext from 'ui/hooks/useToast';
import { useHistory } from 'react-router';
import { ToastProps } from 'ui/contexts/overlay/Toast';
import { useLoading } from 'ui/contexts/overlay/Loading';
import { useEffect } from 'react';
import { has } from 'lodash';
import {
  CommercialAgreementsList,
  CommercialAgreementsTypename,
  COMMERCIAL_AGREEMENTS_QUERY,
} from 'commercialAgreements/graphql/commercialAgreementsQuery';
const DASHBOARD_COMMERCIAL_AGREEMENTS_ROUTE =
  '/dashboard/billing-cycle/commercial-agreements';

const BREADCRUMB_PAGES: Page[] = [
  {
    name: 'Acordos Comerciais',
    route: DASHBOARD_COMMERCIAL_AGREEMENTS_ROUTE,
    current: false,
  },
  {
    name: 'Novo Acordo Comercial',
    route: null,
    current: true,
  },
];
let TITLE = 'Novo Acordo Comercial';
const CREATE_ERROR_TOAST: ToastProps = {
  text: 'Houve um erro ao tentar criar o Acordo Comercial',
  title: 'Algo deu errado!',
  variant: 'danger',
};

const CREATE_SUCCESS_TOAST: ToastProps = {
  title: 'Sucesso!',
  variant: 'primary',
  text: 'Sucesso ao cadastrar um novo acordo.',
};

export default function CreateCommercialAgreementsPage() {
  const { push } = useHistory();
  const { addToast } = useToastContext();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    resetField,
    control,
    formState: { errors },
  } = useForm<FormFields>();

  const { showLoading, closeLoading, LoadingOverlay } = useLoading();

  const [commercialAgreementCreateMutation, { loading: loadingCreate }] =
    useMutation<CommercialAgreementCreated, CommercialAgreementMutationVariables>(
      COMMERCIAL_AGREEMENT_CREATE_MUTATION,
      {
        onError(error: ApolloError) {
          if (has(error.graphQLErrors[0], 'details')) {
            addToast(CREATE_ERROR_TOAST);
          }

          setFormError(
            error,
            (field: string, error: ErrorOption) => {
              setError(field as keyof FormFields, error);

              setTimeout(() => clearErrors(), 2500);
            },
            t
          );
        },
        onCompleted() {
          addToast(CREATE_SUCCESS_TOAST);
          push(DASHBOARD_COMMERCIAL_AGREEMENTS_ROUTE);
        },
      }
    );

  const [
    generationUnitsSelect,
    {
      data: generationUnits,
      loading: loadingGenerationUnits,
      refetch: refetchGenerationUnits,
    },
  ] = useLazyQuery<GenerationUnitsSelectList>(
    GENERATION_UNITS_SELECT_QUERY,
    GENERATION_UNITS_SELECT_QUERY_VARIABLES
  );

  const onSubmit: SubmitHandler<FormFields> = (commercialAgreementCreateInput) => {
    const commercialAgreementStartValidity =
      commercialAgreementCreateInput.commercialAgreementStartValidity;
    const convertCommercialAgreementStartValidity = new Date(
      commercialAgreementStartValidity.getFullYear(),
      commercialAgreementStartValidity.getMonth(),
      1
    );
    commercialAgreementCreateInput = unMaskFormFields(
      commercialAgreementCreateInput
    );
    commercialAgreementCreateMutation({
      variables: {
        commercialAgreementCreateInput: {
          commercialAgreementHasPisCofins:
            commercialAgreementCreateInput.commercialAgreementHasPisCofins,
          commercialAgreementHasExtraRemuneration:
            commercialAgreementCreateInput.commercialAgreementHasExtraRemuneration,
          commercialAgreementReceiptRule:
            commercialAgreementCreateInput.commercialAgreementReceiptRule,
          commercialAgreementOemValue:
            commercialAgreementCreateInput.commercialAgreementOemValue,
          commercialAgreementLeaseValue:
            commercialAgreementCreateInput.commercialAgreementLeaseValue,
          commercialAgreementHasOem:
            commercialAgreementCreateInput.commercialAgreementHasOem,
          commercialAgreementDiscountMax:
            commercialAgreementCreateInput.commercialAgreementDiscountMax,
          commercialAgreementDiscountMin:
            commercialAgreementCreateInput.commercialAgreementDiscountMin,
          commercialAgreementBaseCostPerCreditIcms:
            commercialAgreementCreateInput.commercialAgreementBaseCostPerCreditIcms,
          commercialAgreementBaseCostPerCredit:
            commercialAgreementCreateInput.commercialAgreementBaseCostPerCredit,
          generationUnitId: commercialAgreementCreateInput.generationUnitId,
          commercialAgreementStartValidity: formatDateToSubmitDateString(
            String(convertCommercialAgreementStartValidity)
          ),
        },
      },
      update(cache, { data }) {
        const existingData = cache.readQuery<CommercialAgreementsList>({
          query: COMMERCIAL_AGREEMENTS_QUERY,
        });
        cache.writeQuery({
          query: COMMERCIAL_AGREEMENTS_QUERY,
          data: {
            commercialAgreements: {
              __typename: CommercialAgreementsTypename,
              afterCursor: existingData?.commercialAgreements.afterCursor || null,
              beforeCursor: existingData?.commercialAgreements.beforeCursor || null,
              entries: [
                data?.commercialAgreementCreate,
                ...(existingData?.commercialAgreements.entries || []),
              ],
            },
          },
        });
      },
    });
  };

  const isLoading = loadingCreate || loadingGenerationUnits;

  useEffect(() => {
    if (isLoading) {
      showLoading();
      return;
    }

    closeLoading();
  }, [isLoading, showLoading, closeLoading]);

  useEffect(() => {
    refetchGenerationUnits && refetchGenerationUnits();

    generationUnitsSelect();
  }, [generationUnitsSelect, refetchGenerationUnits]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay />
      <Dashboard
        dashboardHeader={<></>}
        dashboardMainHeaderTitle={
          <DashboardMainHeaderForm breadcrumbPages={BREADCRUMB_PAGES} title={TITLE}>
            <Button type="submit" size="sm" disabled={isLoading}>
              Salvar
            </Button>
          </DashboardMainHeaderForm>
        }
      >
        <CommercialAgreementFields
          generationUnits={generationUnits?.generationUnits.entries}
          setValue={setValue}
          resetField={resetField}
          clearErrors={clearErrors}
          control={control}
          register={register}
          errors={errors}
        />
      </Dashboard>
    </form>
  );
}
