export type IcmsTaxId = number;

export enum IcmsConsumerUnitConsumptionClassType {
  COMMERCIAL = 'COMERCIAL',
  RESIDENTIAL = 'RESIDENCIAL',
  INDUSTRIAL = 'INDUSTRIAL',
}

export type IcmsRangesFeeType = {
  consumptionRangePercentage: String;
  consumptionKwhStartRange: String;
  consumptionKwhEndRange: String;
};

export interface IcmsTax {
  id: IcmsTaxId;
  icmsCountryState: String;
  icmsConsumerUnitConsumptionClass: IcmsConsumerUnitConsumptionClassType;
  icmsValidityStartDate: Date;
  icmsValidityEndDate: Date;
  icmsRangesFee: IcmsRangesFeeType[];
}
