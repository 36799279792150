import { gql } from '@apollo/client';

import { Groups, ContactGroups, Contact } from 'contactGroups/models/contactGroups';

export interface ContactGroupsUpdateMutationInput {
  id: number;
  group: Groups;
  contacts: Contact[];
  entities: {
    cooperatives: {
      id: number;
    }[];
    consumerUnits: {
      id: number;
    }[];
    generationUnits: {
      id: number;
    }[];
  };
}

export interface ContactGroupsUpdateMutationVariables {
  contactGroupUpdateInput: ContactGroupsUpdateMutationInput;
}

export interface ContactGroupsUpdated {
  contactGroups: ContactGroups;
}

export const CONTACT_GROUP_UPDATE_MUTATION = gql`
  mutation contactGroupUpdate($contactGroupUpdateInput: ContactGroupUpdateInput!) {
    contactGroupUpdate(input: $contactGroupUpdateInput) {
      group {
        id
        groupName
      }
      contacts {
        id
        contactName
        contactEmail
        contactPhone
        contactType {
          id
          contactTypeName
        }
      }
      entities {
        cooperatives {
          id
        }
        consumerUnits {
          id
        }
        generationUnits {
          id
        }
      }
    }
  }
`;
