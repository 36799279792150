import { Button } from 'ui';
import { Dashboard, DashboardMainHeaderForm } from 'dashboard/components/dashboard';
import GenerationUnitFields, {
  FormFields,
} from 'generationUnits/components/form/GenerationUnitFormFields';
import { Page } from 'dashboard/components/dashboard/Breadcrumbs';

import { useForm, SubmitHandler, ErrorOption } from 'react-hook-form';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { setFormError } from 'utils/form';
import {
  GenerationUnitCreated,
  GenerationUnitMutationVariables,
  GENERATION_UNIT_CREATE_MUTATION,
} from 'generationUnits/graphql/generationUnitCreateMutation';
import {
  GenerationUnitsList,
  GenerationUnitsTypename,
  GENERATION_UNITS_QUERY,
} from 'generationUnits/graphql/generationUnitsQuery';
import {
  POWER_DISTRIBUTION_UNITS_SELECT_QUERY,
  POWER_DISTRIBUTION_UNITS_SELECT_QUERY_VARIABLES,
  PowerDistributionUnitsSelectList,
} from 'powerDistributionUnits/graphql/powerDistributionUnitsSelectQuery';
import {
  COOPERATIVES_SELECT_QUERY,
  COOPERATIVES_SELECT_QUERY_VARIABLES,
  CooperativesSelectList,
} from 'cooperatives/graphql/cooperativesSelectQuery';
import {
  FINANCIAL_ACCOUNTS_QUERY,
  FINANCIAL_ACCOUNTS_SELECT_QUERY_VARIABLES,
  FinancialAccountList,
} from 'financialAccounts/graphql/financialAccountsQuery';

import useToastContext from 'ui/hooks/useToast';
import { useHistory } from 'react-router';
import { ToastProps } from 'ui/contexts/overlay/Toast';
import { useLoading } from 'ui/contexts/overlay/Loading';
import { useEffect } from 'react';
import { has } from 'lodash';

const DASHBOARD_GENERATION_UNITS_ROUTE =
  '/dashboard/business-entities/generation-units';

const BREADCRUMB_PAGES: Page[] = [
  {
    name: 'Unidades Geradoras',
    route: DASHBOARD_GENERATION_UNITS_ROUTE,
    current: false,
  },
  {
    name: 'Nova Unidade Geradora',
    route: null,
    current: true,
  },
];
const TITLE = 'Nova Unidade Geradora';
const CREATE_ERROR_TOAST: ToastProps = {
  text: 'Houve um erro ao tentar criar a Unidade Geradora',
  title: 'Algo deu errado!',
  variant: 'danger',
};

const CREATE_SUCCESS_TOAST: ToastProps = {
  title: 'Sucesso!',
  variant: 'primary',
  text: 'Sucesso ao cadastrar a Unidade Geradora.',
};

export default function CreateGenerationUnitsPage() {
  const { push } = useHistory();
  const { addToast } = useToastContext();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    control,
    formState: { errors },
  } = useForm<FormFields>();
  const { showLoading, closeLoading, LoadingOverlay } = useLoading();

  const [generationUnitCreateMutation, { loading: loadingCreate }] = useMutation<
    GenerationUnitCreated,
    GenerationUnitMutationVariables
  >(GENERATION_UNIT_CREATE_MUTATION, {
    onError(error: ApolloError) {
      if (has(error.graphQLErrors[0], 'details')) {
        addToast(CREATE_ERROR_TOAST);
      }

      setFormError(
        error,
        (field: string, error: ErrorOption) => {
          setError(field as keyof FormFields, error);

          setTimeout(() => clearErrors(), 2500);
        },
        t
      );
    },
    onCompleted() {
      addToast(CREATE_SUCCESS_TOAST);
      push(DASHBOARD_GENERATION_UNITS_ROUTE);
    },
  });

  const [
    powerDistributionUnitsSelect,
    {
      data: powerDistributionUnits,
      loading: loadingPowerDistributionUnits,
      refetch: refetchPowerDistributionUnits,
    },
  ] = useLazyQuery<PowerDistributionUnitsSelectList>(
    POWER_DISTRIBUTION_UNITS_SELECT_QUERY,
    POWER_DISTRIBUTION_UNITS_SELECT_QUERY_VARIABLES
  );

  const [
    cooperativesSelect,
    {
      data: cooperatives,
      loading: loadingCooperatives,
      refetch: refetchCooperatives,
    },
  ] = useLazyQuery<CooperativesSelectList>(
    COOPERATIVES_SELECT_QUERY,
    COOPERATIVES_SELECT_QUERY_VARIABLES
  );

  const [
    financialAccountsSelect,
    {
      data: financialAccounts,
      loading: loadingFinancialAccounts,
      refetch: refetchFinancialAccounts,
    },
  ] = useLazyQuery<FinancialAccountList>(
    FINANCIAL_ACCOUNTS_QUERY,
    FINANCIAL_ACCOUNTS_SELECT_QUERY_VARIABLES
  );

  const onSubmit: SubmitHandler<FormFields> = (generationUnitCreateInput) => {
    generationUnitCreateInput.generationUnitAddressPostalCode = Number(
      generationUnitCreateInput.generationUnitAddressPostalCode
    );
    generationUnitCreateInput.generationUnitPowerCapacity = Number(
      generationUnitCreateInput.generationUnitPowerCapacity
    );
    generationUnitCreateMutation({
      variables: {
        generationUnitCreateInput,
      },
      update(cache, { data }) {
        const existingData = cache.readQuery<GenerationUnitsList>({
          query: GENERATION_UNITS_QUERY,
        });
        cache.writeQuery({
          query: GENERATION_UNITS_QUERY,
          data: {
            generationUnits: {
              __typename: GenerationUnitsTypename,
              afterCursor: existingData?.generationUnits.afterCursor || null,
              beforeCursor: existingData?.generationUnits.beforeCursor || null,
              entries: [
                data?.generationUnitCreate,
                ...(existingData?.generationUnits.entries || []),
              ],
            },
          },
        });
      },
    });
  };

  const isLoading =
    loadingPowerDistributionUnits ||
    loadingCreate ||
    loadingCooperatives ||
    loadingFinancialAccounts;

  useEffect(() => {
    if (isLoading) {
      showLoading();
      return;
    }

    closeLoading();
  }, [isLoading, showLoading, closeLoading]);

  useEffect(() => {
    refetchPowerDistributionUnits && refetchPowerDistributionUnits();

    powerDistributionUnitsSelect();
  }, [powerDistributionUnitsSelect, refetchPowerDistributionUnits]);

  useEffect(() => {
    refetchCooperatives && refetchCooperatives();

    cooperativesSelect();
  }, [cooperativesSelect, refetchCooperatives]);

  useEffect(() => {
    refetchFinancialAccounts && refetchFinancialAccounts();

    financialAccountsSelect();
  }, [financialAccountsSelect, refetchFinancialAccounts]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay />
      <Dashboard
        dashboardHeader={<></>}
        dashboardMainHeaderTitle={
          <DashboardMainHeaderForm breadcrumbPages={BREADCRUMB_PAGES} title={TITLE}>
            <Button type="submit" size="sm" disabled={isLoading}>
              Salvar
            </Button>
          </DashboardMainHeaderForm>
        }
      >
        <GenerationUnitFields
          powerDistributionUnits={
            powerDistributionUnits?.powerDistributionUnits.entries
          }
          control={control}
          cooperatives={cooperatives?.cooperatives.entries}
          financialAccounts={financialAccounts?.financialAccounts.entries}
          errors={errors}
          register={register}
        />
      </Dashboard>
    </form>
  );
}
