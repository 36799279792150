import masks, { MaskInterface } from './masks';
import { LOCALE_DATE_CONFIG } from './form';
import { Props } from 'ui/components/form/BaseInput';

export function handleValidateDays(month: number, year: number) {
  switch (month) {
    case 2:
      if (year % 4) {
        return 28;
      }
      if (year % 100) {
        return 29;
      }
      if (year % 400) {
        return 28;
      }
      return 29;
    case 9:
    case 4:
    case 6:
    case 11:
      return 30;
    default:
      return 31;
  }
}

export function validateIndividualDocument(document: string): boolean {
  if (document === '00000000000') return false;

  let rest;
  let sum = 0;

  for (let i = 1; i <= 9; i++) {
    sum += parseInt(document.substring(i - 1, i)) * (11 - i);
  }

  rest = (sum * 10) % 11;

  if ([10, 11].includes(rest)) rest = 0;
  if (rest !== parseInt(document.substring(9, 10))) return false;

  sum = 0;
  for (let i = 1; i <= 10; i++) {
    sum += parseInt(document.substring(i - 1, i)) * (12 - i);
  }

  rest = (sum * 10) % 11;

  if ([10, 11].includes(rest)) rest = 0;
  if (rest !== parseInt(document.substring(10, 11))) return false;

  return true;
}

const calculateCompanyDocumentDigits = (index: number, numbers: number[]) => {
  let sum = 0;
  let factor = index - 7;

  for (let i = index; i >= 1; i--) {
    const number = numbers[index - i];

    sum += number * factor--;
    if (factor < 2) factor = 9;
  }

  const result = 11 - (sum % 11);
  return result > 9 ? 0 : result;
};

export function validateCompanyDocument(document: string): boolean {
  const numbers = document.split('').map((n) => parseInt(n, 10));

  const digits = numbers.slice(12);
  const digit0 = calculateCompanyDocumentDigits(12, numbers);

  if (digit0 !== digits[0]) return false;

  const digit1 = calculateCompanyDocumentDigits(13, numbers);
  return digit1 === digits[1];
}

export function handleValidateEntityType(entityType: string) {
  switch (entityType) {
    case 'INDIVIDUAL':
      return 'PF';
    case 'COMPANY':
      return 'PJ';
    default:
      break;
  }
}

export function handleOnChange(
  event: React.ChangeEvent<HTMLInputElement>,
  props: Props,
  setInputError: (error: string) => void,
  mask?: MaskInterface
) {
  if (mask) {
    event.target.value = masks[mask](event.target.value);
  }
  setInputError('');

  props.onChange && props.onChange(event);
}

export function handleValidateMaskOnBlur(
  event: React.FocusEvent<HTMLInputElement>,
  props: Props,
  setInputError: (error: string) => void,
  mask?: MaskInterface
) {
  switch (mask) {
    case 'date':
      const splitedDate = event.target.value.split('/');

      if (event.target.value.split('/').length > 2) {
        const day = Number(splitedDate[0]);
        const month = Number(splitedDate[1]);
        const year = Number(splitedDate[2]);

        if (
          month < 1 ||
          month > 12 ||
          year < 1 ||
          handleValidateDays(month, year) < day
        ) {
          setInputError('Data inválida');
        } else {
          return (event.target.value = new Date(
            year,
            month - 1,
            day
          ).toLocaleDateString(LOCALE_DATE_CONFIG.locale));
        }
      } else if (event.target.value.split('/').length === 2) {
        const day = 1;
        const month = Number(splitedDate[0]);
        const year = Number(splitedDate[1]);

        if (
          month < 1 ||
          month > 12 ||
          year < 1 ||
          handleValidateDays(month, year) < day
        ) {
          setInputError('Data inválida');
        } else {
          return (event.target.value =
            new Date(year, month - 1, day)
              .toLocaleDateString(LOCALE_DATE_CONFIG.locale)
              .split('/')[1] +
            '/' +
            year);
        }
      } else if (event.target.value.length < 3 && event.target.value !== '') {
        setInputError('Data inválida');
      }
      break;
    case 'decimalEightDigitsPrecision':
      const splitedDecimal = event.target.value.split(',');

      if (splitedDecimal[0] === '' && event.target.value !== '') {
        return (event.target.value = '0,' + splitedDecimal[1]);
      }
      break;
    case 'document':
      const document = event.target.value.replace(/\D/g, '');

      if (document.length === 11) {
        if (!validateIndividualDocument(document)) {
          setInputError('CPF inválido');
        }
      } else if (document.length === 14) {
        if (!validateCompanyDocument(document)) {
          setInputError('CNPJ inválido');
        }
      }
      break;
  }

  props.onBlur && props.onBlur(event);
}
