import { CSSProperties } from 'react';

interface Props {
  style?: CSSProperties;
  className?: string;
}

export default function EconomyReportGroupA({ style, className }: Props) {
  return (
    <div
      className={className || 'flex items-center'}
      style={
        style || {
          width: '270px',
          height: '143px',
        }
      }
    >
      <svg
        width="270"
        height="143"
        viewBox="0 0 270 143"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="88.2019"
          y="111.987"
          width="73.8631"
          height="23.12"
          stroke="#6B6B6B"
        />
        <path
          d="M86.3211 111.442L97.9016 98.5615H152.366L163.946 111.442H86.3211Z"
          stroke="#6B6B6B"
        />
        <rect
          x="119.376"
          y="119.724"
          width="10.8325"
          height="15.3834"
          stroke="#6B6B6B"
        />
        <rect
          x="112.194"
          y="119.377"
          width="8.9584"
          height="18.2944"
          transform="rotate(90 112.194 119.377)"
          stroke="#6B6B6B"
        />
        <rect
          x="156.384"
          y="119.377"
          width="8.9584"
          height="18.2944"
          transform="rotate(90 156.384 119.377)"
          stroke="#6B6B6B"
        />
        <rect
          x="82.2859"
          y="135.652"
          width="86.1507"
          height="6.28152"
          stroke="#6B6B6B"
        />
        <path
          d="M147.55 68.4721V98.2892M147.55 68.4721H184.41V55.7378H145.847L143.679 68.4721H147.55Z"
          stroke="#6B6B6B"
        />
        <path d="M159.84 106.822V72.0337H176.956V111.879" stroke="#6B6B6B" />
        <path
          d="M151.978 50.9146L157.751 38.7168H176.482L182.255 50.9146H151.978Z"
          stroke="#6B6B6B"
        />
        <path
          d="M158.751 55.9235V50.9443M176.023 55.9235V50.9443"
          stroke="#6B6B6B"
        />
        <rect
          x="164.43"
          y="76.3457"
          width="8.80477"
          height="3.64436"
          stroke="#6B6B6B"
        />
        <rect
          x="164.43"
          y="90.2783"
          width="8.80477"
          height="3.64436"
          stroke="#6B6B6B"
        />
        <rect
          x="164.43"
          y="104.728"
          width="8.80477"
          height="3.64436"
          stroke="#6B6B6B"
        />
        <rect
          x="164.43"
          y="83.0542"
          width="8.80477"
          height="3.64436"
          stroke="#6B6B6B"
        />
        <rect
          x="164.43"
          y="96.9873"
          width="8.80477"
          height="3.64436"
          stroke="#6B6B6B"
        />
        <rect
          x="184.683"
          y="14.3691"
          width="35.8627"
          height="127.337"
          stroke="#6B6B6B"
        />
        <rect
          x="196.489"
          y="134.908"
          width="12.2823"
          height="6.4688"
          stroke="#6B6B6B"
        />
        <path d="M190.326 22.7437H215.129" stroke="#6B6B6B" />
        <path d="M190.326 32.3008H215.129" stroke="#6B6B6B" />
        <path d="M190.326 41.8574H215.129" stroke="#6B6B6B" />
        <path d="M190.326 51.4146H215.129" stroke="#6B6B6B" />
        <path d="M190.326 61.4268H215.129" stroke="#6B6B6B" />
        <path d="M190.326 70.9834H215.129" stroke="#6B6B6B" />
        <path d="M190.326 80.7681H215.129" stroke="#6B6B6B" />
        <path d="M190.326 90.7803H215.129" stroke="#6B6B6B" />
        <path d="M190.326 100.337H215.129" stroke="#6B6B6B" />
        <path d="M190.326 109.894H215.129" stroke="#6B6B6B" />
        <path d="M190.326 119.906H215.129" stroke="#6B6B6B" />
        <path d="M190.326 129.463H215.129" stroke="#6B6B6B" />
        <path d="M202.942 0.898926V13.9693" stroke="#6B6B6B" />
        <path d="M268.537 111L268.537 142" stroke="#6B6B6B" />
        <path
          d="M51.4073 77.9327L51.3705 77.7914C50.2184 73.3649 50.3074 68.6962 51.6218 64.3151C51.821 63.651 52.7668 63.6598 52.9484 64.329C54.1371 68.7083 54.1023 73.3298 52.8478 77.6907L52.7732 77.9501C52.574 78.6424 51.5888 78.6298 51.4073 77.9327Z"
          stroke="#6B6B6B"
        />
        <path
          d="M36.8387 88.8674L36.9427 88.7649C40.2002 85.5539 44.2878 83.2966 48.7391 82.2443C49.4139 82.0848 49.8792 82.9083 49.3905 83.4002C46.1922 86.6193 42.1725 88.8998 37.7686 89.9939L37.5066 90.059C36.8075 90.2326 36.3257 89.3731 36.8387 88.8674Z"
          stroke="#6B6B6B"
        />
        <path
          d="M67.1603 89.0563L67.0563 88.9538C63.7989 85.7428 59.7112 83.4856 55.2599 82.4333C54.5851 82.2738 54.1198 83.0973 54.6085 83.5891C57.8068 86.8082 61.8265 89.0888 66.2305 90.1828L66.4924 90.2479C67.1915 90.4216 67.6733 89.562 67.1603 89.0563Z"
          stroke="#6B6B6B"
        />
        <circle cx="51.972" cy="81.4562" r="1.69152" stroke="#6B6B6B" />
        <path d="M51.9724 141.916V83.1318" stroke="#6B6B6B" />
        <circle cx="109.392" cy="67.6558" r="4.9908" stroke="#6B6B6B" />
        <path d="M109.487 79.6009V75.6514" stroke="#6B6B6B" />
        <path d="M109.487 59.9495V56" stroke="#6B6B6B" />
        <path d="M109.487 79.6009V75.6514" stroke="#6B6B6B" />
        <path d="M109.487 59.9495V56" stroke="#6B6B6B" />
        <path d="M99.9312 74.7246L103.129 72.4072" stroke="#6B6B6B" />
        <path d="M115.844 63.1943L119.042 60.877" stroke="#6B6B6B" />
        <path d="M99.2681 61.9002L102.688 63.875" stroke="#6B6B6B" />
        <path d="M116.286 71.7259L119.707 73.7007" stroke="#6B6B6B" />
        <path
          d="M1.47266 102.687C1.47266 98.6661 4.73202 95.4067 8.75266 95.4067C12.7733 95.4067 16.0327 98.6661 16.0327 102.687V120.114C16.0327 124.135 12.7733 127.394 8.75266 127.394C4.73202 127.394 1.47266 124.135 1.47266 120.114V102.687Z"
          stroke="#6B6B6B"
        />
        <path
          d="M27.3025 119.336C27.3025 114.37 31.3282 110.344 36.2941 110.344H37.2277C42.1936 110.344 46.2193 114.37 46.2193 119.336C46.2193 124.302 42.1936 128.327 37.2277 128.327H36.2941C31.3282 128.327 27.3025 124.302 27.3025 119.336Z"
          stroke="#6B6B6B"
        />
        <path
          d="M164.853 120.27C164.853 115.476 168.739 111.589 173.533 111.589H174.467C179.261 111.589 183.147 115.476 183.147 120.27C183.147 125.064 179.261 128.95 174.467 128.95H173.533C168.739 128.95 164.853 125.064 164.853 120.27Z"
          stroke="#6B6B6B"
        />
        <path
          d="M57.4888 112.957C57.4888 108.077 61.4448 104.121 66.3248 104.121C71.2048 104.121 75.1608 108.077 75.1608 112.957V117.936C75.1608 122.816 71.2048 126.772 66.3248 126.772C61.4448 126.772 57.4888 122.816 57.4888 117.936V112.957Z"
          stroke="#6B6B6B"
        />
        <path
          d="M8.75293 141.965C8.75293 133.457 8.75293 128.688 8.75293 120.181"
          stroke="#6B6B6B"
        />
        <path
          d="M36.7607 141.965C36.7607 133.457 36.7607 128.688 36.7607 120.181"
          stroke="#6B6B6B"
        />
        <path
          d="M174 141.965C174 133.822 174 129.257 174 121.114"
          stroke="#6B6B6B"
        />
        <path
          d="M66.3247 141.965C66.3247 131.635 66.3247 125.843 66.3247 115.513"
          stroke="#6B6B6B"
        />
        <path
          d="M248.663 123.73L250.533 119.496H260.05L261.919 123.73H248.663Z"
          stroke="#6B6B6B"
        />
        <path
          d="M227.16 123.502L229.691 119.269H242.89L245.421 123.502H227.16Z"
          stroke="#6B6B6B"
        />
        <rect
          x="250.898"
          y="124.047"
          width="8.78454"
          height="12.8804"
          stroke="#6B6B6B"
        />
        <rect
          x="243.527"
          y="123.819"
          width="6.96416"
          height="14.9283"
          transform="rotate(90 243.527 123.819)"
          stroke="#6B6B6B"
        />
        <rect
          x="221.09"
          y="137.245"
          width="47.2401"
          height="4.68869"
          stroke="#6B6B6B"
        />
        <rect
          x="220.862"
          y="111.532"
          width="47.4676"
          height="3.3234"
          stroke="#6B6B6B"
        />
        <path
          d="M234.761 110.987C235.017 107.475 237.947 104.706 241.524 104.706H247.896C251.473 104.706 254.403 107.475 254.659 110.987H234.761Z"
          stroke="#6B6B6B"
        />
        <path
          d="M239.086 80.1621L244.262 68.366C244.697 67.3746 246.178 67.6852 246.178 68.7678V76.0494C246.178 76.5607 246.564 76.9896 247.072 77.0438L250.987 77.4604C251.694 77.5357 252.099 78.3049 251.76 78.9307L245.53 90.4467C244.996 91.4322 243.494 90.9295 243.661 89.8215L244.674 83.1165C244.759 82.5534 244.357 82.0331 243.791 81.9728L239.896 81.5583C239.224 81.4867 238.815 80.7816 239.086 80.1621Z"
          stroke="#6B6B6B"
        />
      </svg>
    </div>
  );
}
