import { useMemo, useState } from 'react';

import {
  Control,
  useWatch,
  Controller,
  FieldError,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';

import { Button } from 'ui';
import TextField from 'ui/form/TextField';
import CheckboxField from 'ui/form/CheckboxField';
import DatePickerFieldDobleView, {
  RangeDate,
} from 'ui/form/DatePickerFieldDobleView';

import omit from 'lodash/omit';

import { FinancialAccountPayoutStatus } from 'financialAccountPayouts/models/financialAccountPayouts';

import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';

export type FormFilters = {
  financialAccountPayoutAccountName: string;
  financialAccountPayoutScheduleDate?: RangeDate | null;
  financialAccountPayoutEffectiveDate?: RangeDate | null;
  financialAccountPayoutStatusDone?: FinancialAccountPayoutStatus;
  financialAccountPayoutStatusCancel?: FinancialAccountPayoutStatus;
  financialAccountPayoutStatusPending?: FinancialAccountPayoutStatus;
};

export type FormErrors = {
  financialAccountPayoutStatusDone?: FieldError;
  financialAccountPayoutAccountName?: FieldError;
  financialAccountPayoutStatusCancel?: FieldError;
  financialAccountPayoutStatusPending?: FieldError;
  financialAccountPayoutScheduleDateTo?: FieldError;
  financialAccountPayoutEffectiveDateTo?: FieldError;
  financialAccountPayoutScheduleDateFrom?: FieldError;
  financialAccountPayoutEffectiveDateFrom?: FieldError;
};

export default function FinancialAccountPayoutFormFilters({
  errors,
  control,
  register,
  setValue,
  isLoading,
}: {
  errors: FormErrors;
  isLoading?: boolean;
  control: Control<FormFilters>;
  setValue: UseFormSetValue<FormFilters>;
  register: UseFormRegister<FormFilters>;
}) {
  const FINANCIAL_ACCOUNT_PAYOUT_STATUS = useMemo(
    () => [
      {
        label: 'Falha',
        value: FinancialAccountPayoutStatus.CANCEL,
        name: 'financialAccountPayoutStatusCancel' as const,
      },
      {
        label: 'Pendente',
        value: FinancialAccountPayoutStatus.PENDING,
        name: 'financialAccountPayoutStatusPending' as const,
      },
      {
        label: 'Realizado',
        value: FinancialAccountPayoutStatus.DONE,
        name: 'financialAccountPayoutStatusDone' as const,
      },
    ],
    []
  );

  const FINANCIAL_ACCOUNT_PAYOUT_TEXT_FILTERS = useMemo(
    () => [
      {
        placeholder: 'Digitar',
        label: 'Conta Financeira',
        id: 'financialAccountPayoutAccountName' as const,
        error: errors.financialAccountPayoutAccountName?.message,
      },
    ],
    [errors]
  );

  const FINANCIAL_ACCONT_PAYOUT_DATE_FILTERS = useMemo(
    () => [
      {
        id: 1,
        label: 'Solicitação',
        name: 'financialAccountPayoutScheduleDate' as const,
        error: {
          endDateInput: errors.financialAccountPayoutScheduleDateFrom?.message,
          startDateInput: errors.financialAccountPayoutScheduleDateTo?.message,
        },
      },
      {
        id: 2,
        label: 'Transferência',
        name: 'financialAccountPayoutEffectiveDate' as const,
        error: {
          endDateInput: errors.financialAccountPayoutEffectiveDateFrom?.message,
          startDateInput: errors.financialAccountPayoutEffectiveDateTo?.message,
        },
      },
    ],
    [errors]
  );

  const activeFiltersCount = useWatch({
    control,
    name: [
      'financialAccountPayoutStatusDone',
      'financialAccountPayoutAccountName',
      'financialAccountPayoutStatusCancel',
      'financialAccountPayoutScheduleDate',
      'financialAccountPayoutEffectiveDate',
      'financialAccountPayoutStatusPending',
    ],
  }).filter((filter) => filter).length;

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  function handleFiltersOpen() {
    setIsFiltersOpen(!isFiltersOpen);
  }

  function handleClearFilters() {
    FINANCIAL_ACCOUNT_PAYOUT_TEXT_FILTERS.forEach((filter) =>
      setValue(filter.id, '')
    );

    FINANCIAL_ACCOUNT_PAYOUT_STATUS.forEach((status) => {
      setValue(status.name, undefined, {
        shouldValidate: true,
      });
    });

    FINANCIAL_ACCONT_PAYOUT_DATE_FILTERS.forEach((filter) => {
      setValue(filter.name, undefined, {
        shouldValidate: true,
      });
    });
  }

  return (
    <div className="w-full flex flex-col gap-4 items-start justify-start mb-3">
      <div className="flex flex-col gap-1">
        <p className="font-bold text-gray-dark400">Filtros avançados</p>
        <Button
          size="md"
          type="button"
          variant="advancedFilter"
          onClick={handleFiltersOpen}
          className="flex items-center justify-center"
        >
          <p className="text-sm font-medium">Filtros ({activeFiltersCount})</p>
          {isFiltersOpen ? (
            <MdKeyboardArrowUp size={20} />
          ) : (
            <MdKeyboardArrowDown size={20} />
          )}
        </Button>
      </div>
      {isFiltersOpen && (
        <div className="w-full flex flex-col gap-4 items-start justify-start bg-gray-dark700 rounded-lg">
          <div className="flex gap-12 items-start justify-start p-7">
            <div className="flex flex-col gap-4 items-start justify-start">
              <p className="font-bold text-gray-dark400">Selecionar Entidade</p>
              <div className="flex gap-4 items-start">
                {FINANCIAL_ACCOUNT_PAYOUT_TEXT_FILTERS.map((filter) => (
                  <TextField
                    id={filter.id}
                    key={filter.id}
                    error={filter.error}
                    label={filter.label}
                    placeholder={filter.placeholder}
                    {...register(filter.id)}
                  />
                ))}
              </div>
            </div>

            <div className="flex flex-col items-start justify-start">
              <p className="font-bold text-gray-dark400 mb-3">
                Status de Transferência
              </p>
              {FINANCIAL_ACCOUNT_PAYOUT_STATUS.map((status) => (
                <CheckboxField
                  key={status.value}
                  value={status.value}
                  inputLabel={status.label}
                  {...register(status.name)}
                />
              ))}
            </div>

            <div className="flex flex-col gap-4 items-start justify-start">
              <p className="font-bold text-gray-dark400">Data solicitação</p>
              <div className="flex items-center justify-start gap-4">
                {FINANCIAL_ACCONT_PAYOUT_DATE_FILTERS.slice(0, 1).map((date) => (
                  <Controller
                    key={date.id}
                    name={date.name}
                    control={control}
                    render={({ field }) => (
                      <DatePickerFieldDobleView
                        {...omit(field, 'ref')}
                        selectRange
                        endLabel="Até"
                        id={date.name}
                        label={date.label}
                        error={date.error}
                        placeholder="DD/MM/AAAA"
                        endPlaceholder="DD/MM/AAAA"
                        calendarWrapperClassName="z-10"
                        rangeInputWrapperClassName="flex items-start justify-center gap-6"
                      />
                    )}
                  />
                ))}
              </div>
            </div>

            <div className="flex flex-col gap-4 items-start justify-start">
              <p className="font-bold text-gray-dark400">Data Transferência</p>
              <div className="flex items-center justify-start gap-4">
                {FINANCIAL_ACCONT_PAYOUT_DATE_FILTERS.slice(1, 2).map((date) => (
                  <Controller
                    key={date.id}
                    name={date.name}
                    control={control}
                    render={({ field }) => (
                      <DatePickerFieldDobleView
                        {...omit(field, 'ref')}
                        selectRange
                        endLabel="Até"
                        id={date.name}
                        label={date.label}
                        error={date.error}
                        placeholder="DD/MM/AAAA"
                        endPlaceholder="DD/MM/AAAA"
                        calendarWrapperClassName="z-10"
                        rangeInputWrapperClassName="flex items-start justify-center gap-6"
                      />
                    )}
                  />
                ))}
              </div>
            </div>
          </div>

          <div className="w-full flex items-center justify-between border-t border-gray-dark500 p-7">
            <p className="font-bold flex text-gray-dark500 w-full">
              ({activeFiltersCount}) Filtros selecionados
            </p>
            <div className="w-full flex gap-4 items-center justify-end">
              <Button
                size="sm"
                type="submit"
                className="w-24"
                disabled={isLoading}
                variant="secondaryGray"
                onClick={handleClearFilters}
              >
                Limpar
              </Button>
              <Button
                size="sm"
                type="submit"
                disabled={isLoading || !activeFiltersCount}
              >
                {`Aplicar ${activeFiltersCount} filtros`}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
