import { gql } from '@apollo/client';
import { ConsumerUnit } from 'consumerUnits/model/consumerUnit';

export interface ConsumerUnitCreateMutationInput {
  consumerUnitPowerDistributionUnitIdentifier: string;
  consumerUnitAddressCity: string;
  consumerUnitAddressPostalCode: number;
  consumerUnitAddressState: string;
  consumerUnitAddressStreet: string;
  consumerUnitAddressDistrict: string;
  consumerUnitAddressComplement?: string;
  consumerUnitDiscountPercentageValue: string;
  powerDistributionUnitCredentialsUser: string;
  powerDistributionUnitCredentialsPasswordInput: string;
  consumerUnitModality: string;
  consumerUnitConsumptionClass: string;
  consumerUnitConsumptionGroupType: string;
  cooperativeId: number;
  cooperativeMemberId: number;
  powerDistributionUnitId: number;
}

export interface ConsumerUnitCreateMutationVariables {
  consumerUnitCreateInput: ConsumerUnitCreateMutationInput;
}

export interface ConsumerUnitCreated {
  consumerUnitCreate: ConsumerUnit;
}

export const CONSUMER_UNIT_CREATE_MUTATION = gql`
  mutation consumerUnitCreate($consumerUnitCreateInput: ConsumerUnitCreateInput!) {
    consumerUnitCreate(input: $consumerUnitCreateInput) {
      id
      consumerUnitPowerDistributionUnitIdentifier
      consumerUnitAddressCity
      consumerUnitAddressPostalCode
      consumerUnitAddressState
      consumerUnitAddressStreet
      consumerUnitAddressDistrict
      consumerUnitAddressComplement
      consumerUnitDiscountPercentageValue
      powerDistributionUnitCredentialsUser
      consumerUnitModality
      consumerUnitConsumptionClass
      consumerUnitConsumptionGroupType
      cooperative {
        cooperativeLegalName
      }
      cooperativeMember {
        cooperativeMemberLegalName
      }
      powerDistributionUnit {
        powerDistributionUnitLegalName
      }
    }
  }
`;
