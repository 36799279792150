import { Control, Controller, FieldError, UseFormRegister } from 'react-hook-form';

import omit from 'lodash/omit';

import { Button } from 'ui';
import TextField from 'ui/form/TextField';
import SelectField from 'ui/form/SelectField';
import DatePickerFieldSingleView from 'ui/form/DatePickerFieldSingleView';

import { ConsumerUnitBillingCaptureStatus } from 'consumerUnitBillingCapture/model/consumerUnitBillingCaptures';

export type FormFilters = {
  cooperativeMemberLegalName: string;
  consumerUnitBillingCycleDate: Date;
  consumerUnitPowerDistributionUnitIdentifier: string;
  consumerUnitBillingCaptureStatus: ConsumerUnitBillingCaptureStatus;
};

export type FormErrors = {
  cooperativeMemberLegalName?: FieldError;
  consumerUnitBillingCycleDate?: FieldError;
  consumerUnitBillingCaptureStatus?: FieldError;
  consumerUnitPowerDistributionUnitIdentifier?: FieldError;
};

export default function ConsumerUnitBillingCyclesFormFilters({
  errors,
  control,
  register,
  isLoading,
}: {
  errors: FormErrors;
  isLoading?: boolean;
  control: Control<FormFilters>;
  register: UseFormRegister<FormFilters>;
}) {
  const consumerUnitBillingCaptureStatus = [
    {
      key: 'ERROR',
      value: 'ERRO',
    },
    {
      key: 'SUCCESS',
      value: 'SUCESSO',
    },
    {
      key: 'WAITING',
      value: 'INDISPONÍVEL',
    },
  ];

  return (
    <div className="grid grid-cols-6 gap-4 pb-6 items-end">
      <Controller
        control={control}
        name="consumerUnitBillingCycleDate"
        render={({ field }) => (
          <DatePickerFieldSingleView
            {...omit(field, 'ref')}
            placeholder="MM/AA"
            labelDate="Mês da Fatura"
            id="consumerUnitBillingCycleDate"
            options={{ month: '2-digit', year: '2-digit' }}
            error={errors.consumerUnitBillingCycleDate?.message}
          />
        )}
      />

      <TextField
        label="Cooperado"
        placeholder="Digitar"
        id="cooperativeMemberLegalName"
        error={errors.cooperativeMemberLegalName?.message}
        {...register('cooperativeMemberLegalName')}
      />

      <TextField
        label="UC"
        placeholder="Digitar"
        id="consumerUnitPowerDistributionUnitIdentifier"
        error={errors.consumerUnitPowerDistributionUnitIdentifier?.message}
        {...register('consumerUnitPowerDistributionUnitIdentifier')}
      />

      <Controller
        control={control}
        name="consumerUnitBillingCaptureStatus"
        render={({ field }) => (
          <SelectField
            {...omit(field, 'ref')}
            label="Status da Captura"
            emptyOptionLabel="Selecionar"
            id="consumerUnitBillingCaptureStatus"
            options={consumerUnitBillingCaptureStatus}
            error={errors.consumerUnitBillingCaptureStatus?.message}
          />
        )}
      />

      <Button type="submit" size="sm" disabled={isLoading} className="self-end w-24">
        Filtrar
      </Button>
    </div>
  );
}
