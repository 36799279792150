import { useAuth } from 'auth/contexts/Auth';
import { useHistory } from 'react-router';
import { Button } from 'ui';
import { RiLogoutBoxRLine } from 'react-icons/ri';

export default function LogoutLink() {
  const { push } = useHistory();
  const { logout } = useAuth();

  return (
    <Button
      variant="link"
      onClick={() => logout(push)}
      className="group p-3 mx-2 mb-3 rounded-md flex flex-col items-center text-center text-xs font-medium !text-gray-dark500 hover:!text-gray-dark400"
      data-testid="logout-btn"
    >
      <RiLogoutBoxRLine className="h-6 w-6" aria-hidden="true" />
      <span></span>
      <span className="mt-2">Sair</span>
    </Button>
  );
}
