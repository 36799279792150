import React, { useCallback, useState } from 'react';
import range from 'lodash/range';
import SelectField, { Props as SelectFieldProps } from 'ui/form/SelectField';
import { Section } from '../components';
import AddButton from 'dashboard/components/dashboard/AddButton';
import { useModal } from 'ui/contexts/overlay/Modal';
import TextField from 'ui/form/TextField';
import DatePickerFieldSingleView from 'ui/form/DatePickerFieldSingleView';
import { Controller, useForm } from 'react-hook-form';
import { Button, SearchInput } from 'ui';
import Accordion from 'ui/components/accordion/Accordion';
import { Option } from 'ui/components/form/SearchInput';
import MultipleSearchInput from 'ui/components/form/MultipleSearchInput';
import TextAreaInput from 'ui/components/form/TextAreaInput';

const StatefulSelectField = (
  selectFieldProps: Partial<SelectFieldProps<string>>
) => {
  const [value, setValue] = React.useState<string | null>(null);

  return (
    <SelectField
      label="Options"
      options={[
        {
          key: 'key-1',
          value: 'Option 1',
        },
        {
          key: 'key-2',
          value: 'Option 2',
        },
        {
          key: 'key-3',
          value: 'Option 3',
        },
      ]}
      {...selectFieldProps}
      value={value}
      onChange={setValue}
      emptyOptionLabel="Select an option"
    />
  );
};

const multipleSearchInputOptions = [
  {
    key: 'key-1',
    value: 'Option 1',
  },
  {
    key: 'key-2',
    value: 'Option 2',
  },
  {
    key: 'key-3',
    value: 'Option 3',
  },
  {
    key: 'key-4',
    value: 'Option 4',
  },
  {
    key: 'key-5',
    value: 'Option 5',
  },
  {
    key: 'key-6',
    value: 'Option 6',
  },
  {
    key: 'key-7',
    value: 'Option 7',
  },
  {
    key: 'key-8',
    value: 'Option 8',
  },
  {
    key: 'key-9',
    value: 'Option 9',
  },
  {
    key: 'key-10',
    value: 'Option 10',
  },
  {
    key: 'key-11',
    value: 'Option 11',
  },
  {
    key: 'key-12',
    value: 'Option 12',
  },
];

const tariffFlagOptions = [
  {
    key: 'GREEN',
    value: 'VERDE',
  },
  {
    key: 'YELLOW',
    value: 'AMARELA',
  },
  {
    key: 'RED_ON_PEAK',
    value: 'VERMELHA PT1',
  },
  {
    key: 'RED_ON_PEAK_TWO',
    value: 'VERMELHA PT2',
  },
];

export const UiDashboard = () => {
  const { ModalOverlay, closeConfirm, showConfirm } = useModal();
  const { control } = useForm<any>();
  const onClickAddButton = () => showConfirm();

  const onCancelDelete = () => closeConfirm();

  const [searchBarValue, setSearchBarValue] = useState<
    string | number | readonly string[]
  >('');

  const [searchBarSelectedOption, setSearchBarSelectedOption] = useState<Option<
    string | number
  > | null>(null);

  const [multipleSearchBarValue, setMultipleSearchBarValue] = useState<
    string | number | readonly string[]
  >('');

  const [multipleSearchBarSelectedOption, setMultipleSearchBarSelectedOption] =
    useState<Option<string | number>[] | null>([]);

  const handleSearchBarSelect = useCallback(
    (item: Option<string | number>) => {
      setSearchBarSelectedOption(item);
      setSearchBarValue(item.value);
    },
    [setSearchBarSelectedOption, setSearchBarValue]
  );

  const handleSearchBarChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchBarValue(e.target.value);
    },
    []
  );

  const handleChangeMultipleSearchBar = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setMultipleSearchBarValue(e.target.value);
    },
    []
  );

  const handleSelectMultipleSearchBar = useCallback(
    (item: Option<string | number>) => {
      setMultipleSearchBarSelectedOption((prev) => {
        return prev?.some((option) => option.key === item.key)
          ? prev.filter((option) => option.key !== item.key)
          : [...(prev || []), item];
      });
    },
    []
  );

  const handleSelectAllMultipleSearchBar = useCallback(() => {
    const selectedOptions =
      String(multipleSearchBarValue)?.length > 0
        ? multipleSearchInputOptions?.filter((option) =>
            option.value
              ?.toLowerCase()
              .includes(String(multipleSearchBarValue)?.toLowerCase())
          )
        : multipleSearchInputOptions;

    setMultipleSearchBarSelectedOption(
      multipleSearchBarSelectedOption?.length === selectedOptions?.length
        ? []
        : selectedOptions
    );
  }, [multipleSearchBarSelectedOption, multipleSearchBarValue]);

  return (
    <div className="flex w-full h-full py-12 items-center bg-gray-dark800 justify-center">
      <div className="flex flex-col gap-y-6 w-full h-full p-4 overflow-y-auto scrollbar max-w-3xl">
        <Section title="MultipleSearchBar">
          <MultipleSearchInput
            isAllSelectable
            value={multipleSearchBarValue}
            options={multipleSearchInputOptions}
            onChange={handleChangeMultipleSearchBar}
            selectItem={handleSelectMultipleSearchBar}
            selectAll={handleSelectAllMultipleSearchBar}
            selectedOption={multipleSearchBarSelectedOption}
            placeholder="Search for something... (isAllSelectable = true)"
          />
        </Section>
        <Section title="Text Area Input">
          <TextAreaInput placeholder="Justifique o motivo das alterações" />
          <TextAreaInput disabled placeholder="Justifique o motivo das alterações" />
        </Section>
        <Section title="Search Bar">
          <SearchInput
            value={searchBarValue}
            onChange={handleSearchBarChange}
            selectItem={handleSearchBarSelect}
            placeholder="Search for something..."
            selectedOption={searchBarSelectedOption}
            options={[
              {
                key: 'key-1',
                value: 'Option 1',
              },
              {
                key: 'key-2',
                value: 'Option 2',
              },
              {
                key: 'key-3',
                value: 'Option 3',
              },
              {
                key: 'key-4',
                value: 'Option 4',
              },
              {
                key: 'key-5',
                value: 'Option 5',
              },
              {
                key: 'key6',
                value: 'Option 6',
              },
              {
                key: 'key-7',
                value: 'Option 7',
              },
              {
                key: 'key-8',
                value: 'Option 8',
              },
              {
                key: 'key-9',
                value: 'Option 9',
              },
              {
                key: 'key-10',
                value: 'Option 10',
              },
              {
                key: 'key-11',
                value: 'Option 11',
              },
              {
                key: 'key12',
                value: 'Option 12',
              },
            ]}
          />
        </Section>
        <Section title="Accordion">
          <Accordion title="Case 01" length={0} />
          <Accordion title="Case 02" length={2}>
            <div className="flex gap-4">
              <div className="flex flex-col gap-2">
                <p className="text-sm font-medium text-gray-dark400">Example 01</p>
                <div>
                  <AddButton onClick={onClickAddButton} label="Tarifa" />
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <p className="text-sm font-medium text-gray-dark400">Example 02</p>
                <div>
                  <AddButton onClick={onClickAddButton} label="Tarifa" />
                </div>
              </div>
            </div>
          </Accordion>
          <Accordion title="Case 03" length={0} required />
          <Accordion
            title="Case 04"
            length={1}
            required
            error={'An error has occurred'}
          >
            <div className="flex flex-col gap-2">
              <p className="text-sm font-medium text-gray-dark400">Example</p>
              <div>
                <AddButton onClick={onClickAddButton} label="Tarifa" />
              </div>
            </div>
          </Accordion>
        </Section>
        <Section title="Mask">
          <Accordion title="Mask" length={11}>
            <TextField
              id="date"
              mask="date"
              label="Date"
              placeholder="Ex. MM/AAAA ou DD/MM/AAAA"
            />
            <TextField
              id="decimal"
              mask="decimal"
              label="Decimal (With MaxLenght = [21,2])"
              placeholder="Ex. 1.000,00"
              maxLength={30}
            />
            <TextField
              id="decimal"
              mask="decimalEightDigitsPrecision"
              label="Decimal Eight Digits Precision (With Precision = [3,8])"
              placeholder="Ex. 100,00000000"
            />
            <TextField
              id="email"
              mask="email"
              label="Email (Only @nexenergy.com.br)"
              placeholder="Ex. email@nexenergy.com.br"
            />
            <TextField
              id="currency"
              mask="currency"
              label="Currency (With MaxLenght = [21,2])"
              placeholder="Ex. R$ 1.000,00"
              maxLength={33}
            />
            <TextField
              id="postalCode"
              mask="postalCode"
              label="Postal Code"
              placeholder="Ex. 00000-000"
            />
            <TextField
              id="textOnly"
              mask="textOnly"
              label="Text Only"
              placeholder="Ex. abcde"
            />
            <TextField
              id="numberOnly"
              mask="numberOnly"
              label="Number Only (With MaxLenght = [24])"
              placeholder="Ex. 12345"
              maxLength={31}
            />
            <TextField
              id="phone"
              mask="phone"
              label="Phone (With MaxLenght)"
              placeholder="Ex. (00) 0000-0000"
              maxLength={14}
            />
            <TextField
              id="phone"
              mask="phone"
              label="Phone (Without MaxLenght)"
              placeholder="Ex. (00) 0000-0000"
            />
            <TextField
              id="mobilePhone"
              mask="mobilePhone"
              label="Mobile Phone (With MaxLenght)"
              placeholder="Ex. (00) 00000-0000"
              maxLength={15}
            />
            <TextField
              id="mobilePhone"
              mask="mobilePhone"
              label="Mobile Phone (Without MaxLenght)"
              placeholder="Ex. (00) 00000-0000"
            />
            <TextField
              id="individualDocument"
              mask="individualDocument"
              label="Individual Document"
              placeholder="Ex. 000.000.000-00"
            />
            <TextField
              id="companyDocument"
              mask="companyDocument"
              label="Company Document"
              placeholder="Ex. 00.000.000/0000-00"
            />
            <TextField
              id="document"
              mask="document"
              label="Document (By length)"
              placeholder="Ex. 000.000.000-00 or 00.000.000/0000-00"
            />
          </Accordion>
        </Section>

        <Section title="Button">
          <AddButton onClick={onClickAddButton} label="Tarifa" />
        </Section>

        <ModalOverlay title="Nova Tarifa" variant="primary">
          <div className="flex flex-row flex-wrap gap-6">
            <div className="w-36">
              <Controller
                render={({ field: { onChange, value } }) => (
                  <SelectField
                    label="Bandeira"
                    id="tariffFlag"
                    options={tariffFlagOptions}
                    emptyOptionLabel="Nenhuma"
                    onChange={onChange}
                    value={value}
                  />
                )}
                name="tariffFlag"
                control={control}
              />
            </div>

            <Controller
              render={({ field: { onChange, value } }) => (
                <DatePickerFieldSingleView
                  required
                  id="pisCofinsValidityStartAt"
                  labelDate="Início de vigência"
                  value={value}
                  onChange={onChange}
                />
              )}
              name="pisCofinsValidityStartAt"
              control={control}
              rules={{ required: 'Campo obrigatório' }}
            />

            <TextField
              error={'Valor deve ser de 0 a 1, com até 6 casas decimais'}
              label="Tarifa"
              id="tariff"
              placeholder="Ex. 0,0012823"
            />
          </div>

          <div className="flex flex-row-reverse mt-5">
            <Button type="submit" variant="primaryGreen" size="md">
              Adicionar
            </Button>
            <Button
              variant="primaryGray"
              className="mr-2"
              size="md"
              onClick={onCancelDelete}
            >
              Cancelar
            </Button>
          </div>
        </ModalOverlay>

        <Section title="Optional">
          <StatefulSelectField />
        </Section>
        <Section title="Required">
          <StatefulSelectField required />
        </Section>
        <Section title="More than 7 options">
          <StatefulSelectField
            options={range(1, 11).map((num) => ({
              key: `key-${num}`,
              value: `Options ${num}`,
            }))}
          />
        </Section>
        <Section title="Without Options">
          <StatefulSelectField options={[]} />
        </Section>
        <Section title="Disabled">
          <StatefulSelectField disabled />
        </Section>
        <Section title="With Error">
          <StatefulSelectField error="An error has occurred" />
        </Section>
      </div>
    </div>
  );
};

export default UiDashboard;
