import { gql } from '@apollo/client';
import { TariffFlagModalityModalityFlagType } from 'tariffFlagTariffs/models/tariffFlagModality';
import { TariffFlagTariff } from 'tariffFlagTariffs/models/tariffFlagTariff';

export interface TariffFlagTariffCreateInput {
  tariffFlagTariffModalityFlag: TariffFlagModalityModalityFlagType;
  tariffFlagTariffValidityPeriod: string;
  tariffFlagTariffFee: string;
}

export interface TariffFlagTariffMutationVariables {
  tariffFlagTariffCreateInput: TariffFlagTariffCreateInput;
}

export interface TariffFlagTariffCreated {
  tariffFlagTariffCreate: TariffFlagTariff;
}

export const TARIFF_FLAG_TARIFF_CREATE_MUTATION = gql`
  mutation tariffFlagTariffCreate(
    $tariffFlagTariffCreateInput: TariffFlagTariffCreateInput!
  ) {
    tariffFlagTariffCreate(input: $tariffFlagTariffCreateInput) {
      id
      tariffFlagTariffModalityFlag
      tariffFlagTariffValidityPeriod
      tariffFlagTariffFee
    }
  }
`;
