import { gql } from '@apollo/client';

import { ConsumptionGroupsType } from 'consumerUnits/model/consumerUnit';

import { PowerDistributionUnitId } from 'powerDistributionUnits/models/powerDistributionUnit';

import {
  ConsumerUnitType,
  BillConsumerUnitId,
  ConsumerUnitBillData,
  BillConsumedEnergyKwh,
  ConsumerUnitBillDataId,
  BillExcessEnergyCredits,
  BillConsumedEnergyCredits,
  BillGeneratedEnergyCredits,
  BillConsumedEnergyCreditsTariffs,
} from 'powerDistributionUnits/powerDistributionUnitBillData/consumerUnitBillData/models/consumerUnitBillData';

export interface ConsumerUnitBillDataCreated {
  powerDistributionUnitConsumerUnitBillDataCreate: ConsumerUnitBillData;
}

export interface ConsumerUnitBillDataCreateMutationInput {
  id: ConsumerUnitBillDataId;
  billConsumerUnitId: BillConsumerUnitId;
  powerDistributionUnitId: PowerDistributionUnitId;
  billDueDate: string;
  billIssueDate: string;
  billReferenceDate: string;
  billNextReadingDate: string;
  billReadingStartedAt: string;
  billReadingFinishedAt: string;
  billTotalValue: string;
  billTotalReadingDays: string;
  billConsumedEnergyKwh: BillConsumedEnergyKwh;
  billExcessEnergyCredits: BillExcessEnergyCredits;
  billConsumedEnergyCredits: BillConsumedEnergyCredits;
  billGeneratedEnergyCredits: BillGeneratedEnergyCredits;
  billConsumedEnergyCreditsTariffs: BillConsumedEnergyCreditsTariffs;
  billConsumerUnitType: ConsumerUnitType;
  billConsumerUnitConsumptionGroupType: ConsumptionGroupsType;
  convertCreditsOnPeakOffPeak: boolean;
}

export interface ConsumerUnitBillDataMutationVariables {
  powerDistributionUnitConsumerUnitBillDataCreateInput: ConsumerUnitBillDataCreateMutationInput;
}

export const CONSUMER_UNIT_BILL_DATA_CREATE_MUTATION = gql`
  mutation powerDistributionUnitConsumerUnitBillDataCreate(
    $powerDistributionUnitConsumerUnitBillDataCreateInput: PowerDistributionUnitConsumerUnitBillDataCreateInput!
  ) {
    powerDistributionUnitConsumerUnitBillDataCreate(
      input: $powerDistributionUnitConsumerUnitBillDataCreateInput
    ) {
      id
      billConsumerUnitId
      powerDistributionUnitId
      billDueDate
      billIssueDate
      billReferenceDate
      billNextReadingDate
      billReadingStartedAt
      billReadingFinishedAt
      billTotalValue
      billTotalReadingDays
      billConsumerUnitType
      billConsumerUnitConsumptionGroupType
      billConsumedEnergyKwh {
        consumedEnergyKwhTotal
        consumedEnergyKwhOnPeak
        consumedEnergyKwhOffPeak
      }
      billExcessEnergyCredits {
        excessEnergyCreditsTotal
        excessEnergyCreditsOnPeak
        excessEnergyCreditsOffPeak
      }
      billConsumedEnergyCredits {
        consumedEnergyCreditsTotal
        consumedEnergyCreditsOnPeak
        consumedEnergyCreditsOffPeak
      }
      billGeneratedEnergyCredits {
        generatedEnergyCreditsTotal
        generatedEnergyCreditsOnPeak
        generatedEnergyCreditsOffPeak
      }
      billConsumedEnergyCreditsTariffs {
        consumedEnergyCreditsTariffsTotal
        consumedEnergyCreditsTariffsOnPeak
        consumedEnergyCreditsTariffsOffPeak
      }
      convertCreditsOnPeakOffPeak
    }
  }
`;
