import { Button } from 'ui';
import { MdDelete } from 'react-icons/md';
import { Props } from './Button';

export const DeleteButton = (props: Props) => (
  <Button size="sm" {...props} variant="secondaryRed">
    <MdDelete className="flex-shrink-0 h-5 w-5" />
    Deletar
  </Button>
);

export default DeleteButton;
