import { gql } from '@apollo/client';

export interface ContactGroupQueryVariables {
  id: string;
}

export const contactGroupTypename = 'ContactGroup';

export const CONTACT_GROUP_QUERY = gql`
  query contactGroup($id: ID!) {
    contactGroup(id: $id) {
      group {
        id
        groupName
      }
      contacts {
        id
        contactName
        contactEmail
        contactPhone
        contactType {
          id
          contactTypeName
        }
      }
      entities {
        cooperatives {
          id
          cooperativeLegalName
          cooperativeDocumentId
          cooperativeAddressState
          financialAccount {
            financialAccountLegalName
          }
        }
        consumerUnits {
          id
          consumerUnitAddressCity
          consumerUnitAddressState
          consumerUnitAddressStreet
          consumerUnitAddressDistrict
          consumerUnitPowerDistributionUnitIdentifier
          cooperativeMember {
            cooperativeMemberLegalName
            cooperativeMemberDocumentId
          }
          powerDistributionUnit {
            powerDistributionUnitLegalName
          }
        }
        generationUnits {
          id
          generationUnitLegalName
          financialAccount {
            financialAccountLegalName
          }
          powerDistributionUnit {
            powerDistributionUnitLegalName
          }
        }
      }
    }
  }
`;
