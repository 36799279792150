import {
  GenerationUnit,
  GenerationUnitConsumptionGroupTypes,
} from 'generationUnits/models/generationUnit';

import { GenerationUnitLeaseCycle } from 'generationUnits/generationUnitLeaseCycles/models/generationUnitLeaseCycles';

import { ConsumerUnitBillingCapture } from 'consumerUnitBillingCapture/model/consumerUnitBillingCaptures';

export type BillConsumerUnitId = number;
export type PowerDistributionUnitId = number;
export type GenerationUnitBillDataId = number;

export enum ConsumerUnitType {
  CONSUMER = 'CONSUMER',
  GENERATION = 'GENERATION',
}

export type BillConsumedEnergyKwh = {
  consumedEnergyKwhTotal: string;
  consumedEnergyKwhOnPeak: string;
  consumedEnergyKwhOffPeak: string;
};

export type BillExcessEnergyCredits = {
  excessEnergyCreditsTotal: string;
  excessEnergyCreditsOnPeak: string;
  excessEnergyCreditsOffPeak: string;
};

export type BillConsumedEnergyCredits = {
  consumedEnergyCreditsTotal: string;
  consumedEnergyCreditsOnPeak: string;
  consumedEnergyCreditsOffPeak: string;
};

export type BillGeneratedEnergyCredits = {
  generatedEnergyCreditsTotal: string;
  generatedEnergyCreditsOnPeak: string;
  generatedEnergyCreditsOffPeak: string;
};

export type BillConsumedEnergyCreditsTariffs = {
  consumedEnergyCreditsTariffsTotal: string;
  consumedEnergyCreditsTariffsOnPeak: string;
  consumedEnergyCreditsTariffsOffPeak: string;
};

export interface GenerationUnitBillData {
  id: GenerationUnitBillDataId;
  billConsumerUnitId: BillConsumerUnitId;
  powerDistributionUnitId: PowerDistributionUnitId;
  billDueDate: string;
  billIssueDate: string;
  billReferenceDate: string;
  billNextReadingDate: string;
  billReadingStartedAt: string;
  billReadingFinishedAt: string;
  billTotalValue: string;
  billTotalReadingDays: string;
  billConsumedEnergyKwh: BillConsumedEnergyKwh;
  billExcessEnergyCredits: BillExcessEnergyCredits;
  billConsumedEnergyCredits: BillConsumedEnergyCredits;
  billGeneratedEnergyCredits: BillGeneratedEnergyCredits;
  billConsumedEnergyCreditsTariffs: BillConsumedEnergyCreditsTariffs;
  billConsumerUnitType: ConsumerUnitType;
  billConsumerUnitConsumptionGroupType: GenerationUnitConsumptionGroupTypes;
}

export type GenerationUnitBillDataDetails = {
  generationUnit: GenerationUnit;
  generationUnitLeaseCycle: GenerationUnitLeaseCycle;
  powerDistributionUnitBillDatas: GenerationUnitBillData;
  consumerUnitBillingCapture: ConsumerUnitBillingCapture;
};
