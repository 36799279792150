import { useCallback, useEffect, useMemo, useState } from 'react';

import { has } from 'lodash';

import { useLocation } from 'react-router-dom';

import { SubmitHandler, useForm } from 'react-hook-form';

import { ApolloError, useLazyQuery } from '@apollo/client';

import { INITIAL_QUERY_STATE_CONFIG } from 'graphql/apollo/config';

import { formatDateToSubmitDateString } from 'utils/form';

import useToastContext from 'ui/hooks/useToast';
import { ToastProps } from 'ui/contexts/overlay/Toast';
import { useLoading } from 'ui/contexts/overlay/Loading';

import { Pagination } from 'dashboard/components/table';
import { Dashboard, DashboardMainHeaderForm } from 'dashboard/components/dashboard';

import {
  ConsumerUnitBillingCaptureStatusCounterList,
  CONSUMER_UNIT_BILLING_CAPTURE_STATUS_COUNTER_QUERY,
  CONSUMER_UNIT_BILLING_CYCLES_SELECT_QUERY_VARIABLES,
} from 'consumerUnitBillingCapture/graphql/consumerUnitBillingCaptureStatusCounterQuery';

import {
  ConsumerUnitBillingCaptureDownloadBill,
  CONSUMER_UNIT_BILLING_CAPTURE_DOWNLOAD_BILL_QUERY,
} from 'consumerUnitBillingCapture/graphql/consumerUnitBillingCaptureDownloadBillQuery';

import { ConsumerUnitBillingCycle } from 'consumerUnits/consumerUnitBillingCycles/models/consumerUnitBillingCycles';

import { CONSUMER_UNIT_BILLING_CYCLES_QUERY } from 'consumerUnits/consumerUnitBillingCycles/graphql/consumerUnitBillingCyclesQuery';

import ConsumerUnitBillingCyclesFormFilters, {
  FormFilters,
} from 'consumerUnits/consumerUnitBillingCycles/components/form/ConsumerUnitBillingCyclesFormFilters';

import ConsumerUnitBillingCyclesTable from 'consumerUnits/consumerUnitBillingCycles/components/table/ConsumerUnitBillingCyclesTable';

import ConsumerUnitBillingCaptureStatusCounterItem from 'consumerUnitBillingCapture/components/counter/ConsumerUnitBillingCaptureStatusCounterItem';

import ConsumerUnitBillDataDetails from 'powerDistributionUnits/powerDistributionUnitBillData/consumerUnitBillData/components/detail/ConsumerUnitBillDataDetails';

import ConsumerUnitBillDataCreditsWallet from 'powerDistributionUnits/powerDistributionUnitBillData/consumerUnitBillData/components/wallets/credits/ConsumerUnitBillDataCreditsWallet';
import ConsumerUnitBillDataEconomyWallet from 'powerDistributionUnits/powerDistributionUnitBillData/consumerUnitBillData/components/wallets/economy/ConsumerUnitBillDataEconomyWallet';

type State = {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  consumerUnitBillingCycles: ConsumerUnitBillingCycle[];
};

const DOWNLOAD_ERROR_TOAST: ToastProps = {
  variant: 'danger',
  title: 'Algo deu errado!',
  text: 'Houve um erro ao tentar realizar o download da fatura.',
};

const DOWNLOAD_SUCCESS_TOAST: ToastProps = {
  title: 'Sucesso!',
  variant: 'primary',
  text: 'Sucesso ao realizar o download da fatura.',
};

const LIST_ERROR_TOAST: ToastProps = {
  variant: 'danger',
  title: 'Algo deu errado!',
  text: 'Não foi possível carregar a lista de Creditação de Unidade Consumidora',
};

export type ModalProps = 'details' | 'creditsWallet' | 'economyWallet';

const DASHBOARD_TITLE = 'Creditação de Unidade Consumidora';

type BillingCyclesFilterOptions = {
  consumerUnitBillingCycleDate: string;
  consumerUnitPowerDistributionUnitIdentifier: string;
};

export default function ListConsumerUnitBillingCyclesPage() {
  const location = useLocation();
  const { addToast } = useToastContext();
  const { LoadingOverlay, showLoading, closeLoading } = useLoading();

  const [consumerUnitBillingCycleData, setConsumerUnitBillingCycleData] =
    useState<ConsumerUnitBillingCycle>();

  const [showSidebarModal, setShowSidebarModal] = useState<ModalProps>();

  const [state, setState] = useState<State>({
    hasNextPage: false,
    hasPreviousPage: false,
    consumerUnitBillingCycles: [],
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormFilters>();

  const [ConsumerUnitBillingCycleList, { data, loading, error, refetch }] =
    useLazyQuery(CONSUMER_UNIT_BILLING_CYCLES_QUERY, INITIAL_QUERY_STATE_CONFIG);

  const HAS_CONSUMER_UNIT_BILLING_CAPTURE_URL = useMemo(
    () => ({
      consumerUnitBillingCaptureUrl:
        consumerUnitBillingCycleData?.consumerUnitBillingCapture
          ?.consumerUnitBillingCaptureUrl || '',
    }),
    [consumerUnitBillingCycleData]
  );

  const downloadBill = useCallback(
    (url: string) => {
      if (url) {
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute(
          'download',
          HAS_CONSUMER_UNIT_BILLING_CAPTURE_URL?.consumerUnitBillingCaptureUrl as string
        );
        document.body.appendChild(link);
        link.click();
      }
    },
    [HAS_CONSUMER_UNIT_BILLING_CAPTURE_URL?.consumerUnitBillingCaptureUrl]
  );

  const [
    consumerUnitBillingCaptureDownloadBill,
    {
      data: consumerUnitBillingCaptureDownload,
      loading: loadingConsumerUnitBillingCaptureDownload,
    },
  ] = useLazyQuery<ConsumerUnitBillingCaptureDownloadBill>(
    CONSUMER_UNIT_BILLING_CAPTURE_DOWNLOAD_BILL_QUERY,
    {
      variables: HAS_CONSUMER_UNIT_BILLING_CAPTURE_URL,
      onError(error: ApolloError) {
        if (has(error.graphQLErrors[0], 'message')) {
          addToast(DOWNLOAD_ERROR_TOAST);
        }
      },
      onCompleted() {
        const { consumerUnitBillingCaptureUrl } =
          consumerUnitBillingCaptureDownload?.consumerUnitBillingCaptureDownloadBill ||
          {};

        downloadBill(consumerUnitBillingCaptureUrl || '');

        addToast(DOWNLOAD_SUCCESS_TOAST);
      },
    }
  );

  const [
    consumerUnitBillingCaptureStatusCounterSelect,
    {
      data: consumerUnitBillingCaptureStatusCounter,
      loading: loadingConsumerUnitBillingCaptureStatusCounter,
      refetch: refetchConsumerUnitBillingCaptureStatusCounter,
    },
  ] = useLazyQuery<ConsumerUnitBillingCaptureStatusCounterList>(
    CONSUMER_UNIT_BILLING_CAPTURE_STATUS_COUNTER_QUERY,
    CONSUMER_UNIT_BILLING_CYCLES_SELECT_QUERY_VARIABLES
  );

  useEffect(() => {
    refetchConsumerUnitBillingCaptureStatusCounter &&
      refetchConsumerUnitBillingCaptureStatusCounter();

    consumerUnitBillingCaptureStatusCounterSelect();
  }, [
    consumerUnitBillingCaptureStatusCounterSelect,
    refetchConsumerUnitBillingCaptureStatusCounter,
  ]);

  useEffect(() => {
    ConsumerUnitBillingCycleList();
  }, [ConsumerUnitBillingCycleList]);

  useEffect(() => {
    refetch && refetch();
  }, [refetch]);

  useEffect(() => {
    if (error) {
      addToast(LIST_ERROR_TOAST);
    }
  }, [addToast, error]);

  const isLoading =
    loading ||
    loadingConsumerUnitBillingCaptureDownload ||
    loadingConsumerUnitBillingCaptureStatusCounter;

  useEffect(() => {
    if (isLoading) {
      return showLoading();
    }

    if (data) {
      setState({
        hasNextPage: !!data.consumerUnitBillingCycles.afterCursor,
        hasPreviousPage: !!data.consumerUnitBillingCycles.beforeCursor,
        consumerUnitBillingCycles: data.consumerUnitBillingCycles.entries,
      });
    }

    closeLoading();
  }, [data, isLoading, closeLoading, showLoading]);

  const handleClickNext = () => {
    refetch &&
      refetch({
        before: null,
        after: data.consumerUnitBillingCycles.afterCursor,
      });
  };

  const handleClickBefore = () => {
    refetch &&
      refetch({
        after: null,
        before: data.consumerUnitBillingCycles.beforeCursor,
      });
  };

  const handleClickOpenSideBarModal = (
    modal: ModalProps,
    consumerUnitBillingCycle: ConsumerUnitBillingCycle
  ) => {
    setShowSidebarModal(modal);
    setConsumerUnitBillingCycleData(consumerUnitBillingCycle);
  };

  const handleClickCloseBillDetails = () => {
    setShowSidebarModal(undefined);
  };

  const handleClickDownloadBill = () => consumerUnitBillingCaptureDownloadBill();

  const {
    consumerUnitBillingCycleDate,
    consumerUnitPowerDistributionUnitIdentifier,
  } = (location.state as BillingCyclesFilterOptions) || {};

  useEffect(() => {
    !!location.state &&
      refetch({
        filters: {
          consumerUnitPowerDistributionUnitIdentifier,
          consumerUnitBillingCycleDate: consumerUnitBillingCycleDate,
        },
      });
  }, [
    refetch,
    location.state,
    consumerUnitBillingCycleDate,
    consumerUnitPowerDistributionUnitIdentifier,
  ]);

  const onSubmit: SubmitHandler<FormFilters> = (consumerUnitBillingCyclesInput) => {
    !!consumerUnitBillingCyclesInput.consumerUnitBillingCycleDate &&
      Object.assign(consumerUnitBillingCyclesInput, {
        consumerUnitBillingCycleDate: formatDateToSubmitDateString(
          String(consumerUnitBillingCyclesInput.consumerUnitBillingCycleDate)
        ),
      });

    refetch({
      filters: Object.fromEntries(
        Object.entries(consumerUnitBillingCyclesInput).filter(([, value]) => !!value)
      ),
    });
  };

  return (
    <Dashboard
      dashboardMainHeaderTitle={<DashboardMainHeaderForm title={DASHBOARD_TITLE} />}
    >
      <ConsumerUnitBillingCaptureStatusCounterItem
        consumerUnitBillingCaptureStatusCounter={
          consumerUnitBillingCaptureStatusCounter?.consumerUnitBillingCaptureStatusCounter
        }
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <ConsumerUnitBillingCyclesFormFilters
          errors={errors}
          control={control}
          register={register}
          isLoading={isLoading}
        />
      </form>

      <div className="rounded-lg">
        <ConsumerUnitBillingCyclesTable
          onClickOpenSideBarModal={handleClickOpenSideBarModal}
          consumerUnitBillingCycles={state.consumerUnitBillingCycles}
        />
        <Pagination
          onNextClick={handleClickNext}
          disableNext={!state.hasNextPage}
          onPreviousClick={handleClickBefore}
          disableBefore={!state.hasPreviousPage}
        />
      </div>

      {showSidebarModal === 'details' && (
        <ConsumerUnitBillDataDetails
          onClickCloseModal={handleClickCloseBillDetails}
          handleClickDownloadBill={handleClickDownloadBill}
          consumerUnitBillingCycle={consumerUnitBillingCycleData}
        />
      )}

      {showSidebarModal === 'creditsWallet' && (
        <ConsumerUnitBillDataCreditsWallet
          onClickCloseModal={handleClickCloseBillDetails}
          consumerUnitBillingCycle={consumerUnitBillingCycleData}
        />
      )}

      {showSidebarModal === 'economyWallet' && (
        <ConsumerUnitBillDataEconomyWallet
          onClickCloseModal={handleClickCloseBillDetails}
          consumerUnitBillingCycle={consumerUnitBillingCycleData}
        />
      )}

      <LoadingOverlay />
    </Dashboard>
  );
}
