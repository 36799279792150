import { gql } from '@apollo/client';
import { InternalUser } from 'internalUsers/models/internalUser';

export interface InternalUserCreateMutationInput {
  userName: string;
  userEmail: string;
  userIsAdmin: boolean;
}

export interface InternalUserCreateMutationVariables {
  internalUserCreateInput: InternalUserCreateMutationInput;
}

export interface InternalUserCreated {
  internalUserCreate: InternalUser;
}

export const INTERNAL_USER_CREATE_MUTATION = gql`
  mutation internalUserCreate($internalUserCreateInput: InternalUserCreateInput!) {
    internalUserCreate(input: $internalUserCreateInput) {
      id
      userName
      userEmail
      userAccessBlockedAt
      userIsAdmin
    }
  }
`;
