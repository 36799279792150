import React from 'react';
import ReactModal from 'react-modal';

type Props = {
  onCloseModal: () => void;
  isOpen: boolean;
  shouldCloseOnOverlayClick?: boolean;
};

const Loading = ({ isOpen, onCloseModal }: Props) => {
  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onCloseModal}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      overlayElement={(overlayProps, contentElement) => (
        <div
          {...overlayProps}
          className="fixed inset-0 h-full w-full bg-black bg-opacity-70"
          style={{
            zIndex: 20,
          }}
        >
          {contentElement}
        </div>
      )}
      contentElement={(props, contentChildren) => (
        <div
          {...props}
          className="h-full w-full flex items-center justify-center"
          style={{}}
        >
          {contentChildren}
        </div>
      )}
    >
      <svg
        data-testid="loading-overlay"
        width="75"
        height="75"
        viewBox="-1 -1 76 76"
        xmlns="http://www.w3.org/2000/svg"
        stroke="#fff"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(1 1)" strokeWidth="4">
            <circle strokeOpacity=".5" cx="36" cy="36" r="36" />
            <path d="M72 36c0-9.94-8.06-36-36-36" stroke="#71F697">
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 36 36"
                to="360 36 36"
                dur="1s"
                repeatCount="indefinite"
              />
            </path>
          </g>
        </g>
      </svg>
    </ReactModal>
  );
};

export default Loading;
