import React from 'react';
import { FiAlertTriangle } from 'react-icons/fi';
import { MdDone, MdInfoOutline, MdOutlineAddAlert } from 'react-icons/md';
import ReactModal from 'react-modal';
import { Button } from 'ui';
import { Variant } from 'ui/models/overlay';

type Props = {
  variant: Variant;
  onCloseModal: () => void;
  isOpen: boolean;
  contentMessage: string;
  contentLabel: string;
  shouldCloseOnOverlayClick?: boolean;
  shouldCloseOnEsc?: boolean;
  onConfirm(): void;
  onCancel(): void;
};

const variants: Record<
  Variant,
  {
    icon: React.ReactNode;
    confirmButton: 'primaryRed' | 'primaryGreen' | 'primaryGray';
    cancelButton: 'primaryRed' | 'primaryGray' | 'secondaryGray' | 'icon' | 'link';
  }
> = {
  alert: {
    icon: <MdOutlineAddAlert className="text-yellow-600 text-2xl" />,
    confirmButton: 'primaryGray',
    cancelButton: 'secondaryGray',
  },
  danger: {
    icon: <MdInfoOutline className="text-red-a1 text-2xl" />,
    confirmButton: 'primaryRed',
    cancelButton: 'primaryGray',
  },
  primary: {
    icon: <MdDone className="text-primary text-2xl" />,
    confirmButton: 'primaryGreen',
    cancelButton: 'primaryGray',
  },
  warning: {
    icon: <FiAlertTriangle className="text-red-a1 text-2xl" />,
    confirmButton: 'primaryRed',
    cancelButton: 'primaryGray',
  },
};

const Confirm = ({
  isOpen,
  onCloseModal,
  contentLabel,
  contentMessage,
  shouldCloseOnOverlayClick,
  shouldCloseOnEsc,
  onConfirm,
  onCancel,
  variant,
}: Props) => {
  return (
    <ReactModal
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={onCloseModal}
      contentLabel={contentLabel}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldCloseOnEsc={shouldCloseOnEsc}
      overlayElement={(overlayProps, contentElement) => (
        <div
          {...overlayProps}
          className="fixed inset-0 h-full w-full bg-black bg-opacity-70 flex items-center justify-center z-50"
          style={{}}
        >
          {contentElement}
        </div>
      )}
      contentElement={({ title, ...props }) => (
        <div {...props} className="flex items-center justify-center" style={{}}>
          <div
            className="bg-gray-dark700 rounded-lg shadow-xl p-8 max-w-md flex"
            style={{ minWidth: '512px' }}
          >
            <div className="mr-5">
              <div className="bg-gray-dark600 p-2 rounded-full">
                {variants[variant].icon}
              </div>
            </div>
            <div>
              <h4 className="text-gray-dark400 font-medium text-lg">
                {contentLabel}
              </h4>
              <p className="text-gray-dark500 text-sm m-5 ml-0">{contentMessage}</p>
              <div className="flex flex-row-reverse">
                <Button
                  variant={variants[variant].confirmButton}
                  size="md"
                  onClick={onConfirm}
                >
                  Confirmar
                </Button>
                <Button
                  variant={variants[variant].cancelButton}
                  className="mr-2"
                  size="md"
                  onClick={onCancel}
                >
                  Cancelar
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default Confirm;
