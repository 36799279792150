import { DeleteButton as Button } from 'ui';
import { OverlayConfig } from 'ui/models/overlay';
import { Props as ButtonProps } from 'ui/components/button/Button';

const DELETE_CONFIG: OverlayConfig = {
  variant: 'danger',
  title: 'Desativação do Grupos & Contato!',
  text: 'Tem certeza que deseja desativar esse Grupos & Contato? Todos os dados serão permanentemente removidos do nosso sistema. Essa ação não poderá ser revertida.',
};

type Props = Omit<ButtonProps, 'onClick'> & {
  onClick: (config: OverlayConfig) => void;
};

export default function ContactGroupsDeleteButton({ onClick, ...props }: Props) {
  const handleOnClick = () => onClick(DELETE_CONFIG);

  return <Button onClick={handleOnClick} {...props} />;
}
