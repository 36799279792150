import { gql } from '@apollo/client';

export type CooperativeDeleteMutationVariables = {
  id: number;
};

export const COOPERATIVE_DELETE_MUTATION = gql`
  mutation cooperativeDelete($id: ID!) {
    cooperativeDelete(id: $id) {
      message
    }
  }
`;
