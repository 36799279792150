import { MdLock, MdNoEncryption } from 'react-icons/md';
import { Button } from 'ui';
import { OverlayConfig } from 'ui/models/overlay';

const UNBLOCK_USER_ACCESS_OVERLAY_CONFIG: OverlayConfig = {
  title: 'Desbloquear usuário',
  text: 'Tem certeza que deseja desbloquear este usuário?',
  variant: 'danger',
};

const BLOCK_USER_ACCESS_OVERLAY_CONFIG: OverlayConfig = {
  title: 'Bloquear usuário',
  text: 'Tem certeza que deseja bloquear este usuário?',
  variant: 'danger',
};

type Props = {
  disabled: boolean;
  userAccessBlockedAt: null | Date;
  onClickToggleAccess: (overlayConfig: OverlayConfig) => void;
};

export const ToggleAccessButton = ({
  disabled,
  userAccessBlockedAt,
  onClickToggleAccess,
}: Props) => {
  const isBlocked = userAccessBlockedAt !== null;
  const handleOnClick = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (isBlocked) {
      onClickToggleAccess(UNBLOCK_USER_ACCESS_OVERLAY_CONFIG);
    } else {
      onClickToggleAccess(BLOCK_USER_ACCESS_OVERLAY_CONFIG);
    }
  };

  return (
    <Button
      size="sm"
      variant="secondaryGray"
      onClick={handleOnClick}
      disabled={disabled}
    >
      {isBlocked ? (
        <>
          <MdNoEncryption
            data-testid="no-encryption-icon"
            className="flex-shrink-0 h-5 w-5"
          />
          Desbloquear usuário
        </>
      ) : (
        <>
          <MdLock data-testid="lock-icon" className="flex-shrink-0 h-5 w-5" />
          Bloquear usuário
        </>
      )}
    </Button>
  );
};
