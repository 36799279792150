import { FinancialAccount } from 'financialAccounts/models/financialAccounts';

export type FinancialAccountPayoutId = number;

export enum FinancialAccountPayoutStatus {
  DONE = 'DONE',
  CANCEL = 'CANCEL',
  PENDING = 'PENDING',
}

export type FinancialAccountPayoutsCounter = {
  financialAccountPayoutsTotalSum: string;
  financialAccountPayoutsDoneStatusCount: number;
  financialAccountPayoutsCancelStatusCount: number;
};

export enum FinancialAccountPayoutExportType {
  SUMMEDUP = 'SUMMEDUP',
  DETAILED = 'DETAILED',
}

export interface FinancialAccountPayouts {
  id: FinancialAccountPayoutId;
  financialAccount: FinancialAccount;
  financialAccountPayoutTotal: string;
  financialAccountPayoutScheduleDate: string;
  financialAccountPayoutEffectiveDate: string;
  financialAccountPayoutStatus: FinancialAccountPayoutStatus;
}
