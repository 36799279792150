import Table from 'ui/components/table/Table';
import TableBody from 'ui/components/table/TableBody';
import TableHead from 'ui/components/table/TableHead';
import TableHeadColumn from 'ui/components/table/TableHeadColumn';

import ConsumerUnitBillingCyclesTableItem from './ConsumerUnitBillingCyclesTableItem';

import { ModalProps } from 'consumerUnits/consumerUnitBillingCycles/pages/ListConsumerUnitBillingCycles';

import { ConsumerUnitBillingCycle } from 'consumerUnits/consumerUnitBillingCycles/models/consumerUnitBillingCycles';

type Props = {
  consumerUnitBillingCycles: ConsumerUnitBillingCycle[];
  onClickOpenSideBarModal: (
    type: ModalProps,
    consumerUnitBillingCycle: ConsumerUnitBillingCycle
  ) => void;
};

export default function ConsumerUnitBillingingCyclesTable({
  onClickOpenSideBarModal,
  consumerUnitBillingCycles,
}: Props) {
  return (
    <Table>
      <TableHead>
        <TableHeadColumn>ID</TableHeadColumn>
        <TableHeadColumn>MÊS DA FATURA</TableHeadColumn>
        <TableHeadColumn>COOPERADO</TableHeadColumn>
        <TableHeadColumn>UC</TableHeadColumn>
        <TableHeadColumn className="text-center">CREDITAÇÃO</TableHeadColumn>
        <TableHeadColumn className="text-center">ECONOMIA</TableHeadColumn>
        <TableHeadColumn className="text-center">FATURA</TableHeadColumn>
      </TableHead>
      <TableBody>
        {consumerUnitBillingCycles.map(
          (consumerUnitBillingCycle: ConsumerUnitBillingCycle) => (
            <ConsumerUnitBillingCyclesTableItem
              key={consumerUnitBillingCycle.id}
              onClickOpenSideBarModal={onClickOpenSideBarModal}
              consumerUnitBillingCycles={consumerUnitBillingCycle}
            />
          )
        )}
      </TableBody>
    </Table>
  );
}
