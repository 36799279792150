import { gql } from '@apollo/client';

export type FinancialAccountDeleteMutationVariables = {
  id: number;
};

export const financialAccountTypename = 'FinancialAccount';

export const FINANCIAL_ACCOUNT_DELETE_MUTATION = gql`
  mutation financialAccountDelete($id: ID!) {
    financialAccountDelete(id: $id) {
      message
    }
  }
`;
