type Props = {
  value: string;
  className?: string;
};

export const TariffGroupValue = ({ value, className }: Props) => (
  <p className={className}>{value}</p>
);

export default TariffGroupValue;
