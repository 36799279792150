import { gql } from '@apollo/client';

export interface ConsumerUnitBillingCaptureDownloadBill {
  consumerUnitBillingCaptureDownloadBill: {
    consumerUnitBillingCaptureUrl: string;
  };
}

export const ConsumerUnitBillingCaptureDownloadBillTypename =
  'ConsumerUnitBillingCaptureDownloadBill';

export const CONSUMER_UNIT_BILLING_CAPTURE_DOWNLOAD_BILL_QUERY = gql`
  query consumerUnitBillingCaptureDownloadBill(
    $consumerUnitBillingCaptureUrl: String
  ) {
    consumerUnitBillingCaptureDownloadBill(
      consumerUnitBillingCaptureUrl: $consumerUnitBillingCaptureUrl
    ) {
      consumerUnitBillingCaptureUrl
    }
  }
`;
