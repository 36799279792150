import { gql } from '@apollo/client';

export interface TariffGroupQueryVariables {
  id: string;
}

export const TariffGroupTypename = 'PowerDistributionUnitTariffGroup';

export const TARIFF_GROUP_QUERY = gql`
  query powerDistributionUnitTariffGroup($powerDistributionUnitTariffGroupId: ID!) {
    powerDistributionUnitTariffGroup(
      powerDistributionUnitTariffGroupId: $powerDistributionUnitTariffGroupId
    ) {
      id
      tariffGroupConsumptionCosts {
        energyUsageTariff
        energyUsageTariffOnPeak
        energyUsageTariffOffPeak
        energyDistributionServiceTariff
        energyDistributionServiceTariffOnPeak
        energyDistributionServiceTariffOffPeak
      }
      tariffGroupDemandCosts {
        all
        offPeak
        onPeak
      }
      tariffGroupConsumptionGroup
      tariffGroupModality
      tariffGroupValidityEndDate
      tariffGroupValidityStartDate
      powerDistributionUnit {
        id
        powerDistributionUnitLegalName
      }
    }
  }
`;
