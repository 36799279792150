import { gql } from '@apollo/client';

import { ContactGroups } from 'contactGroups/models/contactGroups';

interface ContactGroupConnection {
  entries: ContactGroups[];
  afterCursor: string | null;
  beforeCursor: string | null;
}

export interface ContactGroupList {
  contactGroups: ContactGroupConnection;
}

export const contactGroupsTypename = 'ContactGroupConnection';

const MAX_SIZE = 10000;

export const CONTACT_GROUPS_SELECT_QUERY_VARIABLES = {
  variables: { pageSize: MAX_SIZE, maximumLimit: MAX_SIZE },
};

export const CONTACT_GROUPS_QUERY = gql`
  query contactGroups(
    $pageSize: Int
    $after: String
    $before: String
    $filters: ContactGroupFilterOptions
    $maximumLimit: Int
  ) {
    contactGroups(
      after: $after
      before: $before
      filters: $filters
      pageSize: $pageSize
      maximumLimit: $maximumLimit
    ) {
      afterCursor
      beforeCursor
      entries {
        id
        groupName
        contactName
        contactPhone
        cooperativesCount
        consumerUnitsCount
        generationUnitsCount
      }
    }
  }
`;
