import { gql } from '@apollo/client';

export type CommercialAgreementDeleteMutationVariables = {
  id: number;
};

export const CommercialAgreementTypename = 'CommercialAgreement';

export const COMMERCIAL_AGREEMENT_DELETE_MUTATION = gql`
  mutation commercialAgreementDelete($id: ID!) {
    commercialAgreementDelete(id: $id) {
      message
    }
  }
`;
