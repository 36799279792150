import React from 'react';

import classNames from 'classnames';

import { FieldError } from 'react-hook-form';

export type Props = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
    error?: string | FieldError;
    ref?: React.Ref<HTMLTextAreaElement>;
    tooltipReference?: React.Ref<HTMLDivElement>;
  },
  HTMLTextAreaElement
>;

export const TextAreaInput = React.forwardRef<HTMLTextAreaElement, Props>(
  ({ error, tooltipReference, ...props }, ref) => {
    return (
      <div ref={tooltipReference} className="relative w-full">
        <textarea
          {...props}
          ref={ref}
          name={props.name}
          disabled={props.disabled}
          minLength={props.minLength}
          maxLength={props.maxLength}
          autoComplete={props.autoComplete}
          style={{
            resize: props.disabled ? 'none' : 'vertical',
          }}
          className={classNames(
            props.className ||
              'appearence-none focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-dark500 bg-gray-dark600 shadow-none rounded-md hover:border-primary placeholder-gray-dark500 focus-within:text-gray-dark400 text-gray-dark400',
            {
              'appearence-none border-gray-dark600 bg-gray-dark700 block text-gray-dark550 cursor-default shadow-none rounded-md hover:border-transparent !resize-none':
                props.disabled,
              'appearence-none focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-red-500 bg-transparent shadow-none rounded-md hover:border-red-500 placeholder-red500 focus-within:text-red-500 text-red-500':
                !!error,
            }
          )}
        />
        {error && (
          <p className="text-red-500 text-xs mt-3 font-normal text-left">{error}</p>
        )}
      </div>
    );
  }
);

export default TextAreaInput;
