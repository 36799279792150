import { useEffect, useMemo } from 'react';

import {
  Control,
  useForm,
  useWatch,
  FieldError,
  ErrorOption,
  UseFormSetValue,
  UseFormRegister,
} from 'react-hook-form';

import { Button } from 'ui';
import TextField from 'ui/form/TextField';
import CheckboxField from 'ui/form/CheckboxField';

import {
  MdClose,
  MdAttachFile,
  MdCloudUpload,
  MdRemoveRedEye,
} from 'react-icons/md';

import {
  BillConsumedEnergyKwh,
  ConsumerUnitBillDataId,
  BillExcessEnergyCredits,
  BillConsumedEnergyCredits,
  BillGeneratedEnergyCredits,
  BillConsumedEnergyCreditsTariffs,
} from 'powerDistributionUnits/powerDistributionUnitBillData/consumerUnitBillData/models/consumerUnitBillData';

import { ConsumptionGroupsType } from 'consumerUnits/model/consumerUnit';

import { ConsumerUnitBillingCycle } from 'consumerUnits/consumerUnitBillingCycles/models/consumerUnitBillingCycles';
import { dateWithTimezone } from 'utils/form';

export type FormFields = {
  billFileMetadata: [File];
  id: ConsumerUnitBillDataId;
  billDueDate: string;
  billIssueDate: string;
  billReferenceDate: string;
  billNextReadingDate: string;
  billReadingStartedAt: string;
  billReadingFinishedAt: string;
  billTotalValue: string;
  billTotalReadingDays: string;
  billConsumedEnergyKwh: BillConsumedEnergyKwh;
  billExcessEnergyCredits: BillExcessEnergyCredits;
  billConsumedEnergyCredits: BillConsumedEnergyCredits;
  billGeneratedEnergyCredits: BillGeneratedEnergyCredits;
  billConsumedEnergyCreditsTariffs: BillConsumedEnergyCreditsTariffs;
  convertCreditsOnPeakOffPeak: boolean;
};

type Field =
  | 'billDueDate'
  | 'billIssueDate'
  | 'billTotalValue'
  | 'billFileMetadata'
  | 'billReferenceDate'
  | 'billNextReadingDate'
  | 'billReadingStartedAt'
  | 'billTotalReadingDays'
  | 'billReadingFinishedAt'
  | 'billConsumedEnergyKwh'
  | 'billExcessEnergyCredits'
  | 'billConsumedEnergyCredits'
  | 'billGeneratedEnergyCredits'
  | 'billConsumedEnergyCreditsTariffs'
  | 'convertCreditsOnPeakOffPeak';

export const FORM_FIELDS: Field[] = [
  'billFileMetadata',
  'billDueDate',
  'billIssueDate',
  'billReferenceDate',
  'billNextReadingDate',
  'billReadingStartedAt',
  'billReadingFinishedAt',
  'billTotalValue',
  'billTotalReadingDays',
  'billConsumedEnergyKwh',
  'billExcessEnergyCredits',
  'billConsumedEnergyCredits',
  'billGeneratedEnergyCredits',
  'billConsumedEnergyCreditsTariffs',
  'convertCreditsOnPeakOffPeak',
];

export type FormErrors = {
  billFileMetadata?: [FieldError?];
  id?: FieldError;
  billDueDate?: FieldError;
  billIssueDate?: FieldError;
  billReferenceDate?: FieldError;
  billNextReadingDate?: FieldError;
  billReadingStartedAt?: FieldError;
  billReadingFinishedAt?: FieldError;
  billTotalValue?: FieldError;
  billTotalReadingDays?: FieldError;
  billConsumedEnergyKwh?: {
    consumedEnergyKwhTotal?: FieldError;
    consumedEnergyKwhOnPeak?: FieldError;
    consumedEnergyKwhOffPeak?: FieldError;
  };
  billExcessEnergyCredits?: {
    excessEnergyCreditsTotal?: FieldError;
    excessEnergyCreditsOnPeak?: FieldError;
    excessEnergyCreditsOffPeak?: FieldError;
  };
  billConsumedEnergyCredits?: {
    consumedEnergyCreditsTotal?: FieldError;
    consumedEnergyCreditsOnPeak?: FieldError;
    consumedEnergyCreditsOffPeak?: FieldError;
  };
  billGeneratedEnergyCredits?: {
    generatedEnergyCreditsTotal?: FieldError;
    generatedEnergyCreditsOnPeak?: FieldError;
    generatedEnergyCreditsOffPeak?: FieldError;
  };
  billConsumedEnergyCreditsTariffs?: {
    consumedEnergyCreditsTariffsTotal?: FieldError;
    consumedEnergyCreditsTariffsOnPeak?: FieldError;
    consumedEnergyCreditsTariffsOffPeak?: FieldError;
  };
  convertCreditsOnPeakOffPeak?: FieldError;
};

export default function ConsumerUnitBillDataFormFields({
  control,
  register,
  setValue,
  formErrors,
  uploadedFile,
  onClickOpenModal,
  onClickCancelModal,
  handleClickDownloadBill,
  consumerUnitBillingCycles,
}: {
  formErrors: FormErrors;
  uploadedFile?: File | null;
  control: Control<FormFields>;
  onClickOpenModal?: () => void;
  onClickCancelModal?: () => void;
  handleClickDownloadBill?: () => void;
  setValue: UseFormSetValue<FormFields>;
  register: UseFormRegister<FormFields>;
  consumerUnitBillingCycles: ConsumerUnitBillingCycle;
}) {
  const {
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<FormFields>();

  const {
    consumerUnitBillingCapture,
    consumerUnitBillingCycleDate = consumerUnitBillingCycles
      ?.powerDistributionUnitBillData?.billReferenceDate,
    consumerUnit: { consumerUnitConsumptionGroupType } = {},
  } = consumerUnitBillingCycles;

  const HAS_CONSUMER_UNIT_CONSUMPTION_GROUP_TYPE_B =
    consumerUnitConsumptionGroupType === ConsumptionGroupsType.B;

  const HAS_CONSUMER_UNIT_BILLING_CAPTURE_URL =
    !!consumerUnitBillingCapture?.consumerUnitBillingCaptureUrl;

  const [billReferenceDate, billReadingFinishedAt] = useWatch({
    control,
    name: ['billReferenceDate', 'billReadingFinishedAt'],
  });

  useMemo(() => {
    const REFERENCE_DATE_ERROR: ErrorOption = {
      type: 'manual',
      message: 'O mês de referência deve ser igual ao mês vigente do ciclo',
    };

    if (billReferenceDate?.length === 7 && !!consumerUnitBillingCycleDate) {
      const referenceDateWithTimezone = dateWithTimezone(
        new Date(
          +billReferenceDate.split('/')[1],
          +billReferenceDate.split('/')[0] - 1,
          1
        )
      );

      const readingFinishedAtWithTimezone = dateWithTimezone(
        new Date(
          +billReadingFinishedAt.split('/')[2],
          +billReadingFinishedAt.split('/')[1] - 1,
          1
        )
      );

      const consumerUnitBillingCycleDateWithTimezone = dateWithTimezone(
        new Date(
          +consumerUnitBillingCycleDate?.split('-')[0],
          +consumerUnitBillingCycleDate?.split('-')[1] - 1,
          1
        )
      );

      const isReferenceDateInvalid =
        referenceDateWithTimezone < readingFinishedAtWithTimezone ||
        referenceDateWithTimezone > readingFinishedAtWithTimezone ||
        referenceDateWithTimezone < consumerUnitBillingCycleDateWithTimezone ||
        referenceDateWithTimezone > consumerUnitBillingCycleDateWithTimezone;

      if (isReferenceDateInvalid) {
        setError('billReferenceDate' as keyof FormFields, REFERENCE_DATE_ERROR);
        setTimeout(() => clearErrors(), 6000);
      }
    }
  }, [
    billReferenceDate,
    billReadingFinishedAt,
    consumerUnitBillingCycleDate,
    setError,
    clearErrors,
  ]);

  useEffect(() => {
    if (HAS_CONSUMER_UNIT_CONSUMPTION_GROUP_TYPE_B) {
      setValue('billConsumedEnergyKwh.consumedEnergyKwhOnPeak', '0.00');
      setValue(
        'billConsumedEnergyCreditsTariffs.consumedEnergyCreditsTariffsOnPeak',
        '0.00'
      );
    }
  }, [setValue, HAS_CONSUMER_UNIT_CONSUMPTION_GROUP_TYPE_B]);

  return (
    <div className="w-full flex flex-col gap-8">
      <div className="w-auto">
        {HAS_CONSUMER_UNIT_BILLING_CAPTURE_URL ? (
          <Button
            size="none"
            type="button"
            variant="tertiaryGray"
            onClick={handleClickDownloadBill}
            className="flex justify-start items-center p-2"
          >
            <MdRemoveRedEye
              size={30}
              color="#B6B6B6"
              style={{
                marginLeft: '5px',
                marginRight: '5px',
              }}
            />
            <div className="flex flex-col justify-center items-start w-full cursor-pointer">
              <p className="font-normal text-sm text-gray-dark300 cursor-pointer">
                Visualizar fatura
              </p>
            </div>
          </Button>
        ) : uploadedFile ? (
          <Button
            size="none"
            className="flex justify-start items-center p-2"
            style={{
              backgroundColor: 'transparent',
              border: '2px solid #cccccc5d',
            }}
          >
            <MdAttachFile
              size={25}
              color="#cccccc5d"
              style={{
                marginRight: '10px',
              }}
            />
            <div className="flex flex-col justify-center items-start w-full">
              <p className="font-normal text-sm text-gray-dark400">
                {uploadedFile.name}
              </p>
            </div>
            <MdClose size={30} color="#cccccc5d" onClick={onClickCancelModal} />
          </Button>
        ) : (
          <Button
            size="none"
            type="button"
            variant="tertiaryGray"
            onClick={onClickOpenModal}
            className="flex justify-start items-center p-2"
          >
            <MdCloudUpload
              size={30}
              color="#B6B6B6"
              style={{
                marginLeft: '5px',
                marginRight: '5px',
              }}
            />
            <div className="flex flex-col justify-center items-start w-full cursor-pointer">
              <p className="font-normal text-sm text-gray-dark300 cursor-pointer">
                Importar fatura
              </p>
            </div>
          </Button>
        )}
      </div>
      <div className="w-fit">
        <CheckboxField
          wrapperClassName="text-white"
          error={errors.convertCreditsOnPeakOffPeak?.message}
          {...register('convertCreditsOnPeakOffPeak', { required: false })}
          inputLabel="Conversão de créditos ponta para fora de ponta"
        />
      </div>
      <div className="grid grid-cols-4 gap-8">
        <TextField
          required
          mask="date"
          id="billIssueDate"
          placeholder="DD/MM/AAAA"
          label="Data de disponibilidade"
          error={formErrors.billIssueDate?.message}
          {...register('billIssueDate', {
            required: 'Campo obrigatório',
          })}
        />
        <TextField
          required
          mask="date"
          maxLength={7}
          placeholder="MM/AAAA"
          id="billReferenceDate"
          label="Mês de referência da fatura"
          error={
            errors.billReferenceDate?.message ||
            formErrors.billReferenceDate?.message
          }
          {...register('billReferenceDate', {
            required: 'Campo obrigatório',
          })}
        />
        <TextField
          required
          mask="date"
          id="billDueDate"
          placeholder="DD/MM/AAAA"
          label="Data de vencimento"
          error={formErrors.billDueDate?.message}
          {...register('billDueDate', {
            required: 'Campo obrigatório',
          })}
        />
      </div>
      <div className="grid grid-cols-4 gap-8">
        <TextField
          required
          mask="date"
          id="billReadingStartedAt"
          placeholder="DD/MM/AAAA"
          label="Data leitura anterior"
          error={formErrors.billReadingStartedAt?.message}
          {...register('billReadingStartedAt', {
            required: 'Campo obrigatório',
          })}
        />
        <TextField
          required
          mask="date"
          placeholder="DD/MM/AAAA"
          id="billReadingFinishedAt"
          label="Data leitura atual"
          error={formErrors.billReadingFinishedAt?.message}
          {...register('billReadingFinishedAt', {
            required: 'Campo obrigatório',
          })}
        />
        <TextField
          required
          mask="date"
          id="billNextReadingDate"
          placeholder="DD/MM/AAAA"
          label="Data leitura futura"
          error={formErrors.billNextReadingDate?.message}
          {...register('billNextReadingDate', {
            required: 'Campo obrigatório',
          })}
        />
      </div>
      <div className="grid grid-cols-4 gap-8">
        <TextField
          required
          mask="currency"
          id="billTotalValue"
          placeholder="R$ 0,00"
          label="Valor da fatura"
          error={formErrors.billTotalValue?.message}
          {...register('billTotalValue', {
            required: 'Campo obrigatório',
          })}
        />
      </div>
      <div className="grid grid-cols-4 gap-8">
        <TextField
          required
          mask="decimal"
          placeholder="0,00"
          label="Consumo ponta (kWh)"
          id="consumedEnergyKwhOnPeak"
          disabled={HAS_CONSUMER_UNIT_CONSUMPTION_GROUP_TYPE_B}
          error={formErrors.billConsumedEnergyKwh?.consumedEnergyKwhOnPeak?.message}
          {...register('billConsumedEnergyKwh.consumedEnergyKwhOnPeak', {
            required: 'Campo obrigatório',
          })}
        />
        <TextField
          required
          mask="decimal"
          placeholder="0,00"
          id="consumedEnergyCreditsOnPeak"
          label="Créditos compensados ponta"
          error={
            formErrors.billConsumedEnergyCredits?.consumedEnergyCreditsOnPeak
              ?.message
          }
          {...register('billConsumedEnergyCredits.consumedEnergyCreditsOnPeak', {
            required: 'Campo obrigatório',
          })}
        />
        <TextField
          required
          placeholder="0,00000000"
          mask="decimalEightDigitsPrecision"
          label="Tarifa compensada ponta"
          id="consumedEnergyCreditsTariffsOnPeak"
          disabled={HAS_CONSUMER_UNIT_CONSUMPTION_GROUP_TYPE_B}
          error={
            formErrors.billConsumedEnergyCreditsTariffs
              ?.consumedEnergyCreditsTariffsOnPeak?.message
          }
          {...register(
            'billConsumedEnergyCreditsTariffs.consumedEnergyCreditsTariffsOnPeak',
            {
              required: 'Campo obrigatório',
            }
          )}
        />
      </div>
      <div className="grid grid-cols-4 gap-8">
        <TextField
          required
          mask="decimal"
          placeholder="0,00"
          id="consumedEnergyKwhOffPeak"
          label="Consumo fora ponta (kWh)"
          error={formErrors.billConsumedEnergyKwh?.consumedEnergyKwhOffPeak?.message}
          {...register('billConsumedEnergyKwh.consumedEnergyKwhOffPeak', {
            required: 'Campo obrigatório',
          })}
        />
        <TextField
          required
          mask="decimal"
          placeholder="0,00"
          id="consumedEnergyCreditsOffPeak"
          label="Créditos compensados fora ponta"
          error={
            formErrors.billConsumedEnergyCredits?.consumedEnergyCreditsOffPeak
              ?.message
          }
          {...register('billConsumedEnergyCredits.consumedEnergyCreditsOffPeak', {
            required: 'Campo obrigatório',
          })}
        />
        <TextField
          required
          placeholder="0,00000000"
          mask="decimalEightDigitsPrecision"
          label="Tarifa compensada fora ponta"
          id="consumedEnergyCreditsTariffsOffPeak"
          error={
            formErrors.billConsumedEnergyCreditsTariffs
              ?.consumedEnergyCreditsTariffsOffPeak?.message
          }
          {...register(
            'billConsumedEnergyCreditsTariffs.consumedEnergyCreditsTariffsOffPeak',
            {
              required: 'Campo obrigatório',
            }
          )}
        />
      </div>
    </div>
  );
}
