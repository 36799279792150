import { Control, Controller, FieldError, UseFormRegister } from 'react-hook-form';
import omit from 'lodash/omit';

import { TariffFlagModalityModalityFlagType } from 'tariffFlagTariffs/models/tariffFlagModality';
import DatePickerFieldSingleView from 'ui/form/DatePickerFieldSingleView';
import SelectField from 'ui/form/SelectField';
import TextField from 'ui/form/TextField';

export type FormFields = {
  tariffFlagTariffModalityFlag: TariffFlagModalityModalityFlagType;
  tariffFlagTariffValidityPeriod: Date;
  tariffFlagTariffFee: string;
};

export type FormErrors = {
  tariffFlagTariffModalityFlag?: FieldError | undefined;
  tariffFlagTariffValidityPeriod?: FieldError | undefined;
  tariffFlagTariffFee?: FieldError | undefined;
};

const tariffFlagTariffModalityFlagOptions = [
  {
    key: 'GREEN',
    value: 'VERDE',
  },
  {
    key: 'YELLOW',
    value: 'AMARELA',
  },
  {
    key: 'RED_ON_PEAK',
    value: 'VERMELHA PT1',
  },
  {
    key: 'RED_ON_PEAK_TWO',
    value: 'VERMELHA PT2',
  },
];

export default function TariffFlagModalityFormFields({
  errors,
  control,
  register,
}: {
  errors: FormErrors;
  control: Control<FormFields>;
  register: UseFormRegister<FormFields>;
}) {
  return (
    <div className="grid grid-cols-12 gap-6">
      <div className="col-start-1 col-end-7">
        <Controller
          name="tariffFlagTariffModalityFlag"
          control={control}
          rules={{ required: 'Campo obrigatório' }}
          render={({ field }) => (
            <SelectField
              required
              {...omit(field, 'ref')}
              error={errors.tariffFlagTariffModalityFlag?.message}
              label="Bandeira"
              emptyOptionLabel="Selecionar"
              id="tariffFlagTariffModalityFlag"
              options={tariffFlagTariffModalityFlagOptions}
            />
          )}
        />
      </div>

      <div className="col-start-7 col-end-12">
        <Controller
          render={({ field }) => (
            <DatePickerFieldSingleView
              required
              {...omit(field, 'ref')}
              error={errors.tariffFlagTariffValidityPeriod?.message}
              id="tariffFlagTariffValidityPeriod"
              labelDate="Início da vigência"
              placeholder="Mês e ano"
              options={{ month: '2-digit', year: '2-digit' }}
            />
          )}
          name="tariffFlagTariffValidityPeriod"
          control={control}
          rules={{ required: 'Campo obrigatório' }}
        />
      </div>

      <div className="col-start-1 col-end-10">
        <TextField
          required
          error={errors.tariffFlagTariffFee?.message}
          label="Tarifa (em R$/kWh)"
          id="tariffFlagTariffFee"
          {...register('tariffFlagTariffFee', { required: 'Campo obrigatório' })}
          placeholder="Ex. 0,018740"
          mask="decimalSixDigitsPrecision"
        />
      </div>
    </div>
  );
}
