import { gql } from '@apollo/client';

export interface powerDistributionUnitVariables {
  powerDistributionUnitId: string;
}

export const powerDistributionUnitTypename = 'PowerDistributionUnit';

export const POWER_DISTRIBUTION_UNIT_QUERY = gql`
  query powerDistributionUnit($powerDistributionUnitId: ID!) {
    powerDistributionUnit(powerDistributionUnitId: $powerDistributionUnitId) {
      id
      powerDistributionUnitIcmsFlag
      powerDistributionUnitLegalName
      powerDistributionUnitDeletedAt
      powerDistributionUnitDocumentId
      powerDistributionUnitCountryState
      powerDistributionUnitPisCofinsFlag
    }
  }
`;
