import { GenerationUnit } from 'generationUnits/models/generationUnit';
import GenerationUnitsTableItem from './GenerationUnitsTableItem';
import Table from 'ui/components/table/Table';
import TableHead from 'ui/components/table/TableHead';
import TableHeadColumn from 'ui/components/table/TableHeadColumn';
import TableBody from 'ui/components/table/TableBody';

type Props = {
  generationUnits: GenerationUnit[];
  onClickEditButton: (id: number) => void;
  onClickRemoveButton: (id: number) => void;
  disableEditButton: boolean;
  disableRemoveButton: boolean;
};

export default function GenerationUnitsTable({
  generationUnits,
  onClickEditButton,
  onClickRemoveButton,
  disableEditButton,
  disableRemoveButton,
}: Props) {
  return (
    <Table>
      <TableHead>
        <TableHeadColumn>Nome</TableHeadColumn>
        <TableHeadColumn>Conc.</TableHeadColumn>
        <TableHeadColumn>UF</TableHeadColumn>
        <TableHeadColumn>Cooperativa</TableHeadColumn>
        <TableHeadColumn>Início</TableHeadColumn>
        <TableHeadColumn>Conta Financeira</TableHeadColumn>
        <TableHeadColumn>Rateio</TableHeadColumn>
        <TableHeadColumn>Acordo Comercial</TableHeadColumn>
        <TableHeadColumn className="text-right">Ações</TableHeadColumn>
      </TableHead>
      <TableBody>
        {generationUnits.map((generationUnit: GenerationUnit) => (
          <GenerationUnitsTableItem
            key={generationUnit.id}
            generationUnit={generationUnit}
            onClickEditButton={onClickEditButton}
            onClickRemoveButton={onClickRemoveButton}
            disableEditButton={disableEditButton}
            disableRemoveButton={disableRemoveButton}
          />
        ))}
      </TableBody>
    </Table>
  );
}
