import { gql } from '@apollo/client';
import {
  CooperativeMemberEntityType,
  CooperativeMemberModalityType,
  CooperativeSelect,
} from 'cooperatives/models/cooperativeMember';

interface CooperativeMemberSelectConnection {
  entries: {
    id: number;
    cooperativeMemberEmail: string;
    cooperativeMemberLegalName: string;
    cooperativeMemberDocumentId: string;
    cooperativeMemberOptionalEmail?: string | null;
    cooperativeMemberEntityType: CooperativeMemberEntityType;
    cooperativeMemberModalityType: CooperativeMemberModalityType;
    cooperative: CooperativeSelect;
  }[];
}

export interface CooperativeMembersSelectList {
  cooperativeMembers: CooperativeMemberSelectConnection;
}

export const cooperativeMembersTypename = 'CooperativeMemberConnection';

const MAX_SIZE = 10000;

export const COOPERATIVE_MEMBERS_SELECT_QUERY_VARIABLES = {
  variables: { pageSize: MAX_SIZE, maximumLimit: MAX_SIZE },
};

export const COOPERATIVE_MEMBERS_SELECT_QUERY = gql`
  query cooperativeMembers(
    $after: String
    $before: String
    $pageSize: Int
    $maximumLimit: Int
  ) {
    cooperativeMembers(
      after: $after
      before: $before
      pageSize: $pageSize
      maximumLimit: $maximumLimit
    ) {
      entries {
        id
        cooperativeMemberEmail
        cooperativeMemberLegalName
        cooperativeMemberDocumentId
        cooperativeMemberEntityType
        cooperativeMemberModalityType
        cooperativeMemberOptionalEmail
        cooperative {
          id
          cooperativeLegalName
        }
      }
    }
  }
`;
