import omit from 'lodash/omit';
import TextField from 'ui/form/TextField';
import { Control, Controller, FieldError, UseFormRegister } from 'react-hook-form';
import CountryStateSelectField from 'ui/form/CountryStateSelectField';
import CheckboxField from 'ui/form/CheckboxField';

export type FormFields = {
  powerDistributionUnitLegalName: string;
  powerDistributionUnitIcmsFlag: boolean;
  powerDistributionUnitDocumentId: string;
  powerDistributionUnitCountryState: string;
  powerDistributionUnitPisCofinsFlag: boolean;
};

type Field =
  | 'powerDistributionUnitLegalName'
  | 'powerDistributionUnitIcmsFlag'
  | 'powerDistributionUnitDocumentId'
  | 'powerDistributionUnitCountryState'
  | 'powerDistributionUnitPisCofinsFlag';

export const FORM_FIELDS: Field[] = [
  'powerDistributionUnitLegalName',
  'powerDistributionUnitIcmsFlag',
  'powerDistributionUnitDocumentId',
  'powerDistributionUnitCountryState',
  'powerDistributionUnitPisCofinsFlag',
];

export type FormErrors = {
  powerDistributionUnitIcmsFlag?: FieldError | undefined;
  powerDistributionUnitLegalName?: FieldError | undefined;
  powerDistributionUnitDocumentId?: FieldError | undefined;
  powerDistributionUnitCountryState?: FieldError | undefined;
  powerDistributionUnitPisCofinsFlag?: FieldError | undefined;
};

export default function PowerDistributionUnitFields({
  errors,
  register,
  control,
}: {
  errors: FormErrors;
  register: UseFormRegister<FormFields>;
  control: Control<FormFields>;
}) {
  return (
    <div className="w-full flex-col flex items-start justify-center gap-8">
      <div className="w-full grid grid-cols-3 gap-8 items-center justify-start">
        <TextField
          required
          error={errors.powerDistributionUnitLegalName?.message}
          label="Nome"
          id="powerDistributionUnitLegalName"
          {...register('powerDistributionUnitLegalName', {
            required: 'Campo obrigatório',
          })}
          placeholder="Ex.: COPEL"
        />

        <Controller
          name="powerDistributionUnitCountryState"
          control={control}
          rules={{ required: 'Campo obrigatório' }}
          render={({ field }) => (
            <CountryStateSelectField
              required
              {...omit(field, 'ref')}
              error={errors.powerDistributionUnitCountryState?.message}
              label="Estado"
              id="powerDistributionUnitCountryState"
            />
          )}
        />

        <TextField
          required
          error={errors.powerDistributionUnitDocumentId?.message}
          label="Documento"
          mask="document"
          id="powerDistributionUnitDocumentId"
          {...register('powerDistributionUnitDocumentId', {
            required: 'Campo obrigatório',
          })}
          placeholder="Ex.: 28.054.303/0001-06"
        />
      </div>

      <div className="grid grid-cols-2 gap-8 items-center justify-start">
        <CheckboxField
          inputLabel="Possui ICMS?"
          {...register('powerDistributionUnitIcmsFlag')}
        />

        <CheckboxField
          inputLabel="Possui PIS/COFINS?"
          {...register('powerDistributionUnitPisCofinsFlag')}
        />
      </div>
    </div>
  );
}
