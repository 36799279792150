import { gql } from '@apollo/client';
import {
  CommercialAgreement,
  CommercialAgreementReceiptRuleType,
} from 'commercialAgreements/models/commercialAgreement';

export interface CommercialAgreementCreateMutationInput {
  commercialAgreementStartValidity: string;
  commercialAgreementHasPisCofins: boolean;
  commercialAgreementHasExtraRemuneration: boolean;
  commercialAgreementReceiptRule: CommercialAgreementReceiptRuleType;
  commercialAgreementOemValue: string;
  commercialAgreementLeaseValue: string;
  commercialAgreementHasOem: boolean;
  commercialAgreementDiscountMax: string;
  commercialAgreementDiscountMin: string;
  commercialAgreementBaseCostPerCreditIcms: string;
  commercialAgreementBaseCostPerCredit: string;
  generationUnitId: number;
}

export interface CommercialAgreementMutationVariables {
  commercialAgreementCreateInput: CommercialAgreementCreateMutationInput;
}

export interface CommercialAgreementCreated {
  commercialAgreementCreate: CommercialAgreement;
}

export const COMMERCIAL_AGREEMENT_CREATE_MUTATION = gql`
  mutation commercialAgreementCreate(
    $commercialAgreementCreateInput: commercialAgreementCreateInput!
  ) {
    commercialAgreementCreate(input: $commercialAgreementCreateInput) {
      id
      commercialAgreementStartValidity
      commercialAgreementHasPisCofins
      commercialAgreementHasExtraRemuneration
      commercialAgreementReceiptRule
      commercialAgreementLeaseValue
      commercialAgreementOemValue
      commercialAgreementHasOem
      commercialAgreementDiscountMin
      commercialAgreementDiscountMax
      commercialAgreementBaseCostPerCreditIcms
      commercialAgreementBaseCostPerCredit
      generationUnit {
        id
        generationUnitLegalName
      }
    }
  }
`;
