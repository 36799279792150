import { Control, Controller, FieldError, UseFormRegister } from 'react-hook-form';
import omit from 'lodash/omit';
import { Option } from 'ui/components/form/SelectInput';
import DatePickerFieldSingleView from 'ui/form/DatePickerFieldSingleView';
import SelectField from 'ui/form/SelectField';
import FileUpload from 'apportionmentCycles/components/apportionmentCycleConsumerUnits/upload/fileUpload';
import { useEffect, useState } from 'react';
import { GenerationUnitSelect } from 'generationUnits/models/generationUnit';

export type FormFields = {
  generationUnitId?: number;
  apportionmentCycleConsumerUnitFileMetadata: [File];
  apportionmentCycleConsumerUnitReferenceMonth: Date;
};

export type FormErrors = {
  generationUnitId?: FieldError | undefined;
  apportionmentCycleConsumerUnitReferenceMonth?: FieldError | undefined;
};

export default function ApportionmentCycleConsumerUnitFormFields({
  errors,
  control,
  register,
  generationUnits,
}: {
  errors: FormErrors;
  control: Control<FormFields>;
  register: UseFormRegister<FormFields>;
  generationUnits?: GenerationUnitSelect[];
}) {
  const [generationUnitOptions, setGenerationUnitOptions] = useState<
    Option<number>[]
  >([]);

  useEffect(
    () =>
      setGenerationUnitOptions(
        generationUnits?.map((generationUnit) => ({
          key: generationUnit.id,
          value: generationUnit.generationUnitLegalName,
        })) ?? []
      ),
    [generationUnits]
  );

  return (
    <div className="flex flex-col gap-4 pt-4">
      <div className="flex gap-4 w-full">
        <Controller
          name="generationUnitId"
          control={control}
          rules={{ required: 'Este campo é obrigatório' }}
          render={({ field }) => (
            <SelectField
              {...omit(field, 'ref')}
              wrapperClassName="w-64 max-w-full"
              error={errors.generationUnitId?.message}
              label="Unidade Geradora"
              emptyOptionLabel="Selecionar Unidade Geradora"
              id="generationUnitId"
              options={generationUnitOptions}
            />
          )}
        />
        <Controller
          render={({ field }) => (
            <DatePickerFieldSingleView
              {...omit(field, 'ref')}
              calendarWrapperClassName="z-10"
              error={errors.apportionmentCycleConsumerUnitReferenceMonth?.message}
              id="apportionmentCycleConsumerUnitReferenceMonth"
              labelDate="Início do rateio"
              placeholder="MM/AA"
              options={{ month: '2-digit', year: '2-digit' }}
            />
          )}
          name="apportionmentCycleConsumerUnitReferenceMonth"
          control={control}
          rules={{ required: 'Este campo é obrigatório' }}
        />
      </div>

      <FileUpload
        accept=".csv"
        label="Importar arquivo"
        id="apportionmentCycleConsumerUnitFileMetadata"
        {...register('apportionmentCycleConsumerUnitFileMetadata', {
          required: 'Este campo é obrigatório',
        })}
      />
    </div>
  );
}
