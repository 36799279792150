import { gql } from '@apollo/client';

export type PowerDistributionUnitDeleteMutationVariables = {
  id: number;
};

export const POWER_DISTRIBUTION_UNIT_DELETE_MUTATION = gql`
  mutation powerDistributionUnitDelete($id: ID!) {
    powerDistributionUnitDelete(id: $id) {
      message
    }
  }
`;
