import { useContext, useEffect, useState } from 'react';

import { has } from 'lodash';

import { useTranslation } from 'react-i18next';

import { useHistory } from 'react-router-dom';

import { ErrorOption, SubmitHandler, useForm } from 'react-hook-form';

import { ApolloError, useMutation } from '@apollo/client';

import { Button } from 'ui';
import useToastContext from 'ui/hooks/useToast';
import { ToastProps } from 'ui/contexts/overlay/Toast';
import { useLoading } from 'ui/contexts/overlay/Loading';
import { ModalFormProps, ModalProps } from 'ui/models/overlay';

import { formatDateToIsoString, setFormError } from 'utils/form';

import {
  ChargeUpdateCreated,
  CHARGE_UPDATE_MUTATION,
  ChargeUpdateMutationVariables,
} from 'charges/graphql/chargeUpdateMutation';

import ChargesUpdateFormFields, {
  FormFields,
} from '../form/ChargesUpdateFormFields';

import {
  ChargeId,
  ChargeUpdateCancelType,
  ChargeUpdateRequestType,
} from 'charges/models/charges';
import { AuthContext } from 'auth/contexts/Auth';
import { ConsumerUnit } from 'consumerUnits/model/consumerUnit';
import { ConsumerUnitBillData } from 'powerDistributionUnits/powerDistributionUnitBillData/consumerUnitBillData/models/consumerUnitBillData';

export interface ChargesUpdateModalData {
  chargeId?: ChargeId;
  consumerUnit?: ConsumerUnit;
  powerDistributionUnitBillData?: ConsumerUnitBillData;
}

type Props = {
  refetch: () => void;
  modalProps: ModalProps;
  onClickCloseModal: () => void;
  chargeUpdateModalData?: ChargesUpdateModalData;
  ModalOverlay: (modal: ModalFormProps) => JSX.Element;
};

const CREATE_ERROR_TOAST = (text?: string) =>
  ({
    variant: 'danger',
    title: 'Algo deu errado!',
    text: text || 'Houve um erro ao tentar reemitir a cobrança.',
  } as ToastProps);

const CREATE_SUCCESS_TOAST = (text?: string) =>
  ({
    variant: 'primary',
    title: 'Sucesso!',
    text: text || 'Cobrança reemitida com sucesso.',
  } as ToastProps);

const BILL_DATA_ROUTE = '/dashboard/bill-data/consumer-units/create';

export default function ChargesUpdateModal({
  refetch,
  modalProps,
  ModalOverlay,
  onClickCloseModal,
  chargeUpdateModalData: {
    chargeId,
    consumerUnit,
    powerDistributionUnitBillData,
  } = {} as ChargesUpdateModalData,
}: Props) {
  const {
    control,
    setError,
    register,
    setValue,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm<FormFields>();

  const { t } = useTranslation();

  const { push } = useHistory();

  const { addToast } = useToastContext();

  const { user } = useContext(AuthContext);

  const { showLoading, closeLoading } = useLoading();

  const [chargeUpdateRequestType, setChargeUpdateRequestType] =
    useState<ChargeUpdateRequestType>();

  const [chargeUpdateModalCreateMutation, { loading: loadingCreate }] = useMutation<
    ChargeUpdateCreated,
    ChargeUpdateMutationVariables
  >(CHARGE_UPDATE_MUTATION, {
    onError(error: ApolloError) {
      if (has(error.graphQLErrors[0], 'message')) {
        addToast(CREATE_ERROR_TOAST(t(error.graphQLErrors[0].message)));
      } else {
        addToast(CREATE_ERROR_TOAST());
      }

      onClickCloseModal();

      setFormError(
        error,
        (field: string, error: ErrorOption) => {
          setError(field as keyof FormFields, error);

          setTimeout(() => clearErrors(), 2500);
        },
        t
      );
    },
    onCompleted() {
      refetch();

      onClickCloseModal();

      switch (chargeUpdateRequestType) {
        case ChargeUpdateRequestType.CHARGE_CANCEL_REISSUE:
          push({
            pathname: BILL_DATA_ROUTE,
            state: {
              consumerUnit,
              powerDistributionUnitBillData,
            },
          });
          break;
        case ChargeUpdateRequestType.CHARGE_CANCEL:
          addToast(CREATE_SUCCESS_TOAST('A cobrança foi cancelada.'));
          break;
        case ChargeUpdateRequestType.CHARGE_UPDATE:
          addToast(CREATE_SUCCESS_TOAST('A cobrança foi atualizada com sucesso.'));
          break;
      }
    },
  });

  const onSubmit: SubmitHandler<FormFields> = (chargeUpdateInput) => {
    const CHARGE_CANCEL_TYPE =
      chargeUpdateInput.chargeUpdateRequestType ===
      ChargeUpdateRequestType.CHARGE_CANCEL;

    const CHARGE_UPDATE_REQUEST_TYPE =
      (CHARGE_CANCEL_TYPE &&
        chargeUpdateInput.chargeUpdateCancelType ===
          ChargeUpdateCancelType.REISSUE &&
        ChargeUpdateRequestType.CHARGE_CANCEL_REISSUE) ||
      ChargeUpdateRequestType.CHARGE_CANCEL;

    setChargeUpdateRequestType(CHARGE_UPDATE_REQUEST_TYPE);

    if (CHARGE_CANCEL_TYPE) {
      Object.assign(chargeUpdateInput, {
        chargeUpdateRequestType: CHARGE_UPDATE_REQUEST_TYPE,
      });

      delete chargeUpdateInput.newDueDate;
    }

    delete chargeUpdateInput.chargeUpdateCancelType;

    Object.assign(chargeUpdateInput, {
      id: chargeId,
      chargeUpdateRequestingUser: user?.userEmail,
    });

    !!chargeUpdateInput.newDueDate &&
      chargeUpdateInput.newDueDate.length > 0 &&
      Object.assign(chargeUpdateInput, {
        newDueDate: formatDateToIsoString(chargeUpdateInput.newDueDate),
      });

    chargeUpdateModalCreateMutation({
      variables: {
        chargeUpdateInput: {
          ...chargeUpdateInput,
          keepInterestAndFine: true,
          keepEarlyPaymentDiscount: false,
        },
      },
    });
  };

  const isLoading = loadingCreate;

  useEffect(() => {
    if (isLoading) {
      showLoading();
      return;
    }

    closeLoading();
  }, [isLoading, showLoading, closeLoading]);

  return (
    <ModalOverlay
      maxWidth="650px"
      title={modalProps.title}
      variant={modalProps.variant}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full flex flex-col items-center justify-center"
      >
        <ChargesUpdateFormFields
          errors={errors}
          control={control}
          register={register}
          setValue={setValue}
        />

        <div className="flex w-full items-center justify-end mt-6 gap-4">
          <Button size="md" variant="primaryGray" onClick={onClickCloseModal}>
            Voltar
          </Button>
          <Button type="submit" variant="primaryGreen" size="md">
            Confirmar
          </Button>
        </div>
      </form>
    </ModalOverlay>
  );
}
