import { FormContainer } from 'ui';
import CheckboxField from 'ui/form/CheckboxField';
import EmailField from 'ui/form/EmailField';
import { FieldError, UseFormRegister } from 'react-hook-form';
import TextField from 'ui/form/TextField';

type Field = 'userName' | 'userEmail' | 'userIsAdmin';

export const FORM_FIELDS: Field[] = ['userName', 'userEmail', 'userIsAdmin'];
export type FormFields = {
  userName: string;
  userEmail: string;
  userIsAdmin: boolean;
};

export type FormErrors = {
  userName?: FieldError | undefined;
  userEmail?: FieldError | undefined;
  userIsAdmin?: FieldError | undefined;
};

export default function UserFormFields({
  disable,
  errors,
  register,
}: {
  disable?: {
    userEmail?: boolean;
  };
  errors: FormErrors;
  register: UseFormRegister<FormFields>;
}) {
  return (
    <FormContainer>
      <CheckboxField
        error={errors.userIsAdmin?.message}
        label="O usuário é administrador?"
        {...register('userIsAdmin', { required: false })}
        wrapperClassName="col-span-3 mb-4 text-white"
        inputLabel="Administrador"
      />
      <TextField
        className="mt-2 focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-dark500 bg-gray-dark600 shadow-none rounded-md hover:border-primary placeholder-gray-dark500 focus-within:text-white text-white"
        required
        error={errors.userName?.message}
        label="Nome"
        id="userName"
        {...register('userName', { required: 'Campo obrigatório' })}
        placeholder="Ex.: João da Silva"
      />
      <EmailField
        required
        label="Email"
        id="userEmail"
        autoComplete="email"
        disabled={disable?.userEmail}
        error={errors.userEmail?.message}
        {...register('userEmail', { required: 'Campo obrigatório' })}
        placeholder="email@nexenergy.com.br"
      />
    </FormContainer>
  );
}
