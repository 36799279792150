import { useEffect, useState } from 'react';

import { Control, Controller, FieldError, UseFormRegister } from 'react-hook-form';

import { Button } from 'ui';
import SelectField from 'ui/form/SelectField';
import { Option } from 'ui/components/form/SelectInput';
import DatePickerFieldSingleView from 'ui/form/DatePickerFieldSingleView';

import omit from 'lodash/omit';

import { GenerationUnitSelectModel } from 'generationUnits/models/generationUnit';

export type FormFilters = {
  generationUnitId: number;
  apportionmentCycleConsumerUnitReferenceMonth: Date;
};

export type FormErrors = {
  generationUnitId?: FieldError;
  apportionmentCycleConsumerUnitReferenceMonth?: FieldError;
};

export default function ApportionmentCycleConsumerUnitFormFilters({
  errors,
  control,
  isLoading,
  generationUnits,
}: {
  errors: FormErrors;
  isLoading?: boolean;
  control: Control<FormFilters>;
  register: UseFormRegister<FormFilters>;
  generationUnits?: GenerationUnitSelectModel[];
}) {
  const [generationUnitOptions, setGenerationUnitOptions] = useState<
    Option<number>[]
  >([]);

  useEffect(
    () =>
      setGenerationUnitOptions(
        (generationUnits instanceof Array &&
          generationUnits.map((generationUnit) => ({
            key: Number(generationUnit.id),
            value: generationUnit.generationUnitLegalName,
          }))) ||
          []
      ),
    [generationUnits]
  );

  return (
    <div className="grid grid-cols-6 gap-4 pb-6">
      <Controller
        name="generationUnitId"
        control={control}
        render={({ field }) => (
          <SelectField
            {...omit(field, 'ref')}
            id="generationUnitId"
            label="Unidade Geradora"
            emptyOptionLabel="Selecionar"
            options={generationUnitOptions}
            error={errors.generationUnitId?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="apportionmentCycleConsumerUnitReferenceMonth"
        render={({ field }) => (
          <DatePickerFieldSingleView
            {...omit(field, 'ref')}
            labelDate="Ciclo"
            placeholder="MM/AA"
            options={{ month: '2-digit', year: '2-digit' }}
            id="apportionmentCycleConsumerUnitReferenceMonths"
            error={errors.apportionmentCycleConsumerUnitReferenceMonth?.message}
          />
        )}
      />

      <Button type="submit" size="sm" disabled={isLoading} className="self-end w-24">
        Filtrar
      </Button>
    </div>
  );
}
