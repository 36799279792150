import React from 'react';
import { useAuth } from 'auth/contexts/Auth';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute: React.FC<{
  component: React.FC;
  path: string;
  exact?: boolean;
}> = ({ path, exact, component }) => {
  const { token } = useAuth();

  return !!token ? (
    <Route path={path} exact={exact} component={component} />
  ) : (
    <Redirect to="/" />
  );
};

export default PrivateRoute;
