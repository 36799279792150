import { gql } from '@apollo/client';

import { Bank } from 'banks/models/bank';

interface BankConnection {
  entries: Bank[];
  afterCursor: string | null;
  beforeCursor: string | null;
}

export interface BankList {
  banks: BankConnection;
}

export const banksTypename = 'BankConnection';

const MAX_SIZE = 10000;

export const BANKS_SELECT_QUERY_VARIABLES = {
  variables: { pageSize: MAX_SIZE, maximumLimit: MAX_SIZE },
};

export const BANKS_QUERY = gql`
  query banks($after: String, $before: String, $pageSize: Int, $maximumLimit: Int) {
    banks(
      after: $after
      before: $before
      pageSize: $pageSize
      maximumLimit: $maximumLimit
    ) {
      afterCursor
      beforeCursor
      entries {
        id
        bankName
        bankCode
      }
    }
  }
`;
