import { gql } from '@apollo/client';
import { PisCofinsTax } from 'pisCofinsTaxes/models/pisCofinsTax';

interface PowerDistributionUnitPisCofinsTax {
  afterCursor: string | null;
  beforeCursor: string | null;
  entries: PisCofinsTax[];
}

export interface PisCofinsTaxesList {
  powerDistributionUnitPisCofinsTaxes: PowerDistributionUnitPisCofinsTax;
}

export const pisCofinsTaxesTypename = 'PowerDistributionUnitPisCofinsTaxConnection';

export const PIS_COFINS_TAXES_QUERY = gql`
  query pixCofinsTaxes(
    $after: String
    $before: String
    $pageSize: Int
    $maximumLimit: Int
  ) {
    powerDistributionUnitPisCofinsTaxes(
      after: $after
      before: $before
      pageSize: $pageSize
      maximumLimit: $maximumLimit
    ) {
      entries {
        id
        pisCofinsValidityStartAt
        pisCofinsValidityEndAt
        pisCofinsPisFee
        pisCofinsCofinsFee
        powerDistributionUnit {
          powerDistributionUnitLegalName
          powerDistributionUnitCountryState
        }
      }
      beforeCursor
      afterCursor
    }
  }
`;
