import { gql } from '@apollo/client';

export const AUTHENTICATE = gql`
  query authenticate($userEmail: String!, $userPassword: String!) {
    authenticate(userEmail: $userEmail, userPassword: $userPassword) {
      user {
        userName
        userEmail
      }
      userToken
    }
  }
`;
