import { Cooperative } from 'cooperatives/models/cooperative';
import { ConsumerUnit } from 'consumerUnits/model/consumerUnit';
import { GenerationUnit } from 'generationUnits/models/generationUnit';

export type GroupId = number;
export type EntityId = number;
export type ContactId = number;
export type ContactTypeId = number;
export type ContactGroupId = number;

export enum ContactGroupsExportType {
  SUMMEDUP = 'SUMMEDUP',
  DETAILED = 'DETAILED',
}

export enum ContactTypes {
  OWNER = 'OWNER',
  DOMAIN = 'DOMAIN',
  FINANCIAL = 'FINANCIAL',
  TECHNICAL = 'TECHNICAL',
  JURIDICAL = 'JURIDICAL',
  ADMINISTRATIVE = 'ADMINISTRATIVE',
}

export enum EntityTypes {
  COOPERATIVE = 'cooperatives',
  CONSUMER_UNIT = 'consumerUnits',
  GENERATION_UNIT = 'generationUnits',
  COOPERATIVE_MEMBER = 'cooperativeMembers',
}

export interface Groups {
  id: GroupId;
  groupName: string;
}

export interface Contact {
  id: ContactId;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  contactType: {
    id: ContactTypeId;
    contactTypeName: ContactTypes;
  };
}

export interface Contacts {
  owner: Contact[];
  domain: Contact[];
  financial: Contact[];
  technical: Contact[];
  juridical: Contact[];
  administrative: Contact[];
}

export interface Entities {
  cooperatives: Cooperative[];
  consumerUnits: ConsumerUnit[];
  generationUnits: GenerationUnit[];
}

export interface ContactGroups {
  group: Groups;
  entities: Entities;
  id: ContactGroupId;
  contacts: Contact[];
}

export interface ContactGroupData {
  contactGroup: ContactGroups;
}

export interface ContactGroupsList {
  id: ContactGroupId;
  groupName: string;
  contactName: string;
  contactPhone: string;
  cooperativesCount: number;
  consumerUnitsCount: number;
  generationUnitsCount: number;
}
