import { CSSProperties } from 'react';

interface Props {
  style?: CSSProperties;
  className?: string;
}

export default function EconomyReportGroupB({ style, className }: Props) {
  return (
    <div
      className={className || 'flex items-center'}
      style={
        style || {
          width: '312px',
          height: '209px',
        }
      }
    >
      <svg
        width="312"
        height="209"
        viewBox="0 0 312 209"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="130.202"
          y="177.987"
          width="73.8631"
          height="23.12"
          stroke="#6B6B6B"
        />
        <path
          d="M128.321 177.442L139.902 164.562H194.366L205.946 177.442H128.321Z"
          stroke="#6B6B6B"
        />
        <rect
          x="161.376"
          y="185.724"
          width="10.8325"
          height="15.3834"
          stroke="#6B6B6B"
        />
        <rect
          x="154.194"
          y="185.377"
          width="8.9584"
          height="18.2944"
          transform="rotate(90 154.194 185.377)"
          stroke="#6B6B6B"
        />
        <rect
          x="198.384"
          y="185.377"
          width="8.9584"
          height="18.2944"
          transform="rotate(90 198.384 185.377)"
          stroke="#6B6B6B"
        />
        <rect
          x="124.286"
          y="201.652"
          width="86.1507"
          height="6.28152"
          stroke="#6B6B6B"
        />
        <path
          d="M189.55 134.472V164.289M189.55 134.472H226.409V121.738H187.847L185.678 134.472H189.55Z"
          stroke="#6B6B6B"
        />
        <path d="M201.84 172.822V138.034H218.956V177.879" stroke="#6B6B6B" />
        <path
          d="M193.978 116.915L199.751 104.717H218.481L224.255 116.915H193.978Z"
          stroke="#6B6B6B"
        />
        <path
          d="M200.751 121.924V116.944M218.022 121.924V116.944"
          stroke="#6B6B6B"
        />
        <rect
          x="206.43"
          y="142.346"
          width="8.80477"
          height="3.64436"
          stroke="#6B6B6B"
        />
        <rect
          x="206.43"
          y="156.278"
          width="8.80477"
          height="3.64436"
          stroke="#6B6B6B"
        />
        <rect
          x="206.43"
          y="170.728"
          width="8.80477"
          height="3.64436"
          stroke="#6B6B6B"
        />
        <rect
          x="206.43"
          y="149.054"
          width="8.80477"
          height="3.64436"
          stroke="#6B6B6B"
        />
        <rect
          x="206.43"
          y="162.987"
          width="8.80477"
          height="3.64436"
          stroke="#6B6B6B"
        />
        <rect
          x="226.683"
          y="80.3691"
          width="35.8627"
          height="127.337"
          stroke="#6B6B6B"
        />
        <rect
          x="238.489"
          y="200.908"
          width="12.2823"
          height="6.4688"
          stroke="#6B6B6B"
        />
        <path d="M232.326 88.7437H257.129" stroke="#6B6B6B" />
        <path d="M232.326 98.3008H257.129" stroke="#6B6B6B" />
        <path d="M232.326 107.857H257.129" stroke="#6B6B6B" />
        <path d="M232.326 117.415H257.129" stroke="#6B6B6B" />
        <path d="M232.326 127.427H257.129" stroke="#6B6B6B" />
        <path d="M232.326 136.983H257.129" stroke="#6B6B6B" />
        <path d="M232.326 146.768H257.129" stroke="#6B6B6B" />
        <path d="M232.326 156.78H257.129" stroke="#6B6B6B" />
        <path d="M232.326 166.337H257.129" stroke="#6B6B6B" />
        <path d="M232.326 175.894H257.129" stroke="#6B6B6B" />
        <path d="M232.326 185.906H257.129" stroke="#6B6B6B" />
        <path d="M232.326 195.463H257.129" stroke="#6B6B6B" />
        <path d="M244.942 66.8989V79.9693" stroke="#6B6B6B" />
        <path d="M310.537 177L310.537 208" stroke="#6B6B6B" />
        <path
          d="M21.2845 130.687L21.2401 130.517C19.8494 125.174 19.9569 119.539 21.5433 114.25C21.7838 113.449 22.9254 113.459 23.1446 114.267C24.5794 119.553 24.5374 125.131 23.0231 130.395L22.9331 130.708C22.6927 131.544 21.5035 131.529 21.2845 130.687Z"
          stroke="#6B6B6B"
        />
        <path
          d="M3.70042 143.886L3.82594 143.762C7.75771 139.886 12.6916 137.162 18.0644 135.892C18.8788 135.699 19.4405 136.693 18.8506 137.287C14.9902 141.172 10.1384 143.925 4.82273 145.245L4.50654 145.324C3.66272 145.534 3.08122 144.496 3.70042 143.886Z"
          stroke="#6B6B6B"
        />
        <path
          d="M40.2984 144.113L40.1728 143.99C36.2411 140.114 31.3072 137.389 25.9344 136.119C25.12 135.927 24.5583 136.921 25.1482 137.514C29.0086 141.4 33.8604 144.153 39.176 145.473L39.4922 145.552C40.3361 145.761 40.9176 144.724 40.2984 144.113Z"
          stroke="#6B6B6B"
        />
        <circle cx="21.9667" cy="134.94" r="2.1452" stroke="#6B6B6B" />
        <path d="M21.9668 207.916V136.963" stroke="#6B6B6B" />
        <circle cx="270.045" cy="19.0641" r="8.48061" stroke="#6B6B6B" />
        <path d="M270.203 38.6008V32.1411" stroke="#6B6B6B" />
        <path d="M270.203 6.45973V0" stroke="#6B6B6B" />
        <path d="M270.203 38.6008V32.1411" stroke="#6B6B6B" />
        <path d="M270.203 6.45973V0" stroke="#6B6B6B" />
        <path d="M254.574 30.6246L259.805 26.8345" stroke="#6B6B6B" />
        <path d="M280.601 11.7662L285.832 7.97607" stroke="#6B6B6B" />
        <path d="M253.488 9.65002L259.082 12.8799" stroke="#6B6B6B" />
        <path d="M281.323 25.7208L286.917 28.9507" stroke="#6B6B6B" />
        <path
          d="M43.4727 168.687C43.4727 164.666 46.732 161.407 50.7527 161.407C54.7733 161.407 58.0327 164.666 58.0327 168.687V186.114C58.0327 190.135 54.7733 193.394 50.7527 193.394C46.732 193.394 43.4727 190.135 43.4727 186.114V168.687Z"
          stroke="#6B6B6B"
        />
        <path
          d="M69.3025 185.336C69.3025 180.37 73.3282 176.344 78.2941 176.344H79.2277C84.1936 176.344 88.2193 180.37 88.2193 185.336C88.2193 190.302 84.1936 194.327 79.2277 194.327H78.2941C73.3282 194.327 69.3025 190.302 69.3025 185.336Z"
          stroke="#6B6B6B"
        />
        <path
          d="M206.853 186.27C206.853 181.476 210.739 177.589 215.533 177.589H216.467C221.261 177.589 225.147 181.476 225.147 186.27C225.147 191.064 221.261 194.95 216.467 194.95H215.533C210.739 194.95 206.853 191.064 206.853 186.27Z"
          stroke="#6B6B6B"
        />
        <path
          d="M99.4888 178.957C99.4888 174.077 103.445 170.121 108.325 170.121C113.205 170.121 117.161 174.077 117.161 178.957V183.936C117.161 188.816 113.205 192.772 108.325 192.772C103.445 192.772 99.4888 188.816 99.4888 183.936V178.957Z"
          stroke="#6B6B6B"
        />
        <path
          d="M50.7529 207.965C50.7529 199.457 50.7529 194.688 50.7529 186.181"
          stroke="#6B6B6B"
        />
        <path
          d="M78.7607 207.965C78.7607 199.457 78.7607 194.688 78.7607 186.181"
          stroke="#6B6B6B"
        />
        <path
          d="M216 207.965C216 199.822 216 195.257 216 187.114"
          stroke="#6B6B6B"
        />
        <path
          d="M108.325 207.965C108.325 197.635 108.325 191.843 108.325 181.513"
          stroke="#6B6B6B"
        />
        <path
          d="M290.663 189.73L292.533 185.496H302.05L303.919 189.73H290.663Z"
          stroke="#6B6B6B"
        />
        <path
          d="M269.16 189.502L271.691 185.269H284.89L287.421 189.502H269.16Z"
          stroke="#6B6B6B"
        />
        <rect
          x="292.898"
          y="190.047"
          width="8.78454"
          height="12.8804"
          stroke="#6B6B6B"
        />
        <rect
          x="285.527"
          y="189.819"
          width="6.96416"
          height="14.9283"
          transform="rotate(90 285.527 189.819)"
          stroke="#6B6B6B"
        />
        <rect
          x="263.09"
          y="203.245"
          width="47.2401"
          height="4.68869"
          stroke="#6B6B6B"
        />
        <rect
          x="262.862"
          y="177.532"
          width="47.4676"
          height="3.3234"
          stroke="#6B6B6B"
        />
        <path
          d="M276.761 176.987C277.017 173.475 279.947 170.706 283.524 170.706H289.896C293.473 170.706 296.403 173.475 296.659 176.987H276.761Z"
          stroke="#6B6B6B"
        />
        <path
          d="M281.086 146.162L286.262 134.366C286.697 133.375 288.178 133.685 288.178 134.768V142.049C288.178 142.561 288.564 142.99 289.072 143.044L292.987 143.46C293.694 143.536 294.099 144.305 293.76 144.931L287.53 156.447C286.996 157.432 285.494 156.929 285.661 155.822L286.674 149.116C286.759 148.553 286.357 148.033 285.791 147.973L281.896 147.558C281.224 147.487 280.815 146.782 281.086 146.162Z"
          stroke="#6B6B6B"
        />
      </svg>
    </div>
  );
}
