import React from 'react';

import BaseInput, { Props as BaseProps } from './BaseInput';

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement> & BaseProps,
  HTMLInputElement
>;

export const NumberInput = React.forwardRef<HTMLInputElement, Props>(
  ({ mask, className, type, ...props }, ref) => {
    return (
      <BaseInput
        ref={ref}
        mask={mask}
        type={type}
        className={className}
        {...props}
      />
    );
  }
);

export default NumberInput;
