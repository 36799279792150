import {
  MdLocalOffer,
  MdMonetizationOn,
  MdSwapVerticalCircle,
} from 'react-icons/md';

import { convertToBRL } from 'utils/form';

import { ChargesByFinancialAccountPayoutCounter } from 'financialAccountPayouts/chargesByFinancialAccountPayout/models/chargesByFinancialAccountPayout';

export default function ChargesByFinancialAccountPayoutCounterItem({
  chargesByFinancialAccountPayoutCounter,
}: {
  chargesByFinancialAccountPayoutCounter?: ChargesByFinancialAccountPayoutCounter;
}) {
  return (
    <div className="w-full items-center justify-start flex gap-8 pb-10">
      <div className="flex items-center justify-start gap-4">
        <MdSwapVerticalCircle className="text-gray-dark500" size={25} />
        <p className="flex items-center justify-start font-medium text-gray-dark500 gap-1">
          <span className="font-bold">
            {chargesByFinancialAccountPayoutCounter?.chargesCount || 0}
          </span>{' '}
          Total de cobranças
        </p>
      </div>
      <div className="flex items-center justify-start gap-4">
        <MdMonetizationOn className="text-gray-dark500" size={25} />
        <p className="flex items-center justify-start font-medium text-gray-dark500 gap-1">
          <span className="font-bold">
            {convertToBRL(
              chargesByFinancialAccountPayoutCounter?.financialAccountPayoutsTotalAmount as string
            )}
          </span>{' '}
          Total transferido
        </p>
      </div>
      <div className="flex items-center justify-start gap-4">
        <MdMonetizationOn className="text-gray-dark500" size={25} />
        <p className="flex items-center justify-start font-medium text-gray-dark500 gap-1">
          <span className="font-bold">
            {convertToBRL(
              chargesByFinancialAccountPayoutCounter?.financialAccountPayoutsOriginalValue as string
            )}
          </span>{' '}
          Valor original
        </p>
      </div>
      <div className="flex items-center justify-start gap-4">
        <MdLocalOffer className="text-gray-dark500" size={25} />
        <p className="flex items-center justify-start font-medium text-gray-dark500 gap-1">
          <span className="font-bold">
            {convertToBRL(
              chargesByFinancialAccountPayoutCounter?.financialAccountPayoutsFinesValue as string
            )}
          </span>{' '}
          Multas
        </p>
      </div>
      <div className="flex items-center justify-start gap-4">
        <MdLocalOffer className="text-gray-dark500" size={25} />
        <p className="flex items-center justify-start font-medium text-gray-dark500 gap-1">
          <span className="font-bold">
            {convertToBRL(
              chargesByFinancialAccountPayoutCounter?.financialAccountPayoutsFeesValue as string
            )}
          </span>{' '}
          Juros
        </p>
      </div>
    </div>
  );
}
