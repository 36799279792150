import React, { createContext, useContext } from 'react';
import { useOverlayModal } from 'ui/hooks/useOverlayModal';
import { ModalFormProps } from 'ui/models/overlay';

interface ModalState {
  ModalOverlay: ({
    title,
    variant,
    children,
    canClose,
  }: ModalFormProps) => JSX.Element;
}

type ModalContextData = {
  showConfirm(): void;
  closeConfirm(): void;
} & ModalState;

const ModalContext = createContext<ModalContextData>({} as ModalContextData);

const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    openModal: showConfirm,
    closeModal: closeConfirm,
    RenderConfirm: ModalOverlay,
  } = useOverlayModal();

  return (
    <ModalContext.Provider
      value={{
        showConfirm,
        closeConfirm,
        ModalOverlay: ({ canClose = true, ...props }) => (
          <ModalOverlay canClose={canClose} {...props} />
        ),
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

function useModal(): ModalContextData {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error('ModalProvider is required!');
  }

  return context;
}

export { ModalProvider, useModal };
