import React from 'react';
import classNames from 'classnames';
import RadioInput, { Props as BaseProps } from 'ui/components/form/RadioInput';

type Props<T> = BaseProps<T> & {
  required?: boolean;
  label?: string | JSX.Element;
  wrapperClassName?: string;
  flexDirectionClassName?: string;
};

const ForwardRefRadioInput = <T extends unknown>(
  {
    required,
    className,
    wrapperClassName,
    flexDirectionClassName,
    label,
    error,
    disabled,
    id,
    ...props
  }: Props<T>,
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  return (
    <fieldset className={wrapperClassName}>
      {label && (
        <div className="flex mb-3">
          <label
            htmlFor={id}
            className={classNames('block text-sm font-medium text-gray-dark400', {
              'text-gray-dark400 opacity-30': disabled,
            })}
          >
            {label}
          </label>
          {required && (
            <p
              className={classNames('text-gray-dark400 h-0', {
                'text-gray-dark400 opacity-30': disabled,
              })}
            >
              *
            </p>
          )}
        </div>
      )}
      <RadioInput
        id={id}
        {...props}
        error={error}
        disabled={disabled}
        ref={ref}
        className={flexDirectionClassName}
      />
    </fieldset>
  );
};

export const RadioField = React.forwardRef(ForwardRefRadioInput) as <T>(
  props: Props<T>
) => ReturnType<typeof ForwardRefRadioInput>;

export default RadioField;
