import { useMemo } from 'react';

import { useHistory } from 'react-router-dom';

import { MdRemoveRedEye } from 'react-icons/md';

import { Tooltip } from 'ui/components/tooltip';
import { TableBodyColumn } from 'ui/components/table';
import TableBodyRow from 'ui/components/table/TableBodyRow';

import ErrorIcon from 'assets/icons/list/ErrorIcon';
import PendingIcon from 'assets/icons/list/PendingIcon';
import SuccessIcon from 'assets/icons/list/SuccessIcon';
import UnavailableIcon from 'assets/icons/list/UnavailableIcon';

import { formatDateToLocaleDateString } from 'utils/form';

import { ModalProps } from 'consumerUnits/consumerUnitBillingCycles/pages/ListConsumerUnitBillingCycles';

import { ConsumerUnitBillingCycle } from 'consumerUnits/consumerUnitBillingCycles/models/consumerUnitBillingCycles';

import { ConsumerUnitBillingCaptureStatus } from 'consumerUnitBillingCapture/model/consumerUnitBillingCaptures';

type Props = {
  consumerUnitBillingCycles: ConsumerUnitBillingCycle;
  onClickOpenSideBarModal: (
    modal: ModalProps,
    consumerUnitBillingCycle: ConsumerUnitBillingCycle
  ) => void;
};

const DATE_CONFIG_OPTIONS = {
  options: { month: '2-digit', year: 'numeric' } as Intl.DateTimeFormatOptions,
};

const CREATE_ROUTE = '/dashboard/bill-data/consumer-units/create';

export default function ConsumerUnitBillingCyclesTableItem({
  onClickOpenSideBarModal,
  consumerUnitBillingCycles: {
    id,
    consumerUnit,
    economyReport,
    summarizedCreditWallet,
    consumerUnitBillingCapture,
    consumerUnitBillingCycleDate,
    powerDistributionUnitBillData,
    economyValuesAveragePercentage,
  },
}: Props) {
  const { push } = useHistory();

  const {
    consumerUnitBillingCaptureError,
    consumerUnitBillingCaptureStatus = ConsumerUnitBillingCaptureStatus.WAITING,
  } = consumerUnitBillingCapture || {};

  const CONSUMER_UNIT_BILLING_CYCLE: ConsumerUnitBillingCycle = {
    id,
    consumerUnit,
    summarizedCreditWallet,
    consumerUnitBillingCapture,
    consumerUnitBillingCycleDate,
    powerDistributionUnitBillData,
    economyValuesAveragePercentage,
  };

  const onClickCreateButton = (
    pathname: string,
    consumerUnitBillingCycles: ConsumerUnitBillingCycle
  ) => {
    push({
      pathname,
      state: consumerUnitBillingCycles,
    });
  };

  const consumerUnitBillingCaptureStatusErrorActived =
    consumerUnitBillingCaptureStatus === ConsumerUnitBillingCaptureStatus.ERROR;

  const consumerUnitBillingCaptureStatusWaitingActived =
    consumerUnitBillingCaptureStatus === ConsumerUnitBillingCaptureStatus.WAITING;

  const HAS_BILL_DATA_AND_BILLING_WAS_CAPTURED =
    !!powerDistributionUnitBillData && consumerUnitBillingCaptureStatus;

  const HAS_SUMMARIZED_CREDIT_WALLET_AND_BILLING_WAS_CAPTURED =
    !!summarizedCreditWallet && HAS_BILL_DATA_AND_BILLING_WAS_CAPTURED;

  const HAS_ECONOMY_REPORT_AND_BILLING_WAS_CAPTURED =
    !!economyReport &&
    !!economyValuesAveragePercentage &&
    HAS_BILL_DATA_AND_BILLING_WAS_CAPTURED;

  const labelStatusIcon = useMemo(() => {
    const status = {
      [ConsumerUnitBillingCaptureStatus.ERROR]: `Erro: ${
        consumerUnitBillingCaptureError ||
        'Falha na captura da fatura, reporte ao time de Produto'
      }`,
      [ConsumerUnitBillingCaptureStatus.MANUAL]:
        (HAS_BILL_DATA_AND_BILLING_WAS_CAPTURED && 'Detalhes da fatura') ||
        'Pendente de informação',
      [ConsumerUnitBillingCaptureStatus.SUCCESS]:
        (HAS_BILL_DATA_AND_BILLING_WAS_CAPTURED && 'Detalhes da fatura') ||
        'Pendente de informação',
      [ConsumerUnitBillingCaptureStatus.WAITING]: 'Fatura indisponível',
    };

    return status[consumerUnitBillingCaptureStatus];
  }, [
    consumerUnitBillingCaptureError,
    consumerUnitBillingCaptureStatus,
    HAS_BILL_DATA_AND_BILLING_WAS_CAPTURED,
  ]);

  return (
    <TableBodyRow dataTestId={`consumer-unit-billing-cycles-row-id-${id}`}>
      <TableBodyColumn>{consumerUnit.id}</TableBodyColumn>
      <TableBodyColumn>
        {formatDateToLocaleDateString(consumerUnitBillingCycleDate, {
          year: DATE_CONFIG_OPTIONS.options.year,
          month: DATE_CONFIG_OPTIONS.options.month,
        })}
      </TableBodyColumn>
      <TableBodyColumn>
        {consumerUnit.cooperativeMember.cooperativeMemberLegalName}
      </TableBodyColumn>
      <TableBodyColumn>
        {consumerUnit.consumerUnitPowerDistributionUnitIdentifier}
      </TableBodyColumn>
      <TableBodyColumn>
        <Tooltip
          placement="bottom"
          arrowPosition="top"
          label={'Carteira de Creditação'}
          disabled={!HAS_SUMMARIZED_CREDIT_WALLET_AND_BILLING_WAS_CAPTURED}
        >
          <MdRemoveRedEye
            size={25}
            onClick={() =>
              HAS_SUMMARIZED_CREDIT_WALLET_AND_BILLING_WAS_CAPTURED &&
              onClickOpenSideBarModal('creditsWallet', {
                id,
                consumerUnit,
                economyReport,
                summarizedCreditWallet,
                consumerUnitBillingCapture,
                consumerUnitBillingCycleDate,
                powerDistributionUnitBillData,
              })
            }
          />
        </Tooltip>
      </TableBodyColumn>
      <TableBodyColumn>
        <Tooltip
          placement="bottom"
          arrowPosition="top"
          label={'Carteira de Economia'}
          disabled={!HAS_ECONOMY_REPORT_AND_BILLING_WAS_CAPTURED}
        >
          <MdRemoveRedEye
            size={25}
            onClick={() =>
              HAS_ECONOMY_REPORT_AND_BILLING_WAS_CAPTURED &&
              onClickOpenSideBarModal('economyWallet', {
                id,
                consumerUnit,
                economyReport,
                consumerUnitBillingCapture,
                consumerUnitBillingCycleDate,
                powerDistributionUnitBillData,
                economyValuesAveragePercentage,
              })
            }
          />
        </Tooltip>
      </TableBodyColumn>
      <TableBodyColumn>
        <Tooltip label={labelStatusIcon} placement="bottom" arrowPosition="top">
          {consumerUnitBillingCaptureStatusErrorActived && (
            <ErrorIcon
              OnClick={() =>
                onClickCreateButton(CREATE_ROUTE, CONSUMER_UNIT_BILLING_CYCLE)
              }
            />
          )}
          {consumerUnitBillingCaptureStatusWaitingActived && (
            <UnavailableIcon
              OnClick={() =>
                onClickCreateButton(CREATE_ROUTE, CONSUMER_UNIT_BILLING_CYCLE)
              }
            />
          )}
          {(HAS_BILL_DATA_AND_BILLING_WAS_CAPTURED && (
            <SuccessIcon
              OnClick={() =>
                onClickOpenSideBarModal('details', {
                  id,
                  consumerUnit,
                  consumerUnitBillingCapture,
                  consumerUnitBillingCycleDate,
                  powerDistributionUnitBillData,
                })
              }
            />
          )) ||
            (!consumerUnitBillingCaptureStatusErrorActived &&
              !consumerUnitBillingCaptureStatusWaitingActived && (
                <PendingIcon
                  OnClick={() =>
                    onClickCreateButton(CREATE_ROUTE, CONSUMER_UNIT_BILLING_CYCLE)
                  }
                />
              ))}
        </Tooltip>
      </TableBodyColumn>
    </TableBodyRow>
  );
}
