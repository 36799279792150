import { gql } from '@apollo/client';

export interface InternalUserCreateMutationVariables {
  userId: string;
}

export const InternalUserTypename = 'InternalUser';

export const INTERNAL_USER_QUERY = gql`
  query internalUser($userId: ID!) {
    internalUser(userId: $userId) {
      id
      userAccessBlockedAt
      userIsAdmin
      userEmail
      userName
    }
  }
`;
