import { Button } from 'ui';

import { MdClose } from 'react-icons/md';
import { IoMdCloudDownload } from 'react-icons/io';

import {
  convertToBRL,
  convertToDecimal,
  formatDateToLocaleDateString,
} from 'utils/form';

import { GenerationUnitLeaseCycle } from 'generationUnits/generationUnitLeaseCycles/models/generationUnitLeaseCycles';

export type Props = {
  onClickCloseModal?: () => void;
  handleClickDownloadBill?: () => void;
  generationUnitLeaseCycle?: GenerationUnitLeaseCycle;
};

const DATE_CONFIG_OPTIONS = {
  options: { month: '2-digit', year: 'numeric' } as Intl.DateTimeFormatOptions,
};

export default function GenerationUnitBillDataDetailsItem({
  onClickCloseModal,
  handleClickDownloadBill,
  generationUnitLeaseCycle,
}: Props) {
  const {
    generationUnit: { generationUnitCapacityFactorAverage = 0 } = {},
    powerDistributionUnitBillDatas: {
      billIssueDate = 0,
      billTotalValue = 0,
      billReferenceDate = 0,
      billNextReadingDate = 0,
      billReadingStartedAt = 0,
      billReadingFinishedAt = 0,
      billExcessEnergyCredits: { excessEnergyCreditsTotal = 0 } = {},
      billConsumedEnergyCredits: { consumedEnergyCreditsTotal = 0 } = {},
      billGeneratedEnergyCredits: { generatedEnergyCreditsTotal = 0 } = {},
    } = {},
  } = generationUnitLeaseCycle || {};

  return (
    <div className="flex flex-col items-start justify-start gap-2 h-screen fixed bg-gray-dark700 right-0 top-0 border-l border-gray-dark600">
      <div className="flex px-10 py-8 items-center justify-start gap-12 border-b border-gray-dark600">
        <h1 className="text-gray-dark400 w-full font-normal text-2xl">
          Detalhes da fatura
        </h1>
        <Button
          type="button"
          size="none"
          variant="tertiaryGray"
          style={{
            padding: '.1rem',
          }}
        >
          <MdClose size={20} color="#6B6B6B" onClick={onClickCloseModal} />
        </Button>
      </div>
      <div className="w-full flex flex-col px-10 py-8 gap-4">
        <div>
          <h2 className="text-gray-dark400 leading-4 font-bold">
            Mês de referência da fatura:
          </h2>
          <p className="text-gray-dark400">
            {formatDateToLocaleDateString(billReferenceDate as string, {
              year: DATE_CONFIG_OPTIONS.options.year,
              month: DATE_CONFIG_OPTIONS.options.month,
            })}
          </p>
        </div>
        <div>
          <h2 className="text-gray-dark400 leading-4 font-bold">
            Ciclo de geração:
          </h2>
          <p className="text-gray-dark400">
            {formatDateToLocaleDateString(billReadingStartedAt as string)} -{' '}
            {formatDateToLocaleDateString(billReadingFinishedAt as string)}
          </p>
        </div>
        <div>
          <h2 className="text-gray-dark400 leading-4">Data de leitura futura:</h2>
          <p className="text-gray-dark400">
            {formatDateToLocaleDateString(billNextReadingDate as string)}
          </p>
        </div>
        <div>
          <h2 className="text-gray-dark400 leading-4">Data de disponibilização:</h2>
          <p className="text-gray-dark400">
            {formatDateToLocaleDateString(billIssueDate as string)}
          </p>
        </div>
        <div>
          <h2 className="text-gray-dark400 leading-4 font-bold">Valor da fatura:</h2>
          <p className="text-gray-dark400">
            {convertToBRL(billTotalValue as string)}
          </p>
        </div>
      </div>
      <div className="flex w-full flex-col px-10 py-8 border-t border-gray-dark600 gap-4">
        <h2 className="text-gray-dark400 leading-4 font-bold">
          Fator de capacidade:{' '}
          <span className="text-gray-dark400 font-medium">
            {convertToDecimal(generationUnitCapacityFactorAverage as string)}
          </span>
        </h2>
        <div>
          <h2 className="text-gray-dark400 leading-4">Geração:</h2>
          <p className="text-gray-dark400">
            {convertToDecimal(generatedEnergyCreditsTotal as string)}
          </p>
        </div>
        <div>
          <h2 className="text-gray-dark400 leading-4">Utilizados:</h2>
          <p className="text-gray-dark400">
            {convertToDecimal(consumedEnergyCreditsTotal as string)}
          </p>
        </div>
        <div>
          <h2 className="text-gray-dark400">Excedentes:</h2>
          <h1 className="text-gray-dark400 text-2xl font-bold">
            {convertToDecimal(excessEnergyCreditsTotal as string)}
          </h1>
        </div>
      </div>
      <Button
        size="none"
        type="button"
        variant="tertiaryGray"
        onClick={handleClickDownloadBill}
        className="flex justify-start items-center p-2 ml-10"
      >
        <IoMdCloudDownload
          size={30}
          color="#B6B6B6"
          style={{
            marginLeft: '5px',
            marginRight: '5px',
          }}
        />
        <div className="flex flex-col justify-center items-start cursor-pointer">
          <p className="font-normal text-sm text-gray-dark300 cursor-pointer">
            Baixar fatura
          </p>
        </div>
      </Button>
    </div>
  );
}
