import { gql } from '@apollo/client';

import { GenerationUnitConsumptionGroupTypes } from 'generationUnits/models/generationUnit';

import { PowerDistributionUnitId } from 'powerDistributionUnits/models/powerDistributionUnit';

import {
  ConsumerUnitType,
  BillConsumerUnitId,
  BillConsumedEnergyKwh,
  GenerationUnitBillData,
  BillExcessEnergyCredits,
  GenerationUnitBillDataId,
  BillConsumedEnergyCredits,
  BillGeneratedEnergyCredits,
  BillConsumedEnergyCreditsTariffs,
} from 'powerDistributionUnits/powerDistributionUnitBillData/generationUnitBillData/models/generationUnitBillData';

export interface GenerationUnitBillDataCreated {
  powerDistributionUnitGenerationUnitBillDataCreate: GenerationUnitBillData;
}

export interface GenerationUnitBillDataCreateMutationInput {
  id: GenerationUnitBillDataId;
  billConsumerUnitId: BillConsumerUnitId;
  powerDistributionUnitId: PowerDistributionUnitId;
  billDueDate: string;
  billIssueDate: string;
  billReferenceDate: string;
  billNextReadingDate: string;
  billReadingStartedAt: string;
  billReadingFinishedAt: string;
  billTotalValue: string;
  billTotalReadingDays: string;
  billConsumedEnergyKwh: BillConsumedEnergyKwh;
  billExcessEnergyCredits: BillExcessEnergyCredits;
  billConsumedEnergyCredits: BillConsumedEnergyCredits;
  billGeneratedEnergyCredits: BillGeneratedEnergyCredits;
  billConsumedEnergyCreditsTariffs: BillConsumedEnergyCreditsTariffs;
  billConsumerUnitType: ConsumerUnitType;
  billConsumerUnitConsumptionGroupType: GenerationUnitConsumptionGroupTypes;
}

export interface GenerationUnitBillDataMutationVariables {
  powerDistributionUnitGenerationUnitBillDataCreateInput: GenerationUnitBillDataCreateMutationInput;
}

export const GENERATION_UNIT_BILL_DATA_CREATE_MUTATION = gql`
  mutation powerDistributionUnitGenerationUnitBillDataCreate(
    $powerDistributionUnitGenerationUnitBillDataCreateInput: PowerDistributionUnitGenerationUnitBillDataCreateInput!
  ) {
    powerDistributionUnitGenerationUnitBillDataCreate(
      input: $powerDistributionUnitGenerationUnitBillDataCreateInput
    ) {
      id
      billConsumerUnitId
      powerDistributionUnitId
      billDueDate
      billIssueDate
      billReferenceDate
      billNextReadingDate
      billReadingStartedAt
      billReadingFinishedAt
      billTotalValue
      billTotalReadingDays
      billConsumerUnitType
      billConsumerUnitConsumptionGroupType
      billConsumedEnergyKwh {
        consumedEnergyKwhTotal
        consumedEnergyKwhOnPeak
        consumedEnergyKwhOffPeak
      }
      billExcessEnergyCredits {
        excessEnergyCreditsTotal
        excessEnergyCreditsOnPeak
        excessEnergyCreditsOffPeak
      }
      billConsumedEnergyCredits {
        consumedEnergyCreditsTotal
        consumedEnergyCreditsOnPeak
        consumedEnergyCreditsOffPeak
      }
      billGeneratedEnergyCredits {
        generatedEnergyCreditsTotal
        generatedEnergyCreditsOnPeak
        generatedEnergyCreditsOffPeak
      }
      billConsumedEnergyCreditsTariffs {
        consumedEnergyCreditsTariffsTotal
        consumedEnergyCreditsTariffsOnPeak
        consumedEnergyCreditsTariffsOffPeak
      }
    }
  }
`;
