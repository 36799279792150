import { useHistory } from 'react-router';

import Routes from 'app/routes';
import client from 'graphql/apollo/client';

import { ApolloProvider } from '@apollo/client';
import { ConfirmProvider } from 'ui/contexts/overlay/Confirm';
import { LoadingProvider } from 'ui/contexts/overlay/Loading';
import { ModalProvider } from 'ui/contexts/overlay/Modal';
import { ToastContextProvider } from 'ui/contexts/overlay/Toast';
import { useAuth } from 'auth/contexts/Auth';

export default function App() {
  const { push } = useHistory();
  const { token, logout } = useAuth();

  return (
    <ApolloProvider client={client(token, logout, push)}>
      <ModalProvider>
        <ToastContextProvider>
          <LoadingProvider>
            <ConfirmProvider>
              <Routes />
            </ConfirmProvider>
          </LoadingProvider>
        </ToastContextProvider>
      </ModalProvider>
    </ApolloProvider>
  );
}
