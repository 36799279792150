import { gql } from '@apollo/client';

import { ChargesByFinancialAccountPayoutCounter } from '../models/chargesByFinancialAccountPayout';

export interface ChargesByFinancialAccountPayoutCounterList {
  chargesByFinancialAccountPayoutCounter: ChargesByFinancialAccountPayoutCounter;
}

export const chargesByFinancialAccountPayoutCounterTypename =
  'ChargesByFinancialAccountPayoutCounter';

export const CHARGES_BY_FINANCIAL_ACCOUNT_PAYOUTS_COUNTER_QUERY = gql`
  query chargesByFinancialAccountPayoutCounter {
    chargesByFinancialAccountPayoutCounter {
      chargesCount
      financialAccountPayoutsFeesValue
      financialAccountPayoutsFinesValue
      financialAccountPayoutsTotalAmount
      financialAccountPayoutsOriginalValue
      financialAccountPayoutsAccomplishedStatusCount
    }
  }
`;
