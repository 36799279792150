import { gql } from '@apollo/client';

import { ConsumerUnitBillingCaptureUploadBillInput } from 'consumerUnitBillingCapture/model/consumerUnitBillingCaptures';

export interface ConsumerUnitBillingCaptureUploadBillMutationVariables {
  consumerUnitUploadBillingCaptureFileMetadata?: File | null;
  consumerUnitUploadBillingCaptureInput: ConsumerUnitBillingCaptureUploadBillInput;
}

export interface ConsumerUnitBillingCaptureUploadBillCreated {
  consumerUnitUploadBillingCaptureFileMetadata?: File | null;
  consumerUnitUploadBillingCaptureCreated: ConsumerUnitBillingCaptureUploadBillInput;
}

export const CONSUMER_UNIT_BILLING_CAPTURE_UPLOAD_BILL_MUTATION = gql`
  mutation consumerUnitBillingCaptureUploadBill(
    $consumerUnitUploadBillingCaptureFileMetadata: Upload!
    $consumerUnitUploadBillingCaptureInput: ConsumerUnitUploadBillingCaptureInput!
  ) {
    consumerUnitBillingCaptureUploadBill(
      consumerUnitUploadBillingCaptureFileMetadata: $consumerUnitUploadBillingCaptureFileMetadata
      input: $consumerUnitUploadBillingCaptureInput
    ) {
      consumerUnitBillingCaptureUrl
    }
  }
`;
