import { gql } from '@apollo/client';
import { InternalUser } from 'internalUsers/models/internalUser';

interface InternalUserConnection {
  afterCursor: string | null;
  beforeCursor: string | null;
  entries: InternalUser[];
}

export interface InternalUserList {
  internalUsers: InternalUserConnection;
}

export const internalUsersTypename = 'InternalUserConnection';

export const INTERNAL_USERS_QUERY = gql`
  query internalUsers(
    $after: String
    $before: String
    $pageSize: Int
    $maximumLimit: Int
  ) {
    internalUsers(
      after: $after
      before: $before
      pageSize: $pageSize
      maximumLimit: $maximumLimit
    ) {
      afterCursor
      beforeCursor
      entries {
        id
        userEmail
        userName
        userAccessBlockedAt
        userIsAdmin
      }
    }
  }
`;
