import { gql } from '@apollo/client';

export type TariffGroupDeleteMutationVariables = {
  id: number;
};

export const TariffGroupTypename = 'PowerDistributionUnitTariffGroup';

export const TARIFF_GROUP_DELETE_MUTATION = gql`
  mutation powerDistributionUnitTariffGroupDelete($id: ID!) {
    powerDistributionUnitTariffGroupDelete(id: $id) {
      message
    }
  }
`;
