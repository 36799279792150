import { Button } from 'ui';
import { useLoading } from 'ui/contexts/overlay/Loading';
import { ModalFormProps, ModalProps } from 'ui/models/overlay';

import { useCSVDownloader } from 'react-papaparse';

type Props = {
  alert: ModalProps;
  onClickContinue?: () => void;
  onClickCloseModal: () => void;
  ModalOverlay: (any: ModalFormProps) => JSX.Element;
};

export default function ChargesAlertModal({
  alert,
  ModalOverlay,
  onClickContinue,
  onClickCloseModal,
}: Props) {
  const { LoadingOverlay } = useLoading();
  const { CSVDownloader, Type } = useCSVDownloader();

  return (
    <ModalOverlay title={alert.title} variant={alert.variant}>
      <div className="grid grid-cols-12">
        <div className="col-start-1 col-end-12">
          <p className="text-gray-dark400 font-small text-sm">{alert.text}</p>
        </div>
      </div>
      <div className="flex flex-row-reverse mt-8 gap-4">
        <CSVDownloader
          data={[]}
          bom={true}
          type={Type.Link}
          filename="Relatório Financeiro"
          config={{
            delimiter: ';',
          }}
        >
          <Button size="md" variant="primaryGray" onClick={onClickContinue}>
            <p className="font-medium text-white">Continuar</p>
          </Button>
        </CSVDownloader>

        <Button variant="secondaryGray" size="md" onClick={onClickCloseModal}>
          <p className="font-medium">Cancelar</p>
        </Button>
      </div>
      <LoadingOverlay />
    </ModalOverlay>
  );
}
