import { gql } from '@apollo/client';
import { PisCofinsTax } from 'pisCofinsTaxes/models/pisCofinsTax';

export interface PowerDistributionUnitPisCofinsTaxUpdateMutationInput {
  id: number;
  pisCofinsValidityStartAt: string;
  pisCofinsValidityEndAt: string;
  pisCofinsPisFee: string;
  pisCofinsCofinsFee: string;
}

export interface PowerDistributionUnitPisCofinsTaxUpdateMutationVariables {
  powerDistributionUnitPisCofinsTaxUpdateInput: PowerDistributionUnitPisCofinsTaxUpdateMutationInput;
}

export interface PowerDistributionUnitPisCofinsTaxUpdated {
  powerDistributionUnitPisCofinsTaxUpdate: Omit<
    PisCofinsTax,
    'powerDistributionUnitId'
  >;
}

export const POWER_DISTRIBUTION_UNIT_PIS_COFINS_UPDATE_MUTATION = gql`
  mutation powerDistributionUnitPisCofinsTaxUpdate(
    $powerDistributionUnitPisCofinsTaxUpdateInput: PowerDistributionUnitPisCofinsTaxUpdateInput!
  ) {
    powerDistributionUnitPisCofinsTaxUpdate(
      input: $powerDistributionUnitPisCofinsTaxUpdateInput
    ) {
      id
      pisCofinsValidityStartAt
      pisCofinsValidityEndAt
      pisCofinsPisFee
      pisCofinsCofinsFee
    }
  }
`;
