import { SyntheticEvent } from 'react';

import masks from 'utils/masks';

import { TableBodyColumn } from 'ui/components/table';
import TableBodyRow from 'ui/components/table/TableBodyRow';

import { ContactGroupsList } from 'contactGroups/models/contactGroups';

import { EditButton, RemoveButton } from 'dashboard/components/table/buttons';

type Props = {
  contactGroups: ContactGroupsList;
  onClickEditButton: (id: number) => void;
  onClickRemoveButton: (id: number) => void;
};

export default function ContactGroupsTableItem({
  contactGroups: {
    id,
    groupName,
    contactName,
    contactPhone,
    cooperativesCount,
    consumerUnitsCount,
    generationUnitsCount,
  },
  onClickEditButton,
  onClickRemoveButton,
}: Props) {
  const handleClickEditButton = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    onClickEditButton(id);
  };

  const handleClickRemoveButton = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    onClickRemoveButton(id);
  };

  return (
    <TableBodyRow dataTestId={`generation-unit-lease-cycles-row-id-${id}`}>
      <TableBodyColumn>
        <p className="font-bold">{groupName}</p>
      </TableBodyColumn>
      <TableBodyColumn>
        <p>{contactName}</p>
      </TableBodyColumn>
      <TableBodyColumn>
        <p>{masks.mobilePhone(contactPhone)}</p>
      </TableBodyColumn>
      <TableBodyColumn>
        <p>{consumerUnitsCount}</p>
      </TableBodyColumn>
      <TableBodyColumn>
        <p>{generationUnitsCount}</p>
      </TableBodyColumn>
      <TableBodyColumn>
        <p>{cooperativesCount}</p>
      </TableBodyColumn>
      <TableBodyColumn className="flex gap-x-3 items-end justify-end">
        <EditButton onClick={handleClickEditButton} />
        <RemoveButton onClick={handleClickRemoveButton} />
      </TableBodyColumn>
    </TableBodyRow>
  );
}
