import { useAuth } from 'auth/contexts/Auth';

import { notionLinks } from 'dashboard/utils/navigation';

import logoV2 from 'dashboard/assets/IntraNEXV2Beta-inferior.svg';

const slackChannel = 'https://nexenergy.slack.com/archives/C02A3M01B1T';
const notionDocumentation =
  'https://www.notion.so/nexpedia/Intranex-v2-0-Billing-back-office-MVP-dabc0d9958c94f19902b84275bcd8dd7';

export default function Content() {
  const { user } = useAuth();
  return (
    <main
      className="flex flex-row w-full items-center justify-center h-full py-5 gap-4"
      style={{
        padding: '0 2rem 0 5rem',
      }}
    >
      <div className="flex flex-col gap-12 w-full max-w-sm justify-center h-full">
        <div className="flex flex-col gap-6">
          <h1 className="text-gray-dark400 font-semibold text-4xl">
            Olá, {user?.userName.split(' ')[0]}
          </h1>
          <p className="text-gray-dark400">
            Seja bem vindo ao IntraNEX v2. <br />
            Confira aqui as informações, tutoriais e <br />
            novidades dessa nova versão. Tire suas <br />
            dúvidas no nosso canal do {''}
            <mark className="underline bg-transparent text-primary">
              <a href={slackChannel} target="_blank" rel="noreferrer">
                Slack #novo-intranex
              </a>
            </mark>
          </p>
          <div className="flex flex-col gap-2 w-fit">
            <p className="text-gray-dark400 font-semibold">Consulte as novidades!</p>
            {notionLinks.map((item) => (
              <a key={item.id} href={item.to} target="_blank" rel="noreferrer">
                <p className="text-gray-dark400 text-sm underline">{item.name}</p>
              </a>
            ))}
            <p className="text-gray-dark500 text-sm">
              Consulte a{' '}
              <mark className="underline bg-transparent text-gray-dark500">
                <a href={notionDocumentation} target="_blank" rel="noreferrer">
                  documentação completa
                </a>
              </mark>
            </p>
          </div>
        </div>
        <div className="py-12 border-gray-dark600 w-full flex items-center justify-center border-t">
          <img className="object-cover" src={logoV2} alt="INTRANEX V2 LOGO" />
        </div>
      </div>
      <div className="flex w-full rounded-lg items-center justify-center h-full">
        <iframe
          src="https://docs.google.com/presentation/d/e/2PACX-1vQfkw9K5kOd59XN99aYU6uN_eCggO3-gvWvduHgv93o9nYXZI2IZl8_Lnpe2_-OFkuRmBJit8ESJ2WC/embed?start=true&loop=true&delayms=3000"
          width="800"
          height="485"
          title="Intranex V2"
        ></iframe>
      </div>
    </main>
  );
}
