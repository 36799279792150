import Table from 'ui/components/table/Table';
import TableHead from 'ui/components/table/TableHead';
import TableHeadColumn from 'ui/components/table/TableHeadColumn';
import TableBody from 'ui/components/table/TableBody';
import { PisCofinsTax } from 'pisCofinsTaxes/models/pisCofinsTax';
import PisCofinsTaxesTableItem from './PisCofinsTaxesTableItem';

type Props = {
  pisCofinsTaxes: PisCofinsTax[];
  onClickEditButton: (id: number) => void;
  onClickRemoveButton: (id: number) => void;
  disableEditButton: boolean;
  disableRemoveButton: boolean;
};

export default function PisCofinsTaxesTable({
  pisCofinsTaxes,
  onClickEditButton,
  onClickRemoveButton,
  disableEditButton,
  disableRemoveButton,
}: Props) {
  return (
    <Table>
      <TableHead>
        <TableHeadColumn>Concessionária</TableHeadColumn>
        <TableHeadColumn className="text-center">UF</TableHeadColumn>
        <TableHeadColumn>Validade</TableHeadColumn>
        <TableHeadColumn>Pis</TableHeadColumn>
        <TableHeadColumn>Cofins</TableHeadColumn>
        <TableHeadColumn className="text-right">Ações</TableHeadColumn>
      </TableHead>
      <TableBody>
        {pisCofinsTaxes.map((pisCofinsTax: PisCofinsTax) => (
          <PisCofinsTaxesTableItem
            key={pisCofinsTax.id}
            pisCofinsTax={pisCofinsTax}
            onClickEditButton={onClickEditButton}
            onClickRemoveButton={onClickRemoveButton}
            disableEditButton={disableEditButton}
            disableRemoveButton={disableRemoveButton}
          />
        ))}
      </TableBody>
    </Table>
  );
}
