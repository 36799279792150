import { useEffect, useState } from 'react';

import { Button } from 'ui';

import { omit } from 'lodash';

import TextField from 'ui/form/TextField';
import SelectField from 'ui/form/SelectField';
import { Option } from 'ui/components/form/SelectInput';

import { Control, Controller, FieldError, UseFormRegister } from 'react-hook-form';

import { CooperativeMemberSelect } from 'cooperatives/models/cooperativeMember';

export type FormFilters = {
  cooperativeMemberId: number;
  consumerUnitPowerDistributionUnitIdentifier: string;
};

export type FormErrors = {
  cooperativeMemberId?: FieldError;
  consumerUnitPowerDistributionUnitIdentifier?: FieldError;
};

export default function CustomApportionmentCycleConsumerUnitFormFilters({
  errors,
  control,
  register,
  isLoading,
  cooperativeMembers,
}: {
  errors: FormErrors;
  isLoading?: boolean;
  control: Control<FormFilters>;
  register: UseFormRegister<FormFilters>;
  cooperativeMembers?: CooperativeMemberSelect[];
}) {
  const [cooperativeMembersOptions, setCooperativeMembersOptions] = useState<
    Option<number>[]
  >([]);

  useEffect(
    () =>
      setCooperativeMembersOptions(
        (cooperativeMembers instanceof Array &&
          cooperativeMembers.map((cooperativeMembers) => ({
            key: Number(cooperativeMembers.id),
            value: cooperativeMembers.cooperativeMemberLegalName,
          }))) ||
          []
      ),
    [cooperativeMembers]
  );

  return (
    <div className="relative w-full flex">
      <div className="w-full grid grid-cols-6 gap-4 pb-6 items-end">
        <TextField
          label="UC"
          placeholder="Digitar"
          id="consumerUnitPowerDistributionUnitIdentifier"
          {...register('consumerUnitPowerDistributionUnitIdentifier')}
          error={errors.consumerUnitPowerDistributionUnitIdentifier?.message}
        />

        <Controller
          name="cooperativeMemberId"
          control={control}
          render={({ field }) => (
            <SelectField
              {...omit(field, 'ref')}
              label="Cooperado"
              id="cooperativeMemberId"
              emptyOptionLabel="Selecionar"
              options={cooperativeMembersOptions}
              error={errors.cooperativeMemberId?.message}
            />
          )}
        />

        <Button
          size="sm"
          type="submit"
          disabled={isLoading}
          className="self-end w-24"
        >
          Filtrar
        </Button>
      </div>
    </div>
  );
}
