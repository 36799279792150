import Login from 'auth/components/login/Login';
import LoginForm from 'auth/components/login/LoginForm';
import { LoadingProvider } from 'ui/contexts/overlay/Loading';

const LoginPage = () => (
  <LoadingProvider>
    <Login>
      <LoginForm />
    </Login>
  </LoadingProvider>
);

export default LoginPage;
