import { gql } from '@apollo/client';
import {
  FinancialAccount,
  FinancialAccountEntityType,
  FinancialAccountType,
} from 'financialAccounts/models/financialAccounts';

export interface FinancialAccountUpdateMutationInput {
  id: number;
  bankId: number;
  financialAccountBankAg?: string;
  financialAccountBankCc?: string;
  financialAccountLegalName: string;
  financialAccountDocumentId: string;
  financialAccountAddressCity: string;
  financialAccountAddressState: string;
  financialAccountAddressStreet: string;
  financialAccountAddressDistrict: string;
  financialAccountAddressComplement: string;
  financialAccountAddressPostalCode: number;
  financialAccountType?: FinancialAccountType;
  financialAccountEntityType?: FinancialAccountEntityType;
}

export interface FinancialAccountUpdateMutationVariables {
  financialAccountUpdateInput: FinancialAccountUpdateMutationInput;
}

export interface FinancialAccountUpdated {
  financialAccount: FinancialAccount;
}

export const FINANCIAL_ACCOUNT_UPDATE_MUTATION = gql`
  mutation financialAccountUpdate(
    $financialAccountUpdateInput: FinancialAccountUpdateInput!
  ) {
    financialAccountUpdate(input: $financialAccountUpdateInput) {
      id
      financialAccountType
      financialAccountBankAg
      financialAccountBankCc
      financialAccountLegalName
      financialAccountEntityType
      financialAccountDocumentId
      financialAccountAddressCity
      financialAccountAddressState
      financialAccountAddressStreet
      financialAccountAddressDistrict
      financialAccountAddressPostalCode
      financialAccountAddressComplement
      bank {
        id
      }
    }
  }
`;
