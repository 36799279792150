import { gql } from '@apollo/client';

import { Cooperative } from 'cooperatives/models/cooperative';

interface CooperativeConnection {
  entries: Cooperative[];
  afterCursor: string | null;
  beforeCursor: string | null;
}

export interface CooperativesWithoutContactGroupsList {
  listCooperativesWithoutContactGroups: CooperativeConnection;
}

export const CooperativesWithoutContactGroupsTypename = 'CooperativeConnection';

export const COOPERATIVES_WITHOUT_GROUP_AND_CONTACTS_QUERY = gql`
  query listCooperativesWithoutContactGroups(
    $after: String
    $before: String
    $pageSize: Int
    $maximumLimit: Int
  ) {
    listCooperativesWithoutContactGroups(
      after: $after
      before: $before
      pageSize: $pageSize
      maximumLimit: $maximumLimit
    ) {
      entries {
        id
        cooperativeLegalName
        cooperativeDocumentId
        cooperativeAddressState
        financialAccount {
          id
          financialAccountLegalName
        }
        cooperativeHeadquarter {
          id
        }
      }
      beforeCursor
      afterCursor
    }
  }
`;
