import { Button } from 'ui';
import { MdSend } from 'react-icons/md';
import { Props as ButtonProps } from 'ui/components/button/Button';
import { OverlayConfig } from 'ui/models/overlay';

const RESET_PASSWORD_USER_OVERLAY_CONFIG: OverlayConfig = {
  title: 'Reenviar Email',
  text: 'Tem certeza que deseja reenviar o Email de confirmação?',
  variant: 'primary',
};

type Props = Omit<ButtonProps, 'onClick'> & {
  onClick: (config: OverlayConfig) => void;
};

export const ResetPasswordButton = ({ onClick, disabled, ...props }: Props) => {
  const handleOnClick = () => onClick(RESET_PASSWORD_USER_OVERLAY_CONFIG);

  return (
    <Button
      size="sm"
      variant="secondaryGray"
      onClick={handleOnClick}
      disabled={disabled}
    >
      <MdSend className="flex-shrink-0 h-5 w-5" />
      Reenviar Email
    </Button>
  );
};

export default ResetPasswordButton;
