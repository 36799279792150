import { gql } from '@apollo/client';

export type IcmsTaxDeleteMutationVariables = {
  id: number;
};

export const icmsTaxTypename = 'IcmsTax';

export const ICMS_TAX_DELETE_MUTATION = gql`
  mutation icmsTaxDelete($id: ID!) {
    icmsTaxDelete(id: $id) {
      message
    }
  }
`;
