import SidebarLogo from './Logo';
import Navigation from './Navigation';
import LogoutLink from './LogoutLink';

export default function Sidebar() {
  return (
    <aside
      aria-label="Sidebar"
      className="w-28 bg-gray-dark600 overflow-y-auto flex flex-col justify-between scrollbar"
    >
      <div className="w-full py-6 flex flex-col items-center">
        <SidebarLogo />

        <Navigation />
      </div>

      <LogoutLink />
    </aside>
  );
}
