import classNames from 'classnames';
import React from 'react';
import { NumberInput } from 'ui';
import { Props as BaseProps } from 'ui/components/form/BaseInput';

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement> &
    BaseProps & {
      id: string;
      label: string;
      wrapperClassName?: string;
    },
  HTMLInputElement
>;

const UNALLOWED_CHARACTERS = ['.', ','];
const ALLOWED_CHARACTERS = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

export const NumberOnlyField = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      id,
      mask,
      type,
      error,
      label,
      required,
      disabled,
      className,
      wrapperClassName,
      ...props
    },
    ref
  ) => {
    return (
      <fieldset className={wrapperClassName}>
        <div className="flex mb-3">
          <label
            htmlFor={id}
            className={classNames(
              'block text-sm font-medium text-gray-dark400',
              disabled && 'text-gray-dark600'
            )}
          >
            {label}
          </label>
          {required && (
            <p
              className={classNames('text-gray-dark400 h-0', {
                'text-gray-dark600': disabled,
              })}
            >
              *
            </p>
          )}
        </div>
        <NumberInput
          id={id}
          ref={ref}
          type={type}
          error={error}
          disabled={disabled}
          className={className}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
            UNALLOWED_CHARACTERS.includes(e.key) && e.preventDefault()
          }
          onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
            !ALLOWED_CHARACTERS.includes(e.key) && e.preventDefault()
          }
          {...props}
        />
      </fieldset>
    );
  }
);

export default NumberOnlyField;
