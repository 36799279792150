import { useEffect } from 'react';

import { omit } from 'lodash';

import {
  ChargesUpdate,
  ChargeUpdateCancelType,
  ChargeUpdateRequestType,
} from 'charges/models/charges';

import {
  Control,
  useWatch,
  Controller,
  FieldError,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';

import TextField from 'ui/form/TextField';
import RadioField from 'ui/form/RadioField';
import { MaskInterface } from 'utils/masks';
import CheckboxField from 'ui/form/CheckboxField';
import TextAreaInput from 'ui/components/form/TextAreaInput';
import { RadioInputVariant } from 'ui/components/form/RadioInput';

export interface FormFields extends ChargesUpdate {}

export type FormErrors = {
  chargeId?: FieldError;
  sendEmail?: FieldError;
  newDueDate?: FieldError;
  chargeUpdateReason?: FieldError;
  chargeUpdateCancelType?: FieldError;
  chargeUpdateRequestType?: FieldError;
  keepEarlyPaymentDiscount?: FieldError;
  chargeUpdateRequestingUser?: FieldError;
};

export default function ChargesUpdateFormFields({
  errors,
  control,
  register,
  setValue,
}: {
  errors: FormErrors;
  control: Control<FormFields>;
  register: UseFormRegister<FormFields>;
  setValue: UseFormSetValue<FormFields>;
}) {
  const CHARGE_UPDATE_REQUEST_TYPE_OPTIONS = [
    {
      label: 'Refaturar ou cancelar',
      value: ChargeUpdateRequestType.CHARGE_CANCEL,
    },
    {
      label: 'Atualizar cobrança',
      value: ChargeUpdateRequestType.CHARGE_UPDATE,
    },
  ];

  const CHARGE_UPDATE_CANCEL_TYPE_OPTIONS = [
    {
      label: 'Quero refaturar',
      value: ChargeUpdateCancelType.REISSUE,
    },
    {
      label: 'Apenas cancelar',
      value: ChargeUpdateCancelType.CANCEL_REISSUE,
    },
  ];

  const [chargeUpdateRequestType] = useWatch({
    control,
    name: ['chargeUpdateRequestType'],
  });

  useEffect(() => {
    if (!chargeUpdateRequestType) {
      setValue('chargeUpdateRequestType', ChargeUpdateRequestType.CHARGE_UPDATE);
    }
  }, [chargeUpdateRequestType, setValue]);

  useEffect(() => {
    switch (chargeUpdateRequestType) {
      case ChargeUpdateRequestType.CHARGE_CANCEL:
        setValue('sendEmail', false);
        setValue('chargeUpdateReason', '');
        setValue('keepEarlyPaymentDiscount', false);
        break;
      case ChargeUpdateRequestType.CHARGE_UPDATE:
        setValue('chargeUpdateReason', '');
        setValue('chargeUpdateCancelType', undefined);
        break;
    }
  }, [chargeUpdateRequestType, setValue]);

  return (
    <div className="flex flex-col w-full items-start justify-center gap-6">
      <Controller
        control={control}
        name="chargeUpdateRequestType"
        rules={{ required: 'Campo obrigatório' }}
        render={({ field }) => (
          <RadioField
            {...omit(field, 'ref')}
            id="chargeUpdateRequestType"
            value={chargeUpdateRequestType}
            variant={RadioInputVariant.OUTLINED}
            options={CHARGE_UPDATE_REQUEST_TYPE_OPTIONS}
            error={errors.chargeUpdateRequestType?.message}
            defaultValue={ChargeUpdateRequestType.CHARGE_UPDATE}
            wrapperClassName="flex flex-col items-start justify-center w-full"
            flexDirectionClassName="flex w-full h-full items-center justify-center"
            style={{
              flexWrap: 'nowrap',
            }}
          />
        )}
      />

      {chargeUpdateRequestType === ChargeUpdateRequestType.CHARGE_CANCEL ? (
        <>
          <Controller
            control={control}
            name="chargeUpdateCancelType"
            rules={{ required: 'Campo obrigatório' }}
            render={({ field }) => (
              <RadioField
                {...omit(field, 'ref')}
                id="chargeUpdateCancelType"
                variant={RadioInputVariant.DEFAULT}
                label="Selecione o que você quer fazer:"
                options={CHARGE_UPDATE_CANCEL_TYPE_OPTIONS}
                error={errors.chargeUpdateCancelType?.message}
                wrapperClassName="flex flex-col items-start justify-center w-full gap-2"
                flexDirectionClassName="flex w-full h-full items-center justify-start"
                style={{
                  flexWrap: 'nowrap',
                }}
              />
            )}
          />

          <h1 className="text-left bg-gray-dark550 px-5 py-3 rounded-md text-gray-dark400 text-sm">
            <strong>Ao refaturar</strong>, você será redirecionado para a tela de
            creditação, onde poderá fazer edições nos valores desta cobrança.
          </h1>
        </>
      ) : (
        <>
          <div className="w-full items-center justify-center gap-4 grid grid-cols-2">
            <TextField
              minLength={10}
              id="newDueDate"
              placeholder={'DD/MM/AAAA'}
              mask={'date' as MaskInterface}
              label="Nova data de vencimento"
              error={errors.newDueDate?.message}
              defaultValue={new Date(
                new Date().setDate(new Date().getDate() + 10)
              ).toLocaleDateString('pt-BR')}
              {...register('newDueDate', {
                required: 'Campo obrigatório',
              })}
            />
          </div>

          <CheckboxField
            {...register('sendEmail')}
            inputLabel="Reenviar cobrança por e-mail"
          />
        </>
      )}

      <TextAreaInput
        id="chargeUpdateReason"
        {...register('chargeUpdateReason', {
          required: 'Campo obrigatório',
        })}
        error={errors.chargeUpdateReason?.message}
        placeholder="Justifique o motivo das alterações"
      />
    </div>
  );
}
