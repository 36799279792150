import { DeleteButton as Button } from 'ui';
import { Props as ButtonProps } from 'ui/components/button/Button';
import { OverlayConfig } from 'ui/models/overlay';

const DELETE_PIS_COFINS_TAXES_OVERLAY_CONFIG: OverlayConfig = {
  title: 'Desativação do PIS/COFINS!',
  text: 'Tem certeza que deseja desativar esse PIS/COFINS? Todos os dados serão permanentemente removidos do nosso sistema. Essa ação não poderá ser revertida.',
  variant: 'danger',
};

type Props = Omit<ButtonProps, 'onClick'> & {
  onClick: (config: OverlayConfig) => void;
};

export const DeleteButton = ({ onClick, ...props }: Props) => {
  const handleOnClick = () => onClick(DELETE_PIS_COFINS_TAXES_OVERLAY_CONFIG);

  return <Button onClick={handleOnClick} {...props} />;
};

export default DeleteButton;
