import { gql } from '@apollo/client';

export interface IcmsTaxQueryVariables {
  icmsId: string;
}

export const IcmsTaxTypename = 'IcmsTax';

export const ICMS_TAX_QUERY = gql`
  query icmsTax($icmsId: ID!) {
    icmsTax(icmsId: $icmsId) {
      id
      icmsCountryState
      icmsConsumerUnitConsumptionClass
      icmsValidityStartDate
      icmsValidityEndDate
      icmsRangesFee {
        consumptionRangePercentage
        consumptionKwhStartRange
        consumptionKwhEndRange
      }
    }
  }
`;
