import { ApportionmentCycleConsumerUnit } from 'apportionmentCycles/models/apportionmentCycleConsumerUnit';
import ApportionmentCycleConsumerUnitItem from './ApportionmentCycleConsumerUnitTableItem';
import Table from 'ui/components/table/Table';
import TableHead from 'ui/components/table/TableHead';
import TableHeadColumn from 'ui/components/table/TableHeadColumn';
import TableBody from 'ui/components/table/TableBody';

type Props = {
  apportionmentCycleConsumerUnits: ApportionmentCycleConsumerUnit[];
};

export default function ApportionmentCycleConsumerUnitsItem({
  apportionmentCycleConsumerUnits,
}: Props) {
  return (
    <Table>
      <TableHead>
        <TableHeadColumn>N°</TableHeadColumn>
        <TableHeadColumn>UC</TableHeadColumn>
        <TableHeadColumn>COOPERADO</TableHeadColumn>
        <TableHeadColumn>RATEIO</TableHeadColumn>
        <TableHeadColumn className="text-center">CRÉDITOS PT</TableHeadColumn>
        <TableHeadColumn className="text-center">CRÉDITOS FP</TableHeadColumn>
        <TableHeadColumn className="text-center">CRÉDITOS TOTAIS</TableHeadColumn>
      </TableHead>
      <TableBody>
        {apportionmentCycleConsumerUnits.map(
          (
            apportionmentCycleConsumerUnit: ApportionmentCycleConsumerUnit,
            index: number
          ) => (
            <ApportionmentCycleConsumerUnitItem
              index={index + 1}
              key={apportionmentCycleConsumerUnit.id}
              apportionmentCycleConsumerUnit={apportionmentCycleConsumerUnit}
            />
          )
        )}
      </TableBody>
    </Table>
  );
}
