import { Button } from 'ui';
import classNames from 'classnames';
import { MdSend } from 'react-icons/md';
import { Props as ButtonProps } from 'ui/components/button/Button';

type Props = Omit<ButtonProps, 'onClick'> & {
  onClick?: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
};

export const SendEmailButton = ({ onClick, disabled, ...props }: Props) => {
  return (
    <Button
      size="sm"
      variant="icon"
      onClick={onClick}
      disabled={disabled}
      className={classNames('w-5 flex items-center justify-center', {
        'cursor-not-allowed': disabled,
      })}
    >
      <MdSend
        className={classNames('flex-shrink-0 h-5 w-5', {
          'text-gray-dark500 opacity-30': disabled,
        })}
      />
    </Button>
  );
};

export default SendEmailButton;
