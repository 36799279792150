import React, { useEffect, useMemo, useRef, useState } from 'react';

import classNames from 'classnames';

import { MdCheck, MdChevronLeft, MdSearch } from 'react-icons/md';

import BaseInput, { Props as BaseProps } from './BaseInput';

export interface Option<T> {
  key: T | null;
  value: string;
}

export interface SearchInputProps<T> {
  label?: string;
  isOpen?: boolean;
  options?: Option<T>[];
  selectedOption?: Option<T> | null;
  selectItem?: (item: Option<T>) => void;
  value?: string | number | readonly string[];
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function SearchInput({
  value,
  label,
  isOpen,
  options,
  onChange,
  selectItem,
  selectedOption,
  ...props
}: SearchInputProps<string | number> &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement> & BaseProps,
    HTMLInputElement
  >) {
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [isOptionsOpen, setIsOptionsOpen] = useState(isOpen || false);

  const [optionsList, setOptionsList] = useState<Option<string | number>[]>(
    options || []
  );

  const IS_EMPTY = useMemo(() => {
    return optionsList?.length === 0;
  }, [optionsList]);

  const handleFilter = useMemo(() => {
    if (!optionsList) return [];

    return optionsList.filter((item) => {
      return (
        item.key === null ||
        item.value.toLowerCase().includes(value?.toString().toLowerCase() || '')
      );
    });
  }, [optionsList, value]);

  const isSelected = useMemo(
    () => (item: Option<string | number>) => {
      return item.key === selectedOption?.key;
    },
    [selectedOption]
  );

  const handleSelect = useMemo(
    () => (item: Option<string | number>) => {
      setIsOptionsOpen(false);

      if (item?.key !== null) {
        selectItem?.(item);
      } else {
        selectItem?.({ key: null, value: '' });
      }
    },
    [selectItem]
  );

  useEffect(() => {
    options &&
      setOptionsList(
        options.length > 1
          ? [{ key: null, value: 'Escolha um Cooperado' }, ...(options || [])]
          : [...(options || [])]
      );
  }, [options]);

  useMemo(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOptionsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useMemo(() => {
    if (value && !selectedOption?.key) {
      setIsOptionsOpen(true);
    }
  }, [selectedOption, value]);

  return (
    <fieldset className="flex flex-col w-full items-start justify-start">
      {label && (
        <div className="flex mb-3">
          <label
            htmlFor={props.id}
            className={classNames('block text-sm font-medium text-gray-dark400', {
              'text-gray-dark400 opacity-30': props.disabled,
            })}
          >
            {label}
          </label>
          {props.required && (
            <p
              className={classNames('text-gray-dark400 h-0', {
                'text-gray-dark400 opacity-30': props.disabled,
              })}
            >
              *
            </p>
          )}
        </div>
      )}
      <div
        ref={dropdownRef}
        className="flex flex-col w-full items-center gap-2 relative"
      >
        <div
          className={classNames(
            'flex items-center justify-start w-full border border-gray-dark500 bg-gray-dark600 shadow-none rounded-md px-4',
            {
              'hover:border-primary': !props.disabled,
              'bg-gray-dark700 border-gray-dark700 hover:!border-gray-dark700':
                props.disabled,
              'border-red-500 hover:border-red-500 bg-transparent': props.error,
            }
          )}
        >
          <div className="flex items-center justify-start w-full">
            <MdSearch
              size={25}
              color="#6B6B6B"
              className={classNames('cursor-pointer', {
                '!text-red-500': props.error,
              })}
            />
            <BaseInput
              {...props}
              error={''}
              type="text"
              id={props.id}
              value={value}
              ref={props.ref}
              name={props.name}
              autoComplete="off"
              onChange={onChange}
              onClick={() => !isOptionsOpen && setIsOptionsOpen(!isOptionsOpen)}
              placeholder={IS_EMPTY ? 'Nenhuma opção disponível' : props.placeholder}
              className={classNames(
                'appearence-none border-none focus:outline-none focus:ring-transparent w-full bg-gray-dark600 shadow-none rounded-md placeholder-gray-dark500 focus-within:text-gray-dark400 text-gray-dark400 text-sm',
                {
                  '!bg-transparent !text-red-500 !placeholder-red500': props.error,
                }
              )}
            />
          </div>
          <MdChevronLeft
            size={20}
            color="#6B6B6B"
            aria-hidden="true"
            className={classNames('cursor-pointer', {
              '!text-red-500': props.error,
              'cursor-not-allowed': props.disabled,
            })}
            onClick={() =>
              !IS_EMPTY && !props.disabled && setIsOptionsOpen(!isOptionsOpen)
            }
            style={{ transform: `rotate(${isOptionsOpen ? 90 : -90}deg)` }}
          />
        </div>
        {isOptionsOpen && (
          <div className="flex w-full items-start justify-start flex-col border border-gray-dark500 rounded-md bg-gray-dark600 max-h-[308px] overflow-y-auto scrollbar focus:outline-none absolute top-11 z-20">
            {handleFilter?.map((item) => (
              <span
                key={item.key}
                onClick={() => handleSelect(item)}
                className={classNames(
                  'text-gray-dark500 flex w-full items-start justify-between bg-gray-dark600 shadow-none placeholder-gray-dark500 focus-within:text-gray-dark400 hover:border-primary px-4 py-3 cursor-pointer hover:text-gray-dark400 text-sm hover:bg-gray-dark550',
                  {
                    'bg-gray-dark700': props.disabled,
                    'border-b border-gray-dark500 hover:border-none':
                      item.key === null,
                    'bg-gray-dark550 !text-gray-dark400': isSelected(item),
                  }
                )}
              >
                {item.value}
                {isSelected(item) && <MdCheck className="text-lg font-semibold" />}
              </span>
            ))}
          </div>
        )}
        {props.error && (
          <p className="w-full text-red-500 text-xs font-normal text-left">
            {props.error}
          </p>
        )}
      </div>
    </fieldset>
  );
}
