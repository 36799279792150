import { gql } from '@apollo/client';

export type InternalUserToggleAccessStatusVariables = {
  id: Number;
};

export const INTERNAL_USER_TOGGLE_ACCESS_STATUS_MUTATION = gql`
  mutation internalUserToggleAccessStatus($id: ID!) {
    internalUserToggleAccessStatus(id: $id) {
      id
      userName
      userEmail
      userAccessBlockedAt
      userIsAdmin
    }
  }
`;
