import { Link } from 'react-router-dom';

export default function SidebarLogo() {
  return (
    <Link
      to="/dashboard"
      className="flex-shrink-0 flex items-center text-white hover:text-primary"
      title="IntraNEX"
    >
      <svg
        width="36"
        height="47"
        viewBox="0 0 36 47"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26.5604 8.5988L26.8574 8.11976L27.8855 6.53892L30.4902 2.51497V2.46707L30.7644 2.03593L32.1124 0H26.469C26.0044 0.0286912 25.5482 0.142171 25.1209 0.335329C24.4161 0.676281 23.8151 1.21515 23.3845 1.89222L23.3159 1.98802L23.0875 2.37126L20.3685 6.53892L18.0152 10.2036L15.936 6.99401L15.639 6.53892L12.8059 2.13174L12.7373 2.03593L12.486 1.65269C12.1041 1.13872 11.6154 0.722756 11.0572 0.436385C10.499 0.150015 9.88588 0.000774177 9.2644 0L3.91797 0L5.19746 1.98802L5.24315 2.05988L5.51733 2.49102L8.14485 6.53892L9.17301 8.11976L9.42434 8.52695L9.47004 8.5988L12.1433 12.7186L12.189 12.7904L14.2681 16.024L12.189 19.2096V19.2814L9.49288 23.4012L8.14485 25.4611L5.54018 29.5329L3.91797 32H9.2644C9.93299 32.0003 10.5913 31.8278 11.1811 31.4977C11.771 31.1677 12.2741 30.6903 12.6459 30.1078L15.6619 25.4611L15.936 25.0299L18.0152 21.8204L20.3685 25.4611L23.3616 30.1078C23.7385 30.6906 24.2459 31.1679 24.8393 31.4977C25.4328 31.8276 26.0943 32 26.766 32H32.0896L30.4902 29.5329L27.8627 25.4611L26.5375 23.4012L23.8643 19.2814L21.7623 16L23.8871 12.7186L26.5604 8.5988Z"
          fill="currentColor"
        />
        <path
          d="M3.98609 47V39.3H7.30809C8.16609 39.3 8.82975 39.4723 9.29909 39.817C9.77575 40.1617 10.0141 40.6493 10.0141 41.28C10.0141 41.698 9.91509 42.05 9.71709 42.336C9.52642 42.622 9.26242 42.8383 8.92509 42.985C8.59509 43.1317 8.23209 43.205 7.83609 43.205L8.02309 42.941C8.51442 42.941 8.93609 43.018 9.28809 43.172C9.64742 43.3187 9.92609 43.5423 10.1241 43.843C10.3221 44.1363 10.4211 44.503 10.4211 44.943C10.4211 45.5957 10.1791 46.1017 9.69509 46.461C9.21842 46.8203 8.49609 47 7.52809 47H3.98609ZM4.80009 46.329H7.51709C8.19175 46.329 8.70875 46.2153 9.06809 45.988C9.42742 45.7533 9.60709 45.383 9.60709 44.877C9.60709 44.3783 9.42742 44.0153 9.06809 43.788C8.70875 43.5533 8.19175 43.436 7.51709 43.436H4.71209V42.765H7.25309C7.86909 42.765 8.34575 42.6477 8.68309 42.413C9.02775 42.1783 9.20009 41.83 9.20009 41.368C9.20009 40.8987 9.02775 40.5503 8.68309 40.323C8.34575 40.0883 7.86909 39.971 7.25309 39.971H4.80009V46.329ZM13.0216 42.743H17.0916V43.436H13.0216V42.743ZM13.1096 46.296H17.7406V47H12.2956V39.3H17.5756V40.004H13.1096V46.296ZM20.9245 47V40.752H18.4605V39.3H25.1705V40.752H22.7065V47H20.9245ZM24.747 47L28.179 39.3H29.939L33.382 47H31.512L28.696 40.202H29.4L26.573 47H24.747ZM26.463 45.35L26.936 43.997H30.896L31.38 45.35H26.463Z"
          fill="currentColor"
        />
      </svg>
    </Link>
  );
}
