export default function ErrorIcon({ OnClick }: { OnClick?: () => void }) {
  return (
    <div className="flex items-center" onClick={OnClick}>
      <svg
        width="22"
        height="23"
        viewBox="0 0 22 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.25 3.75C4.29253 3.75 3.5 4.54253 3.5 5.5V19.5C3.5 20.4575 4.29253 21.25 5.25 21.25H15.75C16.7075 21.25 17.5 20.4575 17.5 19.5V9L12.25 3.75H5.25ZM5.25 5.5H11.375V9.875H15.75V19.5H5.25V5.5ZM7 12.5V14.25H14V12.5H7ZM7 16V17.75H14V16H7Z"
          fill="#717171"
        />
        <path
          d="M16.7222 1C14.6646 1 13 2.66455 13 4.72222C13 6.7799 14.6646 8.44444 16.7222 8.44444C18.7799 8.44444 20.4444 6.7799 20.4444 4.72222C20.4444 2.66455 18.7799 1 16.7222 1Z"
          fill="#717171"
          stroke="#111111"
          strokeWidth="2"
        />
      </svg>
    </div>
  );
}
