import { SyntheticEvent } from 'react';
import { Cooperative } from 'cooperatives/models/cooperative';
import { TableBodyColumn } from 'ui/components/table';
import { EditButton, RemoveButton } from 'dashboard/components/table/buttons';
import TableBodyRow from 'ui/components/table/TableBodyRow';
import classNames from 'classnames';

type Props = {
  cooperative: Cooperative;
  onClickEditButton: (id: number) => void;
  onClickRemoveButton: (id: number) => void;
  disableEditButton: boolean;
  disableRemoveButton: boolean;
};

const SUBSCRIPTION_COSTS_FORMAT_CONFIG = {
  style: 'currency',
  currency: 'BRL',
};

const formatCooperativeSubscriptionCostCents = (
  value: number,
  config: { currency: string; style: string }
) => new Intl.NumberFormat([], config).format(value);

export default function PowerDistributionUnitsTableItem({
  cooperative: {
    id,
    cooperativeLegalName,
    cooperativeAddressState,
    cooperativeDocumentId,
  },
  cooperative,
  onClickEditButton,
  onClickRemoveButton,
  disableEditButton,
  disableRemoveButton,
}: Props) {
  const cooperativeType = cooperative.cooperativeHeadquarter ? 'Filial' : 'Matriz';

  const headquarterName =
    cooperative.cooperativeHeadquarter?.cooperativeLegalName || '-';

  const cooperativeSubscriptionCostCents = formatCooperativeSubscriptionCostCents(
    cooperative.cooperativeSubscriptionCostCents / 100.0,
    SUBSCRIPTION_COSTS_FORMAT_CONFIG
  );
  const financialAccountName =
    cooperative.financialAccount?.financialAccountLegalName;

  const handleClickEditButton = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    onClickEditButton(id);
  };

  const handleClickRemoveButton = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    onClickRemoveButton(id);
  };

  return (
    <TableBodyRow dataTestId={`cooperative-row-id-${id}`}>
      <TableBodyColumn className="font-bold">{cooperativeLegalName}</TableBodyColumn>
      <TableBodyColumn className="font-bold text-center">
        {cooperativeAddressState}
      </TableBodyColumn>
      <TableBodyColumn className="font-normal">
        {cooperativeDocumentId}
      </TableBodyColumn>
      <TableBodyColumn className="font-normal text-center">
        {cooperativeType}
      </TableBodyColumn>
      <TableBodyColumn
        className={classNames('font-normal', {
          'text-left': cooperative.cooperativeHeadquarter,
          'text-center': !cooperative.cooperativeHeadquarter,
        })}
      >
        {headquarterName}
      </TableBodyColumn>
      <TableBodyColumn className="font-normal">
        {financialAccountName}
      </TableBodyColumn>
      <TableBodyColumn className="font-normal">
        {cooperativeSubscriptionCostCents}
      </TableBodyColumn>
      <TableBodyColumn className="flex gap-x-3 items-end justify-end">
        <EditButton onClick={handleClickEditButton} disabled={disableEditButton} />
        <RemoveButton
          onClick={handleClickRemoveButton}
          disabled={disableRemoveButton}
        />
      </TableBodyColumn>
    </TableBodyRow>
  );
}
