import React from 'react';

import BaseInput, { Props as BaseProps } from './BaseInput';

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement> & BaseProps,
  HTMLInputElement
>;

export const TextInput = React.forwardRef<HTMLInputElement, Props>(
  ({ className, type, name, mask, disabled, error, ...props }, ref) => {
    return (
      <BaseInput
        ref={ref}
        name={name}
        type={type}
        mask={mask}
        error={error}
        disabled={disabled}
        className={className}
        {...props}
      />
    );
  }
);

export default TextInput;
