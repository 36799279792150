import { CooperativeMember } from 'cooperatives/models/cooperativeMember';
import CooperativeMembersTableItem from './CooperativeMembersTableItem';
import Table from 'ui/components/table/Table';
import TableHead from 'ui/components/table/TableHead';
import TableHeadColumn from 'ui/components/table/TableHeadColumn';
import TableBody from 'ui/components/table/TableBody';

type Props = {
  cooperativeMembers: CooperativeMember[];
  onClickEditButton: (id: number) => void;
  onClickRemoveButton: (id: number) => void;
  disableEditButton: boolean;
  disableRemoveButton: boolean;
};

export default function CooperativeMembersTable({
  cooperativeMembers,
  onClickEditButton,
  onClickRemoveButton,
  disableEditButton,
  disableRemoveButton,
}: Props) {
  return (
    <Table>
      <TableHead>
        <TableHeadColumn>RAZÃO SOCIAL / NOME</TableHeadColumn>
        <TableHeadColumn>TIPO</TableHeadColumn>
        <TableHeadColumn>DOCUMENTO</TableHeadColumn>
        <TableHeadColumn>COOPERATIVA</TableHeadColumn>
        <TableHeadColumn className="text-right">AÇÕES</TableHeadColumn>
      </TableHead>
      <TableBody>
        {cooperativeMembers.map((cooperativeMember: CooperativeMember) => (
          <CooperativeMembersTableItem
            key={cooperativeMember.id}
            cooperativeMember={cooperativeMember}
            onClickEditButton={onClickEditButton}
            onClickRemoveButton={onClickRemoveButton}
            disableEditButton={disableEditButton}
            disableRemoveButton={disableRemoveButton}
          />
        ))}
      </TableBody>
    </Table>
  );
}
