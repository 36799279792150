import React, { createContext, useContext } from 'react';
import { useOverlay } from 'ui/hooks/useOverlay';

interface LoadingState {
  LoadingOverlay: () => JSX.Element;
}

type LoadingContextData = {
  showLoading(): void;
  closeLoading(): void;
} & LoadingState;

const LoadingContext = createContext<LoadingContextData>({} as LoadingContextData);

const LoadingProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    openModal: showLoading,
    closeModal: closeLoading,
    RenderLoading: LoadingOverlay,
  } = useOverlay();

  return (
    <LoadingContext.Provider
      value={{
        showLoading,
        closeLoading,
        LoadingOverlay,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};

function useLoading(): LoadingContextData {
  const context = useContext(LoadingContext);

  if (!context) {
    throw new Error('LoadingProvider is required!');
  }

  return context;
}

export { LoadingProvider, useLoading };
