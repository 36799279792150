type Props = {
  children: React.ReactNode;
};

export default function TableHead({ children }: Props) {
  return (
    <thead className="bg-gray-dark600">
      <tr>{children}</tr>
    </thead>
  );
}
