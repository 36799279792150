import React from 'react';
import classNames from 'classnames';

export type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLLabelElement>,
  HTMLLabelElement
>;

export const FieldGroupLabel = ({ children, className, ...other }: Props) => (
  <label
    {...other}
    className={classNames(
      'block text-sm font-medium text-gray-dark400 mb-3',
      className
    )}
  >
    {children}
  </label>
);

export default FieldGroupLabel;
