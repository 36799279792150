import React from 'react';
import { CalendarProps } from 'react-calendar';
import ReactCalendar from 'react-calendar';

export interface Props extends Omit<CalendarProps, 'inputRef'> {
  ref?: React.Ref<HTMLInputElement>;
}

export const Calendar = React.forwardRef<HTMLInputElement, Props>((props, ref) => (
  <ReactCalendar {...props} inputRef={ref as CalendarProps['inputRef']} />
));

export default Calendar;
