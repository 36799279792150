import { useEffect, useState } from 'react';
import omit from 'lodash/omit';
import { Control, Controller, FieldError, UseFormRegister } from 'react-hook-form';
import TextField from 'ui/form/TextField';
import SelectField from 'ui/form/SelectField';
import { Option } from 'ui/components/form/SelectInput';
import { PowerDistributionUnitSelect } from 'powerDistributionUnits/models/powerDistributionUnit';
import CountryStateSelectField from 'ui/form/CountryStateSelectField';
import { Button } from 'ui';

export type FormErrors = {
  powerDistributionUnitId?: FieldError;
  generationUnitLegalName?: FieldError;
  generationUnitAddressState?: FieldError;
  generationUnitEnergySource?: FieldError;
  generationUnitConsumerUnitPowerDistributionUnitIdentifier?: FieldError;
};

export type FormFields = {
  powerDistributionUnitId: number;
  generationUnitLegalName: string;
  generationUnitAddressState: string;
  generationUnitEnergySource: string;
  generationUnitConsumerUnitPowerDistributionUnitIdentifier: string;
};

const generationUnitEnergySourceOptions = [
  {
    key: 'UTE',
    value: 'Termelétrica',
  },
  {
    key: 'EOL',
    value: 'Eólica',
  },
  {
    key: 'UFV',
    value: 'Solar',
  },
  {
    key: 'CGH',
    value: 'Hidráulica',
  },
];

export default function GenerationUnitFormFilters({
  errors,
  control,
  register,
  isLoading,
  powerDistributionUnits,
}: {
  errors: FormErrors;
  control: Control<FormFields>;
  isLoading?: boolean;
  register: UseFormRegister<FormFields>;
  powerDistributionUnits?: PowerDistributionUnitSelect[];
}) {
  const [powerDistributionUnitOptions, setPowerDistributionUnitOptions] = useState<
    Option<number>[]
  >([]);
  useEffect(
    () =>
      setPowerDistributionUnitOptions(
        (powerDistributionUnits instanceof Array &&
          powerDistributionUnits.map((powerDistributionUnit) => ({
            key: powerDistributionUnit.id,
            value: powerDistributionUnit.powerDistributionUnitLegalName,
          }))) ||
          []
      ),
    [powerDistributionUnits]
  );

  return (
    <>
      <div className="grid grid-cols-6 gap-4 pb-6">
        <TextField
          error={errors.generationUnitLegalName?.message}
          label="Unidade Geradora"
          id="generationUnitLegalName"
          {...register('generationUnitLegalName')}
          placeholder="Digitar"
        />

        <TextField
          error={
            errors.generationUnitConsumerUnitPowerDistributionUnitIdentifier?.message
          }
          label="UC"
          id="generationUnitConsumerUnitPowerDistributionUnitIdentifier"
          {...register('generationUnitConsumerUnitPowerDistributionUnitIdentifier')}
          placeholder="Digitar"
        />

        <Controller
          name="generationUnitEnergySource"
          control={control}
          render={({ field }) => (
            <SelectField
              {...omit(field, 'ref')}
              error={errors.generationUnitEnergySource?.message}
              label="Fonte de Geração"
              emptyOptionLabel="Selecionar"
              id="generationUnitEnergySource"
              options={generationUnitEnergySourceOptions}
            />
          )}
        />

        <Controller
          name="powerDistributionUnitId"
          control={control}
          render={({ field }) => (
            <SelectField
              {...omit(field, 'ref')}
              error={errors.powerDistributionUnitId?.message}
              label="Concessionária"
              emptyOptionLabel="Selecionar"
              id="powerDistributionUnitId"
              options={powerDistributionUnitOptions}
            />
          )}
        />

        <Controller
          name="generationUnitAddressState"
          control={control}
          render={({ field }) => (
            <CountryStateSelectField
              {...omit(field, 'ref')}
              error={errors.generationUnitAddressState?.message}
              label="UF"
              id="generationUnitAddressState"
            />
          )}
        />

        <Button
          type="submit"
          size="sm"
          disabled={isLoading}
          className="self-end w-24"
        >
          Filtrar
        </Button>
      </div>
    </>
  );
}
