import { gql } from '@apollo/client';

export type PisCofinsTaxDeleteMutationVariables = {
  id: number;
};

export const PIS_COFINS_TAX_DELETE_MUTATION = gql`
  mutation pisCofinsTaxDelete($id: ID!) {
    powerDistributionUnitPisCofinsTaxDelete(id: $id) {
      message
    }
  }
`;
