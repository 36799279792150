import { gql } from '@apollo/client';

interface CooperativeSelectConnection {
  entries: {
    id: number;
    cooperativeLegalName: string;
    cooperativeDocumentId: string;
    cooperativeAddressState?: string;
    financialAccount: {
      id: number;
      financialAccountLegalName: string;
    };
  }[];
}

export interface CooperativesSelectList {
  cooperatives: CooperativeSelectConnection;
}

export const cooperativesTypename = 'CooperativeConnection';

const MAX_SIZE = 10000;

export const COOPERATIVES_SELECT_QUERY_VARIABLES = {
  variables: { pageSize: MAX_SIZE, maximumLimit: MAX_SIZE },
};

export const COOPERATIVES_SELECT_QUERY = gql`
  query cooperatives(
    $after: String
    $before: String
    $pageSize: Int
    $maximumLimit: Int
  ) {
    cooperatives(
      after: $after
      before: $before
      pageSize: $pageSize
      maximumLimit: $maximumLimit
    ) {
      entries {
        id
        cooperativeLegalName
        cooperativeDocumentId
        cooperativeAddressState
        financialAccount {
          id
          financialAccountLegalName
        }
        cooperativeHeadquarter {
          id
        }
      }
    }
  }
`;
