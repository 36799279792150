import { gql } from '@apollo/client';

import { Groups, Contact, ContactGroups } from 'contactGroups/models/contactGroups';

export interface ContactGroupCreateMutationInput {
  group: Groups;
  contacts: Contact[];
  entities: {
    cooperatives: {
      id: number;
    }[];
    consumerUnits: {
      id: number;
    }[];
    generationUnits: {
      id: number;
    }[];
  };
}

export interface ContactGroupMutationVariables {
  contactGroupCreateInput: ContactGroupCreateMutationInput;
}

export interface ContactGroupCreated {
  contactGroupCreate: ContactGroups;
}

export const CONTACT_GROUP_CREATE_MUTATION = gql`
  mutation contactGroupCreate($contactGroupCreateInput: ContactGroupCreateInput!) {
    contactGroupCreate(input: $contactGroupCreateInput) {
      group {
        id
      }
      contacts {
        id
      }
      entities {
        cooperatives {
          id
        }
        consumerUnits {
          id
        }
        generationUnits {
          id
        }
      }
    }
  }
`;
