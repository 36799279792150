import TariffFlagsTableItem from './TariffFlagModalitiesTableItem';
import Table from 'ui/components/table/Table';
import TableHead from 'ui/components/table/TableHead';
import TableHeadColumn from 'ui/components/table/TableHeadColumn';
import TableBody from 'ui/components/table/TableBody';
import { TariffFlagModality } from 'tariffFlagTariffs/models/tariffFlagModality';

type Props = {
  tariffFlagModalities: TariffFlagModality[];
};

export default function TariffFlagsTable({ tariffFlagModalities }: Props) {
  return (
    <Table>
      <TableHead>
        <TableHeadColumn>PERÍODO</TableHeadColumn>
        <TableHeadColumn>BANDEIRA</TableHeadColumn>
        <TableHeadColumn>TARIFA (R$/kWh)</TableHeadColumn>
        <TableHeadColumn>STATUS</TableHeadColumn>
      </TableHead>
      <TableBody>
        {tariffFlagModalities.map(
          (tariffFlagModality: TariffFlagModality, index) => (
            <TariffFlagsTableItem
              key={index}
              id={index + 1}
              tariffFlagModality={tariffFlagModality}
            />
          )
        )}
      </TableBody>
    </Table>
  );
}
