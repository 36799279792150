import React, { ChangeEvent, KeyboardEvent } from 'react';
import { CalendarProps } from 'react-calendar';
import classnames from 'classnames';
import {
  useFloating,
  flip,
  offset,
  getScrollParents,
  shift,
  arrow,
} from '@floating-ui/react-dom';
import { Calendar } from 'ui';
import TextField from 'ui/form/TextField';
import { useOnClickOutside } from 'ui/hooks/useOnClickOutside';
import { dateWithoutTimezone, formatDateToLocaleDateString } from 'utils/form';

type RangeError = {
  startDateInput?: string;
  endDateInput?: string;
};

export type RangeDate = [Date] | [Date, Date];

export interface Props
  extends Omit<
    CalendarProps,
    'inputRef' | 'className' | 'returnValue' | 'value' | 'onChange'
  > {
  required?: boolean;
  wrapperClassName?: string;
  rangeInputWrapperClassName?: string;
  calendarWrapperClassName?: string;
  calendarClassName?: string;
  label: string;
  endLabel?: string;
  placeholder?: string;
  endPlaceholder?: string;
  value?: Date | RangeDate | null;
  onChange?: (
    value: Date | RangeDate | null,
    event: ChangeEvent<HTMLInputElement> | KeyboardEvent<HTMLInputElement>
  ) => void;
  locale?: string;
  disabled?: boolean;
  ref?: React.Ref<HTMLInputElement>;
  error?: string | RangeError;
  id: string;
}

export const DatePickerField = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      required,
      wrapperClassName,
      rangeInputWrapperClassName,
      calendarWrapperClassName,
      calendarClassName,
      label,
      endLabel,
      placeholder,
      endPlaceholder,
      disabled,
      error,
      id,
      selectRange,
      value,
      onChange,
      locale = 'pt-BR',
      ...props
    },
    ref
  ) => {
    const [internalValue, setInternalValue] = React.useState<
      Date | RangeDate | null | undefined
    >(value);
    const [open, setOpen] = React.useState(false);
    const arrowRef = React.useRef(null);

    const {
      x,
      y,
      reference,
      floating,
      strategy,
      update,
      refs,
      middlewareData: { arrow: arrowData },
    } = useFloating({
      placement: 'bottom-start',
      middleware: [
        shift(),
        flip({
          crossAxis: false,
        }),
        offset(15),
        arrow({ element: arrowRef }),
      ],
    });

    useOnClickOutside(
      refs.floating,
      React.useCallback(() => {
        if (!(internalValue instanceof Date) && internalValue?.length === 1) {
          setInternalValue(value);
        }
        setOpen(false);
      }, [internalValue, value])
    );

    React.useEffect(() => {
      setInternalValue(value);

      if (!(value instanceof Date) && value?.length === 2) {
        setOpen(false);
      }
    }, [value]);

    React.useEffect(() => {
      if (!refs.reference.current || !refs.floating.current) {
        return;
      }

      const parents = [
        ...getScrollParents(refs.reference.current),
        ...getScrollParents(refs.floating.current),
      ];
      parents.forEach((parent) => {
        parent.addEventListener('scroll', update);
        parent.addEventListener('resize', update);
      });
      return () => {
        parents.forEach((parent) => {
          parent.removeEventListener('scroll', update);
          parent.removeEventListener('resize', update);
        });
      };
      // This actually can be disabled because the the refs are pointing to DOM nodes
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refs.reference.current, refs.floating.current, update]);

    const onFocusInput: React.InputHTMLAttributes<HTMLInputElement>['onFocus'] =
      () => setOpen(true);

    const onChangeCalendar = (
      value: Date | RangeDate | null,
      event: ChangeEvent<HTMLInputElement> | KeyboardEvent<HTMLInputElement>
    ) => {
      if (!onChange) return;

      if (!value) {
        return onChange(value, event);
      }

      if (!(value instanceof Date) && value?.length === 2) {
        onChange(value, event);
      } else {
        setInternalValue(value);
      }
    };

    const inputDate = (() => {
      const date =
        internalValue instanceof Date ? internalValue : internalValue?.[0];

      if (date) {
        return formatDateToLocaleDateString(
          dateWithoutTimezone(date) as unknown as string
        );
      }

      return '';
    })();

    const toRangeInputDate = (() => {
      const date = (internalValue as RangeDate)?.[1];

      if (date) {
        return formatDateToLocaleDateString(
          dateWithoutTimezone(date) as unknown as string
        );
      }

      return '';
    })();

    const BACKSPACE_KEY = 'Backspace';

    const onBackSpace = (event: KeyboardEvent<HTMLInputElement>) =>
      event.key === BACKSPACE_KEY && internalValue
        ? onChangeCalendar(null, event)
        : null;

    return (
      <div className={classnames(wrapperClassName, 'relative')} ref={ref}>
        <div className={classnames('flex', rangeInputWrapperClassName)}>
          <TextField
            readOnly
            label={label}
            ref={reference}
            value={inputDate}
            disabled={disabled}
            required={required}
            id={`${id}inputDate`}
            onFocus={onFocusInput}
            onKeyDown={onBackSpace}
            placeholder={placeholder}
            error={typeof error === 'string' ? error : error?.startDateInput}
          />
          {selectRange && (
            <TextField
              readOnly
              label={endLabel}
              required={required}
              disabled={disabled}
              onFocus={onFocusInput}
              onKeyDown={onBackSpace}
              value={toRangeInputDate}
              id={`${id}ToRangeInputDate`}
              placeholder={endPlaceholder}
              error={(error as RangeError)?.endDateInput}
            />
          )}
        </div>
        {open && (
          <div
            className={classnames('w-350', calendarWrapperClassName)}
            style={{
              position: strategy,
              left: x ?? 0,
              top: y ?? 0,
            }}
            ref={floating}
          >
            <div className="relative">
              <div
                ref={arrowRef}
                className={classnames(
                  ' h-0 w-0 border-solid border-l-transparent border-r-transparent border-l-8 border-r-8',
                  y && {
                    'border-b-8 border-b-gray-dark600 -top-2': y > 0,
                    'border-t-8 border-t-gray-dark700 -bottom-2': y < 0,
                  }
                )}
                style={{
                  position: strategy,
                  left: arrowData?.x ?? 0,
                }}
              />
              <Calendar
                {...props}
                selectRange={selectRange}
                value={internalValue}
                onChange={onChangeCalendar}
                locale={locale}
                showNeighboringMonth={false}
                showFixedNumberOfWeeks={false}
                showDoubleView={selectRange}
                allowPartialRange
                defaultView="month"
                minDetail="month"
              />
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default DatePickerField;
