import { SyntheticEvent, useMemo } from 'react';

import { useHistory } from 'react-router-dom';

import { MdCheck, MdCircle } from 'react-icons/md';

import { Tooltip } from 'ui/components/tooltip';
import SendEmailButton from '../SendEmailButton';
import { TableBodyColumn } from 'ui/components/table';
import TableBodyRow from 'ui/components/table/TableBodyRow';

import SuccessIcon from 'assets/icons/list/SuccessIcon';

import { ChargeId, Charges, ChargeStatus } from 'charges/models/charges';

import { EconomyReport } from 'economyReport/model/economyReport';

import {
  convertToBRL,
  convertToDecimal,
  formatDateToLocaleDateString,
} from 'utils/form';

import UnavailableIcon from 'assets/icons/list/UnavailableIcon';

import { EditButton } from 'dashboard/components/table/buttons';

import { ChargesUpdateModalData } from '../modal/ChargesUpdateModal';

type Props = {
  charges: Charges;
  disableEditButton: boolean;
  onClickEditButton: ({
    chargeId,
    consumerUnit,
    powerDistributionUnitBillData,
  }: ChargesUpdateModalData) => void;
  onClickSendEmailButton: (chargeId: ChargeId) => void;
};

const BILLING_CYCLES_ROUTE = '/dashboard/billing-cycle/billing-cycles';

const DATE_CONFIG_OPTIONS = {
  options: { month: '2-digit', year: 'numeric' } as Intl.DateTimeFormatOptions,
};

export default function ChargesTableItem({
  charges: {
    id,
    chargeStatus,
    consumerUnit,
    chargeDueDate,
    chargeInterest,
    chargeInvoiceUrl,
    chargeCreatedDate,
    chargePaymentDate,
    chargeClientPaymentDate,
    chargeTotalBilled,
    cooperativeMember,
    chargeTotalCharged,
    chargeAppTotalFees,
    chargeCooperativeTotalValue,
    powerDistributionUnitBillData,
    chargeGenerationUnitTotalValue,
    economyReport: { economyValue = '0' } = {} as EconomyReport,
  },
  onClickEditButton,
  onClickSendEmailButton,
}: Props) {
  const { push } = useHistory();

  const DISABLE_SEND_EMAIL = useMemo(() => {
    return (
      chargeStatus === ChargeStatus.PAYED ||
      chargeStatus === ChargeStatus.EXPIRED ||
      chargeStatus === ChargeStatus.CANCELED
    );
  }, [chargeStatus]);

  const ECONOMY_PERCENTAGE_VALUE = useMemo(() => {
    return convertToDecimal(
      (
        (+economyValue /
          (+chargeTotalCharged +
            +economyValue +
            +powerDistributionUnitBillData?.billTotalValue)) *
        100
      ).toFixed(2)
    );
  }, [economyValue, chargeTotalCharged, powerDistributionUnitBillData]);

  const STATUS = useMemo(() => {
    const status = {
      [ChargeStatus.PENDING]: 'Pendente',
      [ChargeStatus.OPENED]: 'Em Aberto',
      [ChargeStatus.EXPIRED]: (
        <div className="flex items-center justify-start gap-4">
          <p className="font-bold text-red-500">Expirado</p>
        </div>
      ),
      [ChargeStatus.CANCELED]: 'Cancelado',
      [ChargeStatus.QUEUED]: 'Processando',
      [ChargeStatus.DEFAULTER]: (
        <div className="flex items-center justify-start gap-4">
          <p className="font-semibold text-red-a1">Inadimplente</p>
        </div>
      ),
      [ChargeStatus.OVERDUE]: (
        <div className="flex items-center justify-start gap-4">
          <MdCircle className="text-red-500" size={10} />
          <p className="font-semibold">Vencido</p>
        </div>
      ),
      [ChargeStatus.PAYED]: (
        <div className="flex items-center justify-start gap-2">
          <MdCheck className="text-primary" size={20} />
          <p className="text-primary font-semibold">Pago</p>
        </div>
      ),
    };

    return status[chargeStatus];
  }, [chargeStatus]);

  const handleClickEditButton = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    onClickEditButton({
      chargeId: id,
      consumerUnit,
      powerDistributionUnitBillData,
    });
  };

  const handleClickSendEmail = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    onClickSendEmailButton(id);
  };

  return (
    <TableBodyRow dataTestId={`charges-row-id-${id}`}>
      <TableBodyColumn>
        {formatDateToLocaleDateString(
          powerDistributionUnitBillData.billReferenceDate,
          {
            year: DATE_CONFIG_OPTIONS.options.year,
            month: DATE_CONFIG_OPTIONS.options.month,
          }
        )}
      </TableBodyColumn>
      <TableBodyColumn>
        {cooperativeMember?.cooperativeMemberLegalName}
      </TableBodyColumn>
      <TableBodyColumn>
        {consumerUnit?.consumerUnitPowerDistributionUnitIdentifier}
      </TableBodyColumn>
      <TableBodyColumn>
        <Tooltip
          arrowPosition="top"
          label={
            <div className="flex flex-col items-start justify-center">
              <p className="font-light text-sm">
                NEX:
                <span className="font-semibold leading-5 ml-2">
                  {convertToBRL(chargeAppTotalFees)}
                </span>
              </p>
              <p className="font-light text-sm ">
                Cooperativa:
                <span className="font-semibold leading-5 ml-2">
                  {convertToBRL(chargeCooperativeTotalValue)}
                </span>
              </p>
              <p className="font-light text-sm">
                Gerador:
                <span className="font-semibold leading-5 ml-2">
                  {convertToBRL(chargeGenerationUnitTotalValue)}
                </span>
              </p>
            </div>
          }
          placement="bottom"
          offset={{ mainAxis: 5 }}
        >
          <p className="underline">{convertToBRL(chargeTotalBilled)}</p>
        </Tooltip>
      </TableBodyColumn>
      <TableBodyColumn>
        <Tooltip
          arrowPosition="top"
          label={
            <div className="flex flex-col gap-4 items-start justify-center">
              <div className="flex flex-col items-start justify-center">
                <p className="font-light text-sm">NEX:</p>
                <p className="font-light text-sm">
                  Juros:
                  <span className="font-semibold leading-5 ml-2">-</span>
                </p>
                <p className="font-light text-sm">
                  Multa:
                  <span className="font-semibold leading-5 ml-2">-</span>
                </p>
              </div>
              <div className="flex flex-col items-start justify-center">
                <p className="font-light text-sm">Gerador:</p>
                <p className="font-light text-sm">
                  Juros:
                  <span className="font-semibold leading-5 ml-2">-</span>
                </p>
                <p className="font-light text-sm">
                  Multa:
                  <span className="font-semibold leading-5 ml-2">-</span>
                </p>
              </div>
            </div>
          }
          placement="bottom"
          offset={{ mainAxis: 5 }}
        >
          <p className="underline">{convertToBRL(chargeInterest)}</p>
        </Tooltip>
      </TableBodyColumn>
      <TableBodyColumn>
        {formatDateToLocaleDateString(chargeCreatedDate)}
      </TableBodyColumn>
      <TableBodyColumn>
        {formatDateToLocaleDateString(chargeDueDate)}
      </TableBodyColumn>
      <TableBodyColumn>
        {formatDateToLocaleDateString(chargeClientPaymentDate)}
      </TableBodyColumn>
      <TableBodyColumn>{STATUS}</TableBodyColumn>
      <TableBodyColumn>
        <Tooltip
          placement="bottom"
          arrowPosition="top"
          label={'Creditação'}
          offset={{ mainAxis: 5 }}
        >
          <span
            className="underline font-bold cursor-pointer"
            onClick={() => {
              push({
                pathname: BILLING_CYCLES_ROUTE,
                state: {
                  consumerUnitPowerDistributionUnitIdentifier:
                    consumerUnit.consumerUnitPowerDistributionUnitIdentifier,
                  consumerUnitBillingCycleDate:
                    powerDistributionUnitBillData.billReferenceDate,
                },
              });
            }}
          >
            {convertToBRL(economyValue)} ({ECONOMY_PERCENTAGE_VALUE}%)
          </span>
        </Tooltip>
      </TableBodyColumn>
      <TableBodyColumn>
        {!!chargeInvoiceUrl ? (
          <a href={chargeInvoiceUrl} target="_blank" rel="noreferrer">
            <SuccessIcon />
          </a>
        ) : (
          <UnavailableIcon />
        )}
      </TableBodyColumn>
      <TableBodyColumn className="flex gap-2">
        <Tooltip
          placement="bottom"
          arrowPosition="top"
          label={'Editar cobrança'}
          offset={{ mainAxis: 5 }}
        >
          <EditButton
            onClick={handleClickEditButton}
            disabled={chargeStatus === ChargeStatus.PAYED}
          />
        </Tooltip>
        <Tooltip
          placement="bottom"
          arrowPosition="top"
          label={'Reenviar e-mail'}
          offset={{ mainAxis: 5 }}
        >
          <SendEmailButton
            disabled={DISABLE_SEND_EMAIL}
            onClick={handleClickSendEmail}
          />
        </Tooltip>
      </TableBodyColumn>
    </TableBodyRow>
  );
}
