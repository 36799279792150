import React from 'react';
import CheckboxInput from 'ui/components/form/CheckboxInput';
import { Props as BaseProps } from 'ui/components/form/BaseInput';

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement> &
    BaseProps & {
      inputLabel: string;
      label?: string;
      wrapperClassName?: string;
    },
  HTMLInputElement
>;

export const CheckboxField = React.forwardRef<HTMLInputElement, Props>(
  (
    { disabled, className, error, wrapperClassName, inputLabel, label, ...props },
    ref
  ) => {
    return (
      <fieldset className={wrapperClassName}>
        {label && (
          <span className="block text-sm font-medium text-gray-dark400 mb-3">
            {label}
          </span>
        )}

        <CheckboxInput
          ref={ref}
          error={error}
          label={inputLabel}
          disabled={disabled}
          {...props}
        />
      </fieldset>
    );
  }
);

export default CheckboxField;
