import { gql } from '@apollo/client';

import {
  ChargeId,
  ChargesUpdate,
  ChargeUpdateRequestType,
} from 'charges/models/charges';

export interface ChargeUpdateMutationInput {
  id: ChargeId;
  sendEmail: boolean;
  newDueDate?: string;
  chargeUpdateReason: string;
  keepInterestAndFine: boolean;
  keepEarlyPaymentDiscount: boolean;
  chargeUpdateRequestingUser: string;
  chargeUpdateRequestType: ChargeUpdateRequestType;
}

export interface ChargeUpdateMutationVariables {
  chargeUpdateInput: ChargeUpdateMutationInput;
}

export interface ChargeUpdateCreated {
  chargeUpdate: ChargesUpdate;
}

export const CHARGE_UPDATE_MUTATION = gql`
  mutation chargeUpdate($chargeUpdateInput: ChargeUpdateInput!) {
    chargeUpdate(input: $chargeUpdateInput) {
      message
    }
  }
`;
