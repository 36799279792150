export type CooperativeMemberId = number;

export enum CooperativeMemberEntityType {
  INDIVIDUAL = 'INDIVIDUAL',
  COMPANY = 'COMPANY',
}

export enum CooperativeMemberModalityType {
  HEADQUARTER = 'HEADQUARTER',
  BRANCH = 'BRANCH',
}

export interface CooperativeMemberSelect {
  id: CooperativeMemberId;
  cooperativeMemberEmail: string;
  cooperativeMemberLegalName: string;
  cooperativeMemberDocumentId: string;
  cooperativeMemberOptionalEmail?: string | null;
  cooperativeMemberEntityType: CooperativeMemberEntityType;
  cooperativeMemberModalityType?: CooperativeMemberModalityType;
  cooperative: CooperativeSelect;
}

export interface CooperativeMember {
  id: CooperativeMemberId;
  cooperativeMemberEmail: string;
  cooperativeMemberLegalName: string;
  cooperativeMemberDocumentId: string;
  cooperativeMemberOptionalEmail?: string[] | null;
  cooperativeMemberEntityType: CooperativeMemberEntityType;
  cooperativeMemberModalityType: CooperativeMemberModalityType | null;
  cooperative: CooperativeSelect;
}

export interface CooperativeSelect {
  id: number;
  cooperativeLegalName: string;
}
