import { gql } from '@apollo/client';
import { CommercialAgreement } from 'commercialAgreements/models/commercialAgreement';

export interface CommercialAgreementQueryVariables {
  id: string;
}
export interface CommercialAgreementUpdated {
  commercialAgreement: CommercialAgreement;
}

export const CommercialAgreementTypename = 'CommercialAgreement';

export const COMMERCIAL_AGREEMENT_QUERY = gql`
  query commercialAgreement($id: ID!) {
    commercialAgreement(id: $id) {
      id
      commercialAgreementStartValidity
      commercialAgreementHasPisCofins
      commercialAgreementHasExtraRemuneration
      commercialAgreementReceiptRule
      commercialAgreementLeaseValue
      commercialAgreementOemValue
      commercialAgreementHasOem
      commercialAgreementDiscountMin
      commercialAgreementDiscountMax
      commercialAgreementBaseCostPerCreditIcms
      commercialAgreementBaseCostPerCredit
      generationUnit {
        id
        generationUnitLegalName
      }
    }
  }
`;
