import { CommercialAgreement } from 'commercialAgreements/models/commercialAgreement';
import { TableBodyColumn } from 'ui/components/table';
import TableBodyRow from 'ui/components/table/TableBodyRow';
import { EditButton, RemoveButton } from 'dashboard/components/table/buttons';
import { SyntheticEvent } from 'react';
import { MdDone } from 'react-icons/md';
import { Tooltip } from 'ui/components/tooltip';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

type Props = {
  commercialAgreement: CommercialAgreement;
  onClickEditButton: (id: number) => void;
  onClickRemoveButton: (id: number) => void;
  disableEditButton: boolean;
  disableRemoveButton: boolean;
};

interface StartValidityFormatConfig {
  locale: string;
  options: Intl.DateTimeFormatOptions;
}

const START_VALIDITY_FORMAT_CONFIG: StartValidityFormatConfig = {
  locale: 'pt-BR',
  options: { month: '2-digit', year: 'numeric' },
};

const formatCommercialAgreementStartValidity = (date: Date) => {
  const currentDate = new Date(date);
  currentDate.setDate(currentDate.getDate() + 1);
  return currentDate.toLocaleDateString(
    START_VALIDITY_FORMAT_CONFIG.locale,
    START_VALIDITY_FORMAT_CONFIG.options
  );
};

export const TRANSLATE_RECEIPT_RULE_TO_PORTUGUESE = {
  WEEKLY: 'Transferência semanal',
  MONTHLY: 'Transferência mensal',
  D20: 'Transferência D+20',
  MANUAL: 'Saque manual no painel do gateway',
};

export default function CommercialAgreementsTableItem({
  commercialAgreement: {
    id,
    commercialAgreementStartValidity,
    commercialAgreementHasPisCofins,
    commercialAgreementHasExtraRemuneration,
    commercialAgreementReceiptRule,
    commercialAgreementLeaseValue,
    commercialAgreementOemValue,
    commercialAgreementHasOem,
    commercialAgreementDiscountMax,
    commercialAgreementDiscountMin,
    commercialAgreementBaseCostPerCreditIcms,
    commercialAgreementBaseCostPerCredit,
    commercialAgreementValidityStatus,
    generationUnit: { generationUnitLegalName, id: generationUnitId },
  },

  onClickEditButton,
  onClickRemoveButton,
  disableEditButton,
  disableRemoveButton,
}: Props): JSX.Element {
  const handleClickEditButton = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    onClickEditButton(id);
  };

  const handleClickRemoveButton = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    onClickRemoveButton(id);
  };

  const DASHBOARD_GENERATION_UNIT_EDITI_ROUTE = `/dashboard/business-entities/generation-units/${generationUnitId}/edit`;

  const validityStatus = commercialAgreementValidityStatus;
  const commercialAgreementValidityStatusActual = validityStatus === 'ACTUAL';
  const commercialAgreementValidityStatusExpired = validityStatus === 'EXPIRED';
  const commercialAgreementValidityStatusFuture = validityStatus === 'FUTURE';

  const commercialAgreementStatus = {
    Ativo: commercialAgreementValidityStatusActual,
    Pendente: commercialAgreementValidityStatusFuture,
    Expirado: commercialAgreementValidityStatusExpired,
  };

  const commercialAgreementHasOemValue =
    (commercialAgreementHasOem && 'Discriminação de O&M') || 'Nenhuma seleção';

  const commercialAgreementHasExtraRemunerationValue =
    (commercialAgreementHasExtraRemuneration && 'SIM') || 'NÃO';

  const commercialAgreementReceiptRuleValue =
    TRANSLATE_RECEIPT_RULE_TO_PORTUGUESE[commercialAgreementReceiptRule];

  const commercialAgreementHasPisCofinsValue =
    (commercialAgreementHasPisCofins && 'SIM') || 'NÃO';

  const ICMS_VALUE = 'SIM';

  return (
    <TableBodyRow dataTestId={`commercial-agreement-row-id-${id}`}>
      <TableBodyColumn className="font-bold cursor-pointer underline hover:text-gray-dark400">
        {
          <Link to={DASHBOARD_GENERATION_UNIT_EDITI_ROUTE}>
            {generationUnitLegalName}
          </Link>
        }
      </TableBodyColumn>
      <TableBodyColumn className="font-n">
        <Tooltip
          label={
            <>
              <p className="font-medium text-sm py-1">Política de recebimento:</p>
              <p className="font-bold text-xxs">
                <MdDone className="inline-block mr-2" />
                {commercialAgreementReceiptRuleValue}
              </p>
              <p className="font-medium text-sm py-1">Remuneração adicional:</p>
              <p className="font-light text-xxs pb-1">
                {' '}
                Geração ponta:
                <span className="font-bold p-2">
                  {commercialAgreementHasExtraRemunerationValue}
                </span>
              </p>
              <p className="font-light text-xxs pb-1">
                {' '}
                Isenção de PIS/COFINS:
                <span className="font-bold p-2">
                  {commercialAgreementHasPisCofinsValue}
                </span>
              </p>
              <p className="font-light text-xxs pb-1">
                {' '}
                Isenção de ICMS:
                <span className="font-bold p-2">{ICMS_VALUE}</span>
              </p>
              <p className="font-medium text-sm py-1">Custo operacional:</p>
              <p className="font-bold text-xxs">
                <MdDone className="inline-block mr-2" />
                <span>{commercialAgreementHasOemValue}</span>
              </p>
            </>
          }
          placement="bottom"
          arrowPosition="top"
        >
          {formatCommercialAgreementStartValidity(commercialAgreementStartValidity)}
        </Tooltip>
      </TableBodyColumn>
      <TableBodyColumn className="font-normal">
        {`${commercialAgreementBaseCostPerCreditIcms} R$/kWh`}
      </TableBodyColumn>
      <TableBodyColumn className="font-normal">
        {`${commercialAgreementBaseCostPerCredit} R$/kWh`}
      </TableBodyColumn>
      <TableBodyColumn className="font-normal">
        {`R$ ${commercialAgreementLeaseValue}`}
      </TableBodyColumn>
      <TableBodyColumn className="font-normal">
        {`${commercialAgreementDiscountMin}%`}
      </TableBodyColumn>
      <TableBodyColumn className="font-normal">
        {`${commercialAgreementDiscountMax}%`}
      </TableBodyColumn>
      <TableBodyColumn className="font-normal">
        <span
          className={classNames({
            'font-bold text-sm': commercialAgreementValidityStatusFuture,
            'text-primary': commercialAgreementValidityStatusActual,
            'text-sm': commercialAgreementValidityStatusExpired,
          })}
        >
          {commercialAgreementValidityStatusActual && (
            <MdDone className="inline-block mr-2" />
          )}
          {Object.entries(commercialAgreementStatus)
            .filter(([_key, value]) => value)
            .map(([key]) => key)}
        </span>
      </TableBodyColumn>
      <TableBodyColumn className="font-normal flex items-center justify-center">
        {commercialAgreementValidityStatusFuture && (
          <>
            <EditButton
              onClick={handleClickEditButton}
              disabled={disableEditButton}
            />
            <RemoveButton
              onClick={handleClickRemoveButton}
              disabled={disableRemoveButton}
            />
          </>
        )}
      </TableBodyColumn>
    </TableBodyRow>
  );
}
