import classNames from 'classnames';
import React from 'react';
import { EmailInput } from 'ui';
import { Props as BaseProps } from 'ui/components/form/BaseInput';

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement> &
    BaseProps & {
      label?: string;
    },
  HTMLInputElement
>;

export const EmailField = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      id,
      name,
      mask,
      type,
      label,
      error,
      className,
      placeholder,
      autoComplete,
      ...props
    },
    ref
  ) => {
    return (
      <div>
        <label htmlFor={id} className="block text-sm font-normal text-white">
          {label}
        </label>
        <div className="mt-1">
          <EmailInput
            id={id}
            ref={ref}
            mask={mask}
            name={name}
            type={type}
            error={error}
            placeholder={placeholder}
            autoComplete={autoComplete}
            className={classNames(
              'mt-2 focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-dark500 bg-gray-dark600 shadow-none rounded-md hover:border-primary placeholder-gray-dark500 focus-within:text-gray-dark400 text-gray-dark500',
              className
            )}
            {...props}
          />
        </div>
      </div>
    );
  }
);

export default EmailField;
