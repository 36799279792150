import { gql } from '@apollo/client';
import {
  CooperativeMember,
  CooperativeMemberEntityType,
  CooperativeMemberModalityType,
} from 'cooperatives/models/cooperativeMember';

export interface CooperativeMemberUpdateMutationInput {
  id: number;
  cooperativeMemberEmail: string;
  cooperativeMemberLegalName: string;
  cooperativeMemberDocumentId: string;
  cooperativeMemberOptionalEmail?: string[] | null;
  cooperativeMemberEntityType: CooperativeMemberEntityType;
  cooperativeMemberModalityType: CooperativeMemberModalityType | null;
}

export interface CooperativeMemberUpdateMutationVariables {
  cooperativeMemberUpdateInput: CooperativeMemberUpdateMutationInput;
}

export interface CooperativeMemberUpdated {
  cooperativeMemberUpdate: CooperativeMember;
}

export const COOPERATIVE_MEMBER_UPDATE_MUTATION = gql`
  mutation cooperativeMemberUpdate(
    $cooperativeMemberUpdateInput: CooperativeMemberUpdateInput!
  ) {
    cooperativeMemberUpdate(input: $cooperativeMemberUpdateInput) {
      id
      cooperativeMemberEmail
      cooperativeMemberLegalName
      cooperativeMemberEntityType
      cooperativeMemberDocumentId
      cooperativeMemberModalityType
      cooperativeMemberOptionalEmail
      cooperative {
        id
        cooperativeLegalName
      }
    }
  }
`;
