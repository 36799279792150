import { ApolloError, useMutation } from '@apollo/client';
import has from 'lodash/has';
import { useEffect } from 'react';
import { ErrorOption, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TariffFlagTariffFormFields, {
  FormFields,
} from 'tariffFlagTariffs/components/form/TariffFlagTariffFormFields';
import {
  TariffFlagTariffCreated,
  TariffFlagTariffMutationVariables,
  TARIFF_FLAG_TARIFF_CREATE_MUTATION,
} from 'tariffFlagTariffs/graphql/tariffFlagTariffCreateMutation';
import { Button } from 'ui';
import { useLoading } from 'ui/contexts/overlay/Loading';
import { ToastProps } from 'ui/contexts/overlay/Toast';
import useToastContext from 'ui/hooks/useToast';
import { ModalFormProps } from 'ui/models/overlay';
import { dateWithoutTimezone, setFormError } from 'utils/form';
import { unMaskFormFields } from 'utils/unmask';

type Props = {
  ModalOverlay: (any: ModalFormProps) => JSX.Element;
  onClickCloseModal: () => void;
};

const CREATE_ERROR_TOAST: ToastProps = {
  text: 'Houve um erro ao tentar criar bandeira tarifária.',
  title: 'Algo deu errado!',
  variant: 'danger',
};

const CREATE_SUCCESS_TOAST: ToastProps = {
  title: 'Successo!',
  variant: 'primary',
  text: 'Nova bandeira tarifária cadastrada com sucesso.',
};

export default function CreateTariffFlagTariff({
  ModalOverlay,
  onClickCloseModal,
}: Props) {
  const {
    handleSubmit,
    setError,
    clearErrors,
    control,
    register,
    formState: { errors },
  } = useForm<FormFields>();
  const { showLoading, closeLoading, LoadingOverlay } = useLoading();
  const { addToast } = useToastContext();
  const { t } = useTranslation();

  const [tariffFlagTariffCreateMutation, { loading: loadingCreate }] = useMutation<
    TariffFlagTariffCreated,
    TariffFlagTariffMutationVariables
  >(TARIFF_FLAG_TARIFF_CREATE_MUTATION, {
    onError(error: ApolloError) {
      if (has(error.graphQLErrors[0], 'details')) {
        onClickCloseModal();

        addToast(CREATE_ERROR_TOAST);
      }

      setFormError(
        error,
        (field: string, error: ErrorOption) => {
          setError(field as keyof FormFields, error);

          setTimeout(() => clearErrors(), 2500);
        },
        t
      );
    },
    onCompleted() {
      onClickCloseModal();

      addToast(CREATE_SUCCESS_TOAST);
    },
  });

  const onSubmit: SubmitHandler<FormFields> = (tariffFlagTariffCreateInput) => {
    const tariffFlagModalityValidityDate =
      tariffFlagTariffCreateInput.tariffFlagTariffValidityPeriod;
    const convertTariffFlagTariffValidityDateTofirstDay = new Date(
      tariffFlagModalityValidityDate.getFullYear(),
      tariffFlagModalityValidityDate.getMonth(),
      1
    );

    tariffFlagTariffCreateInput = unMaskFormFields(tariffFlagTariffCreateInput);

    tariffFlagTariffCreateMutation({
      variables: {
        tariffFlagTariffCreateInput: {
          tariffFlagTariffModalityFlag:
            tariffFlagTariffCreateInput.tariffFlagTariffModalityFlag,
          tariffFlagTariffValidityPeriod: dateWithoutTimezone(
            convertTariffFlagTariffValidityDateTofirstDay
          )
            .toISOString()
            .split('T')[0],
          tariffFlagTariffFee: tariffFlagTariffCreateInput.tariffFlagTariffFee,
        },
      },
    });
  };

  const isLoading = loadingCreate;

  useEffect(() => {
    if (isLoading) {
      showLoading();
      return;
    }

    closeLoading();
  }, [isLoading, showLoading, closeLoading]);

  return (
    <ModalOverlay title="Nova Tarifa" variant="primary">
      <form onSubmit={handleSubmit(onSubmit)}>
        <TariffFlagTariffFormFields
          errors={errors}
          control={control}
          register={register}
        />

        <div className="flex flex-row-reverse mt-8">
          <Button type="submit" variant="primaryGreen" size="md">
            Criar
          </Button>
          <Button
            variant="primaryGray"
            className="mr-2"
            size="md"
            onClick={onClickCloseModal}
          >
            Cancelar
          </Button>
        </div>
      </form>

      <LoadingOverlay />
    </ModalOverlay>
  );
}
