import {
  IcmsConsumerUnitConsumptionClassType,
  IcmsTax,
} from 'icmsTaxes/models/icmsTaxes';
import { TableBodyColumn, TableBodyRow } from 'ui/components/table';
import { EditButton, RemoveButton } from 'dashboard/components/table/buttons';

import { Tooltip } from 'ui/components/tooltip';
import { convertToDecimal, formatDateToLocaleDateString } from 'utils/form';

type Props = {
  icmsTax: IcmsTax;
  onClickEditButton: (id: number) => void;
  onClickRemoveButton: (id: number) => void;
};

export default function IcmsTableItem({
  icmsTax: {
    id,
    icmsCountryState,
    icmsConsumerUnitConsumptionClass,
    icmsValidityStartDate,
    icmsValidityEndDate,
    icmsRangesFee,
  },
  onClickEditButton,
  onClickRemoveButton,
}: Props) {
  const handleClickEditButton = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    onClickEditButton(id);
  };

  const handleClickRemoveButton = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    onClickRemoveButton(id);
  };

  const fillEmptyIcmsRangesFee = () => {
    const icmsRangesFeeLength = icmsRangesFee.length;

    if (icmsRangesFeeLength < 5) {
      const emptyIcmsRangesFee = Array.from(
        { length: 5 - icmsRangesFeeLength },
        () => ({
          consumptionRangePercentage: '',
          consumptionKwhStartRange: '',
          consumptionKwhEndRange: '',
        })
      );

      return [...icmsRangesFee, ...emptyIcmsRangesFee];
    }

    return icmsRangesFee;
  };

  const TRANSLATE_ICMS_CONSUMER_UNIT_CONSUMPTION_CLASS = {
    COMMERCIAL: IcmsConsumerUnitConsumptionClassType.COMMERCIAL,
    RESIDENTIAL: IcmsConsumerUnitConsumptionClassType.RESIDENTIAL,
    INDUSTRIAL: IcmsConsumerUnitConsumptionClassType.INDUSTRIAL,
  };

  return (
    <TableBodyRow dataTestId={`icms-taxes-row-id-${id}`}>
      <TableBodyColumn className="font-bold">
        {
          TRANSLATE_ICMS_CONSUMER_UNIT_CONSUMPTION_CLASS[
            icmsConsumerUnitConsumptionClass as keyof typeof TRANSLATE_ICMS_CONSUMER_UNIT_CONSUMPTION_CLASS
          ]
        }
      </TableBodyColumn>
      <TableBodyColumn className="text-center">{icmsCountryState}</TableBodyColumn>
      <TableBodyColumn>{`${formatDateToLocaleDateString(
        icmsValidityStartDate as unknown as string
      )} - ${formatDateToLocaleDateString(
        icmsValidityEndDate as unknown as string
      )}`}</TableBodyColumn>
      {fillEmptyIcmsRangesFee().map((icmsRange, index) => (
        <TableBodyColumn key={index} className="font-bold">
          <Tooltip
            label={
              <>
                {convertToDecimal(icmsRange.consumptionKwhStartRange as string)} →{' '}
                {convertToDecimal(icmsRange.consumptionKwhEndRange as string)} kWh
              </>
            }
            placement="bottom"
            arrowPosition="top"
          >
            {(!!icmsRange.consumptionRangePercentage &&
              icmsRange.consumptionRangePercentage + '%') ||
              '-'}
          </Tooltip>
        </TableBodyColumn>
      ))}

      <TableBodyColumn className="flex gap-x-3 items-end justify-end">
        <EditButton onClick={handleClickEditButton} />
        <RemoveButton onClick={handleClickRemoveButton} />
      </TableBodyColumn>
    </TableBodyRow>
  );
}
