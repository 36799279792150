import { gql } from '@apollo/client';
import { IcmsRangesFeeType, IcmsTax } from 'icmsTaxes/models/icmsTaxes';

export interface IcmsTaxUpdateMutationInput {
  id: number;
  icmsValidityStartDate: string;
  icmsValidityEndDate: string;
  icmsRangesFee: IcmsRangesFeeType[];
}

export interface IcmsTaxUpdateMutationVariables {
  icmsTaxUpdateInput: IcmsTaxUpdateMutationInput;
}

export interface IcmsTaxUpdated {
  icmsTaxUpdate: Omit<
    IcmsTax,
    'icmsCountryState' | 'icmsConsumerUnitConsumptionClass'
  >;
}

export const ICMS_TAX_UPDATE_MUTATION = gql`
  mutation icmsTaxUpdate($icmsTaxUpdateInput: IcmsTaxUpdateInput!) {
    icmsTaxUpdate(input: $icmsTaxUpdateInput) {
      id
      icmsValidityStartDate
      icmsValidityEndDate
      icmsRangesFee {
        consumptionRangePercentage
        consumptionKwhStartRange
        consumptionKwhEndRange
      }
    }
  }
`;
