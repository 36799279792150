import * as React from 'react';
import { PowerDistributionUnit } from 'powerDistributionUnits/models/powerDistributionUnit';
import { TableBodyColumn } from 'ui/components/table';
import { EditButton, RemoveButton } from 'dashboard/components/table/buttons';
import TableBodyRow from 'ui/components/table/TableBodyRow';
import { MdDone } from 'react-icons/md';
import masks from 'utils/masks';

type Props = {
  powerDistributionUnit: PowerDistributionUnit;
  onClickEditButton: (id: number) => void;
  onClickRemoveButton: (id: number) => void;
};

export default function PowerDistributionUnitsTableItem({
  powerDistributionUnit: {
    id,
    powerDistributionUnitIcmsFlag,
    powerDistributionUnitLegalName,
    powerDistributionUnitDocumentId,
    powerDistributionUnitCountryState,
    powerDistributionUnitPisCofinsFlag,
  },
  onClickEditButton,
  onClickRemoveButton,
}: Props) {
  const handleClickEditButton = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    onClickEditButton(id);
  };

  const handleClickRemoveButton = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    onClickRemoveButton(id);
  };

  const HAS_ICMS = () => {
    return (
      <>
        {powerDistributionUnitIcmsFlag ? (
          <span className="text-primary">
            <MdDone className="inline-block mr-2" />
            Possuí
          </span>
        ) : (
          <span>Não Possuí</span>
        )}
      </>
    );
  };

  const HAS_PISCOFINS = () => {
    return (
      <>
        {powerDistributionUnitPisCofinsFlag ? (
          <span className="text-primary">
            <MdDone className="inline-block mr-2" />
            Possuí
          </span>
        ) : (
          <span>Não Possuí</span>
        )}
      </>
    );
  };

  return (
    <TableBodyRow dataTestId={`power-distribution-units-row-id-${id}`}>
      <TableBodyColumn className="font-bold">
        {powerDistributionUnitLegalName}
      </TableBodyColumn>
      <TableBodyColumn className="font-bold">
        {masks.document(powerDistributionUnitDocumentId)}
      </TableBodyColumn>
      <TableBodyColumn className="font-normal">
        {powerDistributionUnitCountryState}
      </TableBodyColumn>
      <TableBodyColumn className="font-normal">{HAS_ICMS()}</TableBodyColumn>
      <TableBodyColumn className="font-normal">{HAS_PISCOFINS()}</TableBodyColumn>
      <TableBodyColumn className="flex gap-x-3 items-end justify-end">
        <EditButton onClick={handleClickEditButton} />
        <RemoveButton onClick={handleClickRemoveButton} />
      </TableBodyColumn>
    </TableBodyRow>
  );
}
