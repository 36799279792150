import { Control, Controller, FieldError, UseFormRegister } from 'react-hook-form';
import omit from 'lodash/omit';

import { TariffFlagModalityModalityFlagType } from 'tariffFlagTariffs/models/tariffFlagModality';
import DatePickerFieldSingleView from 'ui/form/DatePickerFieldSingleView';
import SelectField from 'ui/form/SelectField';

export type FormFields = {
  tariffFlagModalityModalityFlag: TariffFlagModalityModalityFlagType;
  tariffFlagModalityValidityDate: Date;
};

export type FormErrors = {
  tariffFlagModalityModalityFlag?: FieldError | undefined;
  tariffFlagModalityValidityDate?: FieldError | undefined;
};

const tariffFlagModalityModalityFlagOptions = [
  {
    key: 'GREEN',
    value: 'VERDE',
  },
  {
    key: 'YELLOW',
    value: 'AMARELA',
  },
  {
    key: 'RED_ON_PEAK',
    value: 'VERMELHA PT1',
  },
  {
    key: 'RED_ON_PEAK_TWO',
    value: 'VERMELHA PT2',
  },
];

export default function TariffFlagModalityFormFields({
  errors,
  control,
  register,
}: {
  errors: FormErrors;
  control: Control<FormFields>;
  register: UseFormRegister<FormFields>;
}) {
  return (
    <div className="grid grid-cols-2 gap-8">
      <Controller
        render={({ field }) => (
          <DatePickerFieldSingleView
            required
            {...omit(field, 'ref')}
            error={errors.tariffFlagModalityValidityDate?.message}
            id="tariffFlagModalityValidityDate"
            labelDate="Período"
            placeholder="Mês e ano"
            options={{ month: '2-digit', year: '2-digit' }}
          />
        )}
        name="tariffFlagModalityValidityDate"
        control={control}
        rules={{ required: 'Campo obrigatório' }}
      />

      <Controller
        name="tariffFlagModalityModalityFlag"
        control={control}
        rules={{ required: 'Campo obrigatório' }}
        render={({ field }) => (
          <SelectField
            required
            {...omit(field, 'ref')}
            error={errors.tariffFlagModalityModalityFlag?.message}
            label="Bandeira"
            emptyOptionLabel="Selecionar"
            id="tariffFlagModalityModalityFlag"
            options={tariffFlagModalityModalityFlagOptions}
          />
        )}
      />
    </div>
  );
}
