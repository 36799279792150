import { TariffGroupProps } from 'tariffGroups/models/tariffGroup';
import TariffGroupTableItem from './TariffGroupTableItem';
import Table from 'ui/components/table/Table';
import TableHead from 'ui/components/table/TableHead';
import TableHeadColumn from 'ui/components/table/TableHeadColumn';
import TableBody from 'ui/components/table/TableBody';

type Props = {
  tariffGroups: TariffGroupProps[];
  onClickEditButton: (id: number) => void;
  onClickRemoveButton: (id: number) => void;
};

export default function TariffGroupsTable({
  tariffGroups,
  onClickEditButton,
  onClickRemoveButton,
}: Props) {
  return (
    <Table>
      <TableHead>
        <TableHeadColumn>Concessionária</TableHeadColumn>
        <TableHeadColumn>Validade</TableHeadColumn>
        <TableHeadColumn>Grupo</TableHeadColumn>
        <TableHeadColumn>Modalidade</TableHeadColumn>
        <TableHeadColumn>Consumo TE</TableHeadColumn>
        <TableHeadColumn>Consumo TUSD</TableHeadColumn>
        <TableHeadColumn>Demanda</TableHeadColumn>
        <TableHeadColumn className="text-right">Ações</TableHeadColumn>
      </TableHead>
      <TableBody>
        {tariffGroups.map((tariffGroup: TariffGroupProps) => (
          <TariffGroupTableItem
            key={tariffGroup.id}
            tariffGroup={tariffGroup}
            onClickEditButton={onClickEditButton}
            onClickRemoveButton={onClickRemoveButton}
          />
        ))}
      </TableBody>
    </Table>
  );
}
