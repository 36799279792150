import { gql } from '@apollo/client';

export interface UserResetPasswordInput {
  userToken: string;
  userPasswordInput: string;
  userPasswordConfirmation: string;
}

export const USER_RESET_PASSWORD = gql`
  mutation internalUserResetPassword(
    $InternalUserResetPasswordInput: InternalUserResetPasswordInput!
  ) {
    internalUserResetPassword(input: $InternalUserResetPasswordInput) {
      message
    }
  }
`;
