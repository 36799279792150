import { MdAssignmentTurnedIn, MdCircle, MdMonetizationOn } from 'react-icons/md';

import { convertToBRL } from 'utils/form';

import { FinancialAccountPayoutsCounter } from 'financialAccountPayouts/models/financialAccountPayouts';

export default function TransfersCounterItem({
  financialAccountPayoutsCounter,
}: {
  financialAccountPayoutsCounter?: FinancialAccountPayoutsCounter;
}) {
  return (
    <div className="w-full items-center justify-start flex gap-8 pb-10">
      <div className="flex items-center justify-start gap-4">
        <MdAssignmentTurnedIn className="text-gray-dark400" size={25} />
        <p className="flex items-center justify-start font-medium text-gray-dark500 gap-1">
          <span className="font-bold">
            {financialAccountPayoutsCounter?.financialAccountPayoutsDoneStatusCount ||
              0}
          </span>{' '}
          Transferências realizadas
        </p>
      </div>
      <div className="flex items-center justify-start gap-4">
        <MdCircle className="text-red-500" size={10} />
        <p className="flex items-center justify-start font-medium text-gray-dark500 gap-1">
          <span className="font-bold">
            {financialAccountPayoutsCounter?.financialAccountPayoutsCancelStatusCount ||
              0}
          </span>{' '}
          Falhas de transferências
        </p>
      </div>
      <div className="flex items-center justify-start gap-4">
        <MdMonetizationOn className="text-gray-dark400" size={25} />
        <p className="flex items-center justify-start font-medium text-gray-dark500 gap-1">
          Total de:
          <span className="font-bold">
            {convertToBRL(
              financialAccountPayoutsCounter?.financialAccountPayoutsTotalSum || '0'
            )}
          </span>
        </p>
      </div>
    </div>
  );
}
