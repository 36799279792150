import { gql } from '@apollo/client';
import {
  TariffGroupModalityType,
  ConsumptionGroups,
  DemandCost,
  ConsumptionCost,
  TariffGroup,
} from 'tariffGroups/models/tariffGroup';

export interface TariffGroupUpdateMutationInput {
  id: number;
  tariffGroupConsumptionCosts: ConsumptionCost;
  tariffGroupDemandCosts?: DemandCost;
  tariffGroupConsumptionGroup: ConsumptionGroups;
  tariffGroupModality: TariffGroupModalityType;
  tariffGroupValidityEndDate: string;
  tariffGroupValidityStartDate: string;
}

export interface TariffGroupMutationVariables {
  powerDistributionUnitTariffGroupUpdateInput: TariffGroupUpdateMutationInput;
}

export interface TariffGroupUpdated {
  powerDistributionUnitTariffGroupUpdate: TariffGroup;
}

export const TARIFF_GROUP_UPDATE_MUTATION = gql`
  mutation powerDistributionUnitTariffGroupUpdate(
    $powerDistributionUnitTariffGroupUpdateInput: PowerDistributionUnitTariffGroupUpdateInput!
  ) {
    powerDistributionUnitTariffGroupUpdate(
      input: $powerDistributionUnitTariffGroupUpdateInput
    ) {
      id
      tariffGroupConsumptionCosts {
        energyUsageTariff
        energyUsageTariffOnPeak
        energyUsageTariffOffPeak
        energyDistributionServiceTariff
        energyDistributionServiceTariffOnPeak
        energyDistributionServiceTariffOffPeak
      }
      tariffGroupDemandCosts {
        all
        offPeak
        onPeak
      }
      tariffGroupConsumptionGroup
      tariffGroupModality
      tariffGroupValidityEndDate
      tariffGroupValidityStartDate
      powerDistributionUnit {
        id
        powerDistributionUnitLegalName
      }
    }
  }
`;
