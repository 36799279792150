import React, { createContext, useContext } from 'react';
import { useOverlay } from 'ui/hooks/useOverlay';
import { ConfirmProps } from 'ui/models/overlay';

interface ConfirmState {
  ConfirmOverlay: ({
    title,
    text,
    variant,
    canClose,
    onConfirm,
    onCancel,
  }: ConfirmProps) => JSX.Element;
}

type ConfirmContextData = {
  showConfirm(): void;
  closeConfirm(): void;
} & ConfirmState;

const ConfirmContext = createContext<ConfirmContextData>({} as ConfirmContextData);

const ConfirmProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    openModal: showConfirm,
    closeModal: closeConfirm,
    RenderConfirm: ConfirmOverlay,
  } = useOverlay();

  return (
    <ConfirmContext.Provider
      value={{
        showConfirm,
        closeConfirm,
        ConfirmOverlay: ({ canClose = true, ...props }) => (
          <ConfirmOverlay canClose={canClose} {...props} />
        ),
      }}
    >
      {children}
    </ConfirmContext.Provider>
  );
};

function useConfirm(): ConfirmContextData {
  const context = useContext(ConfirmContext);

  if (!context) {
    throw new Error('ConfirmProvider is required!');
  }

  return context;
}

export { ConfirmProvider, useConfirm };
