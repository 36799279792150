import { gql } from '@apollo/client';

export type ConsumerUnitDeleteMutationVariables = {
  id: number;
};

export const consumerUnitsTypename = 'ConsumerUnit';

export const CONSUMER_UNIT_DELETE_MUTATION = gql`
  mutation consumerUnitDelete($id: ID!) {
    consumerUnitDelete(id: $id) {
      message
    }
  }
`;
