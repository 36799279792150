import {
  PowerDistributionUnitId,
  PowerDistributionUnit,
} from 'powerDistributionUnits/models/powerDistributionUnit';

export type TariffGroupId = number;

export enum ConsumptionGroups {
  A = 'A',
  B = 'B',
}

export enum TariffGroupModalityType {
  GREEN = 'GREEN',
  BLUE = 'BLUE',
  CONVENTIONAL = 'CONVENTIONAL',
}

export type DemandCostModalityGreen = {
  all: null | string;
};

export type DemandCostModalityBlue = {
  onPeak: null | string;
  offPeak: null | string;
};

export type DemandCost = DemandCostModalityBlue | DemandCostModalityGreen;

export type ConsumptionCostGroupA = {
  energyUsageTariffOnPeak: null | string;
  energyUsageTariffOffPeak: null | string;
  energyDistributionServiceTariffOnPeak: null | string;
  energyDistributionServiceTariffOffPeak: null | string;
};

export type ConsumptionCostGroupB = {
  energyUsageTariff: null | string;
  energyDistributionServiceTariff: null | string;
};

export type ConsumptionCost = ConsumptionCostGroupA | ConsumptionCostGroupB;

export interface TariffGroup {
  id: TariffGroupId;
  tariffGroupConsumptionCosts: ConsumptionCost;
  tariffGroupDemandCosts: null | DemandCost;
  tariffGroupConsumptionGroup: ConsumptionGroups;
  tariffGroupModality: TariffGroupModalityType;
  tariffGroupValidityEndDate: Date;
  tariffGroupValidityStartDate: Date;
  powerDistributionUnit: PowerDistributionUnit;
}

export interface TariffGroupProps {
  id: TariffGroupId;
  tariffGroupConsumptionCosts: {
    energyUsageTariffOnPeak: string;
    energyUsageTariffOffPeak: string;
    energyDistributionServiceTariffOnPeak: string;
    energyDistributionServiceTariffOffPeak: string;
    energyUsageTariff: string;
    energyDistributionServiceTariff: string;
  };
  tariffGroupDemandCosts: {
    all: string;
    onPeak: string;
    offPeak: string;
  };
  tariffGroupConsumptionGroup: ConsumptionGroups;
  tariffGroupModality: TariffGroupModalityType;
  tariffGroupValidityEndDate: Date;
  tariffGroupValidityStartDate: Date;
  powerDistributionUnitId: PowerDistributionUnitId;
  powerDistributionUnit: PowerDistributionUnit;
}
