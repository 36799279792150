import React, { ChangeEvent, KeyboardEvent } from 'react';
import { CalendarProps } from 'react-calendar';
import classNames from 'classnames';
import { Calendar } from 'ui';
import TextField from 'ui/form/TextField';
import { useOnClickOutside } from 'ui/hooks/useOnClickOutside';
import { useFloating, flip, offset, shift, arrow } from '@floating-ui/react-dom';

export interface Props
  extends Omit<
    CalendarProps,
    'inputRef' | 'className' | 'selectRange' | 'returnValue' | 'value' | 'onChange'
  > {
  required?: boolean;
  wrapperClassName?: string;
  rangeInputWrapperClassName?: string;
  calendarWrapperClassName?: string;
  calendarClassName?: string;
  labelDate?: string;
  placeholder?: string;
  value?: Date | null;
  onChange?: (
    value: Date | null,
    event: ChangeEvent<HTMLInputElement> | KeyboardEvent<HTMLInputElement>
  ) => void;
  locale?: string;
  disabled?: boolean;
  ref?: React.Ref<HTMLInputElement>;
  error?: string;
  id: string;
  options?: Intl.DateTimeFormatOptions;
}

export const DatePickerFieldOneView = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      wrapperClassName,
      rangeInputWrapperClassName,
      calendarWrapperClassName,
      calendarClassName,
      labelDate = 'Start Date',
      placeholder,
      disabled,
      required,
      error,
      id,
      value,
      onChange,
      options,
      locale = 'pt-BR',
      ...props
    },
    ref
  ) => {
    const [internalValue, setInternalValue] = React.useState<
      Date | null | undefined
    >(value);
    const [open, setOpen] = React.useState(false);
    const calendarRef = React.useRef<HTMLInputElement | null>(null);

    const arrowRef = React.useRef(null);

    const {
      x,
      y,
      reference,
      floating,
      strategy,
      refs,
      middlewareData: { arrow: arrowData },
    } = useFloating({
      placement: 'bottom-start',
      middleware: [
        shift(),
        flip({
          crossAxis: false,
        }),
        offset(15),
        arrow({ element: arrowRef }),
      ],
    });

    useOnClickOutside(
      refs.floating,
      React.useCallback(() => {
        setInternalValue(value);
        setOpen(false);
      }, [value])
    );

    React.useEffect(() => {
      setInternalValue(value);
      setOpen(false);
    }, [value]);

    const onClickInput = React.useCallback(() => {
      setOpen(!open);
    }, [open]);

    const onChangeCalendar = (
      value: Date | null,
      event: ChangeEvent<HTMLInputElement> | KeyboardEvent<HTMLInputElement>
    ) => {
      if (!onChange) return;

      onChange(value, event);
      setInternalValue(value);
    };

    const inputStartDate = (() => {
      const date = internalValue;

      if (date) {
        return date.toLocaleDateString(locale, options);
      }

      return '';
    })();

    const BACKSPACE_KEY = 'Backspace';

    const onBackSpace = (event: KeyboardEvent<HTMLInputElement>) =>
      event.key === BACKSPACE_KEY && internalValue
        ? onChangeCalendar(null, event)
        : null;

    return (
      <div className={classNames(wrapperClassName, 'relative')} ref={ref}>
        <div className={`${rangeInputWrapperClassName}`}>
          <TextField
            id={id}
            label={labelDate}
            placeholder={placeholder}
            value={inputStartDate}
            disabled={disabled}
            required={required}
            readOnly
            onKeyDown={onBackSpace}
            error={error}
            onClick={onClickInput}
            ref={reference}
            name={id}
          />
        </div>
        {open && (
          <div
            className={classNames('w-350 z-10', calendarWrapperClassName)}
            style={{
              minWidth: '425px',
              position: strategy,
              left: x ?? 0,
              top: y ?? 0,
            }}
            ref={floating}
          >
            <div className="relative">
              <div
                ref={arrowRef}
                className={classNames(
                  ' h-0 w-0 border-solid border-l-transparent border-r-transparent border-l-8 border-r-8',
                  y && {
                    'border-b-8 border-b-gray-dark600 -top-2': y > 0,
                    'border-t-8 border-t-gray-dark700 -bottom-2': y < 0,
                  }
                )}
                style={{
                  position: strategy,
                  left: arrowData?.x ?? 0,
                }}
              />
              <Calendar
                {...props}
                value={internalValue}
                onChange={onChangeCalendar}
                locale={locale}
                showNeighboringMonth={false}
                allowPartialRange
                defaultView="month"
                minDetail="month"
                ref={calendarRef}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default DatePickerFieldOneView;
