import { CustomApportionmentCycleConsumerUnit } from 'customApportionmentCycleConsumerUnit/models/customApportionmentCycleCosumerUnits';
import CustomApportionmentCycleConsumerUnitItem from './CustomAportionmentCycleConsumerUnitsTableItem';
import Table from 'ui/components/table/Table';
import TableHead from 'ui/components/table/TableHead';
import TableHeadColumn from 'ui/components/table/TableHeadColumn';
import TableBody from 'ui/components/table/TableBody';

type Props = {
  customApportionmentCycleConsumerUnits: CustomApportionmentCycleConsumerUnit[];
};

export default function CustomApportionmentCycleConsumerUnitsItem({
  customApportionmentCycleConsumerUnits,
}: Props) {
  return (
    <Table>
      <TableHead>
        <TableHeadColumn>USINA</TableHeadColumn>
        <TableHeadColumn>CONCESSIONÁRIA</TableHeadColumn>
        <TableHeadColumn>QUANT.UC</TableHeadColumn>
        <TableHeadColumn>INÍCIO CICLO</TableHeadColumn>
        <TableHeadColumn className="text-center">RATEIO</TableHeadColumn>
      </TableHead>
      <TableBody>
        {customApportionmentCycleConsumerUnits.map(
          (
            customApportionmentCycleConsumerUnit: CustomApportionmentCycleConsumerUnit,
            index: React.Key | null | undefined
          ) => (
            <CustomApportionmentCycleConsumerUnitItem
              key={index}
              index={index}
              customApportionmentCycleConsumerUnit={
                customApportionmentCycleConsumerUnit
              }
            />
          )
        )}
      </TableBody>
    </Table>
  );
}
