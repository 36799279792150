import { CommercialAgreement } from 'commercialAgreements/models/commercialAgreement';
import CommercialAgreementsTableItem from './CommercialAgreementsTableItem';
import Table from 'ui/components/table/Table';
import TableHead from 'ui/components/table/TableHead';
import TableHeadColumn from 'ui/components/table/TableHeadColumn';
import TableBody from 'ui/components/table/TableBody';

type Props = {
  commercialAgreements: CommercialAgreement[];
  onClickEditButton: (id: number) => void;
  onClickRemoveButton: (id: number) => void;
  disableEditButton: boolean;
  disableRemoveButton: boolean;
};

export default function CommercialAgreementsItem({
  commercialAgreements,
  onClickEditButton,
  onClickRemoveButton,
  disableEditButton,
  disableRemoveButton,
}: Props) {
  return (
    <Table>
      <TableHead>
        <TableHeadColumn>UNID. GERADORA</TableHeadColumn>
        <TableHeadColumn>INÍCIO VIGÊNCIA</TableHeadColumn>
        <TableHeadColumn>VALOR COM ICMS</TableHeadColumn>
        <TableHeadColumn>VALOR SEM ICMS</TableHeadColumn>
        <TableHeadColumn>ALUGUEL IMÓVEL</TableHeadColumn>
        <TableHeadColumn>DESC. MIN</TableHeadColumn>
        <TableHeadColumn>DESC. MAX</TableHeadColumn>
        <TableHeadColumn>STATUS</TableHeadColumn>
        <TableHeadColumn>AÇÕES</TableHeadColumn>
      </TableHead>
      <TableBody>
        {commercialAgreements.map((commercialAgreement: CommercialAgreement) => (
          <CommercialAgreementsTableItem
            key={commercialAgreement.id}
            commercialAgreement={commercialAgreement}
            onClickEditButton={onClickEditButton}
            onClickRemoveButton={onClickRemoveButton}
            disableEditButton={disableEditButton}
            disableRemoveButton={disableRemoveButton}
          />
        ))}
      </TableBody>
    </Table>
  );
}
