import { useEffect, useState } from 'react';

import { has } from 'lodash';

import { setFormError } from 'utils/form';
import { unMaskFormFields } from 'utils/unmask';

import { useHistory } from 'react-router';

import { useTranslation } from 'react-i18next';

import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';

import { useForm, SubmitHandler, ErrorOption } from 'react-hook-form';

import { Button } from 'ui';
import useToastContext from 'ui/hooks/useToast';
import { ToastProps } from 'ui/contexts/overlay/Toast';
import { useLoading } from 'ui/contexts/overlay/Loading';

import { Page } from 'dashboard/components/dashboard/Breadcrumbs';
import { Dashboard, DashboardMainHeaderForm } from 'dashboard/components/dashboard';

import {
  Contact,
  Contacts,
  Entities,
  ContactTypes,
} from 'contactGroups/models/contactGroups';

import ContactGroupsFormFieldsComponent, {
  ContactGroupsFormErrors,
  ContactGroupsFormFields,
  ContactGroupsFormInputs,
} from 'contactGroups/components/form/ContactGroupsFormFields';

import {
  ContactGroupCreated,
  ContactGroupMutationVariables,
  CONTACT_GROUP_CREATE_MUTATION,
} from 'contactGroups/graphql/contactGroupsCreateMutation';

import {
  ContactGroupList,
  CONTACT_GROUPS_QUERY,
  contactGroupsTypename,
} from 'contactGroups/graphql/contactGroupsQuery';

import { COOPERATIVES_SELECT_QUERY_VARIABLES } from 'cooperatives/graphql/cooperativesSelectQuery';

import { CONSUMER_UNITS_SELECT_QUERY_VARIABLES } from 'consumerUnits/graphql/consumerUnitsSelectQuery';

import { GENERATION_UNITS_SELECT_QUERY_VARIABLES } from 'generationUnits/graphql/generationUnitsSelectQuery';

import {
  COOPERATIVE_MEMBERS_SELECT_QUERY,
  COOPERATIVE_MEMBERS_SELECT_QUERY_VARIABLES,
  CooperativeMembersSelectList,
} from 'cooperatives/graphql/cooperativeMembersSelectQuery';

import {
  CONSUMER_UNITS_WITHOUT_GROUP_AND_CONTACTS_QUERY,
  ConsumerUnitsWithoutContactGroupsList,
} from 'consumerUnits/graphql/consumerUnitsWithoutContactGroupsQuery';

import {
  COOPERATIVES_WITHOUT_GROUP_AND_CONTACTS_QUERY,
  CooperativesWithoutContactGroupsList,
} from 'cooperatives/graphql/cooperativesWithoutContactGroupsQuery';
import {
  GENERATION_UNITS_WITHOUT_GROUP_AND_CONTACTS_QUERY,
  GenerationUnitsWithoutContactGroupsList,
} from 'generationUnits/graphql/generationUnitsWithoutContactGroupsQuery';

const DASHBOARD_CONTACT_GROUPS_ROUTE = '/dashboard/contact-groups';

const BREADCRUMB_PAGES: Page[] = [
  {
    current: false,
    name: 'Grupo & Contatos',
    route: DASHBOARD_CONTACT_GROUPS_ROUTE,
  },
  {
    route: null,
    current: true,
    name: 'Novo Grupo & Contatos',
  },
];
const TITLE = 'Novo Grupo & Contatos';

const CREATE_ERROR_TOAST = (text?: string) =>
  ({
    variant: 'danger',
    title: 'Algo deu errado!',
    text: text || 'Houve um erro ao tentar criar o cadastro de Grupo & Contatos',
  } as ToastProps);

const CREATE_SUCCESS_TOAST: ToastProps = {
  title: 'Sucesso!',
  variant: 'primary',
  text: 'Sucesso ao cadastrar Grupo & Contatos',
};

export default function CreateContactGroupsPage() {
  const { push } = useHistory();
  const { t } = useTranslation();
  const { addToast } = useToastContext();
  const { showLoading, closeLoading, LoadingOverlay } = useLoading();

  const {
    reset,
    control,
    register,
    setError,
    setValue,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm<ContactGroupsFormInputs>();

  const [contactList, setContactList] = useState<Contacts>({
    owner: [],
    domain: [],
    financial: [],
    juridical: [],
    technical: [],
    administrative: [],
  });

  const [entitiesList, setEntitiesList] = useState<Entities>({
    cooperatives: [],
    consumerUnits: [],
    generationUnits: [],
  });

  const [
    consumerUnitsSelect,
    { data: consumerUnitsSelectData, loading: consumerUnitsSelectLoading },
  ] = useLazyQuery<ConsumerUnitsWithoutContactGroupsList>(
    CONSUMER_UNITS_WITHOUT_GROUP_AND_CONTACTS_QUERY,
    CONSUMER_UNITS_SELECT_QUERY_VARIABLES
  );

  const [
    cooperativesSelect,
    { data: cooperativesSelectData, loading: cooperativesSelectLoading },
  ] = useLazyQuery<CooperativesWithoutContactGroupsList>(
    COOPERATIVES_WITHOUT_GROUP_AND_CONTACTS_QUERY,
    COOPERATIVES_SELECT_QUERY_VARIABLES
  );

  const [
    generationUnitsSelect,
    { data: generationUnitsSelectData, loading: generationUnitsSelectLoading },
  ] = useLazyQuery<GenerationUnitsWithoutContactGroupsList>(
    GENERATION_UNITS_WITHOUT_GROUP_AND_CONTACTS_QUERY,
    GENERATION_UNITS_SELECT_QUERY_VARIABLES
  );

  const [
    cooperativeMembersSelect,
    { data: cooperativeMembersSelectData, loading: cooperativeMembersSelectLoading },
  ] = useLazyQuery<CooperativeMembersSelectList>(
    COOPERATIVE_MEMBERS_SELECT_QUERY,
    COOPERATIVE_MEMBERS_SELECT_QUERY_VARIABLES
  );

  useEffect(() => {
    consumerUnitsSelect();
  }, [consumerUnitsSelect]);

  useEffect(() => {
    cooperativesSelect();
  }, [cooperativesSelect]);

  useEffect(() => {
    generationUnitsSelect();
  }, [generationUnitsSelect]);

  useEffect(() => {
    cooperativeMembersSelect();
  }, [cooperativeMembersSelect]);

  const [contactGroupCreateMutation, { loading: loadingCreate }] = useMutation<
    ContactGroupCreated,
    ContactGroupMutationVariables
  >(CONTACT_GROUP_CREATE_MUTATION, {
    onError(error: ApolloError) {
      if (has(error.graphQLErrors[0], 'message')) {
        addToast(CREATE_ERROR_TOAST(t(error.graphQLErrors[0].message)));
      }

      setFormError(
        error,
        (field: string, error: ErrorOption) => {
          setError(field as keyof ContactGroupsFormFields, error);

          setTimeout(() => clearErrors(), 2500);
        },
        t
      );
    },
    onCompleted() {
      addToast(CREATE_SUCCESS_TOAST);
      push(DASHBOARD_CONTACT_GROUPS_ROUTE);
      window.location.reload();
    },
  });

  const onSubmit: SubmitHandler<ContactGroupsFormFields> = (
    contactGroupCreateInput,
    event
  ) => {
    event?.preventDefault();

    contactGroupCreateInput = Object.assign(contactGroupCreateInput, {
      contacts: contactList,
      entities: entitiesList,
    });

    contactGroupCreateInput = unMaskFormFields(contactGroupCreateInput);

    const hasLeastOneEntity =
      entitiesList?.cooperatives?.length ||
      entitiesList?.consumerUnits?.length ||
      entitiesList?.generationUnits?.length;

    const hasLeastOneDomainContact = contactList?.domain?.length;

    if (!hasLeastOneDomainContact) {
      setError('contacts', {
        type: 'manual',
        message: 'Este campo precisa ser preenchido',
      });
    }

    if (!hasLeastOneEntity) {
      setError('entities', {
        type: 'manual',
        message: 'Você precisa vincular pelo menos uma entidade ao grupo',
      });
    }

    setTimeout(() => clearErrors(), 3000);

    const entities = Object.keys(contactGroupCreateInput.entities).map((key) => {
      return {
        [key]: Object.values(
          contactGroupCreateInput.entities[key as keyof Entities]
        ).map((entity) => {
          return {
            id: Number(entity.id),
          };
        }),
      };
    });

    const contactsArray: Contact[] = [];

    Object.entries(contactGroupCreateInput.contacts).forEach(([type, contacts]) => {
      const contactType = type.toUpperCase() as ContactTypes;
      const contactArray: Contact[] = Object.values(contacts).map((contact: any) => {
        return {
          ...contact,
          contactType: {
            contactTypeName: contactType,
          },
        };
      });
      contactsArray.push(...contactArray);
    });

    if (hasLeastOneEntity && hasLeastOneDomainContact) {
      contactGroupCreateMutation({
        variables: {
          contactGroupCreateInput: {
            group: contactGroupCreateInput.group,
            contacts: contactsArray,
            entities: entities.reduce((acc, cur) => {
              return { ...acc, ...cur };
            }, {} as any),
          },
        },
        update(cache, { data }) {
          const existingData = cache.readQuery<ContactGroupList>({
            query: CONTACT_GROUPS_QUERY,
          });
          cache.writeQuery({
            query: CONTACT_GROUPS_QUERY,
            data: {
              contactGroups: {
                __typename: contactGroupsTypename,
                afterCursor: existingData?.contactGroups.afterCursor || null,
                beforeCursor: existingData?.contactGroups.beforeCursor || null,
                entries: [
                  data?.contactGroupCreate,
                  ...(existingData?.contactGroups.entries || []),
                ],
              },
            },
          });
        },
      });
    }
  };

  const isLoading =
    loadingCreate ||
    cooperativesSelectLoading ||
    consumerUnitsSelectLoading ||
    generationUnitsSelectLoading ||
    cooperativeMembersSelectLoading;

  useEffect(() => {
    if (isLoading) {
      return showLoading();
    }

    closeLoading();
  }, [isLoading, showLoading, closeLoading]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay />
      <Dashboard
        dashboardMainHeaderTitle={
          <DashboardMainHeaderForm breadcrumbPages={BREADCRUMB_PAGES} title={TITLE}>
            <Button type="submit" size="sm" disabled={isLoading}>
              Salvar
            </Button>
          </DashboardMainHeaderForm>
        }
      >
        <ContactGroupsFormFieldsComponent
          reset={reset}
          control={control}
          setValue={setValue}
          register={register}
          formErrors={errors as ContactGroupsFormErrors}
          cooperatives={
            cooperativesSelectData?.listCooperativesWithoutContactGroups?.entries ||
            []
          }
          consumerUnits={
            consumerUnitsSelectData?.listConsumerUnitsWithoutContactGroups
              ?.entries || []
          }
          generationUnits={
            generationUnitsSelectData?.listGenerationUnitsWithoutContactGroups
              ?.entries || []
          }
          cooperativeMembers={
            cooperativeMembersSelectData?.cooperativeMembers?.entries || []
          }
          handleGetEntityList={(entities: Entities) => setEntitiesList(entities)}
          handleGetContactList={(contacts: Contacts) => setContactList(contacts)}
        />
      </Dashboard>
    </form>
  );
}
