import { GenerationUnit } from 'generationUnits/models/generationUnit';

import { ConsumerUnitBillingCapture } from 'consumerUnitBillingCapture/model/consumerUnitBillingCaptures';

import { GenerationUnitBillData } from 'powerDistributionUnits/powerDistributionUnitBillData/generationUnitBillData/models/generationUnitBillData';
import { ConsumerUnit, ConsumerUnitId } from 'consumerUnits/model/consumerUnit';

export type GenerationUnitLeaseCycleId = number;
export type GenerationUnitLeaseCycleCreditId = number;

export enum GenerationUnitLeaseCycleStatus {
  OPENED = 'OPENED',
  BILLING = 'BILLING',
  PENDING = 'PENDING',
  WAITING = 'WAITING',
  FINISHED = 'FINISHED',
}

export interface GenerationUnitLeaseCycle {
  id: GenerationUnitLeaseCycleId;
  generationUnit: GenerationUnit;
  generationUnitLeaseCycleDate: string;
  apportionmentCycleConsumerUnitCount: number;
  consumerUnitBillingCapture?: ConsumerUnitBillingCapture;
  powerDistributionUnitBillDatas?: GenerationUnitBillData;
  generationUnitLeaseCycleStatus: GenerationUnitLeaseCycleStatus;
}

export interface GenerationUnitLeaseCycleCredit {
  id: GenerationUnitLeaseCycleCreditId;
  consumerUnit: ConsumerUnit;
  consumerUnitId: ConsumerUnitId;
  leaseCycleCreditsOnPeak?: string;
  leaseCycleCreditsOffPeak?: string;
  leaseCycleCreditsReferenceDate: string;
  generationUnitLeaseCycle: GenerationUnitLeaseCycle;
  generationUnitLeaseCycleId: GenerationUnitLeaseCycleId;
}
