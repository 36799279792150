import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Control,
  useWatch,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';

import { Button, SearchInput } from 'ui';
import TextField from 'ui/form/TextField';
import { Option } from 'ui/components/form/SearchInput';

import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';

import { CooperativeSelect } from 'cooperatives/models/cooperative';
import { ConsumerUnitsSelect } from 'consumerUnits/model/consumerUnit';
import { GenerationUnitSelect } from 'generationUnits/models/generationUnit';
import { Entities, EntityTypes } from 'contactGroups/models/contactGroups';
import { MaskInterface } from 'utils/masks';

export type FormFilters = {
  groupName: string;
  contactName: string;
  contactPhone: string;
  contactEmail: string;
  cooperativeLegalName: string;
  generationUnitLegalName: string;
  consumerUnitPowerDistributionUnitIdentifier: string;
};

export default function ContactGroupsFormFilters({
  control,
  register,
  setValue,
  isLoading,
  cooperatives,
  consumerUnits,
  generationUnits,
}: {
  isLoading?: boolean;
  control: Control<FormFilters>;
  cooperatives: CooperativeSelect[];
  consumerUnits: ConsumerUnitsSelect[];
  setValue: UseFormSetValue<FormFilters>;
  register: UseFormRegister<FormFilters>;
  generationUnits: GenerationUnitSelect[];
}) {
  const [selectOptions, setSelectOptions] = useState({
    cooperatives: Array<Option<number>>() || [],
    consumerUnits: Array<Option<number>>() || [],
    generationUnits: Array<Option<number>>() || [],
  });

  const [entitySearchBarValue, setEntitySearchBarValue] = useState({
    cooperatives: '',
    consumerUnits: '',
    generationUnits: '',
  });

  const [entitySearchBarSelectedOption, setEntitySearchBarSelectedOption] = useState(
    {
      cooperatives: ({} as Option<string | number>) || {},
      consumerUnits: ({} as Option<string | number>) || {},
      generationUnits: ({} as Option<string | number>) || {},
    }
  );

  useEffect(() => {
    !!consumerUnits &&
      setSelectOptions((prev) => ({
        ...prev,
        consumerUnits:
          consumerUnits.map((consumerUnit) => ({
            key: consumerUnit.id,
            value: `${consumerUnit?.consumerUnitPowerDistributionUnitIdentifier} - ${consumerUnit?.cooperativeMember?.cooperativeMemberLegalName}`,
          })) || [],
      }));
  }, [consumerUnits]);

  // useEffect(() => {
  //   !!generationUnits &&
  //     setSelectOptions((prev) => ({
  //       ...prev,
  //       generationUnits:
  //         generationUnits.map((generationUnit) => ({
  //           key: generationUnit.id,
  //           value: `${generationUnit.generationUnitLegalName} - ${generationUnit.powerDistributionUnit.powerDistributionUnitLegalName}`,
  //         })) || [],
  //     }));
  // }, [generationUnits]);

  // useEffect(() => {
  //   !!cooperatives &&
  //     setSelectOptions((prev) => ({
  //       ...prev,
  //       cooperatives:
  //         cooperatives.map((cooperative) => ({
  //           key: cooperative.id,
  //           value: `${cooperative.cooperativeLegalName} - ${cooperative.cooperativeAddressState}`,
  //         })) || [],
  //     }));
  // }, [cooperatives]);

  const GROUP_TEXT_FILTERS = useMemo(
    () => [
      {
        label: 'Nome',
        placeholder: 'Digitar',
        id: 'groupName' as const,
      },
    ],
    []
  );

  const CONTACT_TEXT_FILTERS = useMemo(
    () => [
      {
        label: 'Nome',
        placeholder: 'Digitar',
        id: 'contactName' as const,
        mask: 'textOnly' as MaskInterface,
      },
      {
        label: 'Telefone',
        placeholder: 'Digitar',
        id: 'contactPhone' as const,
        mask: 'mobilePhone' as MaskInterface,
      },
      {
        label: 'E-mail',
        placeholder: 'Digitar',
        id: 'contactEmail' as const,
        mask: '' as MaskInterface,
      },
    ],
    []
  );

  const handleEntitySearchBarSelectItem = useCallback(
    (item: Option<string | number>, entityType: keyof Entities) => {
      setEntitySearchBarSelectedOption({
        ...entitySearchBarSelectedOption,
        [entityType]: item,
      });

      setEntitySearchBarValue({
        ...entitySearchBarValue,
        [entityType]: item.value,
      });

      const ENTITIES = {
        cooperatives: 'cooperativeLegalName' as const,
        generationUnits: 'generationUnitLegalName' as const,
        consumerUnits: 'consumerUnitPowerDistributionUnitIdentifier' as const,
      };

      setValue(ENTITIES[entityType], item.value.split(' - ')[0]);
    },
    [entitySearchBarSelectedOption, entitySearchBarValue, setValue]
  );

  const ENTITY_DROPDOWN_FILTERS = useMemo(
    () => [
      {
        label: 'UC',
        disabled: false,
        placeholder: 'Selecionar',
        type: EntityTypes.CONSUMER_UNIT,
        options: selectOptions.consumerUnits,
        value: entitySearchBarValue.consumerUnits,
        selectedOption: entitySearchBarSelectedOption.consumerUnits,
        id: 'consumerUnitPowerDistributionUnitIdentifier' as const,
      },
      {
        label: 'UG',
        disabled: true,
        placeholder: 'Selecionar',
        type: EntityTypes.GENERATION_UNIT,
        options: selectOptions.generationUnits,
        value: entitySearchBarValue.generationUnits,
        selectedOption: entitySearchBarSelectedOption.generationUnits,
        id: 'generationUnitLegalName' as const,
      },
      {
        label: 'Cooperativa',
        disabled: true,
        placeholder: 'Selecionar',
        type: EntityTypes.COOPERATIVE,
        options: selectOptions.cooperatives,
        value: entitySearchBarValue.cooperatives,
        selectedOption: entitySearchBarSelectedOption.cooperatives,
        id: 'cooperativeLegalName' as const,
      },
    ],
    [
      selectOptions.cooperatives,
      selectOptions.consumerUnits,
      selectOptions.generationUnits,
      entitySearchBarValue.cooperatives,
      entitySearchBarValue.consumerUnits,
      entitySearchBarValue.generationUnits,
      entitySearchBarSelectedOption.cooperatives,
      entitySearchBarSelectedOption.consumerUnits,
      entitySearchBarSelectedOption.generationUnits,
    ]
  );

  const activeFiltersCount = useWatch({
    control,
    name: [
      'groupName',
      'contactName',
      'contactPhone',
      'contactEmail',
      'cooperativeLegalName',
      'generationUnitLegalName',
      'consumerUnitPowerDistributionUnitIdentifier',
    ],
  }).filter((filter) => filter).length;

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  function handleFiltersOpen() {
    setIsFiltersOpen(!isFiltersOpen);
  }

  function handleClearFilters() {
    GROUP_TEXT_FILTERS.forEach((filter) => setValue(filter.id, ''));
    CONTACT_TEXT_FILTERS.forEach((filter) => setValue(filter.id, ''));
    ENTITY_DROPDOWN_FILTERS.forEach((filter) => setValue(filter.id, ''));

    setEntitySearchBarValue({
      cooperatives: '',
      consumerUnits: '',
      generationUnits: '',
    });

    setEntitySearchBarSelectedOption({
      cooperatives: ({} as Option<string | number>) || {},
      consumerUnits: ({} as Option<string | number>) || {},
      generationUnits: ({} as Option<string | number>) || {},
    });
  }

  const handleChangeEntity = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, entityType: EntityTypes) => {
      setEntitySearchBarValue({
        ...entitySearchBarValue,
        [entityType]: e.target?.value || '',
      });
    },
    [entitySearchBarValue]
  );

  return (
    <div className="w-full flex flex-col gap-4 items-start justify-start mb-3">
      <div className="flex flex-col gap-1">
        <p className="font-bold text-gray-dark400">Filtros avançados</p>
        <Button
          size="md"
          type="button"
          variant="advancedFilter"
          onClick={handleFiltersOpen}
          className="flex items-center justify-center"
        >
          <p className="text-sm font-medium">Filtros ({activeFiltersCount})</p>
          {isFiltersOpen ? (
            <MdKeyboardArrowUp size={20} />
          ) : (
            <MdKeyboardArrowDown size={20} />
          )}
        </Button>
      </div>
      {isFiltersOpen && (
        <div className="w-full flex flex-col gap-4 items-start justify-start bg-gray-dark700 rounded-lg">
          <div className="w-full flex gap-12 items-stretch justify-start p-7">
            <div className="w-full flex flex-col gap-4 items-start justify-start">
              <p className="font-bold text-gray-dark400">Dados do grupo</p>
              {GROUP_TEXT_FILTERS.map((group) => (
                <TextField
                  id={group.id}
                  key={group.id}
                  label={group.label}
                  wrapperClassName="w-full"
                  placeholder={group.placeholder}
                  {...register(group.id)}
                />
              ))}
            </div>

            <div className="w-full flex flex-col items-start justify-start gap-4">
              <p className="font-bold text-gray-dark400">Dados de contato</p>
              {CONTACT_TEXT_FILTERS.slice(0, 1).map((contact) => (
                <TextField
                  id={contact.id}
                  key={contact.id}
                  label={contact.label}
                  wrapperClassName="w-full"
                  placeholder={contact.placeholder}
                  {...register(contact.id)}
                />
              ))}
              <div className="w-full flex items-start justify-start gap-4">
                {CONTACT_TEXT_FILTERS.slice(1, 3).map((contact) => (
                  <TextField
                    id={contact.id}
                    key={contact.id}
                    mask={contact.mask}
                    label={contact.label}
                    wrapperClassName="w-full"
                    placeholder={contact.placeholder}
                    {...register(contact.id)}
                  />
                ))}
              </div>
            </div>

            <div className="w-full flex flex-col gap-4 items-start justify-start">
              <p className="font-bold text-gray-dark400">Selecionar Entidades</p>
              <div className="flex flex-col items-center justify-start gap-4">
                {ENTITY_DROPDOWN_FILTERS.slice(0, 1).map((entity) => (
                  <SearchInput
                    id={entity.id}
                    key={entity.id}
                    label={entity.label}
                    value={entity.value}
                    options={entity.options}
                    placeholder={entity.placeholder}
                    selectedOption={entity.selectedOption}
                    selectItem={(item) =>
                      handleEntitySearchBarSelectItem(
                        item,
                        entity.type as keyof Entities
                      )
                    }
                    onChange={(e) =>
                      handleChangeEntity(e, entity.type as EntityTypes)
                    }
                  />
                ))}
                <div className="flex items-start justify-start gap-4">
                  {ENTITY_DROPDOWN_FILTERS.slice(1, 3).map((entity) => (
                    <SearchInput
                      id={entity.id}
                      key={entity.id}
                      label={entity.label}
                      value={entity.value}
                      options={entity.options}
                      disabled={entity.disabled}
                      placeholder={entity.placeholder}
                      selectedOption={entity.selectedOption}
                      selectItem={(item) =>
                        handleEntitySearchBarSelectItem(
                          item,
                          entity.type as keyof Entities
                        )
                      }
                      onChange={(e) =>
                        handleChangeEntity(e, entity.type as EntityTypes)
                      }
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="w-full flex items-center justify-between border-t border-gray-dark500 p-7">
            <p className="font-bold flex text-gray-dark500 w-full">
              ({activeFiltersCount}) Filtros selecionados
            </p>
            <div className="w-full flex gap-4 items-center justify-end">
              <Button
                size="sm"
                type="submit"
                className="w-24"
                disabled={isLoading}
                variant="secondaryGray"
                onClick={handleClearFilters}
              >
                Limpar
              </Button>
              <Button
                size="sm"
                type="submit"
                disabled={isLoading || !activeFiltersCount}
              >
                {`Aplicar ${activeFiltersCount} filtros`}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
