import { gql } from '@apollo/client';

export type GenerationUnitDeleteMutationVariables = {
  id: number;
};

export const GENERATION_UNIT_DELETE_MUTATION = gql`
  mutation generationUnitDelete($id: ID!) {
    generationUnitDelete(id: $id) {
      message
    }
  }
`;
