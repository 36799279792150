import { useCallback, useEffect, useMemo, useState } from 'react';

import { has } from 'lodash';

import { useHistory } from 'react-router-dom';

import { SubmitHandler, useForm } from 'react-hook-form';

import { ApolloError, useLazyQuery } from '@apollo/client';

import { INITIAL_QUERY_STATE_CONFIG } from 'graphql/apollo/config';

import useToastContext from 'ui/hooks/useToast';
import { useModal } from 'ui/contexts/overlay/Modal';
import { ToastProps } from 'ui/contexts/overlay/Toast';
import { useLoading } from 'ui/contexts/overlay/Loading';

import { Pagination } from 'dashboard/components/table';
import { Dashboard, DashboardMainHeaderForm } from 'dashboard/components/dashboard';

import {
  GenerationUnitsSelectList,
  GENERATION_UNITS_SELECT_QUERY,
  GENERATION_UNITS_SELECT_QUERY_VARIABLES,
} from 'generationUnits/graphql/generationUnitsSelectQuery';

import {
  ConsumerUnitBillingCaptureStatusCounterList,
  CONSUMER_UNIT_BILLING_CAPTURE_STATUS_COUNTER_QUERY,
  GENERATION_UNIT_LEASE_CYCLES_SELECT_QUERY_VARIABLES,
} from 'consumerUnitBillingCapture/graphql/consumerUnitBillingCaptureStatusCounterQuery';

import {
  GenerationUnitLeaseCycle,
  GenerationUnitLeaseCycleStatus,
} from 'generationUnits/generationUnitLeaseCycles/models/generationUnitLeaseCycles';

import { GENERATION_UNIT_LEASE_CYCLES_QUERY } from 'generationUnits/generationUnitLeaseCycles/graphql/generationUnitLeaseCyclesQuery';

import GenerationUnitLeaseCyclesFormFilters, {
  FormFilters,
} from 'generationUnits/generationUnitLeaseCycles/components/form/GenerationUnitLeaseCyclesFormFilters';
import GenerationUnitLeaseCyclesTable from 'generationUnits/generationUnitLeaseCycles/components/table/GenerationUnitLeaseCyclesTable';

import ConsumerUnitBillingCaptureStatusCounterItem from 'consumerUnitBillingCapture/components/counter/ConsumerUnitBillingCaptureStatusCounterItem';

import DangerGenerationUnitBillData, {
  GenerationUnitBillDataModal,
} from 'powerDistributionUnits/powerDistributionUnitBillData/generationUnitBillData/components/modal/DangerGenerationUnitBillData';
import GenerationUnitBillDataDetails from 'powerDistributionUnits/powerDistributionUnitBillData/generationUnitBillData/components/detail/GenerationUnitBillDataDetails';

import { formatDateToSubmitDateString } from 'utils/form';

import {
  ConsumerUnitBillingCaptureDownloadBill,
  CONSUMER_UNIT_BILLING_CAPTURE_DOWNLOAD_BILL_QUERY,
} from 'consumerUnitBillingCapture/graphql/consumerUnitBillingCaptureDownloadBillQuery';

type State = {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  generationUnitLeaseCycles: GenerationUnitLeaseCycle[];
};

const DOWNLOAD_ERROR_TOAST: ToastProps = {
  variant: 'danger',
  title: 'Algo deu errado!',
  text: 'Houve um erro ao tentar realizar o download da fatura.',
};

const DOWNLOAD_SUCCESS_TOAST: ToastProps = {
  title: 'Sucesso!',
  variant: 'primary',
  text: 'Sucesso ao realizar o download da fatura.',
};

const LIST_ERROR_TOAST: ToastProps = {
  variant: 'danger',
  title: 'Algo deu errado!',
  text: 'Não foi possível carregar a lista de Ciclos de Locação',
};

const CREATE_DANGER_TOAST: ToastProps = {
  variant: 'danger',
  title: 'Atenção!',
  text: 'Existe um ou mais ciclos de locação desta unidade geradora pendentes de preenchimento. Você realmente deseja continuar a cadastrar um novo ciclo?',
};

const DASHBOARD_TITLE = 'Ciclos de Locação';

export default function ListGenerationUnitLeaseCyclesPage() {
  const { push } = useHistory();
  const { addToast } = useToastContext();
  const { LoadingOverlay, showLoading, closeLoading } = useLoading();

  const [dangerGenerationUnitBillDataModal, setDangerGenerationUnitBillDataModal] =
    useState<GenerationUnitBillDataModal>();

  const [generationUnitLeaseCycleData, setGenerationUnitLeaseCycleData] =
    useState<GenerationUnitLeaseCycle>();

  const [
    showGenerationUnitBillDataDetailsModal,
    setShowGenerationUnitBillDataDetailsModal,
  ] = useState(false);

  const [state, setState] = useState<State>({
    hasNextPage: false,
    hasPreviousPage: false,
    generationUnitLeaseCycles: [],
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormFilters>();

  const {
    ModalOverlay,
    showConfirm: modalShowConfirm,
    closeConfirm: modalCloseConfirm,
  } = useModal();

  const [generationUnitLeaseCycleList, { data, loading, error, refetch }] =
    useLazyQuery(GENERATION_UNIT_LEASE_CYCLES_QUERY, INITIAL_QUERY_STATE_CONFIG);

  const [
    generationUnitsSelect,
    {
      data: generationUnits,
      loading: loadingGenerationUnits,
      refetch: refetchGenerationUnits,
    },
  ] = useLazyQuery<GenerationUnitsSelectList>(
    GENERATION_UNITS_SELECT_QUERY,
    GENERATION_UNITS_SELECT_QUERY_VARIABLES
  );

  const HAS_CONSUMER_UNIT_BILLING_CAPTURE_URL = useMemo(
    () => ({
      consumerUnitBillingCaptureUrl:
        generationUnitLeaseCycleData?.consumerUnitBillingCapture
          ?.consumerUnitBillingCaptureUrl || '',
    }),
    [generationUnitLeaseCycleData]
  );

  const downloadBill = useCallback(
    (url: string) => {
      if (url) {
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute(
          'download',
          HAS_CONSUMER_UNIT_BILLING_CAPTURE_URL?.consumerUnitBillingCaptureUrl as string
        );
        document.body.appendChild(link);
        link.click();
      }
    },
    [HAS_CONSUMER_UNIT_BILLING_CAPTURE_URL?.consumerUnitBillingCaptureUrl]
  );

  const [
    consumerUnitBillingCaptureDownloadBill,
    {
      data: consumerUnitBillingCaptureDownload,
      loading: loadingConsumerUnitBillingCaptureDownload,
    },
  ] = useLazyQuery<ConsumerUnitBillingCaptureDownloadBill>(
    CONSUMER_UNIT_BILLING_CAPTURE_DOWNLOAD_BILL_QUERY,
    {
      variables: HAS_CONSUMER_UNIT_BILLING_CAPTURE_URL,
      onError(error: ApolloError) {
        if (has(error.graphQLErrors[0], 'message')) {
          addToast(DOWNLOAD_ERROR_TOAST);
        }
      },
      onCompleted() {
        const { consumerUnitBillingCaptureUrl } =
          consumerUnitBillingCaptureDownload?.consumerUnitBillingCaptureDownloadBill ||
          {};

        downloadBill(consumerUnitBillingCaptureUrl || '');

        addToast(DOWNLOAD_SUCCESS_TOAST);
      },
    }
  );

  const [
    consumerUnitBillingCaptureStatusCounterSelect,
    {
      data: consumerUnitBillingCaptureStatusCounter,
      loading: loadingConsumerUnitBillingCaptureStatusCounter,
      refetch: refetchConsumerUnitBillingCaptureStatusCounter,
    },
  ] = useLazyQuery<ConsumerUnitBillingCaptureStatusCounterList>(
    CONSUMER_UNIT_BILLING_CAPTURE_STATUS_COUNTER_QUERY,
    GENERATION_UNIT_LEASE_CYCLES_SELECT_QUERY_VARIABLES
  );

  useEffect(() => {
    refetchConsumerUnitBillingCaptureStatusCounter &&
      refetchConsumerUnitBillingCaptureStatusCounter();

    consumerUnitBillingCaptureStatusCounterSelect();
  }, [
    consumerUnitBillingCaptureStatusCounterSelect,
    refetchConsumerUnitBillingCaptureStatusCounter,
  ]);

  useEffect(() => {
    refetchGenerationUnits && refetchGenerationUnits();

    generationUnitsSelect();
  }, [generationUnitsSelect, refetchGenerationUnits]);

  useEffect(() => {
    generationUnitLeaseCycleList();
  }, [generationUnitLeaseCycleList]);

  useEffect(() => {
    if (error) {
      addToast(LIST_ERROR_TOAST);
    }
  }, [addToast, error]);

  const isLoading =
    loading ||
    loadingGenerationUnits ||
    loadingConsumerUnitBillingCaptureDownload ||
    loadingConsumerUnitBillingCaptureStatusCounter;

  useEffect(() => {
    if (isLoading) {
      return showLoading();
    }

    if (data) {
      setState({
        hasNextPage: !!data.generationUnitLeaseCycles.afterCursor,
        hasPreviousPage: !!data.generationUnitLeaseCycles.beforeCursor,
        generationUnitLeaseCycles: data.generationUnitLeaseCycles.entries,
      });
    }

    closeLoading();
  }, [data, isLoading, closeLoading, showLoading]);

  const onClickCreateButton = (
    pathname: string,
    generationUnitLeaseCycle: GenerationUnitLeaseCycle
  ) => {
    if (
      state.generationUnitLeaseCycles.filter(
        (generationUnit) =>
          generationUnit.generationUnitLeaseCycleStatus ===
          GenerationUnitLeaseCycleStatus.PENDING
      ).length
    ) {
      modalShowConfirm();
      setDangerGenerationUnitBillDataModal({
        pathname,
        show: true,
        generationUnitLeaseCycle,
      });
    } else {
      push({
        pathname,
        state: {
          generationUnitLeaseCycle,
        },
      });
    }
  };

  const onClickCloseModal = () => {
    modalCloseConfirm();
    setDangerGenerationUnitBillDataModal({
      show: false,
      pathname: '',
      generationUnitLeaseCycle: null,
    });
  };

  const handleClickNext = () => {
    refetch &&
      refetch({
        before: null,
        after: data.generationUnitLeaseCycles.afterCursor,
      });
  };

  const handleClickBefore = () => {
    refetch &&
      refetch({
        after: null,
        before: data.generationUnitLeaseCycles.beforeCursor,
      });
  };

  const handleClickOpenBillDetails = (
    generationUnitLeaseCycle: GenerationUnitLeaseCycle
  ) => {
    setShowGenerationUnitBillDataDetailsModal(true);
    setGenerationUnitLeaseCycleData(generationUnitLeaseCycle);
  };

  const handleClickCloseBillDetails = () =>
    setShowGenerationUnitBillDataDetailsModal(
      !showGenerationUnitBillDataDetailsModal
    );

  const onSubmit: SubmitHandler<FormFilters> = (generationUnitLeaseCycles) => {
    !!generationUnitLeaseCycles.generationUnitLeaseCycleDate &&
      Object.assign(generationUnitLeaseCycles, {
        generationUnitLeaseCycleDate: formatDateToSubmitDateString(
          String(
            new Date(
              generationUnitLeaseCycles.generationUnitLeaseCycleDate.getFullYear(),
              generationUnitLeaseCycles.generationUnitLeaseCycleDate.getMonth(),
              1
            )
          )
        ),
      });

    refetch({
      filters: Object.fromEntries(
        Object.entries(generationUnitLeaseCycles).filter(([, value]) => !!value)
      ),
    });
  };

  return (
    <Dashboard
      dashboardMainHeaderTitle={<DashboardMainHeaderForm title={DASHBOARD_TITLE} />}
    >
      <ConsumerUnitBillingCaptureStatusCounterItem
        consumerUnitBillingCaptureStatusCounter={
          consumerUnitBillingCaptureStatusCounter?.consumerUnitBillingCaptureStatusCounter
        }
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <GenerationUnitLeaseCyclesFormFilters
          errors={errors}
          control={control}
          register={register}
          isLoading={isLoading}
          generationUnits={generationUnits?.generationUnits.entries}
        />
      </form>

      <div className="rounded-lg">
        <GenerationUnitLeaseCyclesTable
          onClickCreateButton={onClickCreateButton}
          onClickOpenBillDetails={handleClickOpenBillDetails}
          generationUnitLeaseCycles={state.generationUnitLeaseCycles}
        />

        <Pagination
          onNextClick={handleClickNext}
          disableNext={!state.hasNextPage}
          onPreviousClick={handleClickBefore}
          disableBefore={!state.hasPreviousPage}
        />
      </div>

      {showGenerationUnitBillDataDetailsModal && (
        <GenerationUnitBillDataDetails
          onClickCloseModal={handleClickCloseBillDetails}
          generationUnitLeaseCycle={generationUnitLeaseCycleData}
          handleClickDownloadBill={() => consumerUnitBillingCaptureDownloadBill()}
        />
      )}

      {dangerGenerationUnitBillDataModal?.show && (
        <DangerGenerationUnitBillData
          ModalOverlay={ModalOverlay}
          danger={CREATE_DANGER_TOAST}
          onClickCloseModal={onClickCloseModal}
          generationUnitBillData={dangerGenerationUnitBillDataModal}
        />
      )}

      <LoadingOverlay />
    </Dashboard>
  );
}
