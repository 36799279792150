import { Button } from 'ui';
import { useLoading } from 'ui/contexts/overlay/Loading';
import { ModalFormProps, ModalProps } from 'ui/models/overlay';

type Props = {
  danger: ModalProps;
  onClickContinue?: () => void;
  onClickCloseModal: () => void;
  ModalOverlay: (any: ModalFormProps) => JSX.Element;
};

export default function ContactGroupsDangerModal({
  danger,
  ModalOverlay,
  onClickContinue,
  onClickCloseModal,
}: Props) {
  const { LoadingOverlay } = useLoading();

  return (
    <ModalOverlay title={danger.title} variant={danger.variant}>
      <div className="grid grid-cols-12">
        <div className="col-start-1 col-end-12">
          <p className="text-gray-dark400 font-small text-sm">{danger.text}</p>
        </div>
      </div>
      <div className="flex flex-row-reverse mt-8 gap-4">
        <Button size="md" variant="primaryRed" onClick={onClickContinue}>
          <p className="font-medium">Continuar</p>
        </Button>

        <Button variant="primaryGray" size="md" onClick={onClickCloseModal}>
          <p className="font-medium">Cancelar</p>
        </Button>
      </div>
      <LoadingOverlay />
    </ModalOverlay>
  );
}
