import { gql } from '@apollo/client';
import {
  CommercialAgreement,
  CommercialAgreementReceiptRuleType,
} from 'commercialAgreements/models/commercialAgreement';

export interface CommercialAgreementUpdateMutationInput {
  id: number;
  commercialAgreementStartValidity: string;
  commercialAgreementHasPisCofins: boolean;
  commercialAgreementHasExtraRemuneration: boolean;
  commercialAgreementReceiptRule: CommercialAgreementReceiptRuleType;
  commercialAgreementOemValue: string;
  commercialAgreementLeaseValue: string;
  commercialAgreementHasOem: boolean;
  commercialAgreementDiscountMax: string;
  commercialAgreementDiscountMin: string;
  commercialAgreementBaseCostPerCreditIcms: string;
  commercialAgreementBaseCostPerCredit: string;
}

export interface CommercialAgreementUpdateMutationVariables {
  commercialAgreementUpdateInput: CommercialAgreementUpdateMutationInput;
}

export interface CommercialAgreementUpdated {
  commercialAgreementUpdate: CommercialAgreement;
}

export const COMMERCIAL_AGREEMENT_UPDATE_MUTATION = gql`
  mutation commercialAgreementUpdate(
    $commercialAgreementUpdateInput: commercialAgreementUpdateInput!
  ) {
    commercialAgreementUpdate(input: $commercialAgreementUpdateInput) {
      id
      commercialAgreementStartValidity
      commercialAgreementHasPisCofins
      commercialAgreementHasExtraRemuneration
      commercialAgreementReceiptRule
      commercialAgreementLeaseValue
      commercialAgreementOemValue
      commercialAgreementHasOem
      commercialAgreementDiscountMin
      commercialAgreementDiscountMax
      commercialAgreementBaseCostPerCreditIcms
      commercialAgreementBaseCostPerCredit
    }
  }
`;
