import { useEffect, useState } from 'react';
import { useConfirm } from 'ui/contexts/overlay/Confirm';
import { useHistory, useParams } from 'react-router';
import { useForm, SubmitHandler, ErrorOption } from 'react-hook-form';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Dashboard, DashboardMainHeaderForm } from 'dashboard/components/dashboard';
import { Page } from 'dashboard/components/dashboard/Breadcrumbs';
import { Button } from 'ui';
import useToastContext from 'ui/hooks/useToast';
import { useLoading } from 'ui/contexts/overlay/Loading';
import {
  convertToDecimal,
  dateWithoutTimezone,
  dateWithTimezone,
  setFormError,
} from 'utils/form';
import TariffGroupFormFields, {
  FormFields,
  FORM_FIELDS,
} from 'tariffGroups/components/form/TariffGroupFields';
import {
  TariffGroupTypename,
  TARIFF_GROUP_QUERY,
} from 'tariffGroups/graphql/tariffGroupQuery';
import { TariffGroupTypename as TariffGroupTypenameList } from 'tariffGroups/graphql/tariffGroupsListQuery';
import {
  TariffGroupUpdated,
  TariffGroupMutationVariables,
  TariffGroupUpdateMutationInput,
  TARIFF_GROUP_UPDATE_MUTATION,
} from 'tariffGroups/graphql/tariffGroupUpdateMutation';
import {
  ConsumptionCost,
  DemandCost,
  TariffGroupModalityType,
} from 'tariffGroups/models/tariffGroup';
import { DEFAULT_OVERLAY_CONFIG, ToastProps } from 'ui/contexts/overlay/Toast';
import {
  TariffGroupDeleteMutationVariables,
  TARIFF_GROUP_DELETE_MUTATION,
} from 'tariffGroups/graphql/tariffGroupDeleteMutation';
import { OverlayConfig, OverlayProps } from 'ui/models/overlay';
import DeleteButton from 'tariffGroups/components/DeleteButton';
import { has } from 'lodash';
import omit from 'lodash/omit';
import { updateCacheById } from 'graphql/apollo/cache';
import { unMaskFormFields } from 'utils/unmask';

const DASHBOARD_TARIFF_GROUPS_ROUTE = '/dashboard/taxes-fees/tariff-groups';

const BREADCRUMB_PAGES: Page[] = [
  {
    name: 'Tarifas de energia',
    route: DASHBOARD_TARIFF_GROUPS_ROUTE,
    current: false,
  },
  {
    name: 'Edição Tarifa de energia',
    route: null,
    current: true,
  },
];
const TITLE = 'Edição Tarifa de energia';
const UPDATE_ERROR_TOAST: ToastProps = {
  text: 'Houve um erro ao tentar atualizar tarifa de energia',
  title: 'Algo deu errado!',
  variant: 'danger',
};

const UPDATE_SUCCESS_TOAST: ToastProps = {
  title: 'Sucesso!',
  variant: 'primary',
  text: 'Sucesso ao atualizar Tarifa de energia.',
};
const FETCH_ERROR_TOAST: ToastProps = {
  title: 'Algo deu errado!',
  variant: 'danger',
  text: 'Não foi possível carregar o tarifa de energia',
};

export default function EditPowerDistributionUnitsPage() {
  const { ConfirmOverlay, showConfirm, closeConfirm } = useConfirm();
  const { push } = useHistory();
  const { addToast } = useToastContext();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const {
    register,
    control,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    resetField,
    formState: { errors },
  } = useForm<FormFields>();
  const { showLoading, closeLoading, LoadingOverlay } = useLoading();

  const [TariffGroupUpdateMutation, { loading: loadingUpdate }] = useMutation<
    TariffGroupUpdated,
    TariffGroupMutationVariables
  >(TARIFF_GROUP_UPDATE_MUTATION, {
    onError(error: ApolloError) {
      if (has(error.graphQLErrors[0], 'details')) {
        addToast(UPDATE_ERROR_TOAST);
      }

      setFormError(
        error,
        (field: string, error: ErrorOption) => {
          setError(field as keyof FormFields, error);

          setTimeout(() => clearErrors(), 2500);
        },
        t
      );
    },
    onCompleted() {
      addToast(UPDATE_SUCCESS_TOAST);
      push(DASHBOARD_TARIFF_GROUPS_ROUTE);
    },
  });

  const [confirmOverlayProps, setConfirmOverlayProps] = useState<OverlayProps>(
    DEFAULT_OVERLAY_CONFIG
  );

  const [TariffGroupDeleteMutation, { loading: deleteLoading }] =
    useMutation<TariffGroupDeleteMutationVariables>(TARIFF_GROUP_DELETE_MUTATION, {
      onError() {
        closeConfirm();
        setConfirmOverlayProps(DEFAULT_OVERLAY_CONFIG);
        addToast(UPDATE_ERROR_TOAST);
      },
      onCompleted() {
        closeConfirm();
        setConfirmOverlayProps(DEFAULT_OVERLAY_CONFIG);
        addToast(UPDATE_SUCCESS_TOAST);
        push(DASHBOARD_TARIFF_GROUPS_ROUTE);
      },
    });

  const [
    TariffGroupQuery,
    {
      data,
      loading: fetchTariffGroupLoading,
      error: fetchTariffGroupError,
      refetch,
    },
  ] = useLazyQuery(TARIFF_GROUP_QUERY, {
    variables: {
      powerDistributionUnitTariffGroupId: id,
    },
  });

  useEffect(() => {
    if (fetchTariffGroupError) {
      addToast(FETCH_ERROR_TOAST);
    }
  }, [addToast, fetchTariffGroupError]);

  useEffect(() => {
    if (data?.powerDistributionUnitTariffGroup) {
      const { tariffGroupValidityStartDate, tariffGroupValidityEndDate } =
        data?.powerDistributionUnitTariffGroup;

      const tariffGroupFields = Object.assign(
        omit(
          data?.powerDistributionUnitTariffGroup,
          'tariffGroupValidityStartDate',
          'tariffGroupValidityEndDate'
        ) as FormFields,
        {
          tariffGroupValidity: [
            dateWithTimezone(new Date(tariffGroupValidityStartDate)),
            dateWithTimezone(new Date(tariffGroupValidityEndDate)),
          ],
        }
      );

      FORM_FIELDS.forEach((field) => {
        if (field === 'energyUsageTariffOffPeak') {
          if (
            data?.powerDistributionUnitTariffGroup.tariffGroupConsumptionGroup ===
            'B'
          ) {
            return setValue(
              field,
              convertToDecimal(
                data?.powerDistributionUnitTariffGroup?.tariffGroupConsumptionCosts[
                  'energyUsageTariff'
                ]
              )
            );
          }
          return setValue(
            field,
            convertToDecimal(
              data?.powerDistributionUnitTariffGroup?.tariffGroupConsumptionCosts[
                field
              ]
            )
          );
        }

        if (field === 'energyUsageTariffOnPeak') {
          return setValue(
            field,
            convertToDecimal(
              data?.powerDistributionUnitTariffGroup?.tariffGroupConsumptionCosts[
                field
              ]
            )
          );
        }

        if (field === 'energyDistributionServiceTariffOffPeak') {
          if (
            data?.powerDistributionUnitTariffGroup.tariffGroupConsumptionGroup ===
            'B'
          ) {
            return setValue(
              field,
              convertToDecimal(
                data?.powerDistributionUnitTariffGroup?.tariffGroupConsumptionCosts[
                  'energyDistributionServiceTariff'
                ]
              )
            );
          }

          return setValue(
            field,
            convertToDecimal(
              data?.powerDistributionUnitTariffGroup?.tariffGroupConsumptionCosts[
                field
              ]
            )
          );
        }

        if (field === 'energyDistributionServiceTariffOnPeak') {
          return setValue(
            field,
            convertToDecimal(
              data?.powerDistributionUnitTariffGroup?.tariffGroupConsumptionCosts[
                field
              ]
            )
          );
        }

        if (field === 'demandCostAll') {
          return setValue(
            field,
            convertToDecimal(
              data?.powerDistributionUnitTariffGroup?.tariffGroupDemandCosts?.all
            )
          );
        }

        if (field === 'demandCostOnPeak') {
          return setValue(
            field,
            convertToDecimal(
              data?.powerDistributionUnitTariffGroup?.tariffGroupDemandCosts?.onPeak
            )
          );
        }

        if (field === 'demandCostOffPeak') {
          return setValue(
            field,
            convertToDecimal(
              data?.powerDistributionUnitTariffGroup?.tariffGroupDemandCosts?.offPeak
            )
          );
        }

        return setValue(field, tariffGroupFields[field]);
      });
    }
  }, [data, setValue]);

  useEffect(() => {
    TariffGroupQuery();
  }, [TariffGroupQuery, id]);

  useEffect(() => {
    refetch && refetch();
  }, [refetch]);

  const onSubmit: SubmitHandler<FormFields> = (
    powerDistributionUnitTariffGroupData
  ) => {
    const {
      demandCostAll,
      demandCostOffPeak,
      demandCostOnPeak,
      energyDistributionServiceTariff,
      energyDistributionServiceTariffOffPeak,
      energyDistributionServiceTariffOnPeak,
      energyUsageTariff,
      energyUsageTariffOffPeak,
      energyUsageTariffOnPeak,
      tariffGroupValidity: [validityStart, validityEnd],
      tariffGroupModality,
      powerDistributionUnitId,
      ...otherData
    } = powerDistributionUnitTariffGroupData;

    let conditionalFields: {
      tariffGroupDemandCosts?: DemandCost;
      tariffGroupConsumptionCosts: ConsumptionCost;
    };

    if (tariffGroupModality === TariffGroupModalityType.CONVENTIONAL) {
      conditionalFields = {
        tariffGroupConsumptionCosts: {
          energyDistributionServiceTariff: energyDistributionServiceTariffOffPeak,
          energyUsageTariff: energyUsageTariffOffPeak,
        },
      };
    } else {
      conditionalFields = {
        tariffGroupConsumptionCosts: {
          energyDistributionServiceTariffOffPeak,
          energyDistributionServiceTariffOnPeak,
          energyUsageTariffOffPeak,
          energyUsageTariffOnPeak,
        },
        tariffGroupDemandCosts:
          tariffGroupModality === TariffGroupModalityType.BLUE
            ? {
                offPeak: demandCostOffPeak,
                onPeak: demandCostOnPeak,
              }
            : {
                all: demandCostAll,
              },
      };
    }

    const tariffGroupValidityStartDate = dateWithoutTimezone(validityStart)
      .toISOString()
      .split('T')[0];

    const tariffGroupValidityEndDate = dateWithoutTimezone(validityEnd)
      .toISOString()
      .split('T')[0];

    const powerDistributionUnitTariffGroupUpdateInput: TariffGroupUpdateMutationInput =
      {
        id: Number(id),
        ...otherData,
        ...conditionalFields,
        tariffGroupModality,
        tariffGroupValidityStartDate,
        tariffGroupValidityEndDate,
      };

    return TariffGroupUpdateMutation({
      variables: {
        powerDistributionUnitTariffGroupUpdateInput: unMaskFormFields(
          powerDistributionUnitTariffGroupUpdateInput
        ),
      },
      update(cache) {
        updateCacheById(cache, Number(id), TariffGroupTypenameList);
      },
    });
  };

  const isLoading = loadingUpdate || deleteLoading || fetchTariffGroupLoading;

  useEffect(() => {
    if (isLoading) {
      showLoading();
      return;
    }

    closeLoading();
  }, [isLoading, showLoading, closeLoading]);

  const onConfirmDelete = () =>
    TariffGroupDeleteMutation({
      variables: { id: id },
      update(cache) {
        updateCacheById(cache, Number(id), TariffGroupTypename);
      },
    });

  const onClickDelete = (overlayConfig: OverlayConfig) => {
    setConfirmOverlayProps({
      ...overlayConfig,
      onConfirm: onConfirmDelete,
    });

    showConfirm();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay />
      <Dashboard
        dashboardHeader={<></>}
        dashboardMainHeaderTitle={
          <DashboardMainHeaderForm breadcrumbPages={BREADCRUMB_PAGES} title={TITLE}>
            {data?.powerDistributionUnitTariffGroup && (
              <>
                {' '}
                <DeleteButton onClick={onClickDelete} disabled={isLoading} />
                <Button type="submit" size="sm" disabled={isLoading}>
                  Salvar
                </Button>
              </>
            )}
            <ConfirmOverlay {...confirmOverlayProps} onCancel={closeConfirm} />
          </DashboardMainHeaderForm>
        }
      >
        {data?.powerDistributionUnitTariffGroup && (
          <TariffGroupFormFields
            powerDistributionUnits={
              data.powerDistributionUnitTariffGroup.powerDistributionUnit
                ? [
                    {
                      id: data.powerDistributionUnitTariffGroup.powerDistributionUnit
                        ?.id,
                      powerDistributionUnitLegalName:
                        data.powerDistributionUnitTariffGroup.powerDistributionUnit
                          ?.powerDistributionUnitLegalName,
                    },
                  ]
                : []
            }
            powerDistributionUnitValue={
              data.powerDistributionUnitTariffGroup.powerDistributionUnit?.id
            }
            disablePowerDistributionUnit
            errors={errors}
            register={register}
            control={control}
            setValue={setValue}
            resetField={resetField}
            clearErrors={clearErrors}
          />
        )}
      </Dashboard>
    </form>
  );
}
