import { BankId, BankSelect } from 'banks/models/bank';

export type FinancialAccountId = number;

export enum FinancialAccountEntityType {
  INDIVIDUAL = 'INDIVIDUAL',
  COMPANY = 'COMPANY',
}

export enum FinancialAccountType {
  CHECKING = 'CHECKING',
  SAVINGS = 'SAVINGS',
}

export interface FinancialAccount {
  bankId: BankId;
  bank?: BankSelect;
  id: FinancialAccountId;
  financialAccountBankAg: string;
  financialAccountBankCc: string;
  financialAccountLegalName: string;
  financialAccountDocumentId: string;
  financialAccountAddressCity: string;
  financialAccountAddressState: string;
  financialAccountAddressStreet: string;
  financialAccountAddressDistrict: string;
  financialAccountAddressComplement: string;
  financialAccountAddressPostalCode: number;
  financialAccountType: FinancialAccountType;
  financialAccountEntityType: FinancialAccountEntityType;
}

export interface FinancialAccountSelect {
  id: FinancialAccountId;
  financialAccountLegalName: string;
}
