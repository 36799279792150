import { gql } from '@apollo/client';
import { CommercialAgreement } from 'commercialAgreements/models/commercialAgreement';

interface CommercialAgreementConnection {
  afterCursor: string | null;
  beforeCursor: string | null;
  entries: CommercialAgreement[];
}

export interface CommercialAgreementsList {
  commercialAgreements: CommercialAgreementConnection;
}

export const CommercialAgreementsTypename = 'CommercialAgreementConnection';

export const COMMERCIAL_AGREEMENTS_QUERY = gql`
  query commercialAgreements(
    $after: String
    $before: String
    $pageSize: Int
    $filters: CommercialAgreementFilterOptions
    $maximumLimit: Int
  ) {
    commercialAgreements(
      after: $after
      before: $before
      pageSize: $pageSize
      filters: $filters
      maximumLimit: $maximumLimit
    ) {
      entries {
        id
        commercialAgreementHasOem
        commercialAgreementOemValue
        commercialAgreementLeaseValue
        commercialAgreementReceiptRule
        commercialAgreementDiscountMin
        commercialAgreementDiscountMax
        commercialAgreementHasPisCofins
        commercialAgreementStartValidity
        commercialAgreementBaseCostPerCredit
        commercialAgreementHasExtraRemuneration
        commercialAgreementBaseCostPerCreditIcms
        commercialAgreementValidityStatus
        generationUnit {
          id
          generationUnitLegalName
        }
      }
      beforeCursor
      afterCursor
    }
  }
`;
