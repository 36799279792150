import { gql } from '@apollo/client';

import { ConsumerUnit } from 'consumerUnits/model/consumerUnit';

interface ConsumerUnitConnection {
  entries: ConsumerUnit[];
  afterCursor: string | null;
  beforeCursor: string | null;
}

export interface ConsumerUnitsWithoutContactGroupsList {
  listConsumerUnitsWithoutContactGroups: ConsumerUnitConnection;
}

export const ConsumerUnitWithoutContactGroupsTypename = 'ConsumerUnitConnection';

export const CONSUMER_UNITS_WITHOUT_GROUP_AND_CONTACTS_QUERY = gql`
  query listConsumerUnitsWithoutContactGroups(
    $after: String
    $before: String
    $pageSize: Int
    $maximumLimit: Int
  ) {
    listConsumerUnitsWithoutContactGroups(
      after: $after
      before: $before
      pageSize: $pageSize
      maximumLimit: $maximumLimit
    ) {
      entries {
        id
        consumerUnitAddressCity
        consumerUnitAddressState
        consumerUnitAddressDistrict
        consumerUnitConsumptionGroupType
        consumerUnitPowerDistributionUnitIdentifier
        cooperative {
          cooperativeLegalName
        }
        cooperativeMember {
          id
          cooperativeMemberLegalName
          cooperativeMemberDocumentId
        }
        powerDistributionUnit {
          powerDistributionUnitLegalName
        }
      }
      beforeCursor
      afterCursor
    }
  }
`;
