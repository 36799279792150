type Props = {
  children: React.ReactNode;
};

export default function TableBody({ children }: Props) {
  return (
    <tbody className="bg-gray-dark700 divide-y divide-gray-dark700">
      {children}
    </tbody>
  );
}
