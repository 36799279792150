import { gql } from '@apollo/client';

export interface PowerDistributionUnitPisCofinsTaxQueryVariables {
  id: string;
}

export const PowerDistributionUnitPisCofinsTaxTypename =
  'PowerDistributionUnitPisCofinsTax';

export const POWER_DISTRIBUTION_UNIT_PIS_COFINS_TAX_QUERY = gql`
  query powerDistributionUnitPisCofinsTax($id: ID!) {
    powerDistributionUnitPisCofinsTax(id: $id) {
      id
      pisCofinsValidityStartAt
      pisCofinsValidityEndAt
      pisCofinsPisFee
      pisCofinsCofinsFee
      powerDistributionUnitId
      powerDistributionUnit {
        powerDistributionUnitLegalName
      }
    }
  }
`;
