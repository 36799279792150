import { FinancialAccount } from 'financialAccounts/models/financialAccounts';
import FinancialAccountsTableItem from './FinancialAccountsTableItem';
import Table from 'ui/components/table/Table';
import TableHead from 'ui/components/table/TableHead';
import TableHeadColumn from 'ui/components/table/TableHeadColumn';
import TableBody from 'ui/components/table/TableBody';

type Props = {
  financialAccounts: FinancialAccount[];
  onClickEditButton: (id: number) => void;
  onClickRemoveButton: (id: number) => void;
};

export default function PowerDistributionUnitsTable({
  financialAccounts,
  onClickEditButton,
  onClickRemoveButton,
}: Props) {
  return (
    <>
      <Table>
        <TableHead>
          <TableHeadColumn>Nome</TableHeadColumn>
          <TableHeadColumn>Tipo</TableHeadColumn>
          <TableHeadColumn>Documento</TableHeadColumn>
          <TableHeadColumn>UF</TableHeadColumn>
          <TableHeadColumn>CEP</TableHeadColumn>
          <TableHeadColumn className="text-right">Ações</TableHeadColumn>
        </TableHead>
        <TableBody>
          {financialAccounts.map((financialAccount: FinancialAccount) => (
            <FinancialAccountsTableItem
              key={financialAccount.id}
              financialAccounts={financialAccount}
              onClickEditButton={onClickEditButton}
              onClickRemoveButton={onClickRemoveButton}
            />
          ))}
        </TableBody>
      </Table>
    </>
  );
}
