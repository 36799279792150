import { Button } from 'ui';
import { Dashboard, DashboardMainHeaderForm } from 'dashboard/components/dashboard';
import { useConfirm } from 'ui/contexts/overlay/Confirm';
import { Page } from 'dashboard/components/dashboard/Breadcrumbs';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import useToastContext from 'ui/hooks/useToast';
import { ErrorOption, SubmitHandler, useForm } from 'react-hook-form';
import CooperativeMemberFormFields, {
  FormFields,
  FORM_FIELDS,
} from 'cooperatives/components/form/CooperativeMemberFormFields';
import { useLoading } from 'ui/contexts/overlay/Loading';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  CooperativeMemberUpdated,
  CooperativeMemberUpdateMutationVariables,
  COOPERATIVE_MEMBER_UPDATE_MUTATION,
} from 'cooperatives/graphql/coopertativeMemberUpdateMutation';
import { setFormError } from 'utils/form';
import {
  CooperativeMemberTypename,
  COOPERATIVE_MEMBER_QUERY,
} from 'cooperatives/graphql/cooperativeMemberQuery';
import { DEFAULT_OVERLAY_CONFIG, ToastProps } from 'ui/contexts/overlay/Toast';
import {
  CooperativeMemberDeleteMutationVariables,
  COOPERATIVE_MEMBER_DELETE_MUTATION,
} from 'cooperatives/graphql/cooperativeMemberDeleteMutation';
import { OverlayConfig, OverlayProps } from 'ui/models/overlay';
import DeleteButton from 'cooperatives/components/CooperativeMemberDeleteButton';
import { has } from 'lodash';
import { updateCacheById } from 'graphql/apollo/cache';
import { CooperativeMemberEntityType } from 'cooperatives/models/cooperativeMember';

const DASHBOARD_COOPERATIVE_MEMBERS_ROUTE =
  '/dashboard/business-entities/cooperative-members';

const BREADCRUMB_PAGES: Page[] = [
  {
    name: 'Cooperados',
    route: DASHBOARD_COOPERATIVE_MEMBERS_ROUTE,
    current: false,
  },
  {
    name: 'Edição de Cooperados',
    route: null,
    current: true,
  },
];

const TITLE = 'Edição de Cooperados';

const UPDATE_ERROR_TOAST: ToastProps = {
  text: 'Houve um erro ao tentar atualizar o Cooperado',
  title: 'Algo deu errado!',
  variant: 'danger',
};
const UPDATE_SUCCESS_TOAST: ToastProps = {
  title: 'Sucesso!',
  variant: 'primary',
  text: 'Sucesso ao atualizar o Cooperado.',
};
const FETCH_ERROR_TOAST: ToastProps = {
  title: 'Algo deu errado!',
  variant: 'danger',
  text: 'Não foi possível carregar o Cooperado',
};

export default function EditCooperativeMembersPage() {
  const { ConfirmOverlay, showConfirm, closeConfirm } = useConfirm();
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();
  const { addToast } = useToastContext();
  const { t } = useTranslation();

  const { showLoading, closeLoading, LoadingOverlay } = useLoading();

  const [cooperativeMemberUpdateMutation, { loading: updateLoading }] = useMutation<
    CooperativeMemberUpdated,
    CooperativeMemberUpdateMutationVariables
  >(COOPERATIVE_MEMBER_UPDATE_MUTATION, {
    onError(error: ApolloError) {
      if (has(error.graphQLErrors[0], 'details')) {
        addToast(UPDATE_ERROR_TOAST);
      }

      setFormError(
        error,
        (field: string, error: ErrorOption) => {
          setError(field as keyof FormFields, error);

          setTimeout(() => clearErrors(), 2500);
        },
        t
      );
    },
    onCompleted() {
      addToast(UPDATE_SUCCESS_TOAST);
      push(DASHBOARD_COOPERATIVE_MEMBERS_ROUTE);
    },
  });

  const [cooperativeMemberDeleteMutation, { loading: deleteLoading }] =
    useMutation<CooperativeMemberDeleteMutationVariables>(
      COOPERATIVE_MEMBER_DELETE_MUTATION,
      {
        onError() {
          closeConfirm();
          setConfirmOverlayProps(DEFAULT_OVERLAY_CONFIG);
          addToast(UPDATE_ERROR_TOAST);
        },
        onCompleted() {
          closeConfirm();
          setConfirmOverlayProps(DEFAULT_OVERLAY_CONFIG);
          addToast(UPDATE_SUCCESS_TOAST);
          push(DASHBOARD_COOPERATIVE_MEMBERS_ROUTE);
        },
      }
    );

  const [
    cooperativeMemberQuery,
    {
      data,
      loading: fetchCooperativeMemberLoading,
      error: fetchCooperativeMemberError,
      refetch,
    },
  ] = useLazyQuery(COOPERATIVE_MEMBER_QUERY, {
    variables: {
      id: id,
    },
  });

  const {
    control,
    register,
    setError,
    setValue,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm<FormFields>();

  useEffect(() => {
    if (fetchCooperativeMemberError) {
      addToast(FETCH_ERROR_TOAST);
    }
  }, [addToast, fetchCooperativeMemberError]);

  useEffect(() => {
    if (data?.cooperativeMember) {
      FORM_FIELDS.forEach((field) => setValue(field, data.cooperativeMember[field]));

      setValue('cooperativeId', data.cooperativeMember.cooperative.id);

      setValue(
        'cooperativeMemberOptionalEmail',
        Array.isArray(data.cooperativeMember.cooperativeMemberOptionalEmail)
          ? data.cooperativeMember.cooperativeMemberOptionalEmail
              .map(
                (error: string, index: number, array: string[]) =>
                  `${error}${index < array.length - 1 ? ',' : ''}`
              )
              .join('')
          : data.cooperativeMember.cooperativeMemberOptionalEmail
      );
    }
  }, [data, setValue]);

  useEffect(() => {
    cooperativeMemberQuery();
  }, [cooperativeMemberQuery, id]);

  useEffect(() => {
    refetch && refetch();
  }, [refetch]);

  const [confirmOverlayProps, setConfirmOverlayProps] = useState<OverlayProps>(
    DEFAULT_OVERLAY_CONFIG
  );

  const isLoading = !!(
    deleteLoading ||
    updateLoading ||
    fetchCooperativeMemberLoading
  );

  useEffect(() => {
    if (isLoading) {
      showLoading();
      return;
    }

    closeLoading();
  }, [isLoading, showLoading, closeLoading]);

  const onSubmit: SubmitHandler<FormFields> = (cooperativeMemberUpdateInput) => {
    const cooperativeMemberModalityType =
      (cooperativeMemberUpdateInput.cooperativeMemberEntityType ===
        CooperativeMemberEntityType.COMPANY &&
        cooperativeMemberUpdateInput.cooperativeMemberModalityType) ||
      null;

    cooperativeMemberUpdateMutation({
      variables: {
        cooperativeMemberUpdateInput: {
          id: Number(id),
          cooperativeMemberLegalName:
            cooperativeMemberUpdateInput.cooperativeMemberLegalName,
          cooperativeMemberDocumentId:
            cooperativeMemberUpdateInput.cooperativeMemberDocumentId,
          cooperativeMemberEntityType:
            cooperativeMemberUpdateInput.cooperativeMemberEntityType,
          cooperativeMemberModalityType: cooperativeMemberModalityType,
          cooperativeMemberEmail:
            cooperativeMemberUpdateInput.cooperativeMemberEmail,
          cooperativeMemberOptionalEmail:
            cooperativeMemberUpdateInput.cooperativeMemberOptionalEmail?.split(','),
        },
      },
    });
  };

  const onConfirmDelete = () =>
    cooperativeMemberDeleteMutation({
      variables: { id: id },
      update(cache) {
        updateCacheById(cache, Number(id), CooperativeMemberTypename);
      },
    });

  const onClickDelete = (overlayConfig: OverlayConfig) => {
    setConfirmOverlayProps({
      ...overlayConfig,
      onConfirm: onConfirmDelete,
    });

    showConfirm();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay />
      <Dashboard
        dashboardHeader={<></>}
        dashboardMainHeaderTitle={
          <DashboardMainHeaderForm title={TITLE} breadcrumbPages={BREADCRUMB_PAGES}>
            {data?.cooperativeMember && (
              <>
                {' '}
                <DeleteButton onClick={onClickDelete} disabled={isLoading} />
                <Button type="submit" size="sm" disabled={isLoading}>
                  Salvar
                </Button>
              </>
            )}
            <ConfirmOverlay {...confirmOverlayProps} onCancel={closeConfirm} />
          </DashboardMainHeaderForm>
        }
      >
        {data?.cooperativeMember && (
          <CooperativeMemberFormFields
            cooperatives={[
              {
                id: data.cooperativeMember.cooperative?.id,
                cooperativeLegalName:
                  data.cooperativeMember.cooperative?.cooperativeLegalName,
              },
            ]}
            formErrors={errors}
            control={control}
            register={register}
            disabledCooperative
          />
        )}
      </Dashboard>
    </form>
  );
}
