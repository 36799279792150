import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';

import { has } from 'lodash';

import { setFormError } from 'utils/form';
import { unMaskFormFields } from 'utils/unmask';

import { useTranslation } from 'react-i18next';

import { useHistory, useLocation } from 'react-router';

import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';

import { useForm, SubmitHandler, ErrorOption } from 'react-hook-form';

import { Button } from 'ui';
import useToastContext from 'ui/hooks/useToast';
import { useModal } from 'ui/contexts/overlay/Modal';
import { ToastProps } from 'ui/contexts/overlay/Toast';
import { useLoading } from 'ui/contexts/overlay/Loading';

import { Page } from 'dashboard/components/dashboard/Breadcrumbs';
import { Dashboard, DashboardMainHeaderForm } from 'dashboard/components/dashboard';

import { ConsumerUnitType } from '../models/consumerUnitBillData';

import {
  ConsumerUnitBillingCycleList,
  consumerUnitBillingCyclesTypename,
  CONSUMER_UNIT_BILLING_CYCLES_QUERY,
} from 'consumerUnits/consumerUnitBillingCycles/graphql/consumerUnitBillingCyclesQuery';

import { ConsumerUnitBillingCycle } from 'consumerUnits/consumerUnitBillingCycles/models/consumerUnitBillingCycles';

import {
  ConsumerUnitBillDataCreated,
  ConsumerUnitBillDataMutationVariables,
  CONSUMER_UNIT_BILL_DATA_CREATE_MUTATION,
} from 'powerDistributionUnits/powerDistributionUnitBillData/consumerUnitBillData/graphql/consumerUnitCreateMutation';

import {
  ConsumerUnitBillingCaptureUploadBillCreated,
  ConsumerUnitBillingCaptureUploadBillMutationVariables,
  CONSUMER_UNIT_BILLING_CAPTURE_UPLOAD_BILL_MUTATION,
} from 'consumerUnitBillingCapture/graphql/consumerUnitBillingCaptureUploadBillMutation';

import {
  ConsumerUnitBillingCaptureDownloadBill,
  CONSUMER_UNIT_BILLING_CAPTURE_DOWNLOAD_BILL_QUERY,
} from 'consumerUnitBillingCapture/graphql/consumerUnitBillingCaptureDownloadBillQuery';

import ConsumerUnitBillDataFormFields, {
  FormFields,
} from 'powerDistributionUnits/powerDistributionUnitBillData/consumerUnitBillData/components/form/consumerUnitBillDataFormFields';

import UploadConsumerUnitBillData from '../components/modal/UploadConsumerUnitBillData';
import DangerConsumerUnitBillData from '../components/modal/DangerConsumerUnitBillData';

const DASHBOARD_ROUTE = '/dashboard/billing-cycle/billing-cycles';
const DASHBOARD_TITLE = 'Cadastrar dados da fatura da Unidade Consumidora';

const BREADCRUMB_PAGES: Page[] = [
  {
    current: false,
    route: DASHBOARD_ROUTE,
    name: 'Creditação de Unidade Consumidora',
  },
  {
    route: null,
    current: true,
    name: DASHBOARD_TITLE,
  },
];

const UPLOAD_ERROR_TOAST = (text?: string) => ({
  variant: 'danger',
  title: 'Algo deu errado!',
  text: text || 'Houve um erro ao tentar realizar o upload da fatura.',
});

const UPLOAD_SUCCESS_TOAST: ToastProps = {
  title: 'Sucesso!',
  variant: 'primary',
  text: 'Sucesso ao realizar o upload da fatura.',
};

const DOWNLOAD_ERROR_TOAST = (text?: string) => ({
  variant: 'danger',
  title: 'Algo deu errado!',
  text: text || 'Houve um erro ao tentar realizar o download da fatura.',
});

const DOWNLOAD_SUCCESS_TOAST: ToastProps = {
  title: 'Sucesso!',
  variant: 'primary',
  text: 'Sucesso ao realizar o download da fatura.',
};

const CREATE_ERROR_TOAST = (text?: string) => ({
  variant: 'danger',
  title: 'Algo deu errado!',
  text:
    text ||
    'Houve um erro ao tentar cadastrar os dados da fatura da Unidade Consumidora.',
});

const CREATE_SUCCESS_TOAST: ToastProps = {
  title: 'Sucesso!',
  variant: 'primary',
  text: 'Sucesso ao cadastrar os dados da fatura da nova Unidade Consumidora.',
};

const CREATE_DANGER_TOAST: ToastProps = {
  variant: 'danger',
  title: 'Inclusão dos dados da fatura',
  text: 'Você tem certeza de que os dados estão corretos? Eles estão diretamente ligados ao faturamento dessa UC.',
};

type ModalProps = 'upload' | 'danger';

export default function CreateConsumerUnitBillDataPage() {
  const { push } = useHistory();
  const { t } = useTranslation();
  const location = useLocation();
  const { addToast } = useToastContext();
  const { showLoading, closeLoading, LoadingOverlay } = useLoading();

  const [uploadedFile, setUploadedFile] = useState<File | null>();

  const [uploadError, setUploadError] = useState(false);

  const consumerUnitBillingCycles = location.state as ConsumerUnitBillingCycle;

  const [
    showUploadConsumerUnitBillDataModal,
    setShowUploadConsumerUnitBillDataModal,
  ] = useState(false);

  const [
    showDangerConsumerUnitBillDataModal,
    setShowDangerConsumerUnitBillDataModal,
  ] = useState(false);

  const {
    control,
    register,
    setError,
    setValue,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm<FormFields>();

  const {
    ModalOverlay,
    showConfirm: modalShowConfirm,
    closeConfirm: modalCloseConfirm,
  } = useModal();

  const [consumerUnitBillDataCreateMutation, { loading: loadingCreate }] =
    useMutation<ConsumerUnitBillDataCreated, ConsumerUnitBillDataMutationVariables>(
      CONSUMER_UNIT_BILL_DATA_CREATE_MUTATION,
      {
        onError(error: ApolloError) {
          modalCloseConfirm();
          setShowDangerConsumerUnitBillDataModal(false);

          if (has(error.graphQLErrors[0], 'message')) {
            addToast(
              CREATE_ERROR_TOAST(
                t(error.graphQLErrors[0].message)
              ) as unknown as ToastProps
            );
          }

          setFormError(
            error,
            (field: string, error: ErrorOption) => {
              setError(field as keyof FormFields, error);

              setTimeout(() => clearErrors(), 2500);
            },
            t
          );
        },
        onCompleted() {
          addToast(CREATE_SUCCESS_TOAST);
          ((!!uploadedFile && !uploadError) || !uploadedFile) &&
            push(DASHBOARD_ROUTE);
        },
      }
    );

  const HAS_CONSUMER_UNIT_BILLING_CAPTURE_URL = useMemo(
    () => ({
      consumerUnitBillingCaptureUrl:
        consumerUnitBillingCycles?.consumerUnitBillingCapture
          ?.consumerUnitBillingCaptureUrl || '',
    }),
    [consumerUnitBillingCycles]
  );

  const downloadBill = useCallback(
    (url: string) => {
      if (url) {
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute(
          'download',
          HAS_CONSUMER_UNIT_BILLING_CAPTURE_URL?.consumerUnitBillingCaptureUrl as string
        );
        document.body.appendChild(link);
        link.click();
      }
    },
    [HAS_CONSUMER_UNIT_BILLING_CAPTURE_URL?.consumerUnitBillingCaptureUrl]
  );

  const [
    consumerUnitBillingCaptureDownloadBill,
    {
      data: consumerUnitBillingCaptureDownload,
      loading: loadingConsumerUnitBillingCaptureDownload,
    },
  ] = useLazyQuery<ConsumerUnitBillingCaptureDownloadBill>(
    CONSUMER_UNIT_BILLING_CAPTURE_DOWNLOAD_BILL_QUERY,
    {
      variables: HAS_CONSUMER_UNIT_BILLING_CAPTURE_URL,
      onError(error: ApolloError) {
        if (has(error.graphQLErrors[0], 'message')) {
          addToast(
            DOWNLOAD_ERROR_TOAST(
              t(error.graphQLErrors[0].message)
            ) as unknown as ToastProps
          );
        }
      },
      onCompleted() {
        const { consumerUnitBillingCaptureUrl } =
          consumerUnitBillingCaptureDownload?.consumerUnitBillingCaptureDownloadBill ||
          {};

        downloadBill(consumerUnitBillingCaptureUrl || '');

        addToast(DOWNLOAD_SUCCESS_TOAST);
      },
    }
  );

  const [consumerUnitBillingCaptureUploadBillMutation, { loading: loadingUpload }] =
    useMutation<
      ConsumerUnitBillingCaptureUploadBillCreated,
      ConsumerUnitBillingCaptureUploadBillMutationVariables
    >(CONSUMER_UNIT_BILLING_CAPTURE_UPLOAD_BILL_MUTATION, {
      onError(error: ApolloError) {
        modalCloseConfirm();
        setUploadError(true);
        setShowDangerConsumerUnitBillDataModal(false);

        if (has(error.graphQLErrors[0], 'message')) {
          addToast(
            UPLOAD_ERROR_TOAST(
              t(error.graphQLErrors[0].message)
            ) as unknown as ToastProps
          );
        }

        setFormError(
          error,
          (field: string, error: ErrorOption) => {
            setError(field as keyof FormFields, error);

            setTimeout(() => clearErrors(), 2500);
          },
          t
        );
      },
      onCompleted() {
        setUploadError(false);

        addToast(UPLOAD_SUCCESS_TOAST);
      },
    });

  const handleUploadConsumerUnitBillingCapture = useCallback(() => {
    !!uploadedFile &&
      consumerUnitBillingCaptureUploadBillMutation({
        variables: {
          consumerUnitUploadBillingCaptureFileMetadata: uploadedFile,
          consumerUnitUploadBillingCaptureInput: {
            consumerUnitUploadBillingCaptureBillConsumerUnitType:
              ConsumerUnitType.CONSUMER,
            consumerUnitUploadBillingCaptureBillConsumerUnitId:
              consumerUnitBillingCycles?.consumerUnit?.id,
            consumerUnitUploadBillingCaptureBillReferenceMonth:
              consumerUnitBillingCycles?.consumerUnitBillingCycleDate ||
              (consumerUnitBillingCycles?.powerDistributionUnitBillData
                ?.billReferenceDate as string),
            consumerUnitUploadBillingCapturePowerDistributionUnitIdentifier:
              consumerUnitBillingCycles?.consumerUnit
                ?.consumerUnitPowerDistributionUnitIdentifier,
          },
        },
      });
  }, [
    uploadedFile,
    consumerUnitBillingCycles,
    consumerUnitBillingCaptureUploadBillMutation,
  ]);

  const onSubmit: SubmitHandler<FormFields> = (consumerUnitBillDataCreateInput) => {
    consumerUnitBillDataCreateInput = unMaskFormFields(
      consumerUnitBillDataCreateInput
    );
    handleUploadConsumerUnitBillingCapture();

    consumerUnitBillDataCreateMutation({
      variables: {
        powerDistributionUnitConsumerUnitBillDataCreateInput: {
          ...consumerUnitBillDataCreateInput,
          billConsumerUnitType: ConsumerUnitType.CONSUMER,
          billConsumerUnitId: Number(consumerUnitBillingCycles?.consumerUnit?.id),
          powerDistributionUnitId:
            Number(
              consumerUnitBillingCycles?.consumerUnit?.powerDistributionUnit?.id
            ) ||
            Number(
              consumerUnitBillingCycles?.powerDistributionUnitBillData
                ?.powerDistributionUnitId
            ),
          billConsumerUnitConsumptionGroupType:
            consumerUnitBillingCycles?.consumerUnit
              ?.consumerUnitConsumptionGroupType,
        },
      },
      update(cache, { data }) {
        const existingData = cache.readQuery<ConsumerUnitBillingCycleList>({
          query: CONSUMER_UNIT_BILLING_CYCLES_QUERY,
        });
        cache.writeQuery({
          query: CONSUMER_UNIT_BILLING_CYCLES_QUERY,
          data: {
            consumerUnitBillingCycles: {
              __typename: consumerUnitBillingCyclesTypename,
              afterCursor:
                existingData?.consumerUnitBillingCycles.afterCursor || null,
              beforeCursor:
                existingData?.consumerUnitBillingCycles.beforeCursor || null,
              entries: [
                data?.powerDistributionUnitConsumerUnitBillDataCreate,
                ...(existingData?.consumerUnitBillingCycles.entries || []),
              ],
            },
          },
        });
      },
    });
  };

  const isLoading =
    loadingCreate || loadingUpload || loadingConsumerUnitBillingCaptureDownload;

  useEffect(() => {
    if (isLoading) {
      showLoading();
      return;
    }

    closeLoading();
  }, [isLoading, showLoading, closeLoading]);

  const onClickOpenModal = (modal: ModalProps) => {
    modalShowConfirm();
    modal === 'upload'
      ? setShowUploadConsumerUnitBillDataModal(true)
      : setShowDangerConsumerUnitBillDataModal(true);
  };

  const onClickCloseModal = (modal: ModalProps) => {
    modalCloseConfirm();
    modal === 'upload'
      ? setShowUploadConsumerUnitBillDataModal(false)
      : setShowDangerConsumerUnitBillDataModal(false);
  };

  const onClickCancelModal = () => {
    modalCloseConfirm();
    setUploadedFile(null);
    setShowUploadConsumerUnitBillDataModal(false);
  };

  const handleUploadFile = (file: ChangeEvent<HTMLInputElement>) => {
    if (file.target.files) {
      setUploadedFile(file.target.files[0]);
    }
  };

  const handleClickDownloadBill = () => consumerUnitBillingCaptureDownloadBill();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay />
      <Dashboard
        dashboardHeader={<></>}
        dashboardMainHeaderTitle={
          <DashboardMainHeaderForm
            title={DASHBOARD_TITLE}
            breadcrumbPages={BREADCRUMB_PAGES}
          >
            <Button
              size="sm"
              type="button"
              disabled={isLoading}
              onClick={() => onClickOpenModal('danger')}
            >
              Salvar
            </Button>
          </DashboardMainHeaderForm>
        }
      >
        <ConsumerUnitBillDataFormFields
          control={control}
          formErrors={errors}
          setValue={setValue}
          register={register}
          uploadedFile={uploadedFile}
          onClickCancelModal={onClickCancelModal}
          handleClickDownloadBill={handleClickDownloadBill}
          onClickOpenModal={() => onClickOpenModal('upload')}
          consumerUnitBillingCycles={consumerUnitBillingCycles}
        />

        {showUploadConsumerUnitBillDataModal && (
          <UploadConsumerUnitBillData
            ModalOverlay={ModalOverlay}
            handleUploadFile={handleUploadFile}
            onClickCancelModal={onClickCancelModal}
            onClickCloseModal={() => onClickCloseModal('upload')}
          />
        )}

        {showDangerConsumerUnitBillDataModal && (
          <DangerConsumerUnitBillData
            ModalOverlay={ModalOverlay}
            danger={CREATE_DANGER_TOAST}
            onClickContinue={handleSubmit(onSubmit)}
            onClickCloseModal={() => onClickCloseModal('danger')}
          />
        )}
      </Dashboard>
    </form>
  );
}
