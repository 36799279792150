import { gql } from '@apollo/client';

import {
  FinancialAccount,
  FinancialAccountType,
  FinancialAccountEntityType,
} from 'financialAccounts/models/financialAccounts';

export interface FinancialAccountCreateMutationInput {
  bankId: number;
  financialAccountBankAg: string;
  financialAccountBankCc: string;
  financialAccountLegalName: string;
  financialAccountDocumentId: string;
  financialAccountAddressCity: string;
  financialAccountAddressState: string;
  financialAccountAddressStreet: string;
  financialAccountAddressDistrict: string;
  financialAccountAddressComplement: string;
  financialAccountAddressPostalCode: number;
  financialAccountType: FinancialAccountType;
  financialAccountEntityType: FinancialAccountEntityType;
}

export interface FinancialAccountMutationVariables {
  financialAccountCreateInput: FinancialAccountCreateMutationInput;
}

export interface FinancialAccountCreated {
  financialAccountCreate: FinancialAccount;
}

export const FINANCIAL_ACCOUNT_CREATE_MUTATION = gql`
  mutation financialAccountCreate(
    $financialAccountCreateInput: FinancialAccountCreateInput!
  ) {
    financialAccountCreate(input: $financialAccountCreateInput) {
      id
      financialAccountType
      financialAccountBankAg
      financialAccountBankCc
      financialAccountLegalName
      financialAccountEntityType
      financialAccountDocumentId
      financialAccountAddressCity
      financialAccountAddressState
      financialAccountAddressStreet
      financialAccountAddressDistrict
      financialAccountAddressPostalCode
      financialAccountAddressComplement
      bank {
        id
      }
    }
  }
`;
