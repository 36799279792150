import { gql } from '@apollo/client';
import { IcmsTax } from 'icmsTaxes/models/icmsTaxes';

interface IcmsTaxesConnection {
  afterCursor: string | null;
  beforeCursor: string | null;
  entries: IcmsTax[];
}

export interface IcmsTaxList {
  icmsTaxes: IcmsTaxesConnection;
}

export const icmsTaxTypename = 'IcmsTaxConnection';

export const ICMS_TAXES_QUERY = gql`
  query icmsTaxes(
    $after: String
    $before: String
    $pageSize: Int
    $maximumLimit: Int
  ) {
    icmsTaxes(
      after: $after
      before: $before
      pageSize: $pageSize
      maximumLimit: $maximumLimit
    ) {
      entries {
        id
        icmsCountryState
        icmsConsumerUnitConsumptionClass
        icmsValidityStartDate
        icmsValidityEndDate
        icmsRangesFee {
          consumptionRangePercentage
          consumptionKwhStartRange
          consumptionKwhEndRange
        }
      }
      beforeCursor
      afterCursor
    }
  }
`;
