import { useCallback, useEffect } from 'react';

import { useParams } from 'react-router';

import { has } from 'lodash';

import { jsPDF } from 'jspdf';

import html2canvas from 'html2canvas';

import { Button } from 'ui';
import useToastContext from 'ui/hooks/useToast';
import { ToastProps } from 'ui/contexts/overlay/Toast';
import { useLoading } from 'ui/contexts/overlay/Loading';

import { ApolloError, useLazyQuery } from '@apollo/client';

import { EconomyReportData } from 'economyReport/model/economyReport';
import { ECONOMY_REPORT_QUERY } from 'economyReport/graphql/economyReportQuery';
import FirstPageEconomyReport from 'economyReport/components/FirstPageEconomyReport';
import { EconomyReportPrintOptions } from 'economyReport/utils/EconomyReportPrintOptions';

const FETCH_ERROR_TOAST: ToastProps = {
  variant: 'danger',
  title: 'Algo deu errado!',
  text: 'Não foi possível carregar o Relatório de Economia',
};

const DOWNLOAD_ERROR_TOAST: ToastProps = {
  variant: 'danger',
  title: 'Algo deu errado!',
  text: 'Houve um erro ao tentar realizar o download do Relatório de Economia.',
};

const DOWNLOAD_SUCCESS_TOAST: ToastProps = {
  title: 'Sucesso!',
  variant: 'primary',
  text: 'Sucesso ao realizar o download do Relatório de Economia.',
};

export type EconomyReportDataProps = {
  economyReportData?: EconomyReportData;
};

export default function EconomyReport() {
  const { addToast } = useToastContext();
  const { id } = useParams<{ id: string }>();
  const { showLoading, closeLoading, LoadingOverlay } = useLoading();

  const generatePDFByHTML = useCallback(
    async (economyReportData: EconomyReportData) => {
      if (!economyReportData) return;

      window.scrollTo(0, 0);

      const firstPage = await html2canvas(
        document.querySelector('#economy-report-first-page') as HTMLElement,
        {
          scale: 8,
          imageTimeout: 0,
          onclone: (document) => EconomyReportPrintOptions(document),
        }
      );

      const doc = new jsPDF({
        unit: 'mm',
        format: 'a4',
        compress: true,
        orientation: 'p',
      });

      doc.addImage(firstPage, '', 0, 0, 210, 297, 'FirstPage');

      doc.save(
        'RE-' +
          economyReportData?.consumerUnitPowerDistributionUnitIdentifier +
          '-' +
          economyReportData?.billReferenceDate
      );
    },
    []
  );

  const [
    economyReportDataQuery,
    {
      data: economyReportData,
      error: fetchEconomyReportDataError,
      loading: fetchEconomyReportDataLoading,
    },
  ] = useLazyQuery<EconomyReportDataProps>(ECONOMY_REPORT_QUERY, {
    variables: {
      id: id,
    },
    onError(error: ApolloError) {
      if (has(error.graphQLErrors[0], 'message')) {
        addToast(DOWNLOAD_ERROR_TOAST);
      }
    },
  });

  const isLoading = !!fetchEconomyReportDataLoading;

  useEffect(() => {
    economyReportDataQuery();
  }, [economyReportDataQuery, id]);

  useEffect(() => {
    if (fetchEconomyReportDataError) {
      addToast(FETCH_ERROR_TOAST);
    }
  }, [addToast, fetchEconomyReportDataError]);

  useEffect(() => {
    if (isLoading) {
      return showLoading();
    }

    closeLoading();
  }, [isLoading, showLoading, closeLoading]);

  const handleDownload = useCallback(() => {
    if (!!economyReportData) {
      generatePDFByHTML(
        economyReportData?.economyReportData || ({} as EconomyReportData)
      );

      addToast(DOWNLOAD_SUCCESS_TOAST);
    } else {
      addToast(DOWNLOAD_ERROR_TOAST);
    }
  }, [economyReportData, addToast, generatePDFByHTML]);

  return (
    <div
      className="flex flex-col w-full items-center justify-start bg-gray-dark600 py-6 gap-6"
      style={{
        paddingBottom: '6.5rem',
      }}
    >
      <LoadingOverlay />
      <FirstPageEconomyReport
        economyReportData={economyReportData?.economyReportData}
      />
      <Button
        type="button"
        onClick={handleDownload}
        className="fixed bottom-0 w-full flex items-center justify-center text-xl z-50"
        style={{
          padding: '1.5rem 2rem',
          borderRadius: 0,
        }}
      >
        CLIQUE PARA BAIXAR
      </Button>
    </div>
  );
}
