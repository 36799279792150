import { gql } from '@apollo/client';

import { GenerationUnit } from 'generationUnits/models/generationUnit';

interface GenerationUnitConnection {
  entries: GenerationUnit[];
  afterCursor: string | null;
  beforeCursor: string | null;
}

export interface GenerationUnitsWithoutContactGroupsList {
  listGenerationUnitsWithoutContactGroups: GenerationUnitConnection;
}

export const GenerationUnitsWithoutContactGroupsTypename =
  'GenerationUnitConnection';

export const GENERATION_UNITS_WITHOUT_GROUP_AND_CONTACTS_QUERY = gql`
  query listGenerationUnitsWithoutContactGroups(
    $after: String
    $before: String
    $pageSize: Int
    $maximumLimit: Int
  ) {
    listGenerationUnitsWithoutContactGroups(
      after: $after
      before: $before
      pageSize: $pageSize
      maximumLimit: $maximumLimit
    ) {
      entries {
        id
        generationUnitLegalName
        generationUnitCapacityFactorAverage
        generationUnitEnergySource
        generationUnitAddressState
        powerDistributionUnitCredentialsUser
        generationUnitPowerCapacity
        cooperative {
          cooperativeLegalName
        }
        financialAccount {
          financialAccountLegalName
        }
        powerDistributionUnit {
          powerDistributionUnitLegalName
        }
        commercialAgreements {
          id
          commercialAgreementStartValidity
          commercialAgreementHasPisCofins
          commercialAgreementHasExtraRemuneration
          commercialAgreementReceiptRule
          commercialAgreementLeaseValue
          commercialAgreementOemValue
          commercialAgreementHasOem
          commercialAgreementDiscountMin
          commercialAgreementDiscountMax
          commercialAgreementBaseCostPerCreditIcms
          commercialAgreementBaseCostPerCredit
        }
      }
      beforeCursor
      afterCursor
    }
  }
`;
