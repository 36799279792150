import { useEffect, useState } from 'react';

import { omit } from 'lodash';

import { Control, Controller, FieldError, UseFormRegister } from 'react-hook-form';

import TextField from 'ui/form/TextField';
import RadioField from 'ui/form/RadioField';
import SelectField from 'ui/form/SelectField';
import { Tooltip } from 'ui/components/tooltip';
import { Option } from 'ui/components/form/SelectInput';
import CountryStateSelectField from 'ui/form/CountryStateSelectField';

import { BankSelect } from 'banks/models/bank';

import {
  FinancialAccountType,
  FinancialAccountEntityType,
} from 'financialAccounts/models/financialAccounts';

export type FormFields = {
  bankId: number;
  financialAccountBankAg: string;
  financialAccountBankCc: string;
  financialAccountLegalName: string;
  financialAccountDocumentId: string;
  financialAccountAddressCity: string;
  financialAccountAddressState: string;
  financialAccountAddressStreet: string;
  financialAccountAddressDistrict: string;
  financialAccountAddressPostalCode: string;
  financialAccountAddressComplement: string;
  financialAccountType: FinancialAccountType;
  financialAccountEntityType: FinancialAccountEntityType;
  financialAccountRespCpf: string;
  financialAccountRespName: string;
  financialAccountTelephone: string;
  financialAccountPriceRange: string;
  financialAccountBusinessType: string;
  financialAccountPhysicalProducts: boolean;
  financialAccountAutomaticTransfer: boolean;
};

type Field =
  | 'bankId'
  | 'financialAccountType'
  | 'financialAccountBankAg'
  | 'financialAccountBankCc'
  | 'financialAccountRespCpf'
  | 'financialAccountRespName'
  | 'financialAccountLegalName'
  | 'financialAccountTelephone'
  | 'financialAccountEntityType'
  | 'financialAccountDocumentId'
  | 'financialAccountPriceRange'
  | 'financialAccountAddressCity'
  | 'financialAccountAddressState'
  | 'financialAccountBusinessType'
  | 'financialAccountAddressStreet'
  | 'financialAccountAddressDistrict'
  | 'financialAccountPhysicalProducts'
  | 'financialAccountAddressPostalCode'
  | 'financialAccountAddressComplement'
  | 'financialAccountAutomaticTransfer';

export const FORM_FIELDS: Field[] = [
  'bankId',
  'financialAccountType',
  'financialAccountBankAg',
  'financialAccountBankCc',
  'financialAccountLegalName',
  'financialAccountEntityType',
  'financialAccountDocumentId',
  'financialAccountAddressCity',
  'financialAccountAddressState',
  'financialAccountAddressStreet',
  'financialAccountAddressDistrict',
  'financialAccountAddressPostalCode',
  'financialAccountAddressComplement',
  'financialAccountRespCpf',
  'financialAccountRespName',
  'financialAccountTelephone',
  'financialAccountPriceRange',
  'financialAccountBusinessType',
  'financialAccountPhysicalProducts',
  'financialAccountAutomaticTransfer',
];

export type FormErrors = {
  bankId?: FieldError;
  financialAccountType?: FieldError;
  financialAccountBankAg?: FieldError;
  financialAccountBankCc?: FieldError;
  financialAccountLegalName?: FieldError;
  financialAccountEntityType?: FieldError;
  financialAccountDocumentId?: FieldError;
  financialAccountAddressCity?: FieldError;
  financialAccountAddressState?: FieldError;
  financialAccountAddressStreet?: FieldError;
  financialAccountAddressDistrict?: FieldError;
  financialAccountAddressComplement?: FieldError;
  financialAccountAddressPostalCode?: FieldError;
  financialAccountRespCpf?: FieldError;
  financialAccountRespName?: FieldError;
  financialAccountTelephone?: FieldError;
  financialAccountPriceRange?: FieldError;
  financialAccountBusinessType?: FieldError;
  financialAccountPhysicalProducts?: FieldError;
  financialAccountAutomaticTransfer?: FieldError;
};

const FIELD_PATTERN_REFERENCE_LINK =
  'https://nexpedia.notion.site/O-que-s-o-as-contas-financeiras-do-Intranex-v2-eef9dee50afd430da0c9b840133e47cd';

const FINANCIAL_ACCOUNTS_ENTITY_TYPE_OPTIONS = [
  {
    label: 'Jurídica',
    value: FinancialAccountEntityType.COMPANY,
  },
  {
    label: 'Física',
    value: FinancialAccountEntityType.INDIVIDUAL,
  },
];

const FINANCIAL_ACCOUNTS_TYPE_OPTIONS = [
  {
    label: 'Conta Corrente',
    value: FinancialAccountType.CHECKING,
  },
  {
    label: 'Conta Poupança',
    value: FinancialAccountType.SAVINGS,
  },
];

export default function UserFormFields({
  banks,
  errors,
  control,
  register,
  financialAccountEntityTypeDisabled,
}: {
  banks: BankSelect[];
  errors: FormErrors;
  control: Control<FormFields>;
  register: UseFormRegister<FormFields>;
  financialAccountEntityTypeDisabled?: boolean;
}) {
  const [
    showFieldPatternReferenceLinkTooltip,
    setShowFieldPatternReferenceLinkTooltip,
  ] = useState(false);

  const [bankOptions, setBankOptions] = useState<Option<number>[]>([]);

  useEffect(
    () =>
      setBankOptions(
        (banks instanceof Array &&
          banks.map((bank) => ({
            key: bank.id,
            value: bank.bankName,
          }))) ||
          []
      ),
    [banks]
  );

  return (
    <div className="flex flex-col w-full gap-4">
      <div className="grid grid-cols-3 gap-4">
        <div className="col-start-1">
          <Controller
            name="financialAccountEntityType"
            control={control}
            rules={{ required: 'Campo obrigatório' }}
            render={({ field }) => (
              <RadioField
                required
                {...omit(field, 'ref')}
                error={errors.financialAccountEntityType?.message}
                label="Entidade"
                id="financialAccountEntityType"
                disabled={financialAccountEntityTypeDisabled}
                options={FINANCIAL_ACCOUNTS_ENTITY_TYPE_OPTIONS}
              />
            )}
          />
        </div>
        <div className="col-start-1">
          <TextField
            required
            error={errors.financialAccountLegalName?.message}
            label="Nome"
            id="financialAccountLegalName"
            {...register('financialAccountLegalName', {
              required: 'Campo obrigatório',
            })}
            placeholder="Ex. Dois Vizinhos"
          />
        </div>
        <div className="col-start-2">
          <TextField
            required
            error={errors.financialAccountDocumentId?.message}
            mask="document"
            label="Documento"
            id="financialAccountDocumentId"
            {...register('financialAccountDocumentId', {
              required: 'Campo obrigatório',
            })}
            placeholder="Ex. 28.054.303/0001-06"
          />
        </div>

        <div className="col-start-1">
          <p className="text-gray-dark400 font-bold text-sm pt-4 pb-4">
            Endereço da conta financeira
          </p>
        </div>
        <div className="col-start-1">
          <TextField
            required
            error={errors.financialAccountAddressPostalCode?.message}
            label="CEP"
            mask="postalCode"
            id="financialAccountAddressPostalCode"
            {...register('financialAccountAddressPostalCode', {
              required: 'Campo obrigatório',
            })}
            placeholder="81000000"
            minLength={9}
            maxLength={9}
          />
        </div>
        <div className="col-start-2">
          <Controller
            name="financialAccountAddressState"
            control={control}
            rules={{ required: 'Campo obrigatório' }}
            render={({ field }) => (
              <CountryStateSelectField
                required
                {...omit(field, 'ref')}
                error={errors.financialAccountAddressState?.message}
                label="Estado"
                id="financialAccountAddressState"
              />
            )}
          />
        </div>
        <div className="col-start-3">
          <TextField
            required
            error={errors.financialAccountAddressCity?.message}
            label="Cidade"
            id="financialAccountAddressCity"
            {...register('financialAccountAddressCity', {
              required: 'Campo obrigatório',
            })}
            placeholder="Ex.: Curitiba"
          />
        </div>
        <div className="col-start-1">
          <TextField
            required
            error={errors.financialAccountAddressDistrict?.message}
            label="Bairro"
            id="financialAccountAddressDistrict"
            {...register('financialAccountAddressDistrict', {
              required: 'Campo obrigatório',
            })}
            placeholder="Ex.: Centro"
          />
        </div>
        <div className="col-start-2">
          <TextField
            required
            error={errors.financialAccountAddressStreet?.message}
            label="Endereço"
            id="financialAccountAddressStreet"
            {...register('financialAccountAddressStreet', {
              required: 'Campo obrigatório',
            })}
            placeholder="Ex.: Comendador Franco 777"
          />
        </div>
        <div className="col-start-3">
          <TextField
            error={errors.financialAccountAddressComplement?.message}
            label="Complemento"
            id="financialAccountAddressComplement"
            {...register('financialAccountAddressComplement', {
              required: false,
            })}
            placeholder="Ex.: Sala 1"
          />
        </div>
      </div>
      <div className="grid grid-cols-3 gap-4">
        <div className="col-start-1">
          <p className="text-gray-dark400 font-bold text-sm pt-4 pb-4">
            Dados bancários
          </p>
        </div>
        <div className="col-start-1">
          <Controller
            control={control}
            name="financialAccountType"
            rules={{ required: 'Campo obrigatório' }}
            render={({ field }) => (
              <RadioField
                required
                {...omit(field, 'ref')}
                label="Tipo de conta"
                id="financialAccountType"
                options={FINANCIAL_ACCOUNTS_TYPE_OPTIONS}
                error={errors.financialAccountType?.message}
                disabled={financialAccountEntityTypeDisabled}
              />
            )}
          />
        </div>
      </div>
      <div className="grid grid-cols-4 gap-4">
        <div className="col-start-1">
          <Controller
            name="bankId"
            control={control}
            rules={{ required: 'Campo obrigatório' }}
            render={({ field }) => (
              <SelectField
                required
                id="bankId"
                label="Banco"
                options={bankOptions}
                {...omit(field, 'ref')}
                error={errors.bankId?.message}
                emptyOptionLabel="Selecione o Banco"
                disabled={financialAccountEntityTypeDisabled}
              />
            )}
          />
        </div>
        <div className="col-start-2">
          <TextField
            required
            label="Conta"
            id="financialAccountBankCc"
            placeholder="Ex.: 999999999-D"
            disabled={financialAccountEntityTypeDisabled}
            error={errors.financialAccountBankCc?.message}
            onFocusCapture={() => {
              setShowFieldPatternReferenceLinkTooltip(true);
            }}
            {...register('financialAccountBankCc', {
              required: 'Campo obrigatório',
            })}
          />
        </div>
        <div className="col-start-3">
          <TextField
            required
            label="Agência"
            placeholder="Ex.: 9999"
            id="financialAccountBankAg"
            disabled={financialAccountEntityTypeDisabled}
            error={errors.financialAccountBankAg?.message}
            onFocusCapture={() => {
              setShowFieldPatternReferenceLinkTooltip(true);
            }}
            {...register('financialAccountBankAg', {
              required: 'Campo obrigatório',
            })}
          />
          <Tooltip
            arrowPosition="left"
            placement="right-end"
            opened={showFieldPatternReferenceLinkTooltip}
            closeTooltip={() => setShowFieldPatternReferenceLinkTooltip(false)}
            label={
              <>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={FIELD_PATTERN_REFERENCE_LINK}
                >
                  <span className="items-center justify-start underline font-bold">
                    Atenção! Verifique aqui
                  </span>
                </a>
                <span>
                  {' '}
                  o padrão esperado <br /> de preenchimento dos dados bancários.
                </span>
              </>
            }
          />
        </div>
      </div>
    </div>
  );
}
