import { Button } from 'ui';
import { Dashboard, DashboardMainHeaderForm } from 'dashboard/components/dashboard';
import PowerDistributionUnitFormFields, {
  FormFields,
} from 'powerDistributionUnits/components/form/PowerDistributionUnitFields';

import { Page } from 'dashboard/components/dashboard/Breadcrumbs';

import { useForm, SubmitHandler, ErrorOption } from 'react-hook-form';
import { ApolloError, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { setFormError } from 'utils/form';
import {
  PowerDistributionUnitCreated,
  PowerDistributionUnitMutationVariables,
  POWER_DISTRIBUTION_UNIT_CREATE_MUTATION,
} from 'powerDistributionUnits/graphql/powerDistributionUnitCreateMutation';
import {
  PowerDistributionUnitList,
  powerDistributionUnitTypename,
  POWER_DISTRIBUTION_UNIT_LIST_QUERY,
} from 'powerDistributionUnits/graphql/powerDistributionUnitListQuery';
import useToastContext from 'ui/hooks/useToast';
import { useHistory } from 'react-router';
import { ToastProps } from 'ui/contexts/overlay/Toast';
import { useLoading } from 'ui/contexts/overlay/Loading';
import { useEffect } from 'react';
import { has } from 'lodash';

const DASHBOARD_POWER_DISTRIBUTION_UNITS_ROUTE =
  '/dashboard/business-entities/power-distribution-units';

const BREADCRUMB_PAGES: Page[] = [
  {
    name: 'Concessionárias',
    route: DASHBOARD_POWER_DISTRIBUTION_UNITS_ROUTE,
    current: false,
  },
  {
    name: 'Nova Concessionária',
    route: null,
    current: true,
  },
];
const TITLE = 'Nova Concessionária';
const CREATE_ERROR_TOAST: ToastProps = {
  text: 'Houve um erro ao tentar criar concessionária',
  title: 'Algo deu errado!',
  variant: 'danger',
};

const CREATE_SUCCESS_TOAST: ToastProps = {
  title: 'Sucesso!',
  variant: 'primary',
  text: 'Sucesso ao cadastrar a Concessionária.',
};

export default function CreatePowerDistributionUnitsPage() {
  const { push } = useHistory();
  const { addToast } = useToastContext();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    control,
    formState: { errors },
  } = useForm<FormFields>();
  const { showLoading, closeLoading, LoadingOverlay } = useLoading();

  const [powerDistributionUnitCreateMutation, { loading }] = useMutation<
    PowerDistributionUnitCreated,
    PowerDistributionUnitMutationVariables
  >(POWER_DISTRIBUTION_UNIT_CREATE_MUTATION, {
    onError(error: ApolloError) {
      if (has(error.graphQLErrors[0], 'details')) {
        addToast(CREATE_ERROR_TOAST);
      }

      setFormError(
        error,
        (field: string, error: ErrorOption) => {
          setError(field as keyof FormFields, error);

          setTimeout(() => clearErrors(), 2500);
        },
        t
      );
    },
    onCompleted() {
      addToast(CREATE_SUCCESS_TOAST);
      push(DASHBOARD_POWER_DISTRIBUTION_UNITS_ROUTE);
    },
  });

  const onSubmit: SubmitHandler<FormFields> = (powerDistributionUnitCreateInput) =>
    powerDistributionUnitCreateMutation({
      variables: {
        powerDistributionUnitCreateInput: {
          ...powerDistributionUnitCreateInput,
          powerDistributionUnitDocumentId:
            powerDistributionUnitCreateInput.powerDistributionUnitDocumentId.replace(
              /[^0-9]/g,
              ''
            ),
        },
      },
      update(cache, { data }) {
        const existingData = cache.readQuery<PowerDistributionUnitList>({
          query: POWER_DISTRIBUTION_UNIT_LIST_QUERY,
        });
        cache.writeQuery({
          query: POWER_DISTRIBUTION_UNIT_LIST_QUERY,
          data: {
            powerDistributionUnits: {
              __typename: powerDistributionUnitTypename,
              afterCursor: existingData?.powerDistributionUnits.afterCursor || null,
              beforeCursor:
                existingData?.powerDistributionUnits.beforeCursor || null,
              entries: [
                data?.powerDistributionUnitCreate,
                ...(existingData?.powerDistributionUnits.entries || []),
              ],
            },
          },
        });
      },
    });

  useEffect(() => {
    if (loading) {
      showLoading();
      return;
    }

    closeLoading();
  }, [loading, showLoading, closeLoading]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay />
      <Dashboard
        dashboardHeader={<></>}
        dashboardMainHeaderTitle={
          <DashboardMainHeaderForm breadcrumbPages={BREADCRUMB_PAGES} title={TITLE}>
            <Button type="submit" size="sm" disabled={loading}>
              Salvar
            </Button>
          </DashboardMainHeaderForm>
        }
      >
        <PowerDistributionUnitFormFields
          errors={errors}
          register={register}
          control={control}
        />
      </Dashboard>
    </form>
  );
}
