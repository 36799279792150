import { useLoading } from 'ui/contexts/overlay/Loading';
import { Button } from 'ui';
import { ModalFormProps, Variant } from 'ui/models/overlay';
import CsvButton from 'apportionmentCycles/components/apportionmentCycleConsumerUnits/CsvButton';
import { useCSVDownloader } from 'react-papaparse';
import { useEffect, useState } from 'react';
import {
  DangerMessageProps,
  handleValidateDangerModal,
} from 'apportionmentCycles/utils';

export type DangerProps = {
  title: string;
  variant: Variant;
  text: string;
};

type Props = {
  onClickReplace: () => void;
  onClickContinue: () => void;
  onClickCloseModal: () => void;
  ModalOverlay: (any: ModalFormProps) => JSX.Element;
  danger: DangerProps;
  errors: DangerMessageProps[];
};

export default function DangerApportiomentCycleConsumerUnit({
  ModalOverlay,
  onClickReplace,
  onClickContinue,
  onClickCloseModal,
  errors,
  danger,
}: Props) {
  const { CSVDownloader, Type } = useCSVDownloader();
  const { LoadingOverlay } = useLoading();

  const [showReplaceButton, setShowReplaceButton] = useState(false);
  const [showContinueButton, setShowContinueButton] = useState(false);

  useEffect(() => {
    setShowReplaceButton(handleValidateDangerModal(errors).hasReplace);
    setShowContinueButton(
      handleValidateDangerModal(errors).hasWarning &&
        !handleValidateDangerModal(errors).hasError
    );
  }, [errors]);

  return (
    <ModalOverlay title={danger.title} variant={danger.variant}>
      <div className="grid grid-cols-12">
        <div className="col-start-1 col-end-12">
          <p className="text-gray-dark400 font-small text-sm">{danger.text}</p>
        </div>
      </div>
      <div className="flex flex-row-reverse mt-8 gap-4">
        {!showReplaceButton && (
          <CSVDownloader
            type={Type.Link}
            filename={'apportionment-errors'}
            bom={true}
            config={{
              delimiter: ';',
            }}
            data={errors}
          >
            <CsvButton variant={'primaryRed'} size="sm">
              <p className="font-semibold">Baixar Log</p>
            </CsvButton>
          </CSVDownloader>
        )}

        {showContinueButton && (
          <Button size="md" variant="primaryGray" onClick={onClickContinue}>
            <p className="font-medium">Continuar</p>
          </Button>
        )}

        {showReplaceButton && (
          <Button size="md" variant="primaryRed" onClick={onClickReplace}>
            <p className="font-medium">Aceitar</p>
          </Button>
        )}

        <Button variant="primaryGray" size="md" onClick={onClickCloseModal}>
          <p className="font-medium">Cancelar</p>
        </Button>
      </div>
      <LoadingOverlay />
    </ModalOverlay>
  );
}
