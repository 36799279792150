import { gql } from '@apollo/client';
import { ApportionmentCycleConsumerUnit } from 'apportionmentCycles/models/apportionmentCycleConsumerUnit';

export interface ApportionmentCycleConsumerUnitCreateInput {
  apportionmentCycleConsumerUnitReferenceMonth: string;
  generationUnitId: number | undefined;
  apportionmentCycleConsumerUnitFileMetadata?: File | undefined;
  ignoreWarnings: boolean;
  operation: string;
}

export interface ApportionmentCycleConsumerUnitMutationVariables {
  apportionmentCycleConsumerUnitFileMetadata: File | undefined;
  apportionmentCycleConsumerUnitCreateInput: ApportionmentCycleConsumerUnitCreateInput;
}

export interface ApportionmentCycleConsumerUnitCreated {
  apportionmentCycleConsumerUnitFileMetadata: File | undefined;
  apportionmentCycleConsumerUnitCreated: ApportionmentCycleConsumerUnit;
}

export const APPORTIONMENT_CYCLE_CONSUMER_UNIT_CREATE_MUTATION = gql`
  mutation apportionmentCycleConsumerUnitCreate(
    $apportionmentCycleConsumerUnitFileMetadata: Upload!
    $apportionmentCycleConsumerUnitCreateInput: ApportionmentCycleConsumerUnitCreateInput
  ) {
    apportionmentCycleConsumerUnitCreate(
      apportionmentCycleConsumerUnitFileMetadata: $apportionmentCycleConsumerUnitFileMetadata
      input: $apportionmentCycleConsumerUnitCreateInput
    ) {
      apportionmentCycleConsumerUnitResults {
        consumerUnitId
        errors {
          message
          type
        }
      }
    }
  }
`;
