import Table from 'ui/components/table/Table';
import TableBody from 'ui/components/table/TableBody';
import TableHead from 'ui/components/table/TableHead';
import TableHeadColumn from 'ui/components/table/TableHeadColumn';

import ChargesTableItem from './ChargesTableItem';

import { ChargeId, Charges } from 'charges/models/charges';

import { ChargesUpdateModalData } from '../modal/ChargesUpdateModal';

type Props = {
  charges: Charges[];
  disableEditButton: boolean;
  onClickEditButton: ({
    chargeId,
    consumerUnit,
    powerDistributionUnitBillData,
  }: ChargesUpdateModalData) => void;
  onClickSendEmailButton: (chargeId: ChargeId) => void;
};

export default function ChargesTable({
  charges,
  disableEditButton,
  onClickEditButton,
  onClickSendEmailButton,
}: Props) {
  return (
    <Table>
      <TableHead>
        <TableHeadColumn>MÊS</TableHeadColumn>
        <TableHeadColumn>COOPERADO</TableHeadColumn>
        <TableHeadColumn>UC</TableHeadColumn>
        <TableHeadColumn>VALOR</TableHeadColumn>
        <TableHeadColumn>MULTA/JUROS</TableHeadColumn>
        <TableHeadColumn>CRIAÇÃO</TableHeadColumn>
        <TableHeadColumn>VENCIMENTO</TableHeadColumn>
        <TableHeadColumn>PAGAMENTO</TableHeadColumn>
        <TableHeadColumn>STATUS</TableHeadColumn>
        <TableHeadColumn>ECONOMIA</TableHeadColumn>
        <TableHeadColumn className="text-right">FATURA</TableHeadColumn>
        <TableHeadColumn>AÇÃO</TableHeadColumn>
      </TableHead>
      <TableBody>
        {charges.map((charge: Charges) => (
          <ChargesTableItem
            key={charge.id}
            charges={charge}
            onClickEditButton={onClickEditButton}
            disableEditButton={disableEditButton}
            onClickSendEmailButton={onClickSendEmailButton}
          />
        ))}
      </TableBody>
    </Table>
  );
}
