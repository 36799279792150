import { gql } from '@apollo/client';
import {
  TariffFlagModalityModalityFlagType,
  TariffFlagModality,
} from 'tariffFlagTariffs/models/tariffFlagModality';

export interface TariffFlagModalityCreateInput {
  tariffFlagModalityModalityFlag: TariffFlagModalityModalityFlagType;
  tariffFlagModalityValidityDate: string;
}

export interface TariffFlagModalityMutationVariables {
  tariffFlagModalityCreateInput: TariffFlagModalityCreateInput;
}

export interface TariffFlagModalityCreated {
  tariffFlagModalityCreate: TariffFlagModality;
}

export const TARIFF_FLAG_MODALITY_CREATE_MUTATION = gql`
  mutation tariffFlagModalityCreate(
    $tariffFlagModalityCreateInput: TariffFlagModalityCreateInput!
  ) {
    tariffFlagModalityCreate(input: $tariffFlagModalityCreateInput) {
      id
      tariffFlagModalityModalityFlag
      tariffFlagModalityValidityDate
      tariffFlagTariff {
        tariffFlagTariffFee
        tariffFlagTariffModalityFlag
        tariffFlagTariffValidityPeriod
      }
    }
  }
`;
