import { useCallback, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Dashboard, DashboardMainHeaderForm } from 'dashboard/components/dashboard';
import { Pagination } from 'dashboard/components/table';
import { useLoading } from 'ui/contexts/overlay/Loading';
import useToastContext from 'ui/hooks/useToast';
import {
  CUSTOM_APPORTIONMENT_CYCLE_CONSUMER_UNITS_QUERY,
  CustomApportionmentCycleConsumerUnitsList,
} from 'customApportionmentCycleConsumerUnit/graphql/customApportionmentCycleConsumerUnitsQuery';
import { CustomApportionmentCycleConsumerUnit } from 'customApportionmentCycleConsumerUnit/models/customApportionmentCycleCosumerUnits';
import { ToastProps } from 'ui/contexts/overlay/Toast';
import { INITIAL_QUERY_STATE_CONFIG } from 'graphql/apollo/config';
import CustomApportionmentCycleConsumerUnitsTable from 'customApportionmentCycleConsumerUnit/components/table/CustomApportionmentCycleConsumerUnitsTable';
import AddButton from 'dashboard/components/dashboard/AddButton';
import CreateApportionmentCycleConsumerUnit from 'apportionmentCycles/components/apportionmentCycleConsumerUnits/modal/CreateApportionmentCycleConsumerUnit';
import { useModal } from 'ui/contexts/overlay/Modal';
import DangerApportionmentCycleConsumerUnit, {
  DangerProps,
} from 'apportionmentCycles/components/apportionmentCycleConsumerUnits/modal/DangerApportionmentCycleConsumerUnit';
import {
  DangerMessageProps,
  handleValidateDangerModal,
} from 'apportionmentCycles/utils';

import { SubmitHandler, useForm } from 'react-hook-form';

import ApportionmentCycleConsumerUnitFormFilters, {
  FormFilters,
} from 'apportionmentCycles/components/apportionmentCycleConsumerUnits/form/ApportiomentCycleConsumerUnitFormFilters';

import {
  GenerationUnitsSelectList,
  GENERATION_UNITS_SELECT_QUERY,
  GENERATION_UNITS_SELECT_QUERY_VARIABLES,
} from 'generationUnits/graphql/generationUnitsSelectQuery';

import { formatDateToSubmitDateString } from 'utils/form';

type ListState = {
  customApportionmentCycleConsumerUnits: CustomApportionmentCycleConsumerUnit[];
  hasNextPage: boolean;
  hasPreviousPage: boolean;
};

const LIST_ERROR_TOAST: ToastProps = {
  title: 'Algo deu errado!',
  variant: 'danger',
  text: 'Não foi possível carregar a lista de Rateios',
};

const CREATE_ERROR_MODAL: DangerProps = {
  title: 'ERRO! Verifique para continuar',
  variant: 'danger',
  text: 'Foram detectados erros no rateio importado, portanto, não será possível cadastrá-lo. Faça o download do log de erros e importe novamente o arquivo.',
};

const CREATE_ONLY_WARNING_MODAL: DangerProps = {
  title: 'AVISO! Verifique para continuar',
  variant: 'warning',
  text: 'Foram detectados possíveis inconformidades no rateio importado. Você poderá continuar mesmo assim ou importar novamente o arquivo. Baixe o log de erros para visualizar.',
};

const CREATE_REPLACE_WARNING_MODAL: DangerProps = {
  title: 'AVISO! Verifique para continuar',
  variant: 'warning',
  text: 'Já existe um rateio cadastrado neste ciclo para esta usina. Deseja substituir o rateio já cadastrado?',
};

export default function CustomApportionmentCycleConsumerUnitsPage() {
  const { addToast } = useToastContext();
  const { LoadingOverlay, showLoading, closeLoading } = useLoading();
  const [listState, setListState] = useState<ListState>({
    customApportionmentCycleConsumerUnits: [],
    hasNextPage: false,
    hasPreviousPage: false,
  });

  const {
    ModalOverlay,
    closeConfirm: modalCloseConfirm,
    showConfirm: modalShowConfirm,
  } = useModal();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormFilters>();

  const [formRefModal, setFormRefModal] = useState<HTMLFormElement | null>(null);
  const [ignoreWarnings, setIgnoreWarnings] = useState(false);
  const [dangerModalType, setDangerModalType] = useState(CREATE_ERROR_MODAL);
  const [operation, setOperation] = useState<'INSERT' | 'REPLACE'>('INSERT');

  const [
    showDangerApportionmentCycleConsumerUnitModal,
    setShowDangerApportionmentCycleConsumerUnitModal,
  ] = useState(false);

  const [
    showApportionmentCycleConsumerUnitModal,
    setShowApportionmentCycleConsumerUnitModal,
  ] = useState(false);

  const [errorResult, setErrorsResult] = useState<DangerMessageProps[]>([]);

  const [
    customApportionmentCycleConsumerUnitsList,
    { data, loading, error, refetch },
  ] = useLazyQuery<CustomApportionmentCycleConsumerUnitsList>(
    CUSTOM_APPORTIONMENT_CYCLE_CONSUMER_UNITS_QUERY,
    INITIAL_QUERY_STATE_CONFIG
  );

  const [
    generationUnitsSelect,
    {
      data: generationUnits,
      loading: loadingGenerationUnits,
      refetch: refetchGenerationUnits,
    },
  ] = useLazyQuery<GenerationUnitsSelectList>(
    GENERATION_UNITS_SELECT_QUERY,
    GENERATION_UNITS_SELECT_QUERY_VARIABLES
  );

  const showDangerModal = (errors: DangerMessageProps[]) => {
    setErrorsResult(errors);

    handleValidateDangerModal(errors).hasError &&
      setDangerModalType(CREATE_ERROR_MODAL);

    if (
      handleValidateDangerModal(errors).hasWarning &&
      !handleValidateDangerModal(errors).hasError
    ) {
      setIgnoreWarnings(true);
      setDangerModalType(CREATE_ONLY_WARNING_MODAL);
    }

    if (handleValidateDangerModal(errors).hasReplace) {
      setOperation('REPLACE');
      setDangerModalType(CREATE_REPLACE_WARNING_MODAL);
    }

    modalShowConfirm();
    setShowDangerApportionmentCycleConsumerUnitModal(true);
  };

  const formRefHandler = (formRef: HTMLFormElement | null) => {
    setFormRefModal(formRef);
  };

  const isError = error;

  useEffect(() => {
    if (isError) {
      addToast(LIST_ERROR_TOAST);
    }
  }, [addToast, isError]);

  const isLoading = loading || loadingGenerationUnits;

  useEffect(() => {
    if (isLoading) {
      showLoading();
      return;
    }
    if (data) {
      setListState({
        customApportionmentCycleConsumerUnits:
          data.customApportionmentCycleConsumerUnits.entries,
        hasNextPage: !!data.customApportionmentCycleConsumerUnits.afterCursor,
        hasPreviousPage: !!data.customApportionmentCycleConsumerUnits.beforeCursor,
      });
    }

    closeLoading();
  }, [closeLoading, data, isLoading, showLoading]);

  const handleClickNext = () => {
    refetch &&
      refetch({
        after: data?.customApportionmentCycleConsumerUnits.afterCursor,
        before: null,
      });
  };

  const handleClickBefore = () => {
    refetch &&
      refetch({
        after: null,
        before: data?.customApportionmentCycleConsumerUnits.beforeCursor,
      });
  };

  const onClickAddButton = () => {
    setOperation('INSERT');
    setIgnoreWarnings(false);

    modalShowConfirm();
    setShowApportionmentCycleConsumerUnitModal(true);
    setShowDangerApportionmentCycleConsumerUnitModal(false);
  };

  const onClickCloseModal = () => {
    modalCloseConfirm();
    setShowApportionmentCycleConsumerUnitModal(false);
    setShowDangerApportionmentCycleConsumerUnitModal(false);
  };

  const handleSubmitModal = useCallback(() => {
    formRefModal?.dispatchEvent(
      new Event('submit', { cancelable: true, bubbles: true })
    );
  }, [formRefModal]);

  const onClickReplace = () => {
    handleSubmitModal();
  };

  const onClickContinue = () => {
    handleSubmitModal();
  };

  useEffect(() => {
    refetchGenerationUnits && refetchGenerationUnits();

    generationUnitsSelect();
  }, [generationUnitsSelect, refetchGenerationUnits]);

  useEffect(() => {
    customApportionmentCycleConsumerUnitsList();
  }, [customApportionmentCycleConsumerUnitsList, handleSubmitModal]);

  useEffect(() => {
    refetch && refetch();
  }, [refetch]);

  const onSubmit: SubmitHandler<FormFilters> = (
    customApportionmentCycleConsumerUnit
  ) => {
    !!customApportionmentCycleConsumerUnit.apportionmentCycleConsumerUnitReferenceMonth &&
      Object.assign(customApportionmentCycleConsumerUnit, {
        apportionmentCycleConsumerUnitReferenceMonth: formatDateToSubmitDateString(
          new Date(
            customApportionmentCycleConsumerUnit.apportionmentCycleConsumerUnitReferenceMonth.getFullYear(),
            customApportionmentCycleConsumerUnit.apportionmentCycleConsumerUnitReferenceMonth.getMonth(),
            1
          ).toString()
        ),
      });

    refetch({
      filters: Object.fromEntries(
        Object.entries(customApportionmentCycleConsumerUnit).filter(
          ([, value]) => !!value
        )
      ),
    });
  };

  return (
    <Dashboard
      dashboardMainHeaderTitle={
        <DashboardMainHeaderForm title="Rateios">
          <AddButton onClick={onClickAddButton} label="Novo Rateio" />
        </DashboardMainHeaderForm>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ApportionmentCycleConsumerUnitFormFilters
          errors={errors}
          control={control}
          register={register}
          isLoading={isLoading}
          generationUnits={generationUnits?.generationUnits.entries}
        />
      </form>

      <div className="rounded-lg bg-gray-background">
        {listState && (
          <>
            <CustomApportionmentCycleConsumerUnitsTable
              customApportionmentCycleConsumerUnits={
                listState.customApportionmentCycleConsumerUnits
              }
            />
            <Pagination
              onNextClick={handleClickNext}
              onPreviousClick={handleClickBefore}
              disableNext={!listState.hasNextPage || isLoading}
              disableBefore={!listState.hasPreviousPage || isLoading}
            />
          </>
        )}
      </div>

      {showApportionmentCycleConsumerUnitModal && (
        <CreateApportionmentCycleConsumerUnit
          operation={operation}
          ignoreWarnings={ignoreWarnings}
          ModalOverlay={ModalOverlay}
          formRefHandler={formRefHandler}
          showDangerModal={showDangerModal}
          onClickCloseModal={onClickCloseModal}
        />
      )}

      {showDangerApportionmentCycleConsumerUnitModal && (
        <DangerApportionmentCycleConsumerUnit
          errors={errorResult}
          danger={dangerModalType}
          ModalOverlay={ModalOverlay}
          onClickReplace={onClickReplace}
          onClickContinue={onClickContinue}
          onClickCloseModal={onClickCloseModal}
        />
      )}
      <LoadingOverlay />
    </Dashboard>
  );
}
