import { gql } from '@apollo/client';

export type CooperativeMemberDeleteMutationVariables = {
  id: number;
};

export const CooperativeMemberTypename = 'CooperativeMember';

export const COOPERATIVE_MEMBER_DELETE_MUTATION = gql`
  mutation cooperativeMemberDelete($id: ID!) {
    cooperativeMemberDelete(id: $id) {
      message
    }
  }
`;
