import React from 'react';

import BaseInput, { Props as BaseProps } from './BaseInput';

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement> & BaseProps,
  HTMLInputElement
>;

export const PasswordInput = React.forwardRef<HTMLInputElement, Props>(
  ({ autoComplete, name, type, mask, error, ...props }, ref) => {
    return (
      <BaseInput
        ref={ref}
        name={name}
        mask={mask}
        type={type}
        error={error}
        autoComplete={autoComplete}
        {...props}
      />
    );
  }
);

export default PasswordInput;
