import { useLocation } from 'react-router-dom';
import FullLogo from 'auth/assets/full-logo.svg';
import LoginIllustration from 'auth/assets/login-illustration.svg';
import { useLoading } from 'ui/contexts/overlay/Loading';

type Props = {
  children: React.ReactNode;
};

export default function Login({ children }: Props) {
  const location = useLocation();
  const { LoadingOverlay } = useLoading();
  const currentPath = location.pathname.split('/')[1];

  function handleValidateLocation() {
    switch (currentPath) {
      case 'reset-password':
        return (
          <>
            Criação de senha.
            <br />
            Cadastre uma nova.
          </>
        );
      case 'forgot-password':
        return (
          <>
            Recuperação
            <br />
            de senha.
          </>
        );
      default:
        return (
          <>
            Faça login para abrir
            <br />o painel de controle
          </>
        );
    }
  }

  return (
    <>
      <div className="relative min-h-screen w-full bg-black flex justify-center items-center relative">
        <div className="flex h-screen w-full justify-center items-center">
          <div
            className="w-full flex flex-col gap-8 justify-end items-center max-w-xl"
            style={{
              padding: '2rem 0 2rem 9rem',
            }}
          >
            <div className="w-full flex">
              <img className="w-auto" src={FullLogo} alt="INTRANEX" />
            </div>
            <div className="w-full flex justify-start items-center">
              <h1 className="text-4xl font-semibold text-white">
                {handleValidateLocation()}
              </h1>
            </div>
            <div className="w-full flex justify-start items-center">
              <div className="w-full flex justify-start items-center">
                {children}
              </div>
            </div>
          </div>
          <div className="h-screen right-0">
            <img
              className="h-screen object-cover"
              src={LoginIllustration}
              alt="ENERGY ILLUSTRATION"
            />
          </div>
        </div>
      </div>

      <LoadingOverlay />
    </>
  );
}
