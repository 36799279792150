import { gql } from '@apollo/client';

export interface CooperativeQueryVariables {
  id: string;
}

export const CooperativeTypename = 'Cooperative';

export const COOPERATIVE_QUERY = gql`
  query cooperative($id: ID!) {
    cooperative(id: $id) {
      id
      cooperativeAddressCity
      cooperativeAddressPostalCode
      cooperativeAddressState
      cooperativeAddressStreet
      cooperativeAddressDistrict
      cooperativeAddressComplement
      cooperativeDocumentId
      cooperativeLegalName
      cooperativeSubscriptionCostCents
      cooperativeHeadquarter {
        id
        cooperativeLegalName
      }
      financialAccount {
        id
        financialAccountLegalName
      }
    }
  }
`;
